import axios from "axios";
import config from "../config";
import { getUserToken } from "../utils/helper";

export const GET_USER_DROPDOWN = "GET_USER_DROPDOWN";
export const GET_USER_DROPDOWN_SUCCESS = "GET_USER_DROPDOWN_SUCCESS";
export const GET_USER_DROPDOWN_FAIL = "GET_USER_DROPDOWN_FAIL";
export const GET_USER_DROPDOWN_RESET = "GET_USER_DROPDOWN_RESET";

export const getUserDropdown = (params) => {
  return {
    type: GET_USER_DROPDOWN,
    params,
  };
};

export const getUserDropdownSuccess = (response) => {
  return {
    type: GET_USER_DROPDOWN_SUCCESS,
    response,
  };
};

export const getUserDropdownFail = (response) => {
  return {
    type: GET_USER_DROPDOWN_FAIL,
    response,
  };
};

export const getUserDropdownReset = () => {
  return {
    type: GET_USER_DROPDOWN_RESET,
  };
};

export const getUserDropdownApi = (data) => {
  return (dispatch) => {
    dispatch(getUserDropdown());
    axios
      .get(`${config.api.base_url}/user/dropdown`, {
        params: data,
        headers: {
          Authorization: "Bearer " + getUserToken(),
        },
      })
      .then((response) => {
        const getUserDropdownData = response.data;
        dispatch(getUserDropdownSuccess(getUserDropdownData));
      })
      .catch((error) => {
        const errorMsg = error.message;
        dispatch(getUserDropdownFail(errorMsg));
      });
  };
};
