import config from '../config';
import axios from 'axios';
import { getUserToken } from '../utils/helper';

export const GET_JOB_COUNTS = 'GET_JOB_COUNTS';
export const GET_JOB_COUNTS_SUCCESS = 'GET_JOB_COUNTS_SUCCESS';
export const GET_JOB_COUNTS_FAIL = 'GET_JOB_COUNTS_FAIL';
export const GET_JOB_COUNTS_RESET = 'GET_JOB_COUNTS_RESET';

export const getJobCounts = (params) => {
  return {
    type: GET_JOB_COUNTS,
    params,
  };
};

export const getJobCountsSuccess = (response) => {
  return {
    type: GET_JOB_COUNTS_SUCCESS,
    response,
  };
};

export const getJobCountsFail = (response) => {
  return {
    type: GET_JOB_COUNTS_FAIL,
    response,
  };
};

export const getJobCountsReset = () => {
  return {
    type: GET_JOB_COUNTS_RESET,
  };
};

export const getJobCountsApi = (params) => {
  return (dispatch) => {
    dispatch(getJobCounts());
    axios
      .get(`${config.api.base_url}/dashboard/job-counts`, {
        params: params,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${getUserToken()}`,
        },
      })
      .then((response) => {
        const getJobCountsData = response.data;
        dispatch(getJobCountsSuccess(getJobCountsData));
      })
      .catch((error) => {
        const errorData = error.response.data;
        dispatch(getJobCountsFail(errorData));
      });
  };
};
