import axios from "axios";
import config from "../config";

export const VERIFY_OTP = "VERIFY_OTP";
export const VERIFY_OTP_SUCCESS = "VERIFY_OTP_SUCCESS";
export const VERIFY_OTP_FAIL = "VERIFY_OTP_FAIL";
export const VERIFY_OTP_RESET = "VERIFY_OTP_RESET";

export const verifyOtp = (params) => {
  return {
    type: VERIFY_OTP,
    params,
  };
};

export const verifyOtpSuccess = (response) => {
  return {
    type: VERIFY_OTP_SUCCESS,
    response,
  };
};

export const verifyOtpFail = (response) => {
  return {
    type: VERIFY_OTP_FAIL,
    response,
  };
};

export const verifyOtpReset = () => {
  return {
    type: VERIFY_OTP_RESET,
  };
};

export const verifyOtpApi = (data) => {
  return (dispatch) => {
    dispatch(verifyOtp());
    axios
      .post(`${config.api.base_url}/auth/verify-otp`, data)
      .then((response) => {
        const verifyOtpData = response.data;
        dispatch(verifyOtpSuccess(verifyOtpData));
      })
      .catch((error) => {
        dispatch(verifyOtpFail(error.response.data));
      });
  };
};
