import React, { useEffect, useState } from "react";
import { Layout, Row, Col, Tag, Card } from "antd";
import { Wrapper } from "../../styles/Button";

import { Link, useNavigate, useParams } from "react-router-dom";
import { connect } from "react-redux";
// import { getJobApplicationsApi } from '../../actions/';

import {
  getResellerByIdApi,
  getResellerByIdReset,
} from "../../actions/getResellerByIdAction";
import moment from "moment";
import {
  capitalizeAndSplitByDash,
  checkModulePermissions,
  hasPermission,
} from "../../utils/helper";

const InventoryView = (props) => {
  const { getResellerByIdState, getResellerById, getResellerByIdReset } = props;
  const navigate = useNavigate();
  const id = useParams().id;

  const [resellerData, setResellerData] = useState();

  useEffect(() => {
    if (hasPermission("resellers", "view-resellers")) {
      getResellerById({ id });
    }
  }, []);

  useEffect(() => {
    if (getResellerByIdState.apiState === "success") {
      setResellerData(getResellerByIdState.data);
    }
  }, [getResellerByIdState.apiState]);

  //Get the number of days, weeks
  const getDays = (date) => {
    const today = new Date();
    const selectedDate = new Date(date);
    const timeDiff = Math.abs(selectedDate.getTime() - today.getTime());
    const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
    if (diffDays === 1) {
      return "Today";
    } else if (diffDays === 2) {
      return "Yesterday";
    } else if (diffDays > 2 && diffDays < 7) {
      return `${diffDays} days ago`;
    } else {
      return Math.floor(diffDays / 7) === 1
        ? `${Math.floor(diffDays / 7)} week ago`
        : `${Math.floor(diffDays / 7)} weeks ago`;
    }
  };

  //Render perks and prerequisites
  const renderTag = (value) => {
    return (
      <Tag className="customTags" title="Smart Phone">
        {value}
      </Tag>
    );
  };

  //Render the job description and job specifications

  if (resellerData)
    return (
      <>
        {hasPermission("resellers", "view-resellers") ? (
          <>
            <div className="mainContent">
              <div className="pageWrapper">
                <Wrapper>
                  <Row gutter={[30, 30]} align="top">
                    <Col xs={24}>
                      <Card className="card">
                        <Row gutter={[30, 0]}>
                          <Col span={24}>
                            <h1 className="headerheading">
                              {resellerData?.alias_name}
                            </h1>
                            <p className="para2">{resellerData?.employer}</p>
                            <Row gutter={[15, 30]}>
                              <Col xs={24} sm={24} md={12}>
                                <p className="para3 mt-5">
                                  <span>First Name</span>
                                </p>

                                <p className="para3">
                                  {resellerData?.first_name}
                                </p>
                              </Col>
                              <Col xs={24} sm={24} md={12}>
                                <p className="para3 mt-5">
                                  <span>Last Name</span>
                                </p>

                                <p className="para3">
                                  {resellerData?.last_name}
                                </p>
                              </Col>
                              <Col xs={24} sm={24} md={12}>
                                <p className="para3">
                                  <span>Email Address</span>
                                </p>

                                <p className="para3">
                                  {resellerData?.email_address}
                                </p>
                              </Col>
                              <Col xs={24} sm={24} md={12}>
                                <p className="para3">
                                  <span>Mobile Number</span>
                                </p>

                                <p className="para3">
                                  {resellerData?.mobile_number}
                                </p>
                              </Col>
                              <Col xs={24} sm={24} md={12}>
                                <p className="para3">
                                  <span>Communication Email Address</span>
                                </p>

                                <p className="para3">
                                  {resellerData?.communication_email_address}
                                </p>
                              </Col>
                              <Col xs={24} sm={24} md={12}>
                                <p className="para3">
                                  <span>Communication Mobile Number</span>
                                </p>

                                <p className="para3">
                                  {resellerData?.communication_mobile_number}
                                </p>
                              </Col>
                              <Col xs={24} sm={24} md={12}>
                                <p className="para3">
                                  <span>Pincode</span>
                                </p>

                                <p className="para3">{resellerData?.pincode}</p>
                              </Col>

                              <Col xs={24} sm={24} md={12}>
                                <p className="para3">
                                  <span>City</span>
                                </p>

                                <p className="para3">{resellerData?.city}</p>
                              </Col>
                              <Col xs={24} sm={24} md={12}>
                                <p className="para3">
                                  <span>State</span>
                                </p>

                                <p className="para3">{resellerData?.state}</p>
                              </Col>
                              <Col xs={24} sm={24} md={12}>
                                <p className="para3">
                                  <span>Country </span>
                                </p>

                                <p className="para3">{resellerData?.country}</p>
                              </Col>
                            </Row>
                            <Row gutter={[30, 30]} className="mt-5">
                              <Col xs={24} sm={24} md={12}>
                                <p className="para3">
                                  <span>PAN Number</span>
                                </p>

                                <p className="para3">
                                  {resellerData?.pan_number}
                                </p>
                              </Col>
                              <Col xs={24} sm={24} md={12}>
                                <p className="para3">
                                  <span>CIN</span>
                                </p>

                                <p className="para3">
                                  {resellerData?.cin_number}
                                </p>
                              </Col>
                              <Col xs={24} sm={24} md={12}>
                                <p className="para3">
                                  <span>GSTIN</span>
                                </p>

                                <p className="para3">
                                  {resellerData?.gstin_number}
                                </p>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Card>
                    </Col>
                  </Row>
                </Wrapper>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="mainContent">
              <div className="pageWrapper">
                <Wrapper>
                  <Row>
                    <Col xs={24} className="mt-5" align="center">
                      <img
                        style={{ maxWidth: "100%" }}
                        src="./images/empty-role.svg"
                        alt=""
                      />
                    </Col>
                    <Col xs={24} className="my-5" align="center">
                      <h1>Unauthorized to view jobs</h1>
                      <br />
                      <br />
                      <p>
                        Please Contact System Administrator for further
                        assistance
                      </p>
                    </Col>
                  </Row>
                </Wrapper>
              </div>
            </div>
          </>
        )}
      </>
    );
};

const mapStateToProps = (state) => ({
  getResellerByIdState: state.getResellerById,
});

const mapDispatchToProps = (dispatch) => ({
  getResellerById: (params) => dispatch(getResellerByIdApi(params)),
  getResellerByIdReset: (params) => dispatch(getResellerByIdReset(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(InventoryView);
