import React, { useEffect, useState } from "react";

import { Row, Col, Tag, Card } from "antd";

import moment from "moment";
import {
  capitalizeAndSplitByDash,
  getSignedUrlForGetObject,
} from "../../utils/helper";
import {
  DownloadResumeButton,
  SectionTitle,
  XContainer,
  CandidateLabel,
} from "../../styles/GlobalStyle";

export default function CandidateDetails(props) {
  const { applicationDetails } = props;

  const [url, setUrl] = useState("");

  const handleOnClick = async (agreement_url) => {
    const url = await getSignedUrlForGetObject(
      "candidates",
      applicationDetails.resume,
      applicationDetails.s3_folder_name
    );
    window.open(url, "_blank");
  };

  const downloadResume = () => {
    const link = document.createElement("a");
    link.href = url;
    link.download = "Resume";
    link.click();
  };
  return (
    <>
      <XContainer fluid>
        <Card className="mt-4">
          <Row gutter={[30, 15]}>
            <Col xs={24}>
              <SectionTitle>Personal Information</SectionTitle>
            </Col>
            <Col xs={24} sm={12} lg={6}>
              <CandidateLabel>Full Name</CandidateLabel>
              <p>
                {capitalizeAndSplitByDash(
                  applicationDetails.first_name +
                    " " +
                    applicationDetails.last_name
                )}
              </p>
            </Col>
            <Col xs={24} sm={12} lg={6}>
              <CandidateLabel>Email</CandidateLabel>
              <p>{applicationDetails.email_address}</p>
            </Col>
            <Col xs={24} sm={12} lg={6}>
              <CandidateLabel>Date Of Birth</CandidateLabel>
              <p>
                {moment(applicationDetails.dateOfBirth).format("DD MMM, YYYY")}
              </p>
            </Col>
            <Col xs={24} sm={12} lg={6}>
              <CandidateLabel>Gender</CandidateLabel>
              <p>{capitalizeAndSplitByDash(applicationDetails.gender)}</p>
            </Col>
          </Row>
        </Card>

        <Card className="mt-4">
          <Row gutter={[30, 15]}>
            <Col xs={24}>
              <SectionTitle>Address Details</SectionTitle>
            </Col>
            <Col xs={24} sm={12} lg={6}>
              <CandidateLabel>Address</CandidateLabel>
              <p>{capitalizeAndSplitByDash(applicationDetails.address)}</p>
            </Col>
            <Col xs={24} sm={12} lg={6}>
              <CandidateLabel>Pincode</CandidateLabel>
              <p>{applicationDetails.pincode}</p>
            </Col>
            <Col xs={24} sm={12} lg={6}>
              <CandidateLabel>City</CandidateLabel>
              <p>{capitalizeAndSplitByDash(applicationDetails.city)}</p>
            </Col>
            <Col xs={24} sm={12} lg={6}>
              <CandidateLabel>State</CandidateLabel>
              <p>{capitalizeAndSplitByDash(applicationDetails.state)}</p>
            </Col>
            <Col xs={24} sm={12} lg={6}>
              <CandidateLabel>Preferred Job Type</CandidateLabel>
              <p>
                {applicationDetails.preferred_job_type
                  ? capitalizeAndSplitByDash(
                      applicationDetails.preferred_job_type.join(", ")
                    )
                  : ""}
              </p>
            </Col>
          </Row>
        </Card>

        <Card className="mt-4">
          <Row gutter={[30, 15]}>
            <Col xs={24}>
              <SectionTitle>CTC Information</SectionTitle>
            </Col>
            <Col xs={24} sm={12} lg={6}>
              <CandidateLabel>Current Salary (PM)</CandidateLabel>
              <p>
                {new Intl.NumberFormat("en-IN", {
                  style: "currency",
                  currency: "INR",
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                }).format(applicationDetails.current_ctc_monthly)}
              </p>
            </Col>
            <Col xs={24} sm={12} lg={6}>
              <CandidateLabel>Current Salary (PA)</CandidateLabel>
              <p>
                {new Intl.NumberFormat("en-IN", {
                  style: "currency",
                  currency: "INR",
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                }).format(applicationDetails.current_ctc_yearly)}
              </p>
            </Col>
            <Col xs={24} sm={12} lg={6}>
              <CandidateLabel>Expected Salary (PM)</CandidateLabel>
              <p>
                {new Intl.NumberFormat("en-IN", {
                  style: "currency",
                  currency: "INR",
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                }).format(applicationDetails.expected_ctc_monthly)}
              </p>
            </Col>
            <Col xs={24} sm={12} lg={6}>
              <CandidateLabel>Expected Salary (PA)</CandidateLabel>
              <p>
                {new Intl.NumberFormat("en-IN", {
                  style: "currency",
                  currency: "INR",
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                }).format(applicationDetails.expected_ctc_yearly)}
              </p>
            </Col>
            <Col xs={24} sm={24} lg={24}>
              <CandidateLabel>Skills</CandidateLabel>
              <br />
              <>
                {applicationDetails.skills?.length > 0
                  ? applicationDetails.skills.map((item) => (
                      <Tag>{item.skill}</Tag>
                    ))
                  : ""}
              </>
            </Col>
          </Row>
        </Card>

        <Card className="mt-4">
          <Row gutter={[30, 15]}>
            <Col xs={24}>
              <SectionTitle>Education</SectionTitle>
            </Col>
            <Col xs={24} sm={12} lg={6}>
              <CandidateLabel>Highest Education</CandidateLabel>
              <p>
                {capitalizeAndSplitByDash(applicationDetails.highest_education)}
              </p>
            </Col>
            <Col xs={24} sm={12} lg={6}>
              <CandidateLabel>Completion Month Year</CandidateLabel>
              <p>
                {moment(applicationDetails.completion_month_year).format(
                  "MMM, YYYY"
                )}
              </p>
            </Col>
            <Col xs={24} sm={12} lg={6}>
              <CandidateLabel>Specialization</CandidateLabel>
              <p>
                {capitalizeAndSplitByDash(applicationDetails.specialization)}
              </p>
            </Col>
            <Col xs={24} sm={12} lg={6}>
              <CandidateLabel>College Name</CandidateLabel>
              <p>{capitalizeAndSplitByDash(applicationDetails.college_name)}</p>
            </Col>
          </Row>
        </Card>

        <Card className="mt-4">
          <Row gutter={[30, 15]} className="mb-3">
            <Col xs={24}>
              <SectionTitle>Work Experience</SectionTitle>
            </Col>
          </Row>
          {applicationDetails.work_experience?.length > 0 &&
            applicationDetails.work_experience.map((item) => (
              <Row gutter={[30, 0]} className="mb-3">
                <Col xs={24} sm={24} lg={24}>
                  <SectionTitle>
                    {capitalizeAndSplitByDash(item.company_name)}
                  </SectionTitle>
                </Col>
                <Col xs={24} sm={12} lg={4}>
                  <CandidateLabel>Designation</CandidateLabel>
                  <p>{capitalizeAndSplitByDash(item.designation)}</p>
                </Col>
                <Col xs={24} sm={12} lg={4}>
                  <CandidateLabel>From</CandidateLabel>
                  <p>{moment(item.from).format("DD MMM, YYYY")}</p>
                </Col>
                <Col xs={24} sm={12} lg={4}>
                  <CandidateLabel>To</CandidateLabel>
                  <p>
                    {item.to
                      ? moment(item.to).format("DD MMM, YYYY")
                      : "Present"}
                  </p>
                </Col>
                <Col xs={24} sm={12} lg={4}>
                  <CandidateLabel>Employment Type</CandidateLabel>
                  <p>{capitalizeAndSplitByDash(item.employment_type)}</p>
                </Col>
                <Col xs={24} sm={12} lg={4}>
                  <CandidateLabel>Location</CandidateLabel>
                  <p>{capitalizeAndSplitByDash(item.location)}</p>
                </Col>
                <Col xs={24} sm={12} lg={4}>
                  <CandidateLabel>Industry</CandidateLabel>
                  <p>{capitalizeAndSplitByDash(item.industry)}</p>
                </Col>
                <Col xs={24} sm={24} lg={24}>
                  <CandidateLabel>Roles and Responsibilities</CandidateLabel>
                  <p>
                    {capitalizeAndSplitByDash(item.roles_and_responsibilities)}
                  </p>
                </Col>
              </Row>
            ))}
          {/* </Row> */}
        </Card>

        <Card className="mt-4">
          <Row>
            <Col xs={24}>
              <SectionTitle>Resume</SectionTitle>
            </Col>
            <Col xs={24}>
              {applicationDetails.resume ? (
                <DownloadResumeButton onClick={() => handleOnClick()}>
                  Download Resume
                </DownloadResumeButton>
              ) : (
                <DownloadResumeButton>Resume not uploaded</DownloadResumeButton>
              )}
            </Col>
          </Row>
        </Card>
      </XContainer>
    </>
  );
}
