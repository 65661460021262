import axios from "axios";
import config from "../config";
import { getUserToken } from "../utils/helper";

export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const UPDATE_PROFILE_SUCCESS = "UPDATE_PROFILE_SUCCESS";
export const UPDATE_PROFILE_FAIL = "UPDATE_PROFILE_FAIL";
export const UPDATE_PROFILE_RESET = "UPDATE_PROFILE_RESET";

export const updateProfile = (params) => {
  return {
    type: UPDATE_PROFILE,
    params,
  };
};

export const updateProfileSuccess = (response) => {
  return {
    type: UPDATE_PROFILE_SUCCESS,
    response,
  };
};

export const updateProfileFail = (response) => {
  return {
    type: UPDATE_PROFILE_FAIL,
    response,
  };
};

export const updateProfileReset = () => {
  return {
    type: UPDATE_PROFILE_RESET,
  };
};

export const updateProfileApi = (data) => {
  return (dispatch) => {
    dispatch(updateProfile());
    axios
      .post(`${config.api.base_url}/settings/update-profile`, data, {
        headers: {
          Authorization: "Bearer " + getUserToken(),
        },
      })
      .then((response) => {
        const updateProfileData = response.data;
        dispatch(updateProfileSuccess(updateProfileData));
      })
      .catch((error) => {
        const errorMsg = error.message;
        dispatch(updateProfileFail(errorMsg));
      });
  };
};
