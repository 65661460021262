import React from "react";
import styled from "styled-components";
import { Card, Typography, Space } from "antd";
import { Link } from "react-router-dom";

const { Title } = Typography;

const jobBoards = [
  {
    name: "LinkedIn",
    color: "#0077b5",
    logo: "/images/linkedin.svg",
    link: "https://www.linkedin.com/",
    description:
      "Post jobs on LinkedIn to reach millions of professionals and attract top talent with targeted listings.",
  },
  {
    name: "Indeed",
    color: "#003a9b",
    logo: "/images/indeed.svg",
    link: "https://in.indeed.com/",
    description:
      "Share your job on Indeed, a leading job search engine, to maximize visibility and attract diverse candidates.",
  },
  {
    name: "Dice",
    color: "#e4002b",
    logo: "/images/dice.svg",
    link: "https://www.dice.com/",
    description:
      "Advertise on Dice, a leading job board for tech professionals, to reach skilled candidates in technology and IT fields.",
  },
  {
    name: "Glassdoor",
    color: "#0caa41",
    logo: "/images/glassdoor.svg",
    link: "https://www.glassdoor.co.in/",
    description:
      "Promote your job on Glassdoor to showcase your company culture and attract talent while enhancing your employer brand.",
  },
  {
    name: "Monster",
    color: "#6f2b8d",
    logo: "/images/monster.svg",
    link: "https://www.foundit.in/",
    description:
      "Advertise on Monster to tap into a vast job seeker database and utilize features like resume search to find the right fit.",
  },
  {
    name: "Naukri",
    color: "#275df5",
    logo: "/images/naukri.svg",
    link: "https://www.naukri.com/",
    description:
      "Post your job on Naukri to access one of India’s largest job portals and connect with millions of job seekers quickly and efficiently.",
  },
];

const JobBoardCards = () => {
  return (
    <CardContainer>
      {jobBoards.map((board, index) => (
        <StyledCard key={index} $color={board.color}>
          <a href={board.link} target="_blank" rel="noopener noreferrer">
            <CardHeader>
              <Logo src={board.logo} alt={`${board.name} Logo`} />
              <StyledTitle level={4}>{board.name}</StyledTitle>
            </CardHeader>
            <p style={{ color: "#fff", marginTop: "10px" }}>
              {board.description}
            </p>
          </a>
        </StyledCard>
      ))}
    </CardContainer>
  );
};

const CardContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr); // 3 columns
  gap: 16px;
  width: 100%;
  justify-content: center;
  margin-bottom: 40px;
  // Responsive design: switches to 2 columns on medium screens, 1 column on small screens
  @media (max-width: 992px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 576px) {
    grid-template-columns: 1fr;
  }
`;

const StyledCard = styled(Card)`
  flex: 1;
  border-radius: 16px;
  background-color: ${(props) => props.$color};
  color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  .ant-card-body {
    padding: 24px;
  }
`;

const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

const Logo = styled.img`
  width: 40px;
  height: auto;
`;

const StyledTitle = styled(Title)`
  && {
    color: white;
    margin-bottom: 0;
  }
`;

export default JobBoardCards;
