import React from "react";
import styled from "styled-components";
import { Input, Button, Select } from "antd";
import { filterByLabel, handleKeyDown } from "../../utils/formFunctions";
import { connect } from "react-redux";
import { getStatesApi } from "../../actions/getStatesAction";

const JobSearchForm = ({
  jobTitle,
  setJobTitle,
  location,
  setLocation,
  handleSubmit,
  application,
  getStates,
  getStatesState,
}) => {
  return (
    <SearchFormWrapper onSubmit={handleSubmit}>
      <InputGroup>
        <InputWrapper>
          <SearchIcon
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/4fe6c3227367930b00234f44b10bc46e439bdaa4ecc43e264223ce0bfdb28eac?placeholderIfAbsent=true&apiKey=ac9769c5e7eb416e8a74975078a910d6"
            alt=""
          />
          <StyledInput
            allowClear
            onClear={() => {
              setJobTitle("");
            }}
            onKeyDown={handleKeyDown}
            placeholder="Job title, Keyword..."
            value={jobTitle}
            onChange={(e) => {
              if (e !== undefined) setJobTitle(e.target.value);
              else setJobTitle("");
            }}
          />
        </InputWrapper>
        <Divider />
        <InputWrapper>
          <LocationIcon
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/c65ae5431bedc84c3b190008148325aa0ea3c58d04f3c1ae210df2666845f20d?placeholderIfAbsent=true&apiKey=ac9769c5e7eb416e8a74975078a910d6"
            alt=""
          />
          <StyledSelect
            placeholder="Your Location"
            value={location}
            onChange={(e) => {
              if (e !== undefined) setLocation(e);
              else setLocation(null);
            }}
            onClear={() => {
              setLocation(null);
            }}
            onFocus={() => getStates({})}
            allowClear
            showSearch
            filterOption={filterByLabel}
            options={
              getStatesState.apiState === "success" &&
              getStatesState.data.all_states.length > 0 &&
              getStatesState.data.all_states.map((state) => {
                return {
                  value: state.state,
                  label: state.state,
                  type: "state",
                  key: state.state,
                };
              })
            }
          />
        </InputWrapper>
      </InputGroup>
      <StyledButton type="primary" htmlType="submit">
        {application ? "Find Candidate" : "Find Job"}
      </StyledButton>
    </SearchFormWrapper>
  );
};

const SearchFormWrapper = styled.form`
  display: flex;
  align-items: center;
  background-color: #fff;
  border: 1px solid #e4e5e8;
  border-radius: 8px;
  box-shadow: 0 12px 40px 0 rgba(0, 44, 109, 0.04);
  margin-top: 27px;
  padding: 12px;
  gap: 12px;
  @media (max-width: 991px) {
    flex-wrap: wrap;
  }
`;

const InputGroup = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
  @media (max-width: 991px) {
    width: 100%;
    flex-direction: column;
    gap: 12px;
  }
`;

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  background-color: #fff;
  border-radius: 5px;
  padding: 16px 18px;
  flex-grow: 1;
  width: 100%; /* Ensure full width */
  max-width: 100%; /* Prevent overflowing */
`;

const StyledInput = styled(Input)`
  border: none;
  outline: none;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  color: #9199a3;
  width: 100%; /* Full width */
  &::placeholder {
    color: #9199a3;
  }
`;

const StyledSelect = styled(Select)`
  border: none !important;
  outline: none;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  color: #9199a3;
  width: 100%; /* Full width */
  &::placeholder {
    color: #9199a3;
  }
  .ant-select-selector {
    border: none !important;
  }
  .ant-select-selection-placeholder {
    color: #9199a3 !important;
    opacity: 1 !important;
  }
  .ant-select-selection-item {
    color: #000 !important; /* Ensure selected item text is visible */
  }
`;

const SearchIcon = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 12px;
`;

const LocationIcon = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 12px;
`;

const Divider = styled.div`
  width: 1px;
  height: 32px;
  background-color: #e4e5e8;
  margin: 0 12px;
  @media (max-width: 991px) {
    width: 100%;
    height: 1px;
    margin: 12px 0;
  }
`;

const StyledButton = styled(Button)`
  background-color: #275df5;
  color: #fff;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
  text-align: center;
  padding: 16px 32px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  white-space: nowrap;
  width: 131px;
  height: 56px;
  @media (max-width: 991px) {
    width: 100%;
    margin-top: 12px;
  }
`;

const mapStateToProps = (state) => ({
  getStatesState: state.getStates,
});

const mapDispatchToProps = (dispatch) => ({
  getStates: (params) => dispatch(getStatesApi(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(JobSearchForm);
