import React, { useState, useEffect } from 'react';
import { Row, Col, Button, Drawer, Space } from 'antd';
import { XButton2, XForm } from '../../styles/GlobalStyle';

const CaseSearch = (props) => {
  let { onClose, open, applyFilters, clearFilters, form } = props;

  return (
    <>
      <Drawer
        title={'Filter'}
        placement='right'
        onClose={onClose}
        open={open}
        extra={
          <Space>
            <Button
              onClick={() => {
                clearFilters();
                onClose();
              }}
              style={{
                border: 'none',
                background: 'transparent',
                width: '100%',
                boxShadow: 'none',
                color: '#232323',
              }}>
              Clear
            </Button>
          </Space>
        }>
        <XForm name='search' layout='vertical' form={form}>
          <Row gutter={(15, 30)}>
            {props.filters?.length > 0 &&
              props.filters.map((filter, index) => {
                return (
                  <Col xs={24} sm={24} lg={24} xl={24}>
                    {filter}
                  </Col>
                );
              })}
          </Row>

          <XButton2
            onClick={() => {
              applyFilters();
              onClose();
            }}
            style={{
              marginLeft: '0px',
              width: '100%',
              background: '#275df5',
              color: '#fff',
            }}>
            Apply
          </XButton2>
        </XForm>
      </Drawer>
    </>
  );
};

export default CaseSearch;
