import React, { useEffect, useState } from "react";
import HeroBanner from "../../components/Home/Hero/index";
import HowItWorks from "../../components/Home/HowItWorks/index";
import PopularCategories from "../../components/Home/Categories/index";
import FeaturedJobs from "../../components/Home/Featured/index";
import Testimonials from "../../components/Home/Testimonials/index";
import CallToAction from "../../components/Home/CallToAction/index";
import { connect } from "react-redux";
import { useForm } from "antd/es/form/Form";
import { checkModulePermissions, isLogin } from "../../utils/helper";
import { notification } from "antd";
import {
  getCandidateJobListApi,
  getCandidateJobListReset,
} from "../../actions/getCandidateJobListAction";
import { getIndustriesApi } from "../../actions/getIndustriesAction";
import {
  getCandidateJobListGuestApi,
  getCandidateJobListGuestReset,
} from "../../actions/getCandidateJobListGuestAction";

const LandingPage = (props) => {
  const defaultFilters = {
    page: 1,
    limit: 10,
    search: "",
    location: "",
    category: "",
    joined_on: "all",
    sortDirection: -1,
    sortBy: "",
    featured: 1,
  };

  const {
    getCandidateJobList,
    getCandidateJobListState,
    getCandidateJobListReset,
    getIndustriesState,
    getIndustries,
    getCandidateJobListGuest,
    getCandidateJobListGuestState,
    getCandidateJobListGuestReset,
  } = props;
  const [form] = useForm();
  const [selectedJobs, setSelectedJobs] = useState(null);
  const [categories, setCategories] = useState([
    {
      icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/a20fbd07e4bb9ad6003f6634c2442302a0b8b616acdf79a23003f3b833ec5f0c?placeholderIfAbsent=true&apiKey=ac9769c5e7eb416e8a74975078a910d6",
      title: "Communication & Soft Skills",
      openPositions: 357,
      _id: "12334454623645645",
    },
    {
      icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/12ca51c432186ebb87f73435a041795ea33734a6ff6497053106201a47be66dd?placeholderIfAbsent=true&apiKey=ac9769c5e7eb416e8a74975078a910d6",
      title: "IT Services",
      openPositions: 247,
      _id: "12334454623645645",
    },
    {
      icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/946ca85f215b80f590ca84adb2590b15296499241b3fcbc8d853d816dc5917ac?placeholderIfAbsent=true&apiKey=ac9769c5e7eb416e8a74975078a910d6",
      title: "Staffing / HR Services",
      openPositions: 125,
      _id: "12334454623645645",
    },
    {
      icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/ee2ff85d02a1ec671d2cb573a7d6c35fb11132cb15688714ab6891c9b1275b95?placeholderIfAbsent=true&apiKey=ac9769c5e7eb416e8a74975078a910d6",
      title: "Engineering & Infrastructure",
      openPositions: 312,
      _id: "12334454623645645",
    },
    {
      icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/b34c06127727cfeb58e16c97a3e3c741e06a1b4f3f41ab62716b15cce52afe28?placeholderIfAbsent=true&apiKey=ac9769c5e7eb416e8a74975078a910d6",
      title: "Banking & Financial Services",
      openPositions: 167,
      _id: "12334454623645645",
    },
    {
      icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/62ce902f978eefa0aacf0e3bf1c03a22c91d665d290fd6bb747e2927cf958ba0?placeholderIfAbsent=true&apiKey=ac9769c5e7eb416e8a74975078a910d6",
      title: "ITES / BPO",
      openPositions: 297,
      _id: "12334454623645645",
    },
  ]);

  const getFiltersFromUrl = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const urlFilters = {};

    // Iterate through each query parameter and set it in the state
    for (const [key, value] of urlParams.entries()) {
      urlFilters[key] = value;
    }

    // Merge with default filters or initial state as needed
    const filters = {
      ...defaultFilters, // Replace with your default filters
      ...urlFilters,
    };

    return filters;
  };
  const initialFilters = getFiltersFromUrl();

  const [filters, setFilters] = useState(initialFilters);
  useEffect(() => {
    // Retrieve filters from the updated URL
    const updatedFilters = getFiltersFromUrl();
    setFilters({ ...updatedFilters });

    // Make API call with updated filters and pagination
    getIndustries({ job_inventory: "yes" });
    if (isLogin()) getCandidateJobList({ ...updatedFilters });
    else getCandidateJobListGuest({ ...updatedFilters });
  }, [window.location.search]);

  useEffect(() => {
    if (getCandidateJobListState.apiState === "success") {
      setSelectedJobs(getCandidateJobListState.data.list);

      getCandidateJobListReset();
    } else if (getCandidateJobListState.apiState === "error") {
      console.log("API Error=>", getCandidateJobListState.message);
      notification.error({
        message: "Unable to get Job List",
      });
      getCandidateJobListReset();
    }
  }, [getCandidateJobListState.apiState]);

  useEffect(() => {
    if (getCandidateJobListGuestState.apiState === "success") {
      setSelectedJobs(getCandidateJobListGuestState.data.list);

      getCandidateJobListGuestReset();
    } else if (getCandidateJobListGuestState.apiState === "error") {
      console.log("API Error=>", getCandidateJobListGuestState.message);
      notification.error({
        message: "Unable to get Job List",
      });
      getCandidateJobListGuestReset();
    }
  }, [getCandidateJobListGuestState.apiState]);

  useEffect(() => {
    if (getIndustriesState.apiState === "success") {
      setCategories(getIndustriesState.data.all_industries);
    } else if (getIndustriesState.apiState === "error") {
      console.log("API Error=>", getIndustriesState.message);
      notification.error({
        message: "Unable to get Job List",
      });
    }
  }, [getIndustriesState.apiState]);

  return (
    <>
      <main>
        <HeroBanner filters={filters} setFilters={setFilters} />
        <HowItWorks />
        <PopularCategories
          filters={filters}
          setFilters={setFilters}
          categories={categories}
        />
        <FeaturedJobs selectedJobs={selectedJobs} />
        <Testimonials />
        <CallToAction />
      </main>
    </>
  );
};

const mapStateToProps = (state) => ({
  getCandidateJobListState: state.getCandidateJobList,
  getIndustriesState: state.getIndustries,
  getCandidateJobListGuestState: state.getCandidateJobListGuest,
});

const mapDispatchToProps = (dispatch) => ({
  getCandidateJobList: (params) => dispatch(getCandidateJobListApi(params)),
  getCandidateJobListReset: () => dispatch(getCandidateJobListReset()),
  getIndustries: (params) => dispatch(getIndustriesApi(params)),
  getCandidateJobListGuest: (params) =>
    dispatch(getCandidateJobListGuestApi(params)),
  getCandidateJobListGuestReset: () =>
    dispatch(getCandidateJobListGuestReset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(LandingPage);
