import React, { useState, useEffect } from "react";
import { Button, notification, Result } from "antd";
import { connect } from "react-redux";
import {
  getCandidateDetailsApi,
  getCandidateDetailsReset,
} from "../../actions/getCandidateDetailsAction";
import { useParams } from "react-router-dom";
import CandidateDetails from "../../components/CandidateDetails/CandidateDetailsCard";
import { hasPermission } from "../../utils/helper";

const Home = (props) => {
  const id = useParams().id;

  const [applicationDetails, setApplicationDetails] = useState({});
  const {
    getCandidateDetails,
    getCandidateDetailsState,
    getCandidateDetailsReset,
  } = props;

  useEffect(() => {
    document.body.classList.add("headerBg");
  });

  useEffect(() => {
    if (id && hasPermission("candidates", "view-candidate")) {
      getCandidateDetails({
        candidate_id: id,
      });
    }
  }, [id]);

  useEffect(() => {
    if (getCandidateDetailsState.apiState === "success") {
      setApplicationDetails(getCandidateDetailsState.data);
    } else if (getCandidateDetailsState.apiState === "error") {
      notification.error({
        message: "Couldnt Load the candidate details",
      });
    }
    getCandidateDetailsReset();
  }, [getCandidateDetailsState.apiState]);

  return (
    <>
      {(applicationDetails && hasPermission("candidates", "view-candidate")) ||
      hasPermission("candidate-profile", "view-candidate") ? (
        <CandidateDetails applicationDetails={applicationDetails} />
      ) : (
        <Result
          status="500"
          title="500"
          subTitle="Sorry, something went wrong."
          extra={<Button type="primary">Back Home</Button>}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  getCandidateDetailsState: state.getCandidateDetails,
});

const mapDispatchToProps = (dispatch) => ({
  getCandidateDetails: (params) => dispatch(getCandidateDetailsApi(params)),
  getCandidateDetailsReset: () => dispatch(getCandidateDetailsReset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
