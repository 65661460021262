import config from "../config";
import axios from "axios";
import { getUserToken } from "../utils/helper";

export const GET_JOB_FROM_INVENTORY = "GET_JOB_FROM_INVENTORY";
export const GET_JOB_FROM_INVENTORY_SUCCESS = "GET_JOB_FROM_INVENTORY_SUCCESS";
export const GET_JOB_FROM_INVENTORY_FAIL = "GET_JOB_FROM_INVENTORY_FAIL";
export const GET_JOB_FROM_INVENTORY_RESET = "GET_JOB_FROM_INVENTORY_RESET";

export const getJobFromInventory = (params) => {
  return {
    type: GET_JOB_FROM_INVENTORY,
    params,
  };
};

export const getJobFromInventorySuccess = (response) => {
  return {
    type: GET_JOB_FROM_INVENTORY_SUCCESS,
    response,
  };
};

export const getJobFromInventoryFail = (response) => {
  return {
    type: GET_JOB_FROM_INVENTORY_FAIL,
    response,
  };
};

export const getJobFromInventoryReset = () => {
  return {
    type: GET_JOB_FROM_INVENTORY_RESET,
  };
};

export const getJobFromInventoryApi = (data) => {
  return (dispatch) => {
    dispatch(getJobFromInventory());
    axios
      .get(`${config.api.base_url}/job-inventory/${data.id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getUserToken()}`,
        },
      })
      .then((response) => {
        const getJobFromInventoryData = response.data;
        dispatch(getJobFromInventorySuccess(getJobFromInventoryData));
      })
      .catch((error) => {
        const errorData = error.response.data;
        dispatch(getJobFromInventoryFail(errorData));
      });
  };
};
