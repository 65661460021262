import axios from "axios";
import config from "../config";
import { getUserToken } from "../utils/helper";

export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAIL = "CHANGE_PASSWORD_FAIL";
export const CHANGE_PASSWORD_RESET = "CHANGE_PASSWORD_RESET";

export const changePassword = (params) => {
  return {
    type: CHANGE_PASSWORD,
    params,
  };
};

export const changePasswordSuccess = (response) => {
  return {
    type: CHANGE_PASSWORD_SUCCESS,
    response,
  };
};

export const changePasswordFail = (response) => {
  return {
    type: CHANGE_PASSWORD_FAIL,
    response,
  };
};

export const changePasswordReset = () => {
  return {
    type: CHANGE_PASSWORD_RESET,
  };
};

export const changePasswordApi = (data) => {
  return (dispatch) => {
    dispatch(changePassword());
    axios
      .post(`${config.api.base_url}/settings/change-password`, data, {
        headers: {
          Authorization: "Bearer " + getUserToken(),
        },
      })
      .then((response) => {
        const changePasswordData = response.data;
        dispatch(changePasswordSuccess(changePasswordData));
      })
      .catch((error) => {
        dispatch(changePasswordFail(error.response.data));
      });
  };
};
