import React from "react";
import styled from "styled-components";

const workflowSteps = [
  {
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/4ecba8d04ec3846666d3a68f2b0ee44bddc05debde956444e2e4e66ef60c4358?placeholderIfAbsent=true&apiKey=ac9769c5e7eb416e8a74975078a910d6",
    title: "Create Account",
    description:
      "Sign up to start your journey. Create a profile to showcase your skills and experience.",
  },
  {
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/117832a317cb669513701aa0e142457b578c44c1f70223b3be4f0d20d3698f5b?placeholderIfAbsent=true&apiKey=ac9769c5e7eb416e8a74975078a910d6",
    title: "Apply for Jobs",
    description:
      "Browse through job listings that match your skills and apply directly from our platform.",
  },
  {
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/1818ec0673e9e2395a8b3e34d1858995a1dd01830f6112e9f1c6c24e0d270140?placeholderIfAbsent=true&apiKey=ac9769c5e7eb416e8a74975078a910d6",
    title: "Contact Partner Directly",
    description: "Get in touch with the recruitment team to discuss job opportunities and make connections.",
  },
];

function SkillsprintsWorkflow() {
  return (
    <WorkflowContainer>
      <WorkflowContent>
        <WorkflowTitle>
          How <HighlightedText>Skillsprints</HighlightedText> work
        </WorkflowTitle>
        <StepContainer>
          {workflowSteps.map((step, index) => (
            <React.Fragment key={index}>
              {index > 0 && (
                <StepConnector>
                  <svg
                    width="21"
                    height="34"
                    viewBox="0 0 21 34"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M3.67305 1L19.6402 16.7742L3.79528 32.5484"
                      fill="#275DF5"
                    />
                    <path
                      d="M3.67305 1L19.6402 16.7742L3.79528 32.5484"
                      stroke="white"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M1.31082 5.54834L12.6739 16.7741L1.39781 27.9999"
                      fill="#275DF5"
                    />
                    <path
                      d="M1.31082 5.54834L12.6739 16.7741L1.39781 27.9999"
                      stroke="white"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </StepConnector>
              )}
              <WorkflowStep {...step} />
            </React.Fragment>
          ))}
        </StepContainer>
      </WorkflowContent>
    </WorkflowContainer>
  );
}

const WorkflowContainer = styled.section`
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  padding: 98px 20px;
`;

const WorkflowContent = styled.div`
  display: flex;
  width: 100%;
  max-width: 1120px;
  flex-direction: column;
`;

const WorkflowTitle = styled.h2`
  color: #18191c;
  text-align: center;
  align-self: center;
  font-family: Inter, sans-serif;
  font-size: 40px;
  font-weight: 500;
  line-height: 48px;
  margin-bottom: 63px;
`;

const HighlightedText = styled.span`
  font-weight: 600;
  color: #275df5;
`;

const StepContainer = styled.div`
  display: flex;
  gap: 30px;
  justify-content: center;
  flex-wrap: wrap;

  @media (max-width: 1200px) {
    flex-direction: column;
    align-items: center;
  }
`;

const StepConnector = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 1200px) {
    transform: rotate(90deg);
    margin: 20px 0;
  }
`;

const WorkflowStep = ({ icon, title, description }) => (
  <StepWrapper>
    <StepIcon>
      <StepIconImage loading="lazy" src={icon} alt={`${title} icon`} />
    </StepIcon>
    <StepInfo>
      <StepTitle>{title}</StepTitle>
      <StepDescription>{description}</StepDescription>
    </StepInfo>
  </StepWrapper>
);

const StepWrapper = styled.article`
  border-radius: 12px;
  background-color: #f5f8ff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  width: 312px;
  min-width: 312px;

  @media (max-width: 360px) {
    width: 100%;
    min-width: unset;
  }
`;

const StepIcon = styled.div`
  border-radius: 80px;
  background-color: #275df5;
  display: flex;
  width: 72px;
  height: 72px;
  align-items: center;
  justify-content: center;
  padding: 20px;
`;

const StepIconImage = styled.img`
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 32px;
`;

const StepInfo = styled.div`
  display: flex;
  margin-top: 24px;
  max-width: 100%;
  width: 100%;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const StepTitle = styled.h3`
  color: #18191c;
  font-family: Inter, sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
  text-align: center;
`;

const StepDescription = styled.p`
  color: #767f8c;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin-top: 12px;
  text-align: center;
`;

export default SkillsprintsWorkflow;
