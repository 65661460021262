import React, { useEffect, useState } from "react";
import { Layout, Row, Col, Tag, Card, Result, Button } from "antd";
import { Wrapper } from "../../styles/Button";

import { Link, useNavigate, useParams } from "react-router-dom";
import { connect } from "react-redux";
// import { getJobApplicationsApi } from '../../actions/';

import {
  getEmployerByIdApi,
  getEmployerByIdReset,
} from "../../actions/getEmployerByIdAction";
import moment from "moment";
import {
  capitalizeAndSplitByDash,
  checkModulePermissions,
  hasPermission,
} from "../../utils/helper";

const InventoryView = (props) => {
  const { getEmployerByIdState, getEmployerById, getEmployerByIdReset } = props;
  const navigate = useNavigate();
  const id = useParams().id;

  const [employerData, setEmployerData] = useState();

  useEffect(() => {
    if (hasPermission("employers", "view-employers")) getEmployerById({ id });
  }, []);

  useEffect(() => {
    if (getEmployerByIdState.apiState === "success") {
      setEmployerData(getEmployerByIdState.data);
    }
  }, [getEmployerByIdState.apiState]);

  //Get the number of days, weeks

  if (employerData)
    return (
      <>
        {hasPermission("employers", "view-employers") ? (
          <>
            <div className="mainContent">
              <div className="pageWrapper">
                <Wrapper>
                  <Row gutter={[30, 30]} align="top">
                    <Col xs={24} md={16}>
                      <Card className="card">
                        <Row gutter={[30, 0]}>
                          <Col span={24}>
                            <h1 className="headerheading">
                              {employerData?.organization_name}
                            </h1>
                            <p className="para2">{employerData?.employer}</p>
                            <Row gutter={[15, 30]}>
                              <Col xs={24} sm={24} md={24}>
                                <p className="para3 mt-5">
                                  <span>Organization Address</span>
                                </p>

                                <p className="para3">
                                  {employerData?.organization_address}
                                </p>
                              </Col>
                              <Col xs={24} sm={24} md={12}>
                                <p className="para3">
                                  <span>Pincode</span>
                                </p>

                                <p className="para3">{employerData?.pincode}</p>
                              </Col>

                              <Col xs={24} sm={24} md={12}>
                                <p className="para3">
                                  <span>City</span>
                                </p>

                                <p className="para3">{employerData?.city}</p>
                              </Col>
                              <Col xs={24} sm={24} md={12}>
                                <p className="para3">
                                  <span>State</span>
                                </p>

                                <p className="para3">{employerData?.state}</p>
                              </Col>
                              <Col xs={24} sm={24} md={12}>
                                <p className="para3">
                                  <span>Country</span>
                                </p>

                                <p className="para3">{employerData?.country}</p>
                              </Col>
                              <Col xs={24} sm={24} md={12}>
                                <p className="para3">
                                  <span>Mobile Number</span>
                                </p>

                                <p className="para3">
                                  {employerData?.mobile_number}
                                </p>
                              </Col>
                              <Col xs={24} sm={24} md={12}>
                                <p className="para3">
                                  <span>Email Address</span>
                                </p>

                                <p className="para3">
                                  {employerData?.email_address}
                                </p>
                              </Col>
                              <Col xs={24} sm={24} md={12}>
                                <p className="para3">
                                  <span>Minimum Retention Period</span>
                                </p>

                                <p className="para3">
                                  {employerData?.minimum_retention_period}
                                </p>
                              </Col>
                              <Col xs={24} sm={24} md={12}>
                                <p className="para3">
                                  <span>Industry</span>
                                </p>

                                <p className="para3">
                                  {employerData?.industry_name}
                                </p>
                              </Col>
                              <Col xs={24} sm={24} md={12}>
                                <p className="para3">
                                  <span>Legal Entity</span>
                                </p>

                                <p className="para3">
                                  {capitalizeAndSplitByDash(
                                    employerData?.legal_entity
                                  )}
                                </p>
                              </Col>
                            </Row>
                            <Row gutter={[30, 30]} className="mt-5">
                              <Col xs={24} sm={24} md={12}>
                                <p className="para3">
                                  <span>PAN Number</span>
                                </p>

                                <p className="para3">
                                  {employerData?.pan_number}
                                </p>
                              </Col>
                              <Col xs={24} sm={24} md={12}>
                                <p className="para3">
                                  <span>CIN</span>
                                </p>

                                <p className="para3">
                                  {employerData?.cin_number}
                                </p>
                              </Col>
                              <Col xs={24} sm={24} md={12}>
                                <p className="para3">
                                  <span>GSTIN</span>
                                </p>

                                <p className="para3">
                                  {employerData?.gstin_number}
                                </p>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Card>
                    </Col>
                    <Col xs={24} md={8}>
                      <Row>
                        <Col span={24}>
                          <div className="addEmployerBg mb-5">
                            <Row gutter={[30, 30]}>
                              <Col xs={24} sm={12} md={12}>
                                <div className="addEmpPara gilroy-bold">
                                  {employerData.total_jobs}
                                </div>
                                <div className="addEmpPara gilroy-semiBold">
                                  Active Jobs
                                </div>
                              </Col>
                              <Col xs={24} sm={12} md={12}>
                                <div className="addEmpPara gilroy-bold">
                                  {employerData.candidates_hired}
                                </div>
                                <div className="addEmpPara gilroy-semiBold">
                                  Inactive Jobs
                                </div>
                              </Col>
                              <Col xs={24} sm={12} md={12}>
                                <div className="addEmpPara gilroy-bold">
                                  {employerData.total_jobs}
                                </div>
                                <div className="addEmpPara gilroy-semiBold">
                                  Active Openings
                                </div>
                              </Col>
                              <Col xs={24} sm={12} md={12}>
                                <div className="addEmpPara gilroy-bold">
                                  {employerData.candidates_hired}
                                </div>
                                <div className="addEmpPara gilroy-semiBold">
                                  Inactive Openings
                                </div>
                              </Col>
                              <Col xs={24} sm={12} md={12}>
                                <div className="addEmpPara gilroy-bold">
                                  {employerData.total_jobs}
                                </div>
                                <div className="addEmpPara gilroy-semiBold">
                                  Candidates Rejected
                                </div>
                              </Col>
                              <Col xs={24} sm={12} md={12}>
                                <div className="addEmpPara gilroy-bold">
                                  {employerData.candidates_hired}
                                </div>
                                <div className="addEmpPara gilroy-semiBold">
                                  Candidates Shortlisted
                                </div>
                              </Col>
                              <Col xs={24} sm={12} md={12}>
                                <div className="addEmpPara gilroy-bold">
                                  {employerData.total_jobs}
                                </div>
                                <div className="addEmpPara gilroy-semiBold">
                                  Candidates Hired
                                </div>
                              </Col>
                              <Col xs={24} sm={12} md={12}>
                                <div className="addEmpPara gilroy-bold">
                                  {employerData.candidates_hired}
                                </div>
                                <div className="addEmpPara gilroy-semiBold">
                                  Total Jobs
                                </div>
                              </Col>
                            </Row>
                            <Row gutter={[30, 30]} className="mt-5">
                              <Col span={24} align="center">
                                <img
                                  width="100%"
                                  src="../images/addEmp-img.svg"
                                  style={{
                                    marginBottom: "-15px",
                                  }}
                                  alt=""
                                />
                              </Col>
                            </Row>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Wrapper>
              </div>
            </div>
          </>
        ) : (
          <Result
            status="404"
            title="404"
            subTitle="Sorry, the page you visited does not exist."
            extra={
              <Button
                type="primary"
                onClick={() => {
                  navigate("/");
                }}>
                Back Home
              </Button>
            }
          />
        )}
      </>
    );
};

const mapStateToProps = (state) => ({
  getEmployerByIdState: state.getEmployerById,
});

const mapDispatchToProps = (dispatch) => ({
  getEmployerById: (params) => dispatch(getEmployerByIdApi(params)),
  getEmployerByIdReset: (params) => dispatch(getEmployerByIdReset(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(InventoryView);
