import config from "../config";
import axios from "axios";
import { getUserToken } from "../utils/helper";

export const UPDATE_JOB_APPLICATION_STATUS = "UPDATE_JOB_APPLICATION_STATUS";
export const UPDATE_JOB_APPLICATION_STATUS_SUCCESS =
  "UPDATE_JOB_APPLICATION_STATUS_SUCCESS";
export const UPDATE_JOB_APPLICATION_STATUS_FAIL =
  "UPDATE_JOB_APPLICATION_STATUS_FAIL";
export const UPDATE_JOB_APPLICATION_STATUS_RESET =
  "UPDATE_JOB_APPLICATION_STATUS_RESET";

export const updateJobApplicationStatus = (params) => {
  return {
    type: UPDATE_JOB_APPLICATION_STATUS,
    params,
  };
};

export const updateJobApplicationStatusSuccess = (response) => {
  return {
    type: UPDATE_JOB_APPLICATION_STATUS_SUCCESS,
    response,
  };
};

export const updateJobApplicationStatusFail = (response) => {
  return {
    type: UPDATE_JOB_APPLICATION_STATUS_FAIL,
    response,
  };
};

export const updateJobApplicationStatusReset = () => {
  return {
    type: UPDATE_JOB_APPLICATION_STATUS_RESET,
  };
};

export const updateJobApplicationStatusApi = (data) => {
  console.log(data);
  return (dispatch) => {
    dispatch(updateJobApplicationStatus());

    // Todo: Add authentication bearer token in this API
    axios
      .post(
        `${config.api.base_url}/application/update-application-status`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getUserToken()}`,
          },
        }
      )
      .then((response) => {
        const updateJobApplicationStatusData = response.data;
        dispatch(
          updateJobApplicationStatusSuccess(updateJobApplicationStatusData)
        );
      })
      .catch((error) => {
        const errorData = error.response.data;
        dispatch(updateJobApplicationStatusFail(errorData));
      });
  };
};
