import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Card, Typography, Space, Dropdown, Menu } from 'antd';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import { MoreOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { getJobApplicationStatisticsApi } from '../../actions/getJobApplicationStatisticsAction';

const { Title, Text } = Typography;

const ProfileViewChart = (props) => {
  const { getJobApplicationStatisticsState, getJobApplicationStatistics } =
    props;

  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [],
  });

  useEffect(() => {
    if (
      //   getJobApplicationStatisticsState &&
      getJobApplicationStatisticsState.apiState === 'success'
    ) {
      console.log(
        getJobApplicationStatisticsState,
        'Statistics fetched successfully'
      );
      // You can update the chart data here
      setChartData({
        labels: getJobApplicationStatisticsState.data.labels,
        // datasets: getJobApplicationStatisticsState.data.datasets,
        datasets: getJobApplicationStatisticsState.data.datasets.map(
          (dataset) => ({
            ...dataset,
            tension: 0.4, // Add tension to smooth the lines
            fill: true, // Optionally fill the area under the line
          })
        ),
      });
    } else if (
      getJobApplicationStatisticsState &&
      getJobApplicationStatisticsState.apiState === 'error'
    ) {
      console.error(
        'Failed to fetch statistics:',
        getJobApplicationStatisticsState.error
      );
    }
  }, [getJobApplicationStatisticsState]);

  useEffect(() => {
    getJobApplicationStatistics();
  }, []);

  const menu = (
    <Menu>
      <Menu.Item key='1'>Monthly</Menu.Item>
      <Menu.Item key='2'>Yearly</Menu.Item>
      <Menu.Item key='3'>Custom Range</Menu.Item>
    </Menu>
  );

  const data = chartData.labels.map((label, index) => ({
    name: label,
    Applied: chartData.datasets[0].data[index],
  }));

  return (
    <StyledCard>
      <CardHeader>
        <Space direction='vertical' size={4}>
          <Title level={4}>Jobs Applied</Title>
          <Text type='secondary'>(Past 10 days)</Text>
        </Space>
        {/* <Dropdown overlay={menu} trigger={["click"]}>
          <MoreOutlined
            style={{ fontSize: "24px", color: "#1c1c1e", cursor: "pointer" }}
          />
        </Dropdown> */}
      </CardHeader>
      <ChartContainer>
        <ResponsiveContainer width='100%' height={300}>
          <LineChart data={data}>
            <CartesianGrid strokeDasharray='3 3' />
            <XAxis dataKey='name' />
            <YAxis />
            <Tooltip />
            <Legend
              wrapperStyle={{
                paddingTop: '20px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              iconType='circle'
              iconSize={10}
            />
            <Line type='monotone' dataKey='Applied' stroke='#36A2EB' />
          </LineChart>
        </ResponsiveContainer>
      </ChartContainer>
    </StyledCard>
  );
};

const StyledCard = styled(Card)`
  width: 100%;
  margin-top: 16px;
  margin-bottom: 40px;
  border-radius: 16px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #f6f9ff;
`;

const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 16px;
`;

const ChartContainer = styled.div`
  width: 100%;
  height: 300px;
`;

const mapStateToProps = (state) => ({
  getJobApplicationStatisticsState: state.getJobApplicationStatistics,
});

const mapDispatchToProps = (dispatch) => ({
  getJobApplicationStatistics: (params) =>
    dispatch(getJobApplicationStatisticsApi(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileViewChart);
