import React, { useRef, useState, useEffect } from "react";
import { connect } from "react-redux";
import { Row, Col } from "antd";

import {
  XSectionBgBorder,
  CarouselH1,
  CarouselPara,
  Title,
  Paragraph,
  Para3,
  SubParagraph,
  StyledLink,
} from "./indexStyle";
import { XContainer } from "../../styles/GlobalStyle";

function AboutUs(props) {
  const [aboutUsData, setAboutUsData] = useState({
    overview:
      "Skill Sprints is a dynamic and innovative global Human Resource consultancy firm focused on enhancing organizational effectiveness through quality recruitment. As a new player in the HR consultancy space, we specialize in helping businesses grow by connecting them with top talent, ensuring they have the right candidates to navigate the complexities of the modern workforce. Our goal is to support organizations in their growth journey by providing exceptional recruitment solutions.",
    vision:
      "At Skill Sprints, we aim to revolutionize global recruitment by providing real-time, accessible, and efficient talent acquisition solutions for businesses across all industries. The flexibility we provide to clients revolves around adapting our recruitment process to meet their specific needs.",
    services: [
      "Global Recruitment Platform: Skill Sprints is a comprehensive online job portal that allows candidates to apply for jobs worldwide and clients to access a live list of shortlisted candidates, ensuring a seamless, real-time hiring experience.",
      "End-to-End Recruitment Support: Our team of expert recruiters works remotely from different parts of the world, providing recruitment support to meet the hiring needs of businesses across any industry.",
      "Industry-Agnostic Talent Acquisition: Whether you’re looking for candidates in tech, finance, healthcare, engineering, or any other sector, Skill Sprints is equipped to deliver qualified professionals globally.",
      "Real-Time Shortlisting: Clients can track and view shortlisted candidates in real-time as our recruiters work to match the best talent with your open positions.",
    ],
    coreValues: [
      "Transparency: We believe in open, honest communication with both candidates and clients.",
      "Innovation: Constantly evolving our platform to provide cutting-edge recruitment tools.",
      "Global Reach: Ensuring opportunities and talent across borders.",
      "Flexibility: We offer clients flexible recruitment solutions, including tailored scalable hiring options, and support for remote, hybrid, or in-office roles, ensuring they can adapt quickly to changing workforce needs.",
    ],
    leadership:
      "Skill Sprints is led by Partner Names Vaishali Gonty & Jyoti Rai, experienced professionals with a passion for transforming recruitment processes. With deep expertise in HR, talent management, finance, and digital transformation, they have built a platform that offers a streamlined hiring experience for businesses worldwide.",
    callToAction:
      "Get in touch with us today to learn how we can transform your recruitment process.",
  });

  useEffect(() => {
    document.body.classList.remove("headerBg");
    document.body.classList.remove("bodyStyle1");
    document.body.classList.remove("bodyStyle2");
    document.body.classList.remove("bodyhomeAfterLogin");
  }, []);
  return (
    <XSectionBgBorder className="pb-5">
      <XContainer fluid>
        <div className="pageFluid py-0 bg-transparent">
          <Row gutter={[30, 30]} align="middle">
            <Col xs={24} className="text-center my-4">
              <Title className="mt-lg-4">About Us</Title>
              <Paragraph>{aboutUsData.overview}</Paragraph>
            </Col>
          </Row>
          <Row
            gutter={[30, 30]}
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "20px 0",
            }}>
            <Col
              xs={24}
              sm={24}
              lg={{ span: 10 }}
              className="text-sm-center p-0"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}>
              <div className="item">
                <img
                  className="img-fluid"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/27fb07fc02cff20cc788735d53684dd469e0f9b6e67090771ba6c797883cc595?placeholderIfAbsent=true&apiKey=ac9769c5e7eb416e8a74975078a910d6"
                  alt="Skill Sprints"
                  width="100%"
                  style={{ height: "22rem" }}
                />
              </div>
            </Col>
            <Col xs={24} sm={24} lg={{ span: 13, offset: 1 }}>
              <CarouselPara>
                <Para3 className="para3" style={{ textAlign: "justify" }}>
                  <Title>Services</Title>
                  <ul style={{ paddingLeft: 0 }}>
                    {aboutUsData.services.map((service, index) => (
                      <li key={index} className="mt-2">
                        <SubParagraph>{service}</SubParagraph>
                      </li>
                    ))}
                  </ul>{" "}
                  <br />
                </Para3>
              </CarouselPara>
            </Col>
          </Row>
          <Row>
            <Col xs={24} sm={24} lg={24}>
              <CarouselPara>
                <Para3 className="para3" style={{ textAlign: "justify" }}>
                  <Title>Vision</Title>
                  <SubParagraph>{aboutUsData.vision}</SubParagraph> <br />
                  <Title>Core Values</Title>
                  <ul style={{ paddingLeft: 0 }}>
                    {aboutUsData.coreValues.map((value, index) => (
                      <li key={index} className="mt-2">
                        <SubParagraph>{value}</SubParagraph>
                      </li>
                    ))}
                  </ul>{" "}
                  <br />
                  <Title>Leadership Insights</Title>
                  <SubParagraph>{aboutUsData.leadership}</SubParagraph> <br />
                  <SubParagraph>
                    <StyledLink href="/contact-us">Get in touch</StyledLink>
                    {aboutUsData.callToAction.split("Get in touch")[1]}
                  </SubParagraph>
                </Para3>
              </CarouselPara>
            </Col>
          </Row>
        </div>
      </XContainer>
    </XSectionBgBorder>
  );
}

export default AboutUs;
