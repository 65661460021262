import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  capitalizeAndSplitByDash,
  getSignedUrlForGetObject,
  timeAgo,
} from "../../utils/helper";

const JobCard = ({
  id,
  job_title,
  company,
  city,
  salary,
  salary_defined_type,
  salary_max,
  salary_min,
  updatedAt,
  employment_type,
  logo,
  skills,
  job_description,
  featured,
  isSelected,
  onClick,
  country,
  s3_folder_name,
}) => {
  const [logoUrl, setLogoUrl] = useState("");

  // Fetch the signed URL for the logo
  useEffect(() => {
    const fetchLogoUrl = async () => {
      const url = await getSignedUrlForGetObject(
        "employers",
        logo,
        s3_folder_name
      );
      setLogoUrl(url);
    };

    fetchLogoUrl();
  }, [logo, s3_folder_name]);
  return (
    <JobCardWrapper
      featured={featured}
      isSelected={isSelected}
      onClick={onClick}>
      <JobInfo>
        <EmployerLogo backgroundColor={logo.backgroundColor}>
          <img src={logoUrl} alt={"Logo"} />
        </EmployerLogo>
        <JobDetails>
          <JobTitle>
            <h3>{job_title}</h3>
            <JobBadges className="ms-2">
              {featured === 1 ? <Badge featured>Featured</Badge> : ""}

              <Badge>{capitalizeAndSplitByDash(employment_type)}</Badge>
            </JobBadges>
          </JobTitle>
          <JobMetadata>
            <MetadataItem>
              <img
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/3d3bcde6297965994169154628627b7bda17f95fc22518345cb7951d20fa4021?placeholderIfAbsent=true&apiKey=ac9769c5e7eb416e8a74975078a910d6"
                alt="city icon"
              />
              <span>{city}</span>
            </MetadataItem>
            <MetadataItem>
              {country === "India" ? (
                <img
                  src="/images/greyrupee.svg"
                  alt="Salary icon"
                  style={{ height: "13.45px", width: "11px" }}
                />
              ) : (
                <img
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/546ba1844e9359eb057f6b1ceab80c4166fdf78e1d7ab24002e0b28509f7e7f8?placeholderIfAbsent=true&apiKey=ac9769c5e7eb416e8a74975078a910d6"
                  alt="Salary icon"
                />
              )}
              <span>
                {salary_defined_type === "fixed"
                  ? salary
                  : `${salary_min} - ${salary_max}`}
              </span>
            </MetadataItem>
            <MetadataItem>
              <img
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/72a1dab8a6cbe1838b4ceacd3e200ce10f08201440beb6c8ed12e23e93a5ebd3?placeholderIfAbsent=true&apiKey=ac9769c5e7eb416e8a74975078a910d6"
                alt="Time icon"
              />
              <span>{timeAgo(updatedAt)}</span>
            </MetadataItem>
          </JobMetadata>
        </JobDetails>
      </JobInfo>
      <SkillTags>
        {skills.map((skill, index) => (
          <SkillTag key={index}>{skill.skill}</SkillTag>
        ))}
      </SkillTags>
      <JobDescription>{job_description}</JobDescription>
    </JobCardWrapper>
  );
};

const JobInfo = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 20px;
  @media (max-width: 576px) {
    flex-direction: column;
  }
`;

const MetadataItem = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  img {
    width: 22px;
    height: 22px;
  }
  @media (max-width: 576px) {
    img {
      width: 18px;
      height: 18px;
    }
  }
`;

const JobMetadata = styled.div`
  display: flex;
  margin-top: 14px;
  align-items: center;
  gap: 16px;
  font-size: 14px;
  color: #636a80;
  font-weight: 400;
  flex-wrap: wrap;
  @media (max-width: 576px) {
    font-size: 12px;
    gap: 12px;
  }
`;

const JobType = styled.span`
  border-radius: 52px;
  background-color: #e8f1ff;
  font-size: 14px;
  color: #275df5;
  font-weight: 400;
  padding: 3px 12px;
  @media (max-width: 576px) {
    font-size: 12px;
  }
`;

const JobTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  flex-wrap: wrap;
  h3 {
    color: #191f33;
    font-size: 20px;
    font-weight: 500;
    line-height: 1.6;
    margin: 0;
  }
  @media (max-width: 576px) {
    h3 {
      font-size: 18px;
    }
  }
`;

const JobDetails = styled.div`
  display: flex;
  flex-direction: column;
  font-family: Inter, sans-serif;
`;

const JobCardWrapper = styled.article`
  border-radius: 12px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 32px;
  cursor: pointer;
  border: 1px solid ${(props) => (props.isSelected ? "#275df5" : "#edf0f5")};
  box-shadow: ${(props) =>
    props.isSelected ? "0 12px 48px 0 rgba(0, 44, 109, 0.1)" : "none"};
  transition: all 0.3s ease;
  &:hover {
    box-shadow: 0px 12px 48px 0px rgba(0, 44, 109, 0.1);
    border-color: #275df5;
  }
  @media (max-width: 768px) {
    padding: 24px;
  }
`;

const EmployerLogo = styled.div`
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 68px;
  height: 68px;
  flex-shrink: 0;
  img {
    border-radius: 6px;
    width: 100%; /* Make the image fully fill the container */
    height: 100%; /* Adjust height to fit container */
    object-fit: contain; /* Keep the aspect ratio and ensure it fits */
  }
`;

const SkillTags = styled.div`
  display: flex;
  margin-top: 24px;
  flex-wrap: wrap;
  gap: 7px;
`;

const SkillTag = styled.span`
  border-radius: 3px;
  background-color: #efefef;
  color: #656565;
  font-size: 10px;
  font-weight: 500;
  padding: 4px 8px;
  white-space: nowrap;
`;

const JobDescription = styled.p`
  color: rgba(20, 20, 20, 0.7);
  margin-top: 24px;
  font: 400 16px/24px DM Sans, sans-serif;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const JobBadges = styled.div`
  display: flex;
  gap: 8px;
  @media (max-width: 768px) {
    margin-top: 10px;
  }
`;

const Badge = styled.span`
  border-radius: 52px;
  padding: 4px 12px;
  font-size: 14px;
  font-weight: 400;
  background-color: ${(props) => (props.featured ? "#ffeded" : "#e8f1ff")};
  color: ${(props) => (props.featured ? "#e05151" : "#275df5")};
`;

export default JobCard;
