import {
  UPDATE_PROFILE,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_FAIL,
  UPDATE_PROFILE_RESET,
} from "../actions/updateProfileAction";

const initialState = {
  apiState: "",
  message: "",
  data: null,
  error: "",
};

const updateProfileReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_PROFILE:
      return {
        ...state,
        apiState: "loading",
      };
    case UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        apiState: "success",
        data: action.response.data,
        message: action.response.message ? action.response.message : "success",
      };
    case UPDATE_PROFILE_FAIL:
      return {
        ...state,
        apiState: "error",
        error: action.response.errors,
        message: action.response.message ? action.response.message : "error",
      };
    case UPDATE_PROFILE_RESET:
      return initialState;
    default:
      return state;
  }
};

export default updateProfileReducer;
