import axios from "axios";
import config from "../config";
import { getUserToken } from "../utils/helper";

export const GET_CANDIDATE_APPLICATIONS_BY_ID =
  "GET_CANDIDATE_APPLICATIONS_BY_ID";
export const GET_CANDIDATE_APPLICATIONS_BY_ID_SUCCESS =
  "GET_CANDIDATE_APPLICATIONS_BY_ID_SUCCESS";
export const GET_CANDIDATE_APPLICATIONS_BY_ID_FAIL =
  "GET_CANDIDATE_APPLICATIONS_BY_ID_FAIL";
export const GET_CANDIDATE_APPLICATIONS_BY_ID_RESET =
  "GET_CANDIDATE_APPLICATIONS_BY_ID_RESET";

export const getCandidateApplicationsById = (params) => {
  return {
    type: GET_CANDIDATE_APPLICATIONS_BY_ID,
    params,
  };
};

export const getCandidateApplicationsByIdSuccess = (response) => {
  return {
    type: GET_CANDIDATE_APPLICATIONS_BY_ID_SUCCESS,
    response,
  };
};

export const getCandidateApplicationsByIdFail = (response) => {
  console.log("action response fail=>", response);
  return {
    type: GET_CANDIDATE_APPLICATIONS_BY_ID_FAIL,
    response,
  };
};

export const getCandidateApplicationsByIdReset = () => {
  return {
    type: GET_CANDIDATE_APPLICATIONS_BY_ID_RESET,
  };
};

export const getCandidateApplicationsByIdApi = (params) => {
  return (dispatch) => {
    dispatch(getCandidateApplicationsById());
    axios
      .get(`${config.api.base_url}/explore/get-candidate-applications-by-id`, {
        params,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + getUserToken(),
        },
      })
      .then((response) => {
        const getCandidateApplicationsByIdData = response.data;
        dispatch(
          getCandidateApplicationsByIdSuccess(getCandidateApplicationsByIdData)
        );
      })
      .catch((error) => {
        const errorData = error.response.data;
        dispatch(getCandidateApplicationsByIdFail(errorData));
      });
  };
};
