import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Row,
  Col,
  Input,
  Form,
  Upload,
  Select,
  notification,
  InputNumber,
  Card,
  Button,
  Result,
} from 'antd';
import {
  ButtonNoBg,
  SubmitBtn,
  SubmitNoBgBtn,
  TabBtnNoBg,
} from '../../styles/Button';

import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import config from '../../config';
import dayjs from 'dayjs';

//Actions
import {
  getIndustries,
  getIndustriesApi,
} from '../../actions/getIndustriesAction';
import {
  getIndustryCategories,
  getIndustryCategoriesApi,
} from '../../actions/getIndustryCategoriesAction';
import {
  getResellerByIdReset,
  getResellerByIdApi,
} from '../../actions/getResellerByIdAction';
import {
  updateResellerReset,
  updateResellerApi,
} from '../../actions/updateResellerAction';

import {
  addResellerReset,
  addResellerApi,
} from '../../actions/addResellerAction';
import { ArrowLeftOutlined } from '@ant-design/icons';

// Others

import {
  maxDropdownSelections,
  maxLocationSelections,
} from '../../utils/constants';
import { validateMobileNumber, handleKeyDown } from '../../utils/formFunctions';
import { v4 as uuidv4 } from 'uuid';
import {
  checkModulePermissions,
  getFileNameFromDocPath,
  getSignedUrlForGetObject,
  getUserToken,
  hasPermission,
} from '../../utils/helper';
import { XCardsTransparent, XContainer } from '../../styles/GlobalStyle';
import { Title } from '../Login/LoginStyle';
import TextArea from 'antd/es/input/TextArea';

const { Option } = Select;
const { Dragger } = Upload;

const AddEditResellers = (props) => {
  let modulePermissions = checkModulePermissions('resellers');

  const {
    getIndustries,
    getIndustriesState,
    getIndustryCategories,
    getIndustryCategoriesState,
    getResellerById,
    getResellerByIdReset,
    getResellerByIdState,
    getResellerByIdResetState,
    addReseller,
    addResellerReset,
    addResellerState,
    addResellerResetState,
    updateReseller,
    updateResellerReset,
    updateResellerResetState,
    updateResellerState,
  } = props;

  const id = useParams().id;
  const navigate = useNavigate();
  const formInitial = {
    first_name: '',
    last_name: '',
    alias_name: '',
    email_address: '',
    mobile_number: '',
    communication_mobile_number: '',
    communication_email_address: '',
    pincode: '',
    city: '',
    state: '',
    address: '',
    agreement_document: '',
    pan_number: '',
  };
  const [form] = Form.useForm();
  const [formData, setFormData] = useState(formInitial);
  const [documentsToUpload, setDocumentsToUpload] = useState([]);
  const [triggerUpdate, setTriggerUpdate] = useState(false);
  const [uploadedDocuments, setUploadedDocuments] = useState(null);
  const [uploadedDocumentsToS3, setUploadedDocumentsToS3] = useState({});
  const [dataLoaded, setDataLoaded] = useState(false);

  useEffect(() => {
    getIndustries();
    if (id) {
      if (hasPermission('resellers', 'view-resellers')) {
        setUploadedDocuments([{ key: 'agreement_document' }]);
        getResellerById({ id: id });
      }
    } else {
      setDataLoaded(true);
      form.setFieldsValue(formData);
    }
  }, []);

  useEffect(() => {
    if (getResellerByIdState.apiState === 'success') {
      const fieldNames = Object.keys(formData);
      const updatedData = {};
      fieldNames.forEach((fieldName) => {
        const fieldValue = getResellerByIdState.data[fieldName];
        if (fieldValue !== undefined) {
          updatedData[fieldName] = fieldValue;
          if (fieldName === 'preferred_job_roles') {
            form.setFieldsValue({
              [fieldName]: fieldValue.map((item) => item._id),
            });
            updatedData[fieldName] = fieldValue.map((item) => item._id);
          } else {
            form.setFieldsValue({
              [fieldName]: fieldValue,
            });
          }
        }
      });

      setFormData({
        ...updatedData,
        ...getResellerByIdState.data,
        pincode: getResellerByIdState.data?.pincode.toString(),
        id: getResellerByIdState.data._id,
        s3_folder_name: getResellerByIdState.data.s3_folder_name,
        preferred_job_roles: getResellerByIdState.data.preferred_job_roles,
      });
      setAllUploadedDocuments(getResellerByIdState.data);
    } else if (getResellerByIdState.apiState === 'error') {
      console.log('API Error=>', getResellerByIdState.message);
      notification.error({
        message: 'Unable to get reseller details',
      });
    }
    getResellerByIdReset();
  }, [getResellerByIdState.apiState]);

  useEffect(() => {
    getIndustryCategories({ industries: formData.industries });
  }, [formData.industries]);

  useEffect(() => {
    if (addResellerState.apiState === 'success') {
      addResellerReset();
      navigate('/reseller');
    } else if (addResellerState.apiState === 'error') {
      console.log('API Error=>', addResellerState);
      // Display notification
      notification.error({
        message: addResellerState.message ?? 'Error',
      });

      // Handle field-specific validation errors
      const fieldErrors = addResellerState.error;
      if (fieldErrors && typeof fieldErrors === 'object') {
        const fields = Object.keys(fieldErrors).map((key) => {
          const message = fieldErrors[key]
            .replace(/_/g, ' ')
            .replace(/\b\w/g, (char) => char.toUpperCase());

          return {
            name: key,
            errors: [message],
          };
        });
        form.setFields(fields);
      }
      addResellerReset();
    }
    setTriggerUpdate(false);
  }, [addResellerState.apiState]);

  useEffect(() => {
    if (updateResellerState.apiState === 'success') {
      updateResellerReset();
      navigate('/reseller');
    } else if (updateResellerState.apiState === 'error') {
      console.log('API Error=>', updateResellerState);
      // Display notification
      notification.error({
        message: updateResellerState.message ?? 'Error',
      });

      // Handle field-specific validation errors
      const fieldErrors = updateResellerState.error;
      if (fieldErrors && typeof fieldErrors === 'object') {
        const fields = Object.keys(fieldErrors).map((key) => {
          const message = fieldErrors[key]
            .replace(/_/g, ' ')
            .replace(/\b\w/g, (char) => char.toUpperCase());

          return {
            name: key,
            errors: [message],
          };
        });
        form.setFields(fields);
      }
      updateResellerReset();
    }
    setTriggerUpdate(false);
  }, [updateResellerState.apiState]);

  useEffect(() => {
    if (triggerUpdate) {
      if (id) {
        updateReseller({ ...formData, id });
      } else {
        addReseller(formData);
      }
      setTriggerUpdate(false);
    }
  }, [triggerUpdate]);

  // Functions
  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const getDocumentObject = async (document_path, folder_name) => {
    let url = await getSignedUrlForGetObject(
      'resellers',
      document_path,
      folder_name
    );
    let file_name = getFileNameFromDocPath(document_path);
    return {
      name: file_name,
      url: url,
      status: 'done',
    };
  };

  const setAllUploadedDocuments = async (data) => {
    const documentPromises = uploadedDocuments.map(async (document) => {
      try {
        const document_path = data[document.key];
        const document_object = await getDocumentObject(
          document_path,
          data.s3_folder_name
        );
        document.name = document_object.name;
        document.url = document_object.url;
        document.status = document_object.status;
        return document;
      } catch (error) {
        console.log('Try and catch error => ', error.message);
        return error;
      }
    });
    await Promise.all(documentPromises);
    setDataLoaded(true);
  };

  const uploadFiles = async () => {
    // keep document paths in an object
    let documentFields = {};
    let allUploadsSuccessful = true; // Track the success status of all uploads
    const s3FolderName = formData.s3_folder_name
      ? formData.s3_folder_name
      : uuidv4();

    // Create an array of promises
    if (documentsToUpload.length > 0) {
      await Promise.all(
        documentsToUpload.map(async (document) => {
          const { fieldName, file, onError, onSuccess, onProgress, fileName } =
            document;
          // Skip the document if it's already uploaded
          if (uploadedDocumentsToS3[fieldName]) {
            return;
          }
          let folder_name = `resellers`;
          let file_name = fileName;
          let get_ext = file.name.split('.');
          let ext = get_ext[get_ext.length - 1];

          try {
            const res = await axios.get(
              `${config.api.base_url}/settings/get-signed-url?action=putObject&folder_name=${folder_name}&file_name=${file_name}&ext=${ext}&id=${s3FolderName}`,
              {
                headers: {
                  'Content-type': 'application/json; charset=UTF-8',
                  Authorization: 'Bearer ' + getUserToken(),
                },
              }
            );

            const result = await axios.put(res.data.data, file, {
              onUploadProgress: ({ total, loaded }) => {
                onProgress(
                  {
                    percent: Math.round((loaded / total) * 100).toFixed(0),
                  },
                  file
                );
              },
            });

            if (result.status === 200) {
              let documentPath = `/${folder_name}/${s3FolderName}/${file_name}.${ext}`;
              documentFields = {
                ...documentFields,
                [fieldName]: documentPath,
              };

              setFormData((prev) => ({
                ...prev,
                [fieldName]: documentPath,
              }));

              onSuccess(result, file);

              // Update the uploaded documents state
              setUploadedDocumentsToS3((prevState) => ({
                ...prevState,
                [fieldName]: documentPath,
              }));
            } else {
              allUploadsSuccessful = false;
            }
          } catch (error) {
            console.log('Try and catch error => ', error.message);
            notification.error({
              message: `Unable to upload file - ${fieldName}`,
            });
            allUploadsSuccessful = false;
          }
        })
      );
    }
    // Only update form data and trigger update if all uploads were successful
    if (allUploadsSuccessful) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        ...documentFields,
        s3_folder_name: s3FolderName,
      }));

      setTriggerUpdate(true);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSelectChange = (value, name) => {
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
    <>
      {hasPermission('resellers', 'add-resellers') ||
      hasPermission('resellers', 'edit-resellers') ? (
        <>
          <XContainer fluid className='mt-3'>
            <Card className='mb-3'>
              <Row>
                <Col xs={24} className='d-flex gap-3'>
                  <Button
                    onClick={() => navigate(-1)}
                    icon={<ArrowLeftOutlined />}
                    size='large'
                  />
                  {id ? (
                    <Title>Edit Reseller</Title>
                  ) : (
                    <Title>Add Reseller</Title>
                  )}
                </Col>
              </Row>
            </Card>
            <XCardsTransparent>
              <div className='mainContent'>
                <div className='pageWrapper'>
                  {dataLoaded && (
                    <Form
                      scrollToFirstError={true}
                      form={form}
                      layout='vertical'
                      // onFinish={onFinish}
                      onFinish={() => {
                        uploadFiles();
                      }}
                      initialValues={formData}>
                      <Row gutter={[30, 0]}>
                        <Col xs={24} sm={12} lg={12}>
                          <Form.Item
                            name='first_name'
                            label='First Name'
                            className='formLable'
                            rules={[
                              {
                                required: true,
                                message: 'Please enter first name',
                              },
                            ]}>
                            <Input
                              onKeyDown={handleKeyDown}
                              name='first_name'
                              placeholder='Enter first name'
                              className='searchInputBrder removeBg'
                              size='large'
                              onChange={handleInputChange}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} lg={12}>
                          <Form.Item
                            name='last_name'
                            label='Last Name'
                            className='formLable'
                            rules={[
                              {
                                required: true,
                                message: 'Please enter last name',
                              },
                            ]}>
                            <Input
                              onKeyDown={handleKeyDown}
                              name='last_name'
                              className='searchInputBrder removeBg'
                              size='large'
                              placeholder='Enter last name'
                              onChange={handleInputChange}
                            />
                          </Form.Item>
                        </Col>

                        <Col xs={24} sm={12} lg={12}>
                          <Form.Item
                            name='mobile_number'
                            label='Mobile Number'
                            className='formLable'
                            rules={[
                              {
                                required: true,
                                message: 'Please enter mobile number',
                              },
                              {
                                validator: validateMobileNumber,
                              },
                            ]}>
                            <Input
                              onKeyDown={handleKeyDown}
                              name='mobile_number'
                              placeholder='Enter mobile number'
                              className='searchInputBrder removeBg'
                              size='large'
                              onChange={handleInputChange}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} lg={12}>
                          <Form.Item
                            name='email_address'
                            label='Email Address'
                            className='formLable'
                            rules={[
                              {
                                required: true,
                                message: 'Please enter email address',
                              },
                              {
                                type: 'email',
                                message: 'Please enter a valid email address',
                              },
                            ]}>
                            <Input
                              onKeyDown={handleKeyDown}
                              name='email_address'
                              placeholder='Enter email address'
                              className='searchInputBrder removeBg'
                              size='large'
                              onChange={handleInputChange}
                            />
                          </Form.Item>
                        </Col>

                        <Col xs={24} sm={12} lg={12}>
                          <Form.Item
                            name='communication_mobile_number'
                            label='Communication Mobile Number'
                            className='formLable'
                            rules={[
                              {
                                required: true,
                                message:
                                  'Please enter communication mobile number',
                              },
                              {
                                validator: validateMobileNumber,
                              },
                            ]}>
                            <Input
                              onKeyDown={handleKeyDown}
                              name='communication_mobile_number'
                              placeholder='Enter communication mobile number'
                              className='searchInputBrder removeBg'
                              size='large'
                              onChange={handleInputChange}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} lg={12}>
                          <Form.Item
                            name='communication_email_address'
                            label='Communication Email Address'
                            className='formLable'
                            rules={[
                              {
                                required: true,
                                message:
                                  'Please enter communication email address',
                              },
                              {
                                type: 'email',
                                message: 'Please enter a valid email address',
                              },
                            ]}>
                            <Input
                              onKeyDown={handleKeyDown}
                              name='communication_email_address'
                              placeholder='Enter communication email address'
                              className='searchInputBrder removeBg'
                              size='large'
                              onChange={handleInputChange}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} lg={24}>
                          <Form.Item
                            name='address'
                            label='Address'
                            className='formLable'
                            rules={[
                              {
                                required: true,
                                message: 'Please enter address',
                              },
                            ]}>
                            <TextArea
                              onKeyDown={handleKeyDown}
                              name='address'
                              className='removeBg'
                              placeholder='Enter address'
                              rows={3}
                              onChange={handleInputChange}
                            />
                          </Form.Item>
                        </Col>

                        <Col xs={24} sm={12} lg={12}>
                          <Form.Item
                            name='country'
                            label='Country'
                            className='formLable'
                            rules={[
                              {
                                required: true,
                                message: 'Please enter Country',
                              },
                            ]}>
                            <Input
                              onKeyDown={handleKeyDown}
                              name='country'
                              placeholder='Enter country'
                              className='searchInputBrder removeBg'
                              size='large'
                              onChange={handleInputChange}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} lg={12}>
                          <Form.Item
                            name='state'
                            label='State'
                            className='formLable'
                            rules={[
                              {
                                required: true,
                                message: 'Please enter state',
                              },
                            ]}>
                            <Input
                              onKeyDown={handleKeyDown}
                              name='state'
                              placeholder='Enter state'
                              className='searchInputBrder removeBg'
                              size='large'
                              onChange={handleInputChange}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} lg={12}>
                          <Form.Item
                            name='city'
                            label='City'
                            className='formLable'
                            rules={[
                              {
                                required: true,
                                message: 'Please enter city',
                              },
                            ]}>
                            <Input
                              onKeyDown={handleKeyDown}
                              name='city'
                              placeholder='Enter city'
                              className='searchInputBrder removeBg'
                              size='large'
                              onChange={handleInputChange}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} lg={12}>
                          <Form.Item
                            name='pincode'
                            label='Pincode'
                            className='formLable'
                            rules={[
                              {
                                required: true,
                                message: 'Please enter pincode',
                              },
                            ]}>
                            <Input
                              onKeyDown={handleKeyDown}
                              name='pincode'
                              placeholder='Enter pincode'
                              className='searchInputBrder removeBg'
                              size='large'
                              onChange={handleInputChange}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={[30, 0]}>
                        <Col xs={24} sm={12}>
                          <Form.Item
                            label='PAN Number'
                            name='pan_number'
                            className='formLable'
                            rules={[
                              {
                                required: true,
                                message: 'Please enter pan number',
                              },
                              {
                                pattern: /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/,
                                message: 'PAN number is invalid',
                              },
                            ]}>
                            <Input
                              onKeyDown={handleKeyDown}
                              name='pan_number'
                              className='searchInputBrder removeBg'
                              size='large'
                              placeholder='Enter PAN Number'
                              onChange={handleInputChange}
                              onInput={(e) =>
                                (e.target.value = e.target.value
                                  .toUpperCase()
                                  .replace(/\s/g, ''))
                              }
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24}>
                          <Form.Item
                            label='Agreement'
                            name='agreement_document'
                            className='formLable'
                            getValueFromEvent={normFile}
                            // rules={[
                            //   {
                            //     required: true,
                            //     message: 'Please upload agreement',
                            //   },
                            //     ]}
                          >
                            <Dragger
                              maxCount={1}
                              onRemove={(file) => {
                                const updatedDocuments =
                                  documentsToUpload.filter(
                                    (item) =>
                                      item.fieldName !== 'agreement_document'
                                  );
                                setDocumentsToUpload(updatedDocuments);
                              }}
                              accept='.pdf'
                              customRequest={(options) => {
                                const existingIndex =
                                  documentsToUpload.findIndex(
                                    (item) =>
                                      item.fieldName === 'agreement_document'
                                  );

                                if (existingIndex !== -1) {
                                  // Update existing object
                                  const updatedDocuments = [
                                    ...documentsToUpload,
                                  ];
                                  updatedDocuments[existingIndex] = {
                                    ...options,
                                    fileName: 'Agreement',
                                    fieldName: 'agreement_document',
                                  };
                                  setDocumentsToUpload(updatedDocuments);
                                } else {
                                  // Add new object
                                  setDocumentsToUpload([
                                    ...documentsToUpload,
                                    {
                                      ...options,
                                      fileName: 'Agreement',
                                      fieldName: 'agreement_document',
                                    },
                                  ]);
                                }

                                options.onSuccess();
                              }}
                              defaultFileList={
                                id
                                  ? [
                                      uploadedDocuments.find(
                                        (doc) =>
                                          doc.key === 'agreement_document'
                                      ),
                                    ]
                                  : []
                              }>
                              <p className='ant-upload-drag-icon'>
                                <img src='/images/upload-icon.svg' alt='' />
                              </p>
                              <div className='uploadHeading'>
                                Drag & drop files or <span>Browse</span>
                              </div>
                              <p className='para3'>
                                Supported formates: JPEG, PNG, JPG
                              </p>
                            </Dragger>
                          </Form.Item>
                        </Col>
                      </Row>

                      <Row className='text-end px-3' align='right'>
                        <Col
                          xs={24}
                          md={24}
                          className='d-flex justify-content-end align-items-center'>
                          <SubmitNoBgBtn className='mr-5' type='primary'>
                            Cancel
                          </SubmitNoBgBtn>
                          <SubmitBtn
                            type='primary'
                            htmlType='submit'
                            className='ms-2'>
                            {id ? 'Save Reseller' : 'Add Recruitment Partner'}
                          </SubmitBtn>
                        </Col>
                      </Row>
                    </Form>
                  )}
                </div>
              </div>
            </XCardsTransparent>
          </XContainer>
        </>
      ) : (
        <Result
          status='404'
          title='404'
          subTitle='Sorry, the page you visited does not exist.'
          extra={
            <Button
              type='primary'
              onClick={() => {
                navigate('/');
              }}>
              Back Home
            </Button>
          }
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  getIndustriesState: state.getIndustries,
  getIndustryCategoriesState: state.getIndustryCategories,
  getResellerByIdState: state.getResellerById,
  getResellerByIdResetState: state.getResellerByIdReset,
  addResellerState: state.addReseller,
  addResellerResetState: state.addResellerReset,
  updateResellerState: state.updateReseller,
  updateResellerResetState: state.updateResellerReset,
});

const mapDispatchToProps = (dispatch) => ({
  getIndustries: (params) => dispatch(getIndustriesApi(params)),
  getIndustryCategories: (params) => dispatch(getIndustryCategoriesApi(params)),
  getResellerById: (params) => dispatch(getResellerByIdApi(params)),
  getResellerByIdReset: (params) => dispatch(getResellerByIdReset(params)),
  addReseller: (params) => dispatch(addResellerApi(params)),
  addResellerReset: (params) => dispatch(addResellerReset(params)),
  updateReseller: (params) => dispatch(updateResellerApi(params)),
  updateResellerReset: (params) => dispatch(updateResellerReset(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddEditResellers);
