import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { loginApi, loginReset } from '../../actions/loginAction';
import { handleInputChange } from '../../utils/formFunctions';
import { getExpiresAt, isLogin } from '../../utils/helper';
import styled from 'styled-components';
import { Form, Input, Button as AntButton } from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';

const Login = (props) => {
  const { login, loginReset, loginState } = props;
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(''); // State for error message

  useEffect(() => {
    if (isLogin()) {
      let expiresAt = getExpiresAt();
      if (expiresAt !== null && new Date() < new Date(expiresAt)) navigate('/');
    }
  }, []);

  useEffect(() => {
    if (loginState.apiState === 'success') {
      setLoading(false);
      localStorage.setItem('accessToken', loginState.data.accessToken);
      localStorage.setItem('refreshToken', loginState.data.refreshToken);
      localStorage.setItem('username', loginState.data.username);
      localStorage.setItem('email', loginState.data.email);
      localStorage.setItem('gender', loginState.data.gender);
      localStorage.setItem('expiresAt', loginState.data.expiresAt);
      localStorage.setItem('role', loginState.data.role.role);
      localStorage.setItem('changePassword', loginState.data.changePassword);
      localStorage.setItem(
        'permissions',
        JSON.stringify(loginState.data.permissions)
      );
      localStorage.setItem('consentGiven', loginState.data.consentGiven);
      loginReset();
      if (!loginState.data.consentGiven) {
        navigate('/consent');
      } else {
        loginState.data.role.role === 'candidate'
          ? navigate('/explore')
          : navigate('/dashboard');
      }
    } else if (loginState.apiState === 'error') {
      setLoading(false);
      setErrorMessage('Invalid username or password');
      loginReset();
    }
    setLoading(false);
  }, [loginState]);

  return (
    <LoginContainer>
      <LoginWrapper>
        <BackgroundImage
          loading='lazy'
          src='https://cdn.builder.io/api/v1/image/assets/TEMP/27fb07fc02cff20cc788735d53684dd469e0f9b6e67090771ba6c797883cc595?placeholderIfAbsent=true&apiKey=ac9769c5e7eb416e8a74975078a910d6'
          alt='Background'
        />
        <LoginForm
          form={form}
          name='login'
          initialValues={{ remember: true }}
          onFinish={() => {
            setLoading(true);
            login(formData);
          }}>
          <LoginContent>
            <LoginHeader>Login to Skillsprints</LoginHeader>
            <LoginFormWrapper>
              <LoginFormContent>
                <LoginOptions>
                  <Divider />
                  <OrSignInText>Sign in with email</OrSignInText>
                  <Divider />
                </LoginOptions>
                <InputFieldsWrapper>
                  <InputField>
                    <Label>Email*</Label>
                    <StyledFormItem
                      name='email'
                      rules={[
                        { required: true, message: 'Please input your Email!' },
                        {
                          type: 'email',
                          message: 'The input is not valid E-mail!',
                        },
                      ]}>
                      <StyledInput
                        name='email'
                        onChange={(e) =>
                          handleInputChange(e, formData, setFormData)
                        }
                        aria-label='Email'
                      />
                    </StyledFormItem>
                  </InputField>
                  <InputField>
                    <Label>Password*</Label>
                    <StyledFormItem
                      name='password'
                      rules={[
                        {
                          required: true,
                          message: 'Please input your Password!',
                        },
                      ]}>
                      <StyledPassword
                        name='password'
                        onChange={(e) =>
                          handleInputChange(e, formData, setFormData)
                        }
                        iconRender={(visible) =>
                          visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                        }
                        aria-label='Password'
                      />
                    </StyledFormItem>
                  </InputField>{' '}
                  {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
                </InputFieldsWrapper>
                <ForgotPasswordLink to='/forgot-email-password'>
                  Forgot password?
                </ForgotPasswordLink>
                <SignUpLink to='/sign-up'>
                  Don't have an account yet, sign up!
                </SignUpLink>
                {loading ? (
                  <SignInButton loading={loading} type='primary'>
                    Signing In...
                  </SignInButton>
                ) : (
                  <SignInButton type='primary' htmlType='submit'>
                    Sign in
                  </SignInButton>
                )}
                {/* <GoogleSignInButton type="button">
                  <GoogleIcon
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/0b620af371ec071306817f4e16e35f725ac350e9fc64e60a15955cf612157082?placeholderIfAbsent=true&apiKey=ac9769c5e7eb416e8a74975078a910d6"
                    alt="Google icon"
                  />
                  Sign in with Google
                </GoogleSignInButton> */}
              </LoginFormContent>
            </LoginFormWrapper>
          </LoginContent>
        </LoginForm>
      </LoginWrapper>
    </LoginContainer>
  );
};

const LoginContainer = styled.main`
  background-color: #fff;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  min-height: 100vh;
`;

// Style for the error message
const ErrorMessage = styled.div`
  color: red;
  margin-top: 10px;
  text-align: center;
`;

const LoginWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 100vh;
  align-items: flex-start;
  justify-content: center;
  @media (max-width: 991px) {
    max-width: 100%;
  }
  @media (max-width: 850px) {
    align-items: center;
  }
`;

const BackgroundImage = styled.img`
  position: absolute;
  inset: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
  @media (max-width: 850px) {
    display: none;
  }
`;

const LoginForm = styled(Form)`
  position: relative;
  background-color: #fff;
  display: flex;
  width: 50%;
  min-height: 100vh;
  max-width: 100%;
  flex-direction: column;
  overflow: hidden;
  padding: 40px 54px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  @media (max-width: 991px) {
    padding: 30px;
  }
  @media (max-width: 850px) {
    width: 100%;
    margin: auto;
    box-shadow: none;
  }
`;

const Logo = styled.h1`
  color: #275df5;
  align-self: flex-start;
  font: 600 22px/40px 'Inter', sans-serif;
  margin-bottom: 40px;
`;

const LoginContent = styled.div`
  display: flex;
  width: 100%;
  max-width: 410px;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
`;

const LoginHeader = styled.h2`
  width: 100%;
  color: #141c24;
  text-align: center;
  font: 600 28px/1.2 'Inter', sans-serif;
  margin-bottom: 36px;
`;

const LoginFormWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
`;

const LoginFormContent = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const LoginOptions = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  gap: 16px;
  color: #637083;
  text-align: center;
  justify-content: center;
  font: 400 14px 'Inter', sans-serif;
  margin-bottom: 32px;
`;

const Divider = styled.div`
  height: 1px;
  flex: 1;
  background-color: #e4e7ec;
`;

const OrSignInText = styled.span`
  white-space: nowrap;
`;

const InputFieldsWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 16px;
`;

const InputField = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const Label = styled.label`
  color: #253142;
  font: 500 14px/1.5 'Inter', sans-serif;
  margin-bottom: 6px;
`;

const StyledFormItem = styled(Form.Item)`
  margin-bottom: 0;
`;

const StyledInput = styled(Input)`
  border-radius: 8px;
  background-color: #fff;
  width: 100%;
  padding: 12px;
  border: 1px solid #ced2da;
  font: 400 16px/1.5 'Inter', sans-serif;
  &:focus {
    border-color: #275df5;
    box-shadow: 0 0 0 2px rgba(39, 93, 245, 0.2);
  }
`;

const StyledPassword = styled(Input.Password)`
  border-radius: 8px;
  background-color: #fff;
  width: 100%;
  padding: 12px;
  border: 1px solid #ced2da;
  font: 400 16px/1.5 'Inter', sans-serif;
  &:focus {
    border-color: #275df5;
    box-shadow: 0 0 0 2px rgba(39, 93, 245, 0.2);
  }
`;

const ForgotPasswordLink = styled(Link)`
  align-self: flex-end;
  color: #637083;
  font: 400 14px/1.5 'Inter', sans-serif;
  cursor: pointer;
  text-decoration: none;
  margin-bottom: 24px;
  &:hover,
  &:focus {
    text-decoration: underline;
  }
`;
const SignUpLink = styled(Link)`
  align-self: flex-center;
  text-align: center;
  color: #637083;
  font: 400 14px/1.5 'Inter', sans-serif;
  cursor: pointer;
  text-decoration: none;
  margin-bottom: 24px;
  &:hover,
  &:focus {
    text-decoration: underline;
  }
`;

const SignInButton = styled(AntButton)`
  width: 100%;
  height: auto;
  padding: 12px;
  font: 600 16px/1.5 'Inter', sans-serif;
  border-radius: 8px;
  margin-bottom: 16px;
  &:hover,
  &:focus {
    opacity: 0.9;
  }
`;

const GoogleSignInButton = styled.button`
  border-radius: 8px;
  background-color: #f2f4f7;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  gap: 8px;
  color: #253142;
  padding: 12px;
  font: 600 16px/1.5 'Inter', sans-serif;
  border: none;
  cursor: pointer;
  &:hover,
  &:focus {
    background-color: #e4e7ec;
  }
`;

const GoogleIcon = styled.img`
  width: 20px;
  height: 20px;
`;

const mapStateToProps = (state) => ({
  loginState: state.login,
});

const mapDispatchToProps = (dispatch) => ({
  login: (params) => dispatch(loginApi(params)),
  loginReset: () => dispatch(loginReset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
