import {
  GET_INDUSTRY_CATEGORIES,
  GET_INDUSTRY_CATEGORIES_SUCCESS,
  GET_INDUSTRY_CATEGORIES_FAIL,
  GET_INDUSTRY_CATEGORIES_RESET,
} from "../actions/getIndustryCategoriesAction";

const initialState = {
  apiState: "",
  message: "",
  data: null,
  error: "",
};

const getIndustryCategoriesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_INDUSTRY_CATEGORIES:
      return {
        ...state,
        apiState: "loading",
      };
    case GET_INDUSTRY_CATEGORIES_SUCCESS:
      return {
        ...state,
        apiState: "success",
        data: action.response.data,
        message: action.response.message ? action.response.message : "success",
      };
    case GET_INDUSTRY_CATEGORIES_FAIL:
      return {
        ...state,
        apiState: "error",
        error: action.response.errors,
        message: action.response.message ? action.response.message : "success",
      };
    case GET_INDUSTRY_CATEGORIES_RESET:
      return initialState;
    default:
      return state;
  }
};

export default getIndustryCategoriesReducer;
