import config from "../config";
import axios from "axios";
import { getUserToken } from "../utils/helper";

export const GET_EMPLOYER_ORGANIZATIONS_LIST =
  "GET_EMPLOYER_ORGANIZATIONS_LIST";
export const GET_EMPLOYER_ORGANIZATIONS_LIST_SUCCESS =
  "GET_EMPLOYER_ORGANIZATIONS_LIST_SUCCESS";
export const GET_EMPLOYER_ORGANIZATIONS_LIST_FAIL =
  "GET_EMPLOYER_ORGANIZATIONS_LIST_FAIL";
export const GET_EMPLOYER_ORGANIZATIONS_LIST_RESET =
  "GET_EMPLOYER_ORGANIZATIONS_LIST_RESET";

export const getEmployerOrganizationsList = (params) => {
  return {
    type: GET_EMPLOYER_ORGANIZATIONS_LIST,
    params,
  };
};

export const getEmployerOrganizationsListSuccess = (response) => {
  return {
    type: GET_EMPLOYER_ORGANIZATIONS_LIST_SUCCESS,
    response,
  };
};

export const getEmployerOrganizationsListFail = (response) => {
  return {
    type: GET_EMPLOYER_ORGANIZATIONS_LIST_FAIL,
    response,
  };
};

export const getEmployerOrganizationsListReset = () => {
  return {
    type: GET_EMPLOYER_ORGANIZATIONS_LIST_RESET,
  };
};

export const getEmployerOrganizationsListApi = (data) => {
  return (dispatch) => {
    dispatch(getEmployerOrganizationsList());
    axios
      .get(`${config.api.base_url}/employers/get-organizations`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getUserToken()}`,
        },
        params: data,
      })
      .then((response) => {
        const getEmployerOrganizationsListData = response.data;
        dispatch(
          getEmployerOrganizationsListSuccess(getEmployerOrganizationsListData)
        );
      })
      .catch((error) => {
        const errorMsg = error.message;
        dispatch(getEmployerOrganizationsListFail(errorMsg));
      });
  };
};
