import cookie from "js-cookie";
import axios from "axios";
import config from "../config";

const getAccessToken = async (refreshToken) => {
  const token = await axios.post(`${config.api.auth_url}get-access-token`, {
    refreshToken,
  });
  return token.data.accessToken;
};

export const validateCookies = ({ req, res }) => {
  if (req.cookies.accessToken) {
    return {
      props: {},
    };
  } else {
    if (req.cookies.refreshToken) {
      const accessToken = getAccessToken(req.cookies.refreshToken);
      cookie.set("accessToken", accessToken);
      cookie.remove("refreshToken");
      return {
        props: {},
      };
    } else {
      return {
        redirect: {
          destination: "/users",
          permanent: false,
        },
      };
    }
  }
};
