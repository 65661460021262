import React, { useEffect, useState } from "react";
import styled from "styled-components";
import SearchSection from "../../components/SearchSection/index";
import {
  Button,
  Col,
  DatePicker,
  Dropdown,
  notification,
  Pagination,
  Radio,
  Result,
  Row,
  Slider,
} from "antd";
import {
  checkModulePermissions,
  fetchListThroughPagination,
  hasPermission,
  urlPagination,
} from "../../utils/helper";
import { useForm } from "antd/es/form/Form";
import {
  getJobApplicationsApi,
  getJobApplicationsReset,
} from "../../actions/getJobApplicationsAction";
import { connect } from "react-redux";
import ApplicationCard from "../../components/CandidateApplicationCards";
import { useNavigate, useParams } from "react-router-dom";
import { XForm } from "../Login/LoginStyle";
import CaseSearch from "../../components/CaseSearch/CaseSearch";
import {
  application_status_list,
  employment_type_list,
  jobFilters,
  rangePresets,
} from "../../utils/constants";
import { filterByLabel } from "../../utils/formFunctions";
import { XSelect } from "../../styles/GlobalStyle";
import { getSkillsApi } from "../../actions/getSkillsAction";
import {
  updateJobApplicationStatusApi,
  updateJobApplicationStatusReset,
} from "../../actions/updateJobApplicationStatusAction";
import { getIndustryCategoriesApi } from "../../actions/getIndustryCategoriesAction";
import { getIndustriesApi } from "../../actions/getIndustriesAction";
import { getEmployerOrganizationsListApi } from "../../actions/getEmployerOrganizationsListAction";
const { RangePicker } = DatePicker;

const Application = (props) => {
  const defaultFilters = {
    page: 1,
    limit: 10,
    state: "",
    category: "",
    posted: "all",
    sortDirection: null,
    candidate_search: "",
    application_status: "",
    skills: [],
    industry: "",
  };
  const id = useParams().id;
  const navigate = useNavigate();

  const {
    getJobApplications,
    getJobApplicationsState,
    getSkills,
    getSkillsState,
    updateJobApplicationStatusState,
    updateJobApplicationStatus,
    updateJobApplicationStatusReset,
    getEmployerOrganizationsList,
    getEmployerOrganizationsListState,
    getIndustryCategories,
    getIndustryCategoriesState,
    getIndustries,
    getIndustriesState,
  } = props;

  const [form] = useForm();
  const [selectedJob, setSelectedJob] = useState(null);
  // const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const getFiltersFromUrl = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const urlFilters = {};

    // Iterate through each query parameter and set it in the state
    for (const [key, value] of urlParams.entries()) {
      urlFilters[key] = value;
    }

    // Merge with default filters or initial state as needed
    const filters = {
      ...defaultFilters, // Replace with your default filters
      ...urlFilters,
    };

    return filters;
  };
  const initialFilters = getFiltersFromUrl();
  const [filters, setFilters] = useState(initialFilters);

  const jobsPerPage = 10;

  const indexOfLastJob = filters.page * jobsPerPage;
  const indexOfFirstJob = indexOfLastJob - jobsPerPage;
  const [totalRecords, setTotalRecords] = useState("");
  const [open, setOpen] = useState(false);
  const [currentJobs, setCurrentJobs] = useState(null);
  const [jobTitle, setJobTitle] = useState(null);

  const handlePageChange = (page) => {
    fetchListThroughPagination(page, setFilters, filters);
    setFilters((prev) => ({ ...prev, page: page }));
  };

  const handleJobClick = (candidate_id, application_id) => {
    setSelectedJob(candidate_id);
    navigate(
      `/applications/candidate-details/${candidate_id}?application_id=${application_id}`
    );
  };

  const applyFilters = () => {
    const queryParams = new URLSearchParams(window.location.search);

    // Iterate through filters and update the query parameters
    Object.entries(filters).forEach(([key, value]) => {
      const currentValue = queryParams.get(key);

      // Check if the parameter exists in the current query and has a value, if yes, update its value
      if (currentValue !== null) {
        queryParams.set(key, value);
      } else if (queryParams.has(key)) {
        // If the parameter exists but has no value, keep its existing value
        queryParams.set(key, queryParams.get(key));
      } else {
        // If the parameter does not exist in the current query, append it with the current value
        queryParams.append(key, value);
      }
    });

    // Set the page to 1
    queryParams.set("page", "1");
    queryParams.set("sort", "createdAt");
    filters.page = 1;

    // Update the URL
    const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
    window.history.pushState({}, "", newUrl);
  };

  const clearFilters = () => {
    const queryParams = new URLSearchParams(window.location.search);

    // Iterate through defaultFilters and update the query parameters
    Object.entries(defaultFilters).forEach(([key, value]) => {
      const currentValue = queryParams.get(key);

      // Check if the parameter exists in the current query and has a value, if yes, update its value
      if (currentValue !== null) {
        queryParams.set(key, value);
      } else if (queryParams.has(key)) {
        // If the parameter exists but has no value, keep its existing value
        queryParams.set(key, queryParams.get(key));
      } else {
        // If the parameter does not exist in the current query, append it with the default value
        queryParams.append(key, value);
      }
    });

    // Clear the form fields
    form.resetFields();

    // Update the state with default values
    setFilters(defaultFilters);

    // Update the URL
    const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
    window.history.pushState({}, "", newUrl);
  };

  const showDrawer = () => {
    getEmployerOrganizationsList({});
    getIndustries({});
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  const handleSortChange = (value, direction) => {
    setFilters({
      ...filters,
      sortBy: value,
      sortDirection: direction,
    });
  };
  const handleStatusUpdate = (status, id) => {
    updateJobApplicationStatus({
      application_id: id,
      status,
      // rejectReason,
    });
  };

  const handleCategorySearch = (search) => {
    if (search.length >= 3) {
      getIndustryCategories({
        search: search,
        industries: filters.industry ? [filters.industry] : null,
      });
    } else if (search.length == 0) {
      getIndustryCategories({
        industries: [filters.industry],
      });
    }
  };

  const handleDateChange = (dateRange) => {
    if (dateRange) {
      setFilters({
        ...filters,
        page: 1,
        posted: [
          dateRange[0].format("YYYY-MM-DD"),
          dateRange[1].format("YYYY-MM-DD"),
        ],
      });
    } else {
      setFilters({
        ...filters,
        posted: [],
      });
    }
  };

  useEffect(() => {
    // Retrieve filters from the updated URL
    const updatedFilters = getFiltersFromUrl();
    setFilters({ ...updatedFilters });

    // Make API call with updated filters and pagination
    if (hasPermission("candidate-applications", "list-applications")) {
      getJobApplications({ ...updatedFilters, job_inventory_id: id });
    }
  }, [window.location.search]);

  useEffect(() => {
    if (getJobApplicationsState.apiState === "success") {
      setTotalRecords(getJobApplicationsState.data.totalCount);
      setSelectedJob(getJobApplicationsState.data.jobList[0]);
      setJobTitle(getJobApplicationsState.data.job_title);

      if (getJobApplicationsState.data.jobList.length === 1) {
        setCurrentJobs(getJobApplicationsState.data.jobList); // Return the whole list with one job
      } else {
        setCurrentJobs(
          getJobApplicationsState.data.jobList.slice(
            indexOfFirstJob,
            Math.min(
              indexOfLastJob,
              getJobApplicationsState.data.jobList.length
            )
          )
        );
      }
      getJobApplicationsReset();
    } else if (getJobApplicationsState.apiState === "error") {
      console.log("API Error=>", getJobApplicationsState.message);
      notification.error({
        message: "Unable to get Job List",
      });
      getJobApplicationsReset();
    }
  }, [getJobApplicationsState.apiState]);

  useEffect(() => {
    if (updateJobApplicationStatusState.apiState === "success") {
      notification.success({
        message: updateJobApplicationStatusState.message,
      });
      getJobApplications({ ...filters, job_inventory_id: id });
    } else if (updateJobApplicationStatusState.apiState === "error") {
      notification.error({
        message: updateJobApplicationStatusState.message,
      });
    }
    updateJobApplicationStatusReset();
  }, [updateJobApplicationStatusState.apiState]);

  return (
    <>
      {hasPermission("candidate-applications", "list-applications") ? (
        <>
          <CaseSearch
            searchOption="reseller"
            title="Resellers"
            filters={[
              <XForm.Item
                name="application_status"
                colon={false}
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
                label="Status"
                className="removeFormMargin">
                <Radio.Group
                  onChange={(value) => {
                    setFilters({
                      ...filters,
                      application_status: value.target.value,
                    });
                  }}
                  name="application_status"
                  defaultValue={filters.application_status || null}>
                  <Row>
                    {application_status_list.map((item, index) => (
                      <Col xs={12} lg={12} md={12} xl={12}>
                        <Radio value={item.value} className="filter-sub-item">
                          {item.label}
                        </Radio>
                      </Col>
                    ))}
                  </Row>
                </Radio.Group>
              </XForm.Item>,
              <XForm.Item colon={false} label="Skills" name="skills">
                <XSelect
                  showSearch
                  allowClear
                  onFocus={() => {
                    getSkills({});
                  }}
                  size="large"
                  className="removeBg"
                  mode="multiple"
                  placeholder="Select skills"
                  filterOption={filterByLabel}
                  defaultValue={filters.skills || null}
                  onChange={(value) => {
                    setFilters({
                      ...filters,
                      skills: value,
                    });
                  }}
                  onClear={() => {
                    setFilters({
                      ...filters,
                      skills: "",
                    });
                  }}
                  options={
                    getSkillsState.apiState == "success" &&
                    getSkillsState.data.length > 0
                      ? getSkillsState.data.map((item) => {
                          return {
                            value: item._id,
                            label: item.skill,
                            type: "skill",
                            id: item._id,
                          };
                        })
                      : []
                  }></XSelect>
              </XForm.Item>,
              <XForm.Item name="salary" label="Salary" className="selectBox">
                <Slider
                  range
                  min={10000}
                  max={10000000}
                  step={10000}
                  defaultValue={[
                    filters.salary_min || 2000000,
                    filters.salary_max || 5000000,
                  ]}
                  onChange={(value) => {
                    setFilters({
                      ...filters,
                      salary_min: value[0],
                      salary_max: value[1],
                    });
                  }}
                />
              </XForm.Item>,
              <XForm.Item
                name="posted"
                colon={false}
                label="By Date"
                className="removeFormMargin">
                <XForm.Item colon={false} name="posted" className="selectBox">
                  <RangePicker
                    size="large"
                    className="removeShadow ml-2"
                    presets={rangePresets}
                    onChange={handleDateChange}
                    defaultValue={filters.posted || null}
                    allowClear
                    style={{
                      borderRadius: "50px",
                      height: "40px",
                    }}></RangePicker>
                </XForm.Item>
              </XForm.Item>,
              <XForm.Item
                colon={false}
                name="sortBy"
                label="Sort By"
                className="selectBox">
                <Dropdown
                  className="removeShadow w-100 ms-0"
                  trigger={["click"]}
                  menu={{
                    items: jobFilters(handleSortChange), // Pass the dynamic function here
                  }}>
                  <Button
                    className="shortBtn"
                    style={{
                      border: "none",
                      padding: 10,
                      height: "40px",
                      width: "110px",
                      backgroundColor: "#ffffff",
                      border: "1px solid #d9d9d9",
                      color: "#000",
                      display: "flex",
                      flexDirection: "row",
                    }}>
                    <p>Sort</p>
                    <img className="mt-1" src="/images/short-icon.svg" alt="" />
                  </Button>
                </Dropdown>
              </XForm.Item>,
            ]}
            onClose={onClose}
            open={open}
            applyFilters={applyFilters}
            form={form}
            clearFilters={clearFilters}
          />
          <SearchSection
            application={true}
            setFilters={setFilters}
            filters={filters}
          />
          <PageContainer>
            <Header
              className="align-items-center flex-wrap"
              style={{
                margin: "10px 0px",
              }}>
              <JobCount>Applications for {jobTitle}</JobCount>
              <FilterContainer onClick={() => showDrawer()}>
                <FilterButton>
                  <img
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/d0e11f66e694b5fbdadd8edb8109ba7b24c06cb1f66c477f282ec451dfd237e7?placeholderIfAbsent=true&apiKey=ac9769c5e7eb416e8a74975078a910d6"
                    alt="Filter icon"
                  />
                  Filter
                </FilterButton>
              </FilterContainer>
            </Header>
            <ContentContainer>
              <JobListContainer>
                {currentJobs &&
                  currentJobs.length > 0 &&
                  currentJobs.map((job) => (
                    <ApplicationCard
                      key={job._id}
                      {...job}
                      onClick={() => handleJobClick(job.candidate_id, job._id)}
                      handleStatusUpdate={handleStatusUpdate}
                    />
                  ))}
              </JobListContainer>
            </ContentContainer>
            <PaginationContainer>
              <StyledPagination
                current={filters.page || 1}
                total={totalRecords || 0}
                pageSize={jobsPerPage}
                onChange={handlePageChange}
                showSizeChanger={false}
              />
            </PaginationContainer>
          </PageContainer>
        </>
      ) : (
        <Result
          status="403"
          title="403"
          subTitle="Sorry, you are not authorized to access this page."
          extra={<Button type="primary">Back Home</Button>}
        />
      )}
    </>
  );
};

const StyledPagination = styled(Pagination)`
  margin-top: 40px;

  .ant-pagination-item {
    border-radius: 50%;
  }

  .ant-pagination-item-active {
    background-color: #275df5;
    border-color: #275df5;
  }

  .ant-pagination-item-active a {
    color: #fff;
  }

  .ant-pagination-prev,
  .ant-pagination-next {
    .ant-pagination-item-link {
      border-radius: 50%;
    }
  }
`;

const PaginationContainer = styled.nav`
  align-self: center;
  justify-content: center;
  display: flex;
  margin-top: 40px;
  align-items: center;
  gap: 8px;
`;

const PageContainer = styled.div`
  max-width: 1150px;
  margin: 0 auto;
  padding: 40px 20px;
`;

const Header = styled.header`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
  row-gap: 15px;
`;

const JobCount = styled.h2`
  font-size: 32px;
  font-weight: 700;
  color: #141414;
`;

const FilterContainer = styled.div`
  display: flex;
  gap: 16px;
  @media (max-width: 480px) {
    width: 100%;
    justify-content: center;
  }
`;

const FilterButton = styled.button`
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: #fff;
  border: 1px solid rgba(20, 20, 20, 0.1);
  border-radius: 4px;
  padding: 12px 16px;
  font-size: 16px;
  color: #141414;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background-color: #f5f5f5;
  }

  img {
    width: 24px;
    height: 24px;
  }
  @media (max-width: 480px) {
    width: 100%;
    justify-content: center;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  gap: 40px;

  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;

const JobListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  max-width: 100%;

  @media (max-width: 1024px) {
    max-width: 100%;
  }
`;

const mapStateToProps = (state) => ({
  getJobApplicationsState: state.getJobApplications,
  getSkillsState: state.getSkills,
  updateJobApplicationStatusState: state.updateJobApplicationStatus,
  getIndustryCategoriesState: state.getIndustryCategories,
  getIndustriesState: state.getIndustries,
  getEmployerOrganizationsListState: state.getEmployerOrganizationsList,
});

const mapDispatchToProps = (dispatch) => ({
  getJobApplications: (params) => dispatch(getJobApplicationsApi(params)),
  getSkills: (params) => dispatch(getSkillsApi(params)),
  updateJobApplicationStatus: (params) =>
    dispatch(updateJobApplicationStatusApi(params)),
  updateJobApplicationStatusReset: (params) =>
    dispatch(updateJobApplicationStatusReset(params)),
  getEmployerOrganizationsList: (params) =>
    dispatch(getEmployerOrganizationsListApi(params)),
  getIndustryCategories: (params) => dispatch(getIndustryCategoriesApi(params)),
  getIndustries: (params) => dispatch(getIndustriesApi(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Application);
