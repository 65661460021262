import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getJobCountByIndustryApi } from '../../actions/getJobCountByIndustryAction';
import styled from 'styled-components';
import { Card, Typography, Space, Progress } from 'antd';

const { Title, Text } = Typography;

const JobApplicationsTable = (props) => {
  const { getJobCountByIndustryState, getJobCountByIndustry } = props;

  const [industryData, setIndustryData] = useState([]);

  useEffect(() => {
    if (
      //   getJobCountByIndustryState &&
      getJobCountByIndustryState.apiState === 'success'
    ) {
      console.log(
        getJobCountByIndustryState,
        'Statistics fetched successfully'
      );
      // You can update the chart data here
      setIndustryData(getJobCountByIndustryState.data);
    } else if (
      getJobCountByIndustryState &&
      getJobCountByIndustryState.apiState === 'error'
    ) {
      console.error(
        'Failed to fetch statistics:',
        getJobCountByIndustryState.error
      );
    }
  }, [getJobCountByIndustryState]);

  useEffect(() => {
    getJobCountByIndustry();
  }, []);

  const statusOrder = [
    'applied',
    'shortlisted',
    'interview_scheduled',
    'hired',
    'rejected',
    'rejected_by_employer',
    'application_in_review',
    'on_hold',
  ];

  const getStatusTitle = (status) => {
    const titles = {
      applied: 'Applied',
      shortlisted: 'Shortlisted',
      interview_scheduled: 'Interview Scheduled',
      hired: 'Hired',
      rejected: 'Rejected',
      rejected_by_employer: 'Rejected by Employer',
      application_in_review: 'In Review',
      on_hold: 'On Hold',
    };
    return titles[status] || status;
  };

  const getStatusColor = (status) => {
    const colors = {
      applied: '#54B435',
      shortlisted: '#FFA500',
      interview_scheduled: '#4D96FF',
      hired: '#00C897',
      rejected: '#FF6B6B',
      rejected_by_employer: '#FF1E00',
      application_in_review: '#9376E0',
      on_hold: '#FFD93D',
    };
    return colors[status] || '#000000';
  };

  return (
    <>
      {industryData.map((data, index) => (
        <StyledCard key={index}>
          <CardHeader>
            <Title level={4}>{data.industry}</Title>
            <Text type='secondary'>Job Count: {data.jobCount}</Text>
          </CardHeader>
          <TableContainer>
            {statusOrder.map((status) => {
              const statusData = data.applicationCounts[status];
              if (statusData) {
                return (
                  <TableRow key={status}>
                    <StatusInfo>
                      <StatusTitle>{getStatusTitle(status)}</StatusTitle>
                      <StatusCount>{statusData.count}</StatusCount>
                    </StatusInfo>
                    <ProgressBar>
                      <Progress
                        percent={parseFloat(statusData.percentage)}
                        strokeColor={getStatusColor(status)}
                        showInfo={false}
                      />
                    </ProgressBar>
                    <PercentageText>{statusData.percentage}%</PercentageText>
                  </TableRow>
                );
              }
              return null;
            })}
          </TableContainer>
        </StyledCard>
      ))}
    </>
  );
};

const StyledCard = styled(Card)`
  width: 100%;
  border-radius: 16px;
  background-color: #f6f9ff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-top: 16px;
  margin-bottom: 40px;

  .ant-card-body {
    padding: 24px;
  }
`;

const CardHeader = styled.div`
  margin-bottom: 24px;
`;

const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const TableRow = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const StatusInfo = styled.div`
  display: flex;
  align-items: center;
  width: 200px;
  @media (max-width: 768px) {
    width: 150px;
  }
`;

const StatusTitle = styled(Text)`
  flex: 1;
`;

const StatusCount = styled(Text)`
  font-weight: bold;
  width: 30px;
  text-align: right;
`;

const ProgressBar = styled.div`
  flex: 1;
`;

const PercentageText = styled(Text)`
  width: 50px;
  text-align: right;
`;

const mapStateToProps = (state) => ({
  getJobCountByIndustryState: state.getJobCountByIndustry,
});

const mapDispatchToProps = (dispatch) => ({
  getJobCountByIndustry: (params) => dispatch(getJobCountByIndustryApi(params)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(JobApplicationsTable);
