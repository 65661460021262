import { Button, Card, Pagination, Affix, List } from "antd";
import styled, { createGlobalStyle, keyframes } from "styled-components";
import { NavLink } from "react-router-dom";

import Container from "react-bootstrap/Container";
import {
  Layout,
  Table,
  Tag,
  Dropdown,
  Modal,
  Form,
  Select,
  Switch,
  AutoComplete,
  Input,
} from "antd";
const { Search } = Input;

export const Theme = {
  colors: {
    white: "#fff",
    menu1: "#a7a7a7",
    menu2: "#232323",
    color1: "#757575",
    color2: "#081D16",
    color3: "#d0e0df",
    color4: "#7f8e89",
    color5: "#f8f8f8",
    color6: "#dadee3",
    color7: "#11263c",
    color8: "#868FA0",
    color9: "#687182",
    color10: "#9B68BF",
    fontPoppins: "'Poppins', sans-serif !important ",
    fontDM: "'DM Sans', sans-serif !important ",
    fontInter: "'Inter', sans-serif !important ",
  },
  media: { mobile: "768px", tab: "998px" },
};
export const XLayout = styled(Layout)`
  background: none !important;
  // margin-bottom: 15px;
`;
export const XPagination = styled(Pagination)`
  .ant-pagination-prev,
  .ant-pagination-next {
    background: #fff;
  }
  .ant-pagination-prev,
  .ant-pagination-next,
  .ant-pagination-disabled {
    border: 1px solid #bebebe;
    min-width: 20px;
    height: 20px;
    line-height: 10px;
  }

  .ant-pagination-disabled,
  .ant-pagination-prev,
  .ant-pagination-next {
    span svg {
      font-size: 10px;
    }
  }

  .ant-pagination-item {
    min-width: 20px;
    border: 1px solid #bebebe;
    height: 20px;
    line-height: 20px;
    background: #fff;
    margin-inline-end: 15px;
  }
  .ant-pagination-item a {
    padding: 0px;
    font-size: 11px;
  }
  .ant-pagination-item-active {
    border-color: ${({ theme }) => theme.colors.bgButton1} !important;
    color: ${({ theme }) => theme.colors.bgButton1} !important;
  }
`;

export const XContainer = styled(Container)`
  padding: 0px 60px;
  @media only screen and (max-width: 768.99px) {
    padding: 0px 15px;
  }
  @media only screen and (min-width: 1920.99px) {
    width: 1800px;
  }
  @media only screen and (min-width: 2000.99px) {
    width: 2400px;
  }
`;
export const XButton = styled(Button)`
  width: 100%;
  border-radius: 50px !important;
  background: ${({ theme }) => theme.colors.menu2};
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.colors.fontDM};
  padding: 10px 0px;
  height: auto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.28px;
  &:hover {
    background: ${({ theme }) => theme.colors.menu2} !important;
    color: ${({ theme }) => theme.colors.white} !important;
  }
`;
export const XButton2 = styled(Button)`
  width: 100%;
  font-family: ${({ theme }) => theme.colors.fontInter} !important;
  padding: 12px 0px;
  height: auto !important;
  border-radius: 20px;
  border: 1px solid ${({ theme }) => theme.colors.color6};
  background: ${({ theme }) => theme.colors.color5};
  color: ${({ theme }) => theme.colors.menu2};
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0.28px;

  &:hover {
    background: #275df5 !important;
    color: ${({ theme }) => theme.colors.white} !important;
  }
`;

export const XCardsTransparent = styled(Card)`
  border-radius: 20px !important;
  border: 1px solid ${({ theme }) => theme.colors.color3};
  background: rgba(255, 255, 255, 0.7) !important;
  .ant-card-body {
    padding: 30px !important;
    @media only screen and (max-width: 768.99px) {
      padding: 10px !important;
    }
  }
`;
export const XTable = styled(Table)`
  .ant-table {
    background: transparent;
  }
  .ant-table-thead > tr > th {
    color: ${({ theme }) => theme.colors.color4};
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    text-transform: uppercase;
    border-top: 2px solid rgba(0, 0, 0, 0.05);
    border-radius: 0px !important;
    &::before {
      display: none;
    }
  }
  .ant-table-tbody > tr > td {
    border-top: 1px solid rgba(0, 0, 0, 0.05);
    border-bottom: 0px;
    color: ${({ theme }) => theme.colors.color7};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.42px;
  }
  .showDate img {
    margin-right: 10px;
    margin-top: -3px;
  }

  td.custom-border-left {
    // border-left: 10px solid green !important;
    // border-radius: 20px !important;
    padding: 0 !important;
  }

  td > .claim_category_line {
    height: 70px !important;
    display: grid;
    place-items: center;
  }
  td > .full {
    border-left: 5px solid #00a954 !important;
  }
  td > .neutral {
    border-left: 5px solid #e9ecef !important;
  }
  td > .rejected {
    border-left: 5px solid #ff1c1c !important;
  }
  td > .partial {
    border-left: 5px solid #ffd17b !important;
  }

  td > .triangle {
    // width: 0;
    // height: 0;
    display: grid;
    place-items: center;
    position: absolute;
    top: 0;
  }
  td > .in_process {
    border-right: 20px solid transparent;
    border-top: 20px solid #1f9254 !important;
  }
  td > .untimely {
    border-right: 20px solid transparent;
    border-top: 20px solid #db3a17 !important;
  }
  td > .escalation {
    border-right: 20px solid transparent;
    border-top: 20px solid #e57711 !important;
  }
  td > .at_risk {
    border-right: 20px solid transparent;
    border-top: 20px solid #e5aa11 !important;
  }
`;
export const XTag = styled(Tag)`
  border-radius: 4px;
  font-family: ${({ theme }) => theme.colors.fontInter};
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.36px;
  padding-left: 20px;
  border: none;
  &:before {
    content: "■";
    position: absolute;
    top: -1px;
    left: 5px;
    font-size: 10px;
  }
`;

export const XSelect = styled(Select)`
  .ant-select-selector {
    border: 1px solid rgba(6, 36, 66, 0.15) !important;
    background: transparent !important;
    box-shadow: none;
    min-height: 45px !important;
    color: ${({ theme }) => theme.colors.menu2};
    font-size: 14px !important;
    font-style: normal;
    font-weight: 400 !important;
    line-height: 45px;
  }
`;

export const XModal = styled(Modal)`
  .ant-modal-title {
    color: ${({ theme }) => theme.colors.menu2};
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.36px;
  }
  label {
    color: ${({ theme }) => theme.colors.menu2};
    font-size: 14px !important;
    font-weight: 500 !important;
  }
  .modalButton {
    width: 100%;
    position: relative;
    margin: 0px;
    display: block;
    color: ${({ theme }) => theme.colors.white};
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.36px;
  }
  .ant-modal-content {
    border-radius: 20px !important;
  }
  .ant-modal-footer {
    .ant-btn {
      border-radius: 20px;
      height: 40px;
      padding: 0px 30px;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0.28px;
    }
    .ant-btn-primary {
      border: 1px solid ${({ theme }) => theme.colors.color6};
      background: ${({ theme }) => theme.colors.menu2};
      &:hover {
        border: 1px solid ${({ theme }) => theme.colors.color6};
        background: ${({ theme }) => theme.colors.menu2};
      }
    }
    .ant-btn-default {
      border-color: ${({ theme }) => theme.colors.color6};
      background: ${({ theme }) => theme.colors.white};
      box-shadow: 0px 0px 0px 1px rgba(70, 79, 96, 0.32),
        0px 5px 5px 0px rgba(0, 0, 0, 0.1);
      color: ${({ theme }) => theme.colors.menu2};
      &:hover {
        border-color: ${({ theme }) => theme.colors.menu2};
        background: ${({ theme }) => theme.colors.menu2};
        color: ${({ theme }) => theme.colors.white};
      }
    }
  }
`;

export const XCardsData = styled(Card)`
  border-radius: 20px;
  border: none;
  padding: 10px;
  .ant-card-body {
    border-radius: 20px;
    background: ${({ theme }) => theme.colors.white};
    box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.05);
    padding: 30px !important;
    @media only screen and (max-width: 768.99px) {
      padding: 0 !important;
    }
  }
`;

export const XSearch = styled(Search)`
  width: 100%;
  .ant-input {
    border-radius: 25px;
    border: 1px solid rgba(6, 36, 66, 0.15);
    box-shadow: none;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 400 !important;
    line-height: 16px;
  }
  .ant-input-wrapper {
    border-radius: 25px !important;
    border: 1px solid rgba(6, 36, 66, 0.15);
    box-shadow: none;
  }
  .ant-input-affix-wrapper {
    border: none;
  }

  .ant-input-affix-wrapper:hover,
  .ant-input-affix-wrapper:focus,
  .ant-input-affix-wrapper:focus-within {
    border: none !important;
    box-shadow: none !important;
    border-color: none !important;
  }

  .ant-input-search-button {
    border-radius: 25px !important;
    border: 1px solid rgba(6, 36, 66, 0.15);
    box-shadow: none;
    background: ${({ theme }) => theme.colors.menu2};
  }
  .ant-input-search-button:hover {
    border-radius: 25px !important;
    border: 1px solid rgba(6, 36, 66, 0.15);
    box-shadow: none;
    background: ${({ theme }) => theme.colors.menu2} !important;
  }
  .ant-input-search-button:focus-visible {
    border-radius: 25px !important;
    border: 1px solid rgba(6, 36, 66, 0.15);
    box-shadow: none;
    background: ${({ theme }) => theme.colors.menu2} !important;
  }
  background: ${({ theme }) => theme.colors.white};
`;

export const XCardsGrdBG = styled(Card)`
  background: #f4f7fe !important;

  .ant-card-body {
    padding: 15px;
    @media only screen and (max-width: 768.99px) {
    }
  }
`;

export const XPara1 = styled.div`
  color: ${({ theme }) => theme.colors.menu2};
  font-family: ${({ theme }) => theme.colors.fontInter};
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px; /* 175% */
  @media only screen and (max-width: 769px) {
    font-size: 14px;
  }
`;
export const GlobalStyle = createGlobalStyle`
	*{
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		font-family: 'Poppins', sans-serif !important;
		${"" /* font-family: 'DM Sans', sans-serif; */}
	}
	body{
		background: transparent url(../images/body-bg.svg) no-repeat center center;
		background-attachment: fixed;
		background-size: cover;
		font-family: 'Poppins', sans-serif !important;
	}
	.ant-layout-has-sider, .ant-layout{
		background:transparent !important;
	}
	a, p{
		font-family: 'Poppins', sans-serif !important;
		font-weight:normal;
		text-decoration:none;
	}
	.offcanvas-header{
		border-bottom:1px solid #CCC;
	}
	.offcanvas.offcanvas-top{
		height:100vh;
		transition:transform 0.3s ease-in-out !important;
		z-index:1050;
		@media only screen and (max-width: 1199.99px) {
			padding-top:60px;
		}
	}
	
	.marginTopMobile {
		@media only screen and (max-width: 991.99px) {
			margin-bottom: 100px !important;
		}
	}

	.marginBottomMobile {
		@media only screen and (max-width: 991.99px) {
			margin-bottom: 70px !important;
		}
	}
	.marginNone {
		@media only screen and (max-width: 991.99px) {
			margin-top: -70px !important;
		}
	}
	.nav-link {
		color: ${({ theme }) => theme.colors.menu1};
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: 24px;
		&:hover {
			color: ${({ theme }) => theme.colors.menu2} !important;
			font-weight: 600;
		}
	}
	.nav-link.active {
		color: ${({ theme }) => theme.colors.menu2} !important;
		font-size: 16px;
		font-style: normal;
		font-weight: 600;
		line-height: 24px;
	}
	.ant-modal-confirm {
		.ant-btn{
			&:hover{
				border-color: ${({ theme }) => theme.colors.menu2} !important;
			}
		}
		.ant-btn-dangerous{
			background: ${({ theme }) => theme.colors.menu2};
			color: ${({ theme }) => theme.colors.white};
			border-color: ${({ theme }) => theme.colors.menu2};
			&:hover{
				background: ${({ theme }) => theme.colors.menu2} !important;
				color: ${({ theme }) => theme.colors.white} !important;
			}
		}
		.ant-btn-default{
			background: ${({ theme }) => theme.colors.white};
			color: ${({ theme }) => theme.colors.menu2};
			border-color: ${({ theme }) => theme.colors.color3};
			&:hover{
				background: ${({ theme }) => theme.colors.menu2} !important;
				color: ${({ theme }) => theme.colors.white} !important;
			}
		}
	}
	.ant-select.ant-select-in-form-item,
	.ant-input,
	.ant-picker,
	.ant-select-selector,
	.ant-input-affix-wrapper {
		background: transparent !important;
	}
	span.successful, .approved{
		background: #e1fcef !important;
		color: #14804a !important;
	}
	span.rejected {
		background: #fff1f0 !important;
		color: #cf1322 !important;
	}
	span.pending, .pending_for_analysis{
		background: #FDE9CB !important;
		color: #E78900 !important;
	}

	span.allocated {
		background: #FFE9DA !important;
		color: #7A0E27 !important;
		border-color: #b7eb8f;
	}
	
	span.analysed {
		background: #FFFCDA !important;
		color: #7A670E !important;
	}

	span.letter_created {
		background: #FFFCDA !important;
		color: #7A670E !important;
	}

	span.letter_sent {
		border-radius: 5px;
		border: 0.5px solid rgba(56, 160, 108, 0.30);
		background: #E1FCEF;
		box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.05);
		color: #38A06C;
	}

	span.No {
		background: #fff1f0 !important;
		color: #cf1322 !important;
		border:1px solid;
		display:flex;
		justify-content:center;
		align-items:center;
	}
	
	span.Yes {
		background: #e1fcef !important;
		color: #38A06C !important;
		border:1px solid;
		display:flex;
		justify-content:center;
		align-items:center;
	}

	.textArea{
		border-radius: 20px;
		border: 1px solid rgba(0, 0, 0, 0.10);
		background: ${({ theme }) => theme.colors.white} !important;
		box-shadow: 0px 2px 10px 0px rgba(25, 15, 64, 0.05);
		padding:10px 15px !important;
	}
	.iconResp{
		width:40px;
	}
	p.modalPara1{
		color: rgba(33, 34, 38, 0.60);
		font-family: Inter;
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 22px; /* 157.143% */
	}
	.confirmModal {
		.ant-btn{
			border-radius: 25px !important;	
		}
		.ant-btn-default{
			background: ${({ theme }) => theme.colors.white};
			border: 1px solid ${({ theme }) => theme.colors.menu2};
			color: ${({ theme }) => theme.colors.menu2};
		};
		.ant-btn-dangerous{
			background: ${({ theme }) => theme.colors.menu2};
			color: ${({ theme }) => theme.colors.white};
		};

	}
	.slideUp {
		
		position: relative;
		transition: all .5s ease-out;
	}
	.slideDown {
		position: relative;
		transition: all .5s ease-out;
		height: 100%;
	}
	.top-0{
		top:0;
	}
	.bottom-0{
		position: relative;
		bottom:0 !important;
	}
	.top-100{
		top:100%!important;
	}
	.bottom-100{
		position: absolute;
		bottom:100% !important;
		z-index: -1;
	}
	.ant-layout-sider-children{
		overflow-y: scroll;
	}
	.ant-layout-sider-children::-webkit-scrollbar {
		width: .2em; 
	}	
	.ant-layout-sider-children::-webkit-scrollbar,
	.ant-layout-sider-children::-webkit-scrollbar-thumb {
		overflow:visible;
		border-radius: 4px;
	}
	.ant-layout-sider-children::-webkit-scrollbar-thumb {
		background: rgba(0,0,0,.2); 
	}
	.cover-bar {
		position: absolute;
		background: #fff;;
		height: 100%;  
		top: 0;
		right: 0;
		width: .2em;
		-webkit-transition: all .5s;
		opacity: 1;
	}
	.customScroll:hover .cover-bar {
		opacity: 0;
		-webkit-transition: all .5s;
	}
	
	.customHorizontalScroll ::-webkit-scrollbar {
		height: 5px !important;
	}
	.customHorizontalScroll ::-webkit-scrollbar-track {
		-webkit-box-shadow: inset 0 0 0px rgba(0, 0, 0, 0);
		border-radius: 10px;
		background-color: ${Theme.colors.color3} !important;
	}
	.customHorizontalScroll ::-webkit-scrollbar-thumb {
		width: 5px;
		background-color: ${Theme.colors.color4};
		border-radius:5px;
	}
	.customHorizontalScroll ::-webkit-scrollbar-thumb:hover {
		background-color: ${Theme.colors.color2};
	}
	.ant-form-item-control-input-content .ant-switch-checked{
		background: ${({ theme }) => theme.colors.menu2};
		.ant-switch-handle {
			top: 1px;
			&::before {
				background: ${({ theme }) => theme.colors.white};
			}
		}
	}
	
	:where(.css-dev-only-do-not-override-3mqfnx).ant-switch.ant-switch-checked:hover:not(.ant-switch-disabled){
		background: ${({ theme }) => theme.colors.menu2};
		.ant-switch-handle {
			top: 1px;
			&::before {
				background: ${({ theme }) => theme.colors.white};
			}
		}
	}
	
	.remove_important > .ant-form-item-required{
		color:green !important;
	}
	canvas{
		width:10%;
	}
	.logo{
		display:block;
		width: 100%;
		text-align: center;
		margin: 30px 0px;
		@media (max-width: 992px) {
			margin:0px;
		}
	}
	.logo a img{
		vertical-align: middle;
		border-style: none;
	}
	
	.ant-layout-sider-children {
		background: #fff;
	}
	.ant-menu-item {
		margin: 15px auto !important;
		padding-left: 16px !important; 
	}
	.nav-link-item {
		display: block;
		text-align: center;
	}
	.nav-link-item: hover{
		background: #f0f0f0f0;
		border-radius: 10px;

	}
	.nav-link-item-active{
		display: block;
		text-align: center;
		background: #f0f0f0f0;
		border-radius: 10px;

	}
	.headerTagStyle{
		color: #232323;
		font-family: Poppins;
		font-size: 20px;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
	}	
	.headerTagDashboard{
		color: #232323;
		font-family: Poppins;
		font-size: 18px;
		font-style: normal;
		font-weight: 500;
		line-height: 32px; /* 177.778% */
		letter-spacing: -0.36px;
	}

	.tableHeader{
		color: #11263C;
		font-family: Poppins;
		font-size: 14px;
		font-style: normal;
		font-weight: 600;
		line-height: 1.2; /* 114.286% */
	}
	.ant-form-item-no-colon{
		color: #11263C  !important;
		font-size: 14px  !important;
		font-style: normal  !important;
		font-weight: 500 !important;
		line-height: 16px  !important; /* 114.286% */
	}
	.filter-sub-item{
		color: #11263C !important;
		font-family: Poppins;
		font-size: 12px !important;
		font-style: normal !important;
		font-weight: 500 !important;
		line-height: 18px !important; /* 150% */
		letter-spacing: 0.36px !important;
		margin: 5px auto;
	}
	.removeFormMargin{
		margin-bottom: 14px !important;
	}
	.ant-drawer-body{
		padding-top: 14px !important;
	}
	.ant-upload-select {
		width: 100% !important;
	}
	.confirmCoverage:hover {
		box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
		background: #000 !important;
	}

	ul.listCircule{
        margin: 0px;
        padding: 0px;
    }
    ul.listCircule li{
        list-style-type: none;
        font-style: italic;
		color: #000;
		font-family: Inter;
		font-size: 15px;
		font-weight: 400;
		line-height: 28px !important;
		margin-bottom: 5px !important;
    } 
    ul.listCircule li::before {
        content: "";
        border:2px solid #5E5E5E;
        border-radius: 50%;
        font-weight: bold;
        display: inline-block; 
        width: 0.7em;
        height:0.7em;
        margin-right:.5em;
        }
	.config-text {
		color: #232323;
		font-family: Inter;
		font-size: 18px;
		font-style: normal;
		font-weight: 600 !important;
		line-height: 28px; /* 155.556% */
	}
	.ant-upload-list-item-container {
		margin-right: 19px !important;
	}

	:where(.css-dev-only-do-not-override-3mqfnx).ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
		color: #232323 !important;
	}

	.no-padding-card .ant-card-body{
		padding:0 !important;
	}
	#revnueModalUploadButton:hover{
		color: black !important;
	}
	.timeline-documents .ant-card-body{
		padding:15px !important;
	}
	ul.markData {
		margin: 0px;
		padding: 0px;
	}
	ul.markData li {
		display: list-item;
		list-style-type: none;
		padding-left: 10px;
		font-weight: 500;
		font-size: 14px;
		line-height: 32px;
		letter-spacing: -0.02em;
		color: #FFFF;
		text-align: left;
	}
	ul.markData li em {
		position: absolute;
		left: 0;
		margin-top: 10px;
		width: 10px;
		height: 10px;
		border-radius: 20px;
	}
	
	.normal-header .ant-list-header {
		font-size: 10px !important;
		padding: 10px !important;
		font-family: 'Inter', sans-serif !important;
		background: none !important;
	}

	h1.headerheading, button.headerheading{
    margin-bottom:5px;
    font-size:22px;
    font-weight:500;
    line-height:24px;
    border:none !important;
    color:${({ theme }) => theme.colors.text};
    box-shadow:none;
    padding:0px;
    font-family: 'Gilroy-Bold' !important;
    &:hover{
        color:${({ theme }) => theme.colors.text} !important;
        border:none !important;
    }
}
.addEmployerBg{
    background: linear-gradient(144.61deg, #275df5 0%, #82a9fc 100%);
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    padding:15px 30px;
    @media only screen and (max-width: 768px) {
        margin-bottom:30px;
    } 
}

.addEmpPara{
    font-size:15px;
    line-height:20px;
    font-weight:normal;
    color: ${({ theme }) => theme.colors.white};
}

.addEmployerBgImg{
    position:absolute;
    top:0;
    right:15px;
    z-index:1;
    background:  url("../images/add-employer-bg.svg") no-repeat top right;
    width:100%;
    height:100%;
}
`;

// ///////////////////////////////////////////SKILLSPRINTS CSS STARTS HERE////////////////////////////////////////////
// ///////////////////////////////////////////SKILLSPRINTS CSS STARTS HERE////////////////////////////////////////////
// ///////////////////////////////////////////SKILLSPRINTS CSS STARTS HERE////////////////////////////////////////////
// ///////////////////////////////////////////SKILLSPRINTS CSS STARTS HERE////////////////////////////////////////////
// ///////////////////////////////////////////SKILLSPRINTS CSS STARTS HERE////////////////////////////////////////////
// ///////////////////////////////////////////SKILLSPRINTS CSS STARTS HERE////////////////////////////////////////////
// ///////////////////////////////////////////SKILLSPRINTS CSS STARTS HERE////////////////////////////////////////////
// ///////////////////////////////////////////SKILLSPRINTS CSS STARTS HERE////////////////////////////////////////////
// ///////////////////////////////////////////SKILLSPRINTS CSS STNTS CSS STARTS HERE////////////////////////////////////////////
// ///////////////////////////////////////////SKILLSPRINTS CSS STARTS HERE////////////////////////////////////////////
// ///////////////////////////////////////////SKILLSPRINTS CSS STARTS HERE////////////////////////////////////////////
// ///////////////////////////////////////////SKILLSPRINTS CSS STARTS HERE////////////////////////////////////////////
// ///////////////////////////////////////////SKILLSPRINTS CSS STARTS HERE////////////////////////////////////////////
// ///////////////////////////////////////////SKILLSPRINTS CSS STARTS HERE////////////////////////////////////////////
// ///////////////////////////////////////////SKILLSPRINTS CSS STARTS HERE////////////////////////////////////////////
// ///////////////////////////////////////////SKILLSPRINTS CSS STARTS HERE////////////////////////////////////////////
// ///////////////////////////////////////////SKILLSPRINTS CSS STARTS HERE////////////////////////////////////////////

export const JobDetailsContainer = styled.section`
  // height: 100vh;
  // overflow-y: auto;
  // scrollbar-width: thin;
  height: 100%;
  border-radius: 12px;
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  justify-content: center;
  padding: 20px;
  border: ${({ isDrawer }) => (isDrawer ? "none" : "1px solid #c8d6ff")};
  @media (max-width: 991px) {
    width: 100%;
    margin-top: 17px;
  }
  @media (max-width: 340px) {
    padding: 15px;
  }
`;

export const JobDetailsScrollable = styled.div`
  padding-top: 20px; // Optional for spacing
  max-height: 96vh;
  overflow-y: auto;

  /* Scrollbar styling */
  &::-webkit-scrollbar {
    width: 3px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: lightgrey; // Light grey color for the scrollbar thumb
    border-radius: 4px;
  }

  &::-webkit-scrollbar-track {
    background: transparent; // Optional for a transparent track
  }

  &:scroll {
    box-shadow: 0px -5px 10px rgba(0, 0, 0, 0.1),
      0px 5px 10px rgba(0, 0, 0, 0.1);
  }
`;

export const JobDetailsContent = styled.div`
  display: flex;
  min-width: 240px;
  width: 100%;
  max-width: 800px;
  flex-direction: column;
  justify-content: flex-start;
  // @media (max-width: 768px) {
  //   padding: 20px;
  // }
`;

export const JobSticky = styled.span`
  display: flex;
  flex-direction: column;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
  animation-name: moveUpAnimationKeyframes;
  animation-timing-function: ease-in;
  border-bottom: 2px solid #00000014;
  box-shadow: 0 2px 4px #00000014;
  margin: 0 -16px !important;
  padding-bottom: 0.25rem;
  padding-left: 16px;
  padding-right: 16px;
  transform-origin: center;
`;

export const JobHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  @media (max-width: 768px) {
    // flex-direction: column;
    align-items: flex-start;
  }
`;

export const JobTitleWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 16px;
`;

export const JobTitle = styled.h1`
  font-size: 25px;
  font-weight: 500;
  color: #18191c;
  margin: 0;
`;

export const JobBadges = styled.div`
  display: flex;
  gap: 8px;
  @media (max-width: 768px) {
    margin-top: 10px;
  }
`;

export const Badge = styled.span`
  border-radius: 52px;
  padding: 4px 12px;
  font-size: 14px;
  font-weight: 400;
  background-color: ${(props) => (props.featured ? "#ffeded" : "#e8f1ff")};
  color: ${(props) => (props.featured ? "#e05151" : "#275df5")};
`;

export const SaveButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e7f0fa;
  border: none;
  border-radius: 4px;
  width: 46px;
  height: 46px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #d0e1f5;
  }

  img {
    width: 24px;
    height: 24px;
  }
`;

export const JobMetadata = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 20px;
`;

export const MetadataItem = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;

  img {
    width: 32px;
    height: 32px;
  }
`;

export const MetadataContent = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  flex-wrap: wrap;
`;

export const MetadataLabel = styled.span`
  // color: #767f8c;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
`;

export const MetadataValue = styled.span`
  color: #0ba02c;
  font-size: 20px;
  font-weight: 500;
`;

export const MetadataSubtext = styled.span`
  color: #767f8c;
  font-size: 14px;
  font-weight: 400;
`;

export const SkillTags = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 20px;
`;

export const SkillTag = styled.span`
  border-radius: 3px;
  background-color: #efefef;
  color: #656565;
  font-size: 12px;
  font-weight: 500;
  padding: 6px 12px;
`;

export const JobInfoGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  margin-bottom: 20px;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

export const InfoItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  img {
    width: 32px;
    height: 32px;
  }
`;

export const InfoLabel = styled.span`
  color: #767f8c;
  font-size: 12px;
  font-weight: 400;
  text-transform: uppercase;
`;

export const InfoValue = styled.span`
  color: #18191c;
  font-size: 14px;
  font-weight: 600;
`;

export const ApplyButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  background-color: #275df5;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 16px 32px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #1e4fd0;
  }

  img {
    width: 24px;
    height: 24px;
  }
  @media (max-width: 768px) {
    width: 100%;
    justify-content: center;
  }
`;

export const Divider = styled.hr`
  border: none;
  border-top: 1px solid #eaeaea;
  margin: 20px 0;
`;

export const SectionTitle = styled.h2`
  font-size: 18px;
  font-weight: 600;
  color: #18191c;
  margin-bottom: 16px;
`;

export const JobDescription = styled.div`
  color: #5e6670;
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 24px;
`;

export const JobTimes = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const Requirements = styled.div`
  margin-bottom: 24px;

  ul {
    padding-left: 20px;
    color: #5e6670;
    font-size: 16px;
    list-style: inherit;
    line-height: 1.5;
  }
`;

export const Desirable = styled(Requirements)``;

export const Benefits = styled(Requirements)``;

export const ShareJob = styled.div`
  margin-top: 24px;
`;

export const SocialButtons = styled.div`
  display: flex;
  gap: 16px;
  margin-top: 16px;
  flex-wrap: wrap;
`;

export const SocialButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border: none;
  border-radius: 4px;
  background-color: #f4f9ff;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #e0eeff;
  }

  img {
    width: 20px;
    height: 20px;
  }
`;

export const CopyLinkButton = styled.button`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  background-color: #f4f9ff;
  color: #275df5;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #e0eeff;
  }

  img {
    width: 20px;
    height: 20px;
  }
`;
export const StyledPagination = styled(Pagination)`
  margin-top: 40px;

  .ant-pagination-item {
    border-radius: 50%;
  }

  .ant-pagination-item-active {
    background-color: #275df5;
    border-color: #275df5;
  }

  .ant-pagination-item-active a {
    color: #fff;
  }

  .ant-pagination-prev,
  .ant-pagination-next {
    .ant-pagination-item-link {
      border-radius: 50%;
    }
  }
`;

export const PaginationContainer = styled.nav`
  align-self: center;
  justify-content: center;
  display: flex;
  margin-top: 40px;
  align-items: center;
  gap: 8px;
`;

export const PageContainer = styled.div`
  max-width: 1150px;
  margin: 0 auto;
  padding: 40px 20px;
`;

export const JobCount = styled.h2`
  font-size: 32px;
  font-weight: 700;
  color: #141414;
`;

export const FilterContainer = styled.div`
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
  @media (max-width: 480px) {
    width: 100%;
    justify-content: center;
  }
`;

export const FilterButton = styled.button`
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: #fff;
  border: 1px solid rgba(20, 20, 20, 0.1);
  border-radius: 4px;
  padding: 12px 16px;
  font-size: 16px;
  color: #141414;
  cursor: pointer;
  transition: all 0.3s ease;
  height: 48px;
  &:hover {
    background-color: #f5f5f5;
  }

  img {
    width: 24px;
    height: 24px;
  }
  @media (max-width: 480px) {
    width: 100%;
    justify-content: center;
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  gap: 40px;

  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;

export const JobListContainer = styled.div`
  max-height: 1113.2px;
  // height: 100vh;
  overflow-y: auto;
  padding-right: 1.5px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  max-width: 500px;

  @media (max-width: 1024px) {
    max-width: 100%;
  }

  /* Scrollbar styling */
  &::-webkit-scrollbar {
    width: 3px;
    display: none; // Hide scrollbar by default
  }
  /* Show scrollbar on hover */
  &:hover::-webkit-scrollbar {
    display: block;
    width: 3px; // Optional, adjust width if needed
  }

  &::-webkit-scrollbar-thumb {
    background-color: lightgrey; // Light grey color for the scrollbar thumb
    border-radius: 4px;
  }

  &::-webkit-scrollbar-track {
    background: transparent; // Optional for a transparent track
  }

  &:scroll {
    box-shadow: 0px -5px 10px rgba(0, 0, 0, 0.1),
      0px 5px 10px rgba(0, 0, 0, 0.1);
  }

  /* Overlay scrollbar on content */
  position: relative;
  &::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 3px; // Match scrollbar width
    background-color: transparent;
  }
`;

export const Drawer = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  max-width: 500px;
  background-color: #fff;
  box-shadow: -2px 0 10px rgba(0, 0, 0, 0.1);
  transform: ${(props) =>
    props.isVisible ? "translateX(0)" : "translateX(100%)"};
  transition: transform 0.3s ease-in-out;
  overflow-y: auto;
  z-index: 1000;
`;

export const CandidateLabel = styled.label`
  font-family: Montserrat;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  color: #6f717b;
  font-size: 14px;
  font-weight: 400;
`;

export const DownloadResumeButton = styled(Button)`
  height: 90px;
  margin: 10px 10px 10px 0;
  border-radius: 15px;
  border: 1px dashed;
  @media only screen and (min-width: 319px) {
    width: 100%;
    height: 90px;
  }
`;

export const Header = styled.header`
  background-color: #fff;
  width: 100%;
  position: relative;
  row-gap: 15px;
  z-index: 1000;
  font-family: "Inter", sans-serif;
  display: flex;
  justify-content: space-between;
  // margin-bottom: 50px;
`;

export const Nav = styled.nav`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  gap: 40px;
  padding: 20px 100px;
  flex-wrap: wrap;
  @media (max-width: 991px) {
    padding: 20px;
  }
`;

export const LogoWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  text-align: center;
`;

export const Logo = styled.h1`
  font-size: 18px;
  font-weight: 700;
  color: #275df5;
  line-height: 40px;
  white-space: nowrap;
  @media (max-width: 991px) {
    white-space: normal;
  }
  display: flex;
  align-items: start;
`;

export const NavLinksWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  font-size: 14px;
  color: #5e6670;
  font-weight: 500;
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }
`;

export const StyledNavLink = styled(NavLink)`
  color: #9199a3;
  font-size: 14px;
  text-decoration: none;
  transition: color 0.3s ease, font-weight 0.3s ease;

  &.active {
    color: #275df5; /* Active link color */
    font-weight: bold; /* Bold active link */
    border-bottom: 2px solid #275df5; /* Underline for active link */
  }

  &:hover {
    color: #275df5; /* Hover color */
  }

  &:not(.active) {
    border-bottom: 2px solid transparent; /* Maintain spacing */
  }
`;

// export const NavLink = styled.a`
//   padding: 14px 0;
//   cursor: pointer;
//   color: ${(props) => (props.isactive === "true" ? "#275df5" : "inherit")};
//   font-weight: ${(props) => (props.isactive === "true" ? "600" : "500")};
//   white-space: nowrap;
//   transition: color 0.3s ease, transform 0.3s ease;
//   &:hover {
//     color: #275df5;
//     transform: translateY(-2px);
//   }
//   @media (max-width: 991px) {
//     white-space: normal;
//     width: 100%;
//   }
// `;

export const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  font-size: 16px;
  text-transform: capitalize;
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }
`;

export const HeaderButton = styled.button`
  border-radius: 3px;
  padding: 12px 24px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  @media (max-width: 991px) {
    padding: 12px 20px;
    width: 100%;
  }
`;

export const SignInButton = styled(Button)`
  color: #275df5;
  background-color: transparent;
  border: 1px solid #cee0f5;
  &:hover {
    background-color: #f0f7ff;
    transform: translateY(-2px);
  }
  width: 132px;
  height: 48px;
  padding: 12px 24px 12px 24px;
  gap: 12px;
  border-radius: 3px 0px 0px 0px;
  opacity: 0px;
`;

export const PostJobButton = styled(Button)`
  color: #fff;
  background-color: #275df5;
  border: none;
  &:hover {
    background-color: #1e4fc4;
    transform: translateY(-2px);
  }
  width: 152px;
  height: 48px;
  padding: 12px 24px 12px 24px;
  gap: 12px;
  border-radius: 3px 0px 0px 0px;
  opacity: 0px;
`;

export const HamburgerButton = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 30px;
  height: 25px;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 10;
  &:focus {
    outline: none;
  }
  span {
    width: 30px;
    height: 3px;
    background: #275df5;
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;
    &:first-child {
      transform: ${({ $isOpen }) => ($isOpen ? "rotate(45deg)" : "rotate(0)")};
    }
    &:nth-child(2) {
      opacity: ${({ $isOpen }) => ($isOpen ? "0" : "1")};
      transform: ${({ $isOpen }) =>
        $isOpen ? "translateX(20px)" : "translateX(0)"};
    }
    &:nth-child(3) {
      transform: ${({ $isOpen }) => ($isOpen ? "rotate(-45deg)" : "rotate(0)")};
    }
  }
`;
const slideIn = keyframes`
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
`;
export const MobileMenu = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  width: 80%;
  height: 100vh;
  background: white;
  padding: 80px 20px 20px;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
  overflow-y: auto;
  z-index: 1;
  transform: ${({ $isOpen }) =>
    $isOpen ? "translateX(0)" : "translateX(100%)"};
  animation: ${({ $isOpen }) => ($isOpen ? slideIn : "none")} 0.3s ease-in-out;
`;

export const Title = styled.h1`
  color: ${({ theme }) => theme.colors.menu2};
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.36px;
  margin-bottom: 2px;
  @media only screen and (max-width: 768.99px) {
    font-size: 16px !important;
  }
`;
export const Title2 = styled.h2`
  margin: 0px;
  color: ${({ theme }) => theme.colors.menu2};
  font-weight: 600;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;

  @media only screen and (min-width: 768.99px) {
  }
`;

export const XForm = styled(Form)`
  label {
    color: ${({ theme }) => theme.colors.color7} !important;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 500 !important;
    line-height: normal;
  }
  input {
    border: 1px solid ${({ theme }) => theme.colors.color3};
    background: transparent;
    height: auto;
    padding-top: 10px;
    padding-bottom: 10px;
    @media only screen and (max-width: 768.99px) {
    }
  }
  button {
    display: inline-block;
    margin-left: 15px;
    border-radius: 20px;
    background: ${({ theme }) => theme.colors.menu2};
    color: #fff;
    font-size: 14px !important;
    padding: 10px 30px;
    height: auto;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    border: 1px solid ${({ theme }) => theme.colors.color6};
    &:hover {
      background: ${({ theme }) => theme.colors.menu2};
      color: #fff;
    }
    @media only screen and (max-width: 768.99px) {
    }
  }
  .ant-upload-list-item-action.ant-btn {
    background: none;
  }
  button.cancel {
    background: ${({ theme }) => theme.colors.white};
    color: #171c26;
  }
  .customLabel {
    color: ${({ theme }) => theme.colors.menu2};
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
  }
  .customHeader {
    color: ${({ theme }) => theme.colors.menu2};
    background: #f3f6f9;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    padding: 5px 15px;
  }
  .ant-switch {
    padding: 0px 5px;
    background: transparent;
    border: 1px solid ${({ theme }) => theme.colors.menu2};

    .ant-switch-handle {
      top: 1px;
      &::before {
        background: ${({ theme }) => theme.colors.menu2};
      }
    }
  }
  button.ant-switch {
    &:hover {
      background: ${({ theme }) => theme.colors.menu2};
      .ant-switch-handle {
        &::before {
          background: ${({ theme }) => theme.colors.white};
        }
      }
    }
  }
  button.ant-switch-checked {
    background: ${({ theme }) => theme.colors.menu2};
    .ant-switch-handle {
      top: 1px;
      &::before {
        background: ${({ theme }) => theme.colors.white};
      }
    }
  }
`;

export const FooterWrapper = styled.footer`
  background-color: #232323;
  color: #fff;
  font-family: "Inter", sans-serif;
`;

export const FooterContent = styled.div`
  max-width: 1120px;
  margin: 0 auto;
  padding: 60px 20px;

  @media (max-width: 1200px) {
    padding: 80px 20px;
  }

  @media (max-width: 991px) {
    padding: 60px 20px;
  }

  @media (max-width: 768px) {
    padding: 40px 20px;
  }
`;

export const FooterColumns = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 40px;

  @media (max-width: 991px) {
    gap: 30px;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 20px;
  }
`;

export const Column = styled.div`
  flex: 1;
  min-width: 200px;

  @media (max-width: 768px) {
    min-width: 100%;
  }
`;

export const FooterLogo = styled.h2`
  font-size: 18px;
  font-weight: 600;
  color: #275df5;
  margin-bottom: 24px;
`;

export const ContactInfo = styled.div`
  margin-top: 24px;
`;

export const PhoneNumber = styled.div`
  display: flex;
  align-items: center;
  font-size: 18px;
  line-height: 2;
  margin-bottom: 12px;
`;

export const CallNow = styled.span`
  color: #5e6670;
  margin-right: 8px;
`;

export const Number = styled.span`
  color: #fff;
`;

export const Address = styled.p`
  color: #767f8c;
  font-size: 14px;
  line-height: 1.5;
  margin-bottom: 0;
`;

export const ColumnTitle = styled.h3`
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 16px;
`;

export const LinkList = styled.ul`
  list-style: none;
  padding: 0;
`;

export const LinkItem = styled.li`
  color: #9199a3;
  font-size: 16px;
  padding: 6px 0;
  cursor: pointer;
  transition: color 0.3s ease;

  a {
    color: #9199a3; /* Text color */
    text-decoration: none; /* Remove underline */
    transition: color 0.3s ease;

    &:hover {
      color: #fff; /* Change color on hover */
    }
  }
`;

export const CopyrightBar = styled.div`
  background-color: #18191c;
  padding: 24px 0;
`;

export const CopyrightContent = styled.div`
  max-width: 1120px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
  padding: 0 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;
  }
`;

export const CopyrightText = styled.p`
  color: #767f8c;
  font-size: 14px;
`;

export const SocialIcons = styled.div`
  display: flex;
  gap: 20px;
`;

export const SocialIcon = styled.img`
  width: 20px;
  height: 20px;
  object-fit: contain;
  cursor: pointer;
  transition: opacity 0.3s ease;

  &:hover {
    opacity: 0.8;
  }
`;
