import styled from "styled-components";
import "../../styles/GlobalStyle";
import { Carousel, List } from "antd";

export const XSectionBgBorder = styled.section`
  width: 100%;
  position: relative;
  background: #f4f7fe !important;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  @media only screen and (max-width: 769px) {
    border: none;
    background: ${({ theme }) => theme.colors.white} !important;
  }
`;
export const CarouselH1 = styled.h1`
  color: ${({ theme }) => theme.colors.text1};
  margin: 0px;
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  // line-height: 24px;
  @media only screen and (max-width: 769px) {
    text-align: center !important;
    font-size: 26px;
    line-height: 36px;
  }
`;
export const CarouselPara = styled.p`
  color: ${({ theme }) => theme.colors.text1};
  margin: 0px;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px;

  @media only screen and (max-width: 769px) {
    text-align: center !important;
    font-size: 16px;
  }
`;
export const Title = styled.h1`
  color: ${({ theme }) => theme.colors.cta_text_color};
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: 0.448px;
`;
export const Paragraph = styled.p`
  color: ${({ theme }) => theme.colors.text2};
  text-align: justify;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.8px;
`;
export const SubParagraph = styled.p`
  color: ${({ theme }) => theme.colors.text2};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.8px;
`;
export const XSectionNonBg = styled.section`
  width: 100%;
  position: relative;
  background: #fff !important;
  @media only screen and (max-width: 769px) {
    border: none;
    background: ${({ theme }) => theme.colors.white} !important;
  }
`;
export const StyledLink = styled.a`
  color: ${({ theme }) =>
    theme.colors
      .cta_text_color}; // You can change this color based on your theme
  font-weight: 700; // Adjust the font-weight if necessary
  cursor: pointer;
  text-decoration: underline; // Optional: to make it look like a link
  &:hover {
    text-decoration: none; // Remove underline on hover for better UX
  }
`;

export const Para3 = styled.p`
  color: ${({ theme }) => theme.colors.text2} !important;
`;

const IndexStyle = () => {};

export default IndexStyle;
