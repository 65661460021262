import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  XContainer,
  XModal,
  XForm,
  XSelect,
  XCardsTransparent,
} from '../../styles/GlobalStyle';
import { ArrowLeftOutlined } from '@ant-design/icons';
import {
  Row,
  Col,
  Input,
  Button,
  Form,
  Switch,
  notification,
  Card,
} from 'antd';
import { useNavigate, useParams } from 'react-router-dom';

// Actions
import {
  getRolesDropdownApi,
  getRolesDropdownReset,
} from '../../actions/getRolesDropdownAction';
import { addUserApi, addUserReset } from '../../actions/addUserAction';
import {
  getUserByIdApi,
  getUserByIdReset,
} from '../../actions/getUserByIdAction';
import { editUserApi, editUserReset } from '../../actions/editUserAction';
import {
  checkModulePermissions,
  hasPermission,
  toTitleCase,
} from '../../utils/helper';
import {
  filterByLabel,
  handleInputChange,
  handleSelectChange,
} from '../../utils/formFunctions';

import { deleteUserApi, deleteUserReset } from '../../actions/deleteUserAction';
import { Title } from '../Login/LoginStyle';
import { SubmitBtn, SubmitNoBgBtn } from '../../styles/Button';

const AddEditUser = (props) => {
  const {
    addUser,
    addUserReset,
    addUserState,
    getUserById,
    getUserByIdState,
    editUser,
    editUserReset,
    editUserState,
    deleteUser,
    deleteUserReset,
    deleteUserState,
    getRolesDropdown,
    getRolesDropdownState,
  } = props;
  const navigate = useNavigate();
  const id = useParams().id;

  const [form] = XForm.useForm();
  const [formData, setFormData] = useState({
    is_active: false,
    role: '',
  });

  useEffect(() => {
    if (addUserState.apiState === 'success') {
      form.resetFields();
      setFormData({});
      navigate(`/manage`);
      notification.success({
        message: addUserState.message,
      });
      addUserReset();
    } else if (addUserState.apiState === 'error') {
      for (const fieldName in addUserState.errors) {
        if (addUserState.errors.hasOwnProperty(fieldName)) {
          const errorMessage = addUserState.errors[fieldName];
          form.setFields([
            {
              name: fieldName,
              errors: [errorMessage.replace(fieldName, toTitleCase(fieldName))],
            },
          ]);
        }
      }
      notification.error({
        message: addUserState.message,
      });
      addUserReset();
    }
  }, [addUserState]);

  useEffect(() => {
    if (getUserByIdState.apiState === 'success') {
      setFormData({
        id: getUserByIdState.data._id,
        name: getUserByIdState.data.name,
        email: getUserByIdState.data.email,
        role: getUserByIdState.data.role,
        is_active: getUserByIdState.data.is_active,
      });
      form.setFieldsValue({
        id: getUserByIdState.data._id,
        name: getUserByIdState.data.name,
        email: getUserByIdState.data.email,
        role: getUserByIdState.data.role,
        is_active: getUserByIdState.data.is_active,
      });
    }
  }, [getUserByIdState]);

  useEffect(() => {
    if (editUserState.apiState === 'success') {
      form.resetFields();
      setFormData({});
      editUserReset();
      navigate(`/manage`);
      notification.success({
        message: editUserState.message,
      });
    } else if (editUserState.apiState === 'error') {
      notification.error({
        message: editUserState.message,
      });
      editUserReset();
    }
  }, [editUserState]);

  useEffect(() => {
    if (deleteUserState.apiState === 'success') {
      notification.success({
        message: deleteUserState.message,
      });
      navigate(`/manage`);
      deleteUserReset();
    }
  }, [deleteUserState]);

  useEffect(() => {
    if (getRolesDropdownState.apiState === 'success') {
      if (getRolesDropdownState.data.length > 0) {
        const updatedTableData = [];
        getRolesDropdownState.data.map((item, index) => {
          updatedTableData.push({
            key: item._id,
            role: item.display_name,
          });
        });
        // setRolesData(updatedTableData);
      }
    }
  }, [getRolesDropdownState]);

  useEffect(() => {
    setFormData({});
    if (id) getUserById({ id: id });
    getRolesDropdown({ type: 'super-admin' });
    form.resetFields();
  }, []);

  // Functions
  const showDeleteConfirm = (_id, name) => {
    XModal.confirm({
      title: 'Delete User?',
      content: (
        <p className='modalPara1'>Are you sure you want to delete {name}?</p>
      ),
      icon: <img className='me-3' src='/images/delete-icon.svg' alt='' />,
      okText: 'Yes, Confirm',
      okType: 'danger',
      cancelText: 'No, Cancel',
      className: 'confirmModal',
      onOk() {
        deleteUser({ id: _id });
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  const updateUserDetails = (id) => {
    editUser(formData);
    form.resetFields();
  };

  const saveUser = () => {
    addUser(formData);
  };

  return (
    <>
      <XContainer fluid className='mt-3'>
        <Card className='mb-3'>
          <Row>
            <Col xs={24} className='d-flex gap-3'>
              <Button
                onClick={() => navigate(-1)}
                icon={<ArrowLeftOutlined />}
                size='large'
              />
              {id ? <Title>Edit User</Title> : <Title>Add User</Title>}
            </Col>
          </Row>
        </Card>

        <XCardsTransparent>
          <XForm
            form={form}
            name='groupForm'
            layout='vertical'
            autoComplete='off'
            onFinish={() => {
              if (id) {
                updateUserDetails();
              } else saveUser();
            }}>
            <Row gutter={(15, 30)} className='mt-4'>
              <Col xs={12}>
                <XForm.Item
                  name='name'
                  label='User Name'
                  rules={[
                    {
                      required: true,
                      message: 'Please input User Name!',
                    },
                  ]}>
                  <Input
                    size='large'
                    placeholder='Enter user name'
                    name='name'
                    onChange={(e) =>
                      handleInputChange(e, formData, setFormData)
                    }
                  />
                </XForm.Item>
              </Col>
              <Col xs={12}>
                <XForm.Item
                  name='email'
                  label='Email ID'
                  rules={[
                    {
                      required: true,
                      message: 'Please input Email ID!',
                    },
                    {
                      type: 'email',
                      message: 'Please input valid Email ID!',
                    },
                  ]}>
                  <Input
                    size='large'
                    placeholder='Enter email id'
                    name='email'
                    onChange={(e) =>
                      handleInputChange(e, formData, setFormData)
                    }
                    disabled={id ? true : false}
                  />
                </XForm.Item>
              </Col>
              <Col xs={12}>
                <XForm.Item
                  name='role'
                  label='Select Role'
                  rules={[
                    {
                      required: true,
                      message: 'Please select Role!',
                    },
                  ]}>
                  <XSelect
                    size='large'
                    placeholder='Select Role'
                    className='removeBg'
                    onChange={(value, option) => {
                      console.log(value);
                      handleSelectChange('role', value, formData, setFormData);
                    }}
                    filterOption={filterByLabel}
                    showSearch
                    options={
                      getRolesDropdownState.apiState === 'success' &&
                      getRolesDropdownState.data.all_roles.length > 0 &&
                      getRolesDropdownState.data.all_roles.map((ele) => {
                        return {
                          key: ele._id,
                          value: ele._id,
                          label: ele.display_name,
                          slug: ele.role,
                        };
                      })
                    }></XSelect>
                </XForm.Item>
              </Col>

              <Col xs={12}>
                <Form.Item
                  name='is_active'
                  label='Suspend User'
                  rules={[
                    {
                      required: true,
                      message: 'Please select Role!',
                    },
                  ]}>
                  <Switch
                    checked={formData && formData.is_active ? false : true}
                    style={{ height: '22px' }}
                    onClick={(value) =>
                      handleSelectChange(
                        'is_active',
                        !value,
                        formData,
                        setFormData
                      )
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row className='mt-4' gutter={[15, 15]}>
              <Col xs={12}>
                {hasPermission('user-management', 'delete-users') ? (
                  <SubmitNoBgBtn
                    onClick={() =>
                      showDeleteConfirm(formData.id, formData.name)
                    }
                    style={{
                      background: '#fff',
                      color: '#000',
                    }}
                    type='text'
                    className='modalButton mr-5'>
                    Delete
                  </SubmitNoBgBtn>
                ) : (
                  <></>
                )}
              </Col>
              <Col xs={12} className='d-flex justify-content-end align-items-center'>
                <SubmitBtn htmlType='submit' className='modalButton'>
                  Submit
                </SubmitBtn>
              </Col>
            </Row>
          </XForm>
        </XCardsTransparent>
      </XContainer>
    </>
  );
};

const mapStateToProps = (state) => ({
  getUserByIdState: state.getUserById,
  editUserState: state.editUser,
  getRolesDropdownState: state.getRolesDropdown,
  addUserState: state.addUser,
  getUsersState: state.getUsers,
  deleteUserState: state.deleteUser,
});
const mapDispatchToProps = (dispatch) => ({
  addUser: (params) => dispatch(addUserApi(params)),
  addUserReset: () => dispatch(addUserReset()),
  getUserById: (params) => dispatch(getUserByIdApi(params)),
  getUserByIdReset: () => dispatch(getUserByIdReset()),
  editUser: (params) => dispatch(editUserApi(params)),
  editUserReset: () => dispatch(editUserReset()),
  getRolesDropdown: (params) => dispatch(getRolesDropdownApi(params)),
  getRolesDropdownReset: () => dispatch(getRolesDropdownReset()),
  deleteUser: (params) => dispatch(deleteUserApi(params)),
  deleteUserReset: () => dispatch(deleteUserReset()),
});
export default connect(mapStateToProps, mapDispatchToProps)(AddEditUser);
