import {
  LOGOUT,
  LOGOUT_SUCCESS,
  LOGOUT_FAIL,
  LOGOUT_RESET,
} from "../actions/logoutAction";

const initialState = {
  apiState: "",
  message: "",
  data: null,
  error: "",
};

const logoutReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGOUT:
      return {
        ...state,
        apiState: "loading",
      };
    case LOGOUT_SUCCESS:
      return {
        ...state,
        apiState: "success",
        data: action.response.data,
        message: action.response.message ? action.response.message : "success",
      };
    case LOGOUT_FAIL:
      return {
        ...state,
        apiState: "error",
        error: action.response.errors,
        message: action.response.message ? action.response.message : "error",
      };
    case LOGOUT_RESET:
      return initialState;
    default:
      return state;
  }
};

export default logoutReducer;
