import axios from "axios";
import config from "../config";
import { getUserToken } from "../utils/helper";

export const GET_ROLE_BY_ID = "GET_ROLE_BY_ID";
export const GET_ROLE_BY_ID_SUCCESS = "GET_ROLE_BY_ID_SUCCESS";
export const GET_ROLE_BY_ID_FAIL = "GET_ROLE_BY_ID_FAIL";
export const GET_ROLE_BY_ID_RESET = "GET_ROLE_BY_ID_RESET";

export const getRoleById = (params) => {
  return {
    type: GET_ROLE_BY_ID,
    params,
  };
};

export const getRoleByIdSuccess = (response) => {
  return {
    type: GET_ROLE_BY_ID_SUCCESS,
    response,
  };
};

export const getRoleByIdFail = (response) => {
  return {
    type: GET_ROLE_BY_ID_FAIL,
    response,
  };
};

export const getRoleByIdReset = () => {
  return {
    type: GET_ROLE_BY_ID_RESET,
  };
};

export const getRoleByIdApi = (data) => {
  return (dispatch) => {
    dispatch(getRoleById());
    axios
      .get(`${config.api.base_url}/settings/get-role-details/${data.id}`, {
        headers: {
          Authorization: "Bearer " + getUserToken(),
        },
      })
      .then((response) => {
        const getRoleByIdData = response.data;
        dispatch(getRoleByIdSuccess(getRoleByIdData));
      })
      .catch((error) => {
        const errorMsg = error.message;
        dispatch(getRoleByIdFail(errorMsg));
      });
  };
};
