import config from "../config";
import axios from "axios";
import { getUserToken } from "../utils/helper";

export const ADD_REMOVE_SAVED_JOBS = "ADD_REMOVE_SAVED_JOBS";
export const ADD_REMOVE_SAVED_JOBS_SUCCESS = "ADD_REMOVE_SAVED_JOBS_SUCCESS";
export const ADD_REMOVE_SAVED_JOBS_FAIL = "ADD_REMOVE_SAVED_JOBS_FAIL";
export const ADD_REMOVE_SAVED_JOBS_RESET = "ADD_REMOVE_SAVED_JOBS_RESET";

export const addRemoveSavedJobs = (params) => {
  return {
    type: ADD_REMOVE_SAVED_JOBS,
    params,
  };
};

export const addRemoveSavedJobsSuccess = (response) => {
  return {
    type: ADD_REMOVE_SAVED_JOBS_SUCCESS,
    response,
  };
};

export const addRemoveSavedJobsFail = (response) => {
  return {
    type: ADD_REMOVE_SAVED_JOBS_FAIL,
    response,
  };
};
export const addRemoveSavedJobsReset = (response) => {
  return {
    type: ADD_REMOVE_SAVED_JOBS_RESET,
  };
};

export const addRemoveSavedJobsApi = (data) => {
  return (dispatch) => {
    dispatch(addRemoveSavedJobs());
    axios
      .post(`${config.api.base_url}/explore/add-remove-saved-jobs`, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getUserToken()}`,
        },
      })
      .then((response) => {
        const addRemoveSavedJobsData = response.data;
        dispatch(addRemoveSavedJobsSuccess(addRemoveSavedJobsData));
      })
      .catch((error) => {
        const errorData = error.message;
        dispatch(addRemoveSavedJobsFail(errorData));
      });
  };
};
