import {
  GET_JOB_APPLICATIONS,
  GET_JOB_APPLICATIONS_SUCCESS,
  GET_JOB_APPLICATIONS_FAIL,
  GET_JOB_APPLICATIONS_RESET,
} from "../actions/getJobApplicationsAction";

const initialState = {
  apiState: "",
  data: [],
  error: "",
};

const getJobApplicationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_JOB_APPLICATIONS:
      return {
        ...state,
        apiState: "loading",
      };
    case GET_JOB_APPLICATIONS_SUCCESS:
      return {
        ...state,
        apiState: "success",
        data: action.response.data,
        message: action.response.message ? action.response.message : "success",
      };
    case GET_JOB_APPLICATIONS_FAIL:
      return {
        ...state,
        apiState: "error",
        error: action.response.message ? action.response.message : "error",
      };
    case GET_JOB_APPLICATIONS_RESET:
      return initialState;
    default:
      return state;
  }
};

export default getJobApplicationsReducer;
