import axios from "axios";
import config from "../config";
import { getUserToken } from "../utils/helper";

export const ADD_CANDIDATE_JOB = "ADD_CANDIDATE_JOB";
export const ADD_CANDIDATE_JOB_SUCCESS = "ADD_CANDIDATE_JOB_SUCCESS";
export const ADD_CANDIDATE_JOB_FAIL = "ADD_CANDIDATE_JOB_FAIL";
export const ADD_CANDIDATE_JOB_RESET = "ADD_CANDIDATE_JOB_RESET";

export const addCandidateJob = (params) => {
  return {
    type: ADD_CANDIDATE_JOB,
    params,
  };
};

export const addCandidateJobSuccess = (response) => {
  return {
    type: ADD_CANDIDATE_JOB_SUCCESS,
    response,
  };
};

export const addCandidateJobFail = (response) => {
  return {
    type: ADD_CANDIDATE_JOB_FAIL,
    response,
  };
};

export const addCandidateJobReset = (response) => {
  return {
    type: ADD_CANDIDATE_JOB_RESET,
  };
};
export const addCandidateJobApi = (data) => {
  return (dispatch) => {
    dispatch(addCandidateJob());
    axios
      .post(`${config.api.base_url}/explore/add-candidate-job`, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + getUserToken(),
        },
      })
      .then((response) => {
        const resellerJob = response.data;
        dispatch(addCandidateJobSuccess(resellerJob));
      })
      .catch((error) => {
        const errorData = error.response.data;
        dispatch(addCandidateJobFail(errorData));
      });
  };
};
