import React, { useState } from "react";
import { Row, Col, Divider, Dropdown } from "antd";
import {
  XTable,
  XCardsData,
  XTag,
  XPagination,
} from "../../styles/GlobalStyle";
import { MoreOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { checkModulePermissions, toTitleCase } from "../../utils/helper";
import { XButtonImg } from "../../pages/Manage/ManageStyle";
import moment from "moment";

const DataTable = (props) => {
  const {
    columns,
    tableData,
    totalRecords,
    setFilters,
    filters,
    state,
    page,
    showDeleteConfirm,
    handleEdit,
  } = props;

  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const [itemData, setItemData] = useState({});
  const filteredColumns = columns.filter(
    (col) => col.dataIndex !== "statusMobile"
  );

  const fetchCases = (pagination, _, sorter, ___) => {
    const queryParams = new URLSearchParams();

    // Append each filter to the query string
    Object.entries(filters).forEach(([key, value]) => {
      queryParams.append(key, value);
    });

    //Setting page filters: page size, sort, sort direction and limit in the query string
    queryParams.set(
      "page",
      pagination.current ? pagination.current : pagination
    );
    queryParams.set("limit", pagination?.pageSize ?? 10);

    if (sorter && Object.keys(sorter).length > 0) {
      queryParams.set("sort", sorter ? sorter.field : "createdAt");
      queryParams.set(
        "sortDirection",
        sorter ? sorter.order : filters.sortDirection
      );
    } else {
      queryParams.set("sort", "createdAt");
      queryParams.set("sortDirection", "descend");
    }

    const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
    window.history.pushState({}, "", newUrl);
    setFilters({
      ...filters,
      sort: sorter ? sorter.field : "createdAt",
      sortDirection: sorter ? sorter.order : "descend",
      page: pagination.current ? pagination.current : pagination,
      limit: pagination.pageSize ? pagination.pageSize : 10,
    });
  };

  // Filter and sort the columns based on the desired order
  console.log(tableData, "0=0=0=0=0=0=0=0=0=0=0=0=0=0=0=0=0=0=0");

  const cardsToRender = (item) => {
    switch (page) {
      case "reseller":
        return (
          <div style={{ padding: 5 }}>
            <Row
              className="p-3"
              style={{
                alignItems: "center",
              }}
              gutter={[10, 0]}>
              <Col xs={12}>
                <Link to={`${item.resellers.props.to}`}>
                  <p
                    style={{
                      color: "#232323",
                      fontFamily: "Inter",
                      fontSize: "16px",
                      fontStyle: "normal",
                      fontWeight: "600",
                      lineHeight: "30px",
                      letterSpacing: "-0.32px",
                      marginBottom: "7px",
                    }}>
                    {item.resellers.props.children}
                  </p>
                </Link>
              </Col>
              <Col
                xs={12}
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}>
                {/* {item.action ? (
									checkModulePermissions(
										'user-management',
									).sub_modules.includes('update-users') ? ( */}
                <Link to={`${item.buttons.props.to}`}>
                  <XButtonImg type="text">
                    <img src="./images/pencil.svg" alt="" />
                  </XButtonImg>
                </Link>
                {/* ) : (
										<></>
									) */}
                {/* ) : (<></>
                )} */}
              </Col>

              <Divider dashed className="mt-1 mb-3" />

              <>
                <Col xs={24} className="my-1">
                  <p
                    style={{
                      color: "#232323",
                      fontFamily: "Inter",
                      fontSize: "12px",
                      fontStyle: "normal",
                      fontWeight: "400",
                      lineHeight: "14px",
                      letterSpacing: "-0.24px",
                      marginBottom: "9px",
                    }}>
                    {moment(item.joinedOn).format("DD-MM-YYYY")}
                  </p>
                </Col>
                <Col xs={24} className="my-1">
                  <p
                    style={{
                      color: "#232323",
                      fontFamily: "Inter",
                      fontSize: "12px",
                      fontStyle: "normal",
                      fontWeight: "400",
                      lineHeight: "14px",
                      letterSpacing: "-0.24px",
                      marginBottom: "9px",
                    }}>
                    <span style={{ fontWeight: "600" }}>State: </span>
                    {item.state}
                  </p>
                </Col>
                <Col xs={24} className="my-1">
                  <p
                    style={{
                      color: "#232323",
                      fontFamily: "Inter",
                      fontSize: "12px",
                      fontStyle: "normal",
                      fontWeight: "400",
                      lineHeight: "14px",
                      letterSpacing: "-0.24px",
                      marginBottom: "9px",
                    }}>
                    <span style={{ fontWeight: "600" }}>City: </span>
                    {item.city}
                  </p>
                </Col>
              </>

              <Col xs={12} className="my-1">
                {item.status ? (
                  <XTag
                    className={`${"active"} mb-2`}
                    style={{
                      background: "#CDFFCD",
                    }}>
                    Active
                  </XTag>
                ) : (
                  <XTag
                    className={`${"inactive"} mb-2`}
                    style={{
                      background: "##FAEEEE",
                    }}>
                    Inactive
                  </XTag>
                )}
              </Col>
            </Row>
          </div>
        );
      case "employer":
        return (
          <div style={{ padding: 5 }}>
            <Row
              className="p-3"
              style={{
                alignItems: "center",
              }}
              gutter={[10, 0]}>
              <Col xs={12}>
                <Link to={`${item.employer.props.to}`}>
                  <p
                    style={{
                      color: "#232323",
                      fontFamily: "Inter",
                      fontSize: "16px",
                      fontStyle: "normal",
                      fontWeight: "600",
                      lineHeight: "30px",
                      letterSpacing: "-0.32px",
                      marginBottom: "7px",
                    }}>
                    {item.employer.props.children}
                  </p>
                </Link>
              </Col>
              <Col
                xs={12}
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}>
                {/* {item.action ? (
                    checkModulePermissions(
                      'user-management',
                    ).sub_modules.includes('update-users') ? ( */}
                <Link to={`${item.buttons.props.to}`}>
                  <XButtonImg type="text">
                    <img src="./images/pencil.svg" alt="" />
                  </XButtonImg>
                </Link>
                {/* ) : (
                      <></>
                    ) */}
                {/* ) : (<></>
                  )} */}
              </Col>

              <Divider dashed className="mt-1 mb-3" />

              <>
                <Col xs={24} className="my-1">
                  <p
                    style={{
                      color: "#232323",
                      fontFamily: "Inter",
                      fontSize: "12px",
                      fontStyle: "normal",
                      fontWeight: "400",
                      lineHeight: "14px",
                      letterSpacing: "-0.24px",
                      marginBottom: "9px",
                    }}>
                    {moment(item.joinedOn).format("DD-MM-YYYY")}
                  </p>
                </Col>
                <Col xs={24} className="my-1">
                  <p
                    style={{
                      color: "#232323",
                      fontFamily: "Inter",
                      fontSize: "12px",
                      fontStyle: "normal",
                      fontWeight: "400",
                      lineHeight: "14px",
                      letterSpacing: "-0.24px",
                      marginBottom: "9px",
                    }}>
                    <span style={{ fontWeight: "600" }}>Industry: </span>
                    {item.industry}
                  </p>
                </Col>
                <Col xs={24} className="my-1">
                  <p
                    style={{
                      color: "#232323",
                      fontFamily: "Inter",
                      fontSize: "12px",
                      fontStyle: "normal",
                      fontWeight: "400",
                      lineHeight: "14px",
                      letterSpacing: "-0.24px",
                      marginBottom: "9px",
                    }}>
                    <span style={{ fontWeight: "600" }}>State: </span>
                    {item.state}
                  </p>
                </Col>
                <Col xs={24} className="my-1">
                  <p
                    style={{
                      color: "#232323",
                      fontFamily: "Inter",
                      fontSize: "12px",
                      fontStyle: "normal",
                      fontWeight: "400",
                      lineHeight: "14px",
                      letterSpacing: "-0.24px",
                      marginBottom: "9px",
                    }}>
                    <span style={{ fontWeight: "600" }}>City: </span>
                    {item.city}
                  </p>
                </Col>
              </>

              <Col xs={12} className="my-1">
                {item.status ? (
                  <XTag
                    className={`${"active"} mb-2`}
                    style={{
                      background: "#CDFFCD",
                    }}>
                    Active
                  </XTag>
                ) : (
                  <XTag
                    className={`${"inactive"} mb-2`}
                    style={{
                      background: "##FAEEEE",
                    }}>
                    Inactive
                  </XTag>
                )}
              </Col>
            </Row>
          </div>
        );
      case "user-management":
        return (
          <div style={{ padding: 5 }}>
            <Row
              className="p-3"
              style={{
                alignItems: "center",
              }}
              gutter={[10, 0]}>
              <Col xs={20}>
                <p
                  style={{
                    color: "#232323",
                    fontFamily: "Inter",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: "600",
                    lineHeight: "30px",
                    letterSpacing: "-0.32px",
                    marginBottom: "7px",
                  }}>
                  {item.userName ? item.userName : item.role}
                </p>
              </Col>
              <Col
                xs={4}
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}>
                {/* {item.action ? (
									checkModulePermissions(
										'user-management',
									).sub_modules.includes('update-users') ? ( */}
                <XButtonImg
                  onClick={() => {
                    handleEdit(item);
                  }}
                  type="text">
                  <img src="./images/pencil.svg" alt="" />
                </XButtonImg>
                {/* ) : (
										<></>
									) */}
                {/* ) : (<></>
                )} */}
              </Col>

              {columns.map((col, index) => {
                if (col.dataIndex === "userName" || col.dataIndex === "action")
                  return null;
                const dataIndex = col.dataIndex;
                const dataValue = item[dataIndex];
                return (
                  <>
                    <Col xs={24}>
                      <p
                        style={{
                          color: "#232323",
                          fontFamily: "Inter",
                          fontSize: "14px",
                          fontStyle: "normal",
                          fontWeight: "400",
                          lineHeight: "14px",
                          letterSpacing: "-0.28px",
                        }}>
                        {dataValue && Array.isArray(dataValue)
                          ? dataValue[1]
                          : dataValue}
                      </p>
                    </Col>
                  </>
                );
              })}
            </Row>
          </div>
        );
      case "role-management":
        return (
          <div style={{ padding: 5 }}>
            <Row
              className="p-3"
              style={{
                alignItems: "center",
              }}
              gutter={[10, 0]}>
              <Col xs={20}>
                <p
                  style={{
                    color: "#232323",
                    fontFamily: "Inter",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: "600",
                    lineHeight: "30px",
                    letterSpacing: "-0.32px",
                    marginBottom: "7px",
                  }}>
                  {item.userName ? item.userName : item.role}
                </p>
              </Col>
              <Col
                xs={4}
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}>
                {/* {item.action ? (
                  checkModulePermissions(
                    'user-management'
                  ).sub_modules.includes('update-users') ? ( */}
                <XButtonImg
                  onClick={() => {
                    handleEdit(item);
                  }}
                  type="text">
                  <img src="./images/pencil.svg" alt="" />
                </XButtonImg>
                {/* ) : (
                    <></>
                  )
                ) : (
                  <></>
                )} */}
              </Col>

              {columns.map((col, index) => {
                if (
                  col.dataIndex === "role" ||
                  col.dataIndex === "action" ||
                  col.dataIndex === "modules"
                )
                  return null;
                const dataIndex = col.dataIndex;
                const dataValue = item[dataIndex];
                return (
                  <>
                    <Col xs={24}>
                      <p
                        style={{
                          color: "#232323",
                          fontFamily: "Inter",
                          fontSize: "14px",
                          fontStyle: "normal",
                          fontWeight: "400",
                          lineHeight: "14px",
                          letterSpacing: "-0.28px",
                        }}>
                        {dataValue && Array.isArray(dataValue)
                          ? dataValue[1]
                          : dataValue}
                      </p>
                    </Col>
                  </>
                );
              })}
            </Row>
          </div>
        );
      case "module":
        return (
          <div style={{ padding: 5 }}>
            <Row
              className="p-3"
              style={{
                alignItems: "center",
              }}
              gutter={[10, 0]}>
              <Col xs={20}>
                <p
                  style={{
                    color: "#232323",
                    fontFamily: "Inter",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: "600",
                    lineHeight: "30px",
                    letterSpacing: "-0.32px",
                    marginBottom: "7px",
                  }}>
                  {item.module_name}
                </p>
              </Col>
              <Col
                xs={4}
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}>
                {item.status ? (
                  <XTag className={`${item.status} mb-2`}>{item.status}</XTag>
                ) : item.action ? (
                  item.action
                ) : (
                  <></>
                )}
              </Col>

              <Divider dashed className="mt-1 mb-3" />

              {columns.map((col, index) => {
                if (
                  col.dataIndex === "action" ||
                  col.dataIndex === "module_name"
                )
                  return null;
                const dataIndex = col.dataIndex;
                const dataValue = item[dataIndex];

                return (
                  <>
                    <Col xs={24} className="my-1">
                      <p
                        style={{
                          color: "#232323",
                          fontFamily: "Inter",
                          fontSize: "12px",
                          fontStyle: "normal",
                          fontWeight: "600",
                          lineHeight: "14px",
                          letterSpacing: "-0.24px",
                          marginBottom: "9px",
                        }}>
                        {col.title}:
                      </p>
                      <p
                        style={{
                          color: "#232323",
                          fontFamily: "Inter",
                          fontSize: "14px",
                          fontStyle: "normal",
                          fontWeight: "400",
                          lineHeight: "14px",
                          letterSpacing: "-0.28px",
                        }}>
                        {dataValue}
                      </p>
                    </Col>
                  </>
                );
              })}
            </Row>
          </div>
        );

      default:
        <></>;
        break;
    }
  };

  return (
    <>
      <Row gutter={16}>
        <Col xs={0} sm={0} md={24} lg={24} xl={24}>
          {/* Render the table for larger screens */}
          <XTable
            columns={filteredColumns}
            dataSource={tableData}
            loading={state === "loading" ? true : false}
            scroll={{ x: 991 }}
            pagination={{
              total: totalRecords,
              current: Number(filters.page),
              showSizeChanger: true,
              pageSize: filters.limit,
            }}
            className="customHorizontalScroll"
            onChange={fetchCases}
            showSorterTooltip={false}
          />
        </Col>
        <Col xs={24} sm={24} md={0} lg={0} xl={0} className="p-0">
          {tableData.map((item, index) => {
            return <XCardsData key={index}>{cardsToRender(item)}</XCardsData>;
          })}
        </Col>
        <Col xs={24} sm={24} md={0} lg={0} xl={0} className="text-center my-4">
          <XPagination
            current={filters.page ? Number(filters.page) : 1}
            total={totalRecords}
            onChange={fetchCases}
            showSizeChanger
            pageSize={10}
          />
        </Col>
      </Row>
    </>
  );
};

export default DataTable;
