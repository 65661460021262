import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import JobCard from '../../components/JobCards/index';
import JobDetails from '../../components/JobDescription/index';
import SearchSection from '../../components/SearchSection/index';
import {
  Col,
  notification,
  Pagination,
  Radio,
  Row,
  DatePicker,
  Select,
  Button,
  Dropdown,
  Slider,
  Result,
} from 'antd';
import {
  ContentContainer,
  FilterButton,
  FilterContainer,
  Header,
  JobCount,
  JobListContainer,
  PageContainer,
  PaginationContainer,
  StyledPagination,
  XSelect,
} from '../../styles/GlobalStyle';
import { connect } from 'react-redux';
import {
  getCandidateJobListApi,
  getCandidateJobListReset,
} from '../../actions/getCandidateJobListAction';
import { addCandidateJobApi } from '../../actions/addCandidateJobAction';
import { getCandidateApplicationsByIdApi } from '../../actions/getCandidateApplicationsByIdAction';
import {
  checkModulePermissions,
  fetchListThroughPagination,
  hasPermission,
} from '../../utils/helper';
import { useForm } from 'antd/es/form/Form';
import CaseSearch from '../../components/CaseSearch/CaseSearch';
import { XForm } from '../Login/LoginStyle';
import { getIndustryCategoriesApi } from '../../actions/getIndustryCategoriesAction';
import { getIndustriesApi } from '../../actions/getIndustriesAction';
import { filterByLabel } from '../../utils/formFunctions';
import {
  rangePresets,
  jobFilters,
  employment_type_list,
  work_type_list,
} from '../../utils/constants';
import { useParams } from 'react-router-dom';
import { getEmployerOrganizationsListApi } from '../../actions/getEmployerOrganizationsListAction';
const { RangePicker } = DatePicker;

const JobListingPage = (props) => {
  const defaultFilters = {
    page: 1,
    limit: 10,
    search: '',
    state: '',
    category: '',
    posted: '',
    industry: '',
    joined_on: '',
    sortDirection: null,
  };

  const {
    getCandidateJobList,
    getCandidateJobListState,
    getCandidateJobListReset,
    addCandidateJobState,
    addCandidateJob,
    getCandidateApplicationsByIdState,
    getCandidateApplicationsById,
    getIndustryCategories,
    getIndustryCategoriesState,
    getIndustries,
    getIndustriesState,
    getEmployerOrganizationsList,
    getEmployerOrganizationsListState,
  } = props;
  const { id } = useParams();

  const [form] = useForm();

  const [selectedJob, setSelectedJob] = useState(null);
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);

  const getFiltersFromUrl = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const urlFilters = {};

    // Iterate through each query parameter and set it in the state
    for (const [key, value] of urlParams.entries()) {
      urlFilters[key] = value;
    }

    // Merge with default filters or initial state as needed
    const filters = {
      ...defaultFilters, // Replace with your default filters
      ...urlFilters,
    };

    return filters;
  };
  const initialFilters = getFiltersFromUrl();

  const [filters, setFilters] = useState(initialFilters);
  // const [tableData, setTableData] = useState([]);
  const [totalRecords, setTotalRecords] = useState('');
  const [open, setOpen] = useState(false);
  const [currentJobs, setCurrentJobs] = useState(null);
  const [candidateApplicationIds, setCandidateApplicationIds] = useState([]);
  const [candidateName, setCandidateName] = useState('');
  const [candidateDetail, setCandidateDetails] = useState({
    name: '',
    email: '',
  });
  const jobsPerPage = 10;

  const indexOfLastJob = filters.page * jobsPerPage;
  const indexOfFirstJob = indexOfLastJob - jobsPerPage;

  const handlePageChange = (page) => {
    fetchListThroughPagination(page, setFilters, filters);
    setFilters((prev) => ({ ...prev, page: page }));
  };

  const handleCloseDrawer = () => {
    setIsDrawerVisible(false);
  };

  const handleJobClick = (job) => {
    setSelectedJob(job);
    if (isDrawerVisible) {
      setIsDrawerVisible(true);
    }
  };

  const handleAddCandidateJob = (id) => {
    //id here includes employer_id, job_id
    let data = { ...id, ...candidateDetail };
    if (id) addCandidateJob(data);
  };

  const showDrawer = () => {
    getIndustries({});
    getEmployerOrganizationsList({});
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  const onChange = (key) => {
    setFilters({
      ...filters,
      [key.target.name]: key.target.value,
    });
  };

  const handleSortChange = (value, direction) => {
    setFilters({
      ...filters,
      sortBy: value,
      sortDirection: direction,
    });
  };

  const handleDateChange = (dateRange) => {
    if (dateRange) {
      setFilters({
        ...filters,
        page: 1,
        posted: [
          dateRange[0].format('YYYY-MM-DD'),
          dateRange[1].format('YYYY-MM-DD'),
        ],
      });
    } else {
      setFilters({
        ...filters,
        posted: [],
      });
    }
  };

  const handleCategorySearch = (search) => {
    if (search.length >= 3) {
      getIndustryCategories({
        search: search,
        industries: filters.industry ? [filters.industry] : null,
      });
    } else if (search.length == 0) {
      getIndustryCategories({
        industries: [filters.industry],
      });
    }
  };
  const applyFilters = () => {
    const queryParams = new URLSearchParams(window.location.search);

    // Iterate through filters and update the query parameters
    Object.entries(filters).forEach(([key, value]) => {
      const currentValue = queryParams.get(key);

      // Check if the parameter exists in the current query and has a value, if yes, update its value
      if (currentValue !== null) {
        queryParams.set(key, value);
      } else if (queryParams.has(key)) {
        // If the parameter exists but has no value, keep its existing value
        queryParams.set(key, queryParams.get(key));
      } else {
        // If the parameter does not exist in the current query, append it with the current value
        queryParams.append(key, value);
      }
    });

    // Set the page to 1
    queryParams.set('page', '1');
    filters.page = 1;

    // Update the URL
    const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
    window.history.pushState({}, '', newUrl);
  };

  const clearFilters = () => {
    const queryParams = new URLSearchParams(window.location.search);

    // Iterate through defaultFilters and update the query parameters
    Object.entries(defaultFilters).forEach(([key, value]) => {
      const currentValue = queryParams.get(key);

      // Check if the parameter exists in the current query and has a value, if yes, update its value
      if (currentValue !== null) {
        queryParams.set(key, value);
      } else if (queryParams.has(key)) {
        // If the parameter exists but has no value, keep its existing value
        queryParams.set(key, queryParams.get(key));
      } else {
        // If the parameter does not exist in the current query, append it with the default value
        queryParams.append(key, value);
      }
    });

    // Clear the form fields
    form.resetFields();

    // Update the state with default values
    setFilters(defaultFilters);

    // Update the URL
    const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
    window.history.pushState({}, '', newUrl);
  };

  useEffect(() => {
    // Retrieve filters from the updated URL
    const updatedFilters = getFiltersFromUrl();
    setFilters({ ...updatedFilters });

    // Make API call with updated filters and pagination
    if (hasPermission('recruiter-submission', 'list-jobs')) {
      getCandidateJobList({ ...updatedFilters });
      getCandidateApplicationsById({ candidate_id: id });
    }
  }, [window.location.search]);

  useEffect(() => {
    if (getCandidateJobListState.apiState === 'success') {
      setTotalRecords(getCandidateJobListState.data.totalCount);
      // setTableData(getCandidateJobListState.data.list);
      setSelectedJob(getCandidateJobListState.data.list[0]);

      if (getCandidateJobListState.data.list.length === 1) {
        setCurrentJobs(getCandidateJobListState.data.list); // Return the whole list with one job
      } else {
        setCurrentJobs(
          getCandidateJobListState.data.list.slice(
            indexOfFirstJob,
            Math.min(indexOfLastJob, getCandidateJobListState.data.list.length)
          )
        );
      }
      getCandidateJobListReset();
    } else if (getCandidateJobListState.apiState === 'error') {
      console.log('API Error=>', getCandidateJobListState.message);
      notification.error({
        message: 'Unable to get Job List',
      });
      getCandidateJobListReset();
    }
  }, [getCandidateJobListState.apiState]);

  useEffect(() => {
    if (getCandidateApplicationsByIdState.apiState === 'success') {
      let candidateDetails = getCandidateApplicationsByIdState.data.candidate;
      setCandidateDetails({
        name: candidateDetails.first_name + ' ' + candidateDetails.last_name,
        email: candidateDetails.email_address,
      });
      getCandidateApplicationsByIdState.data?.applications?.map(
        (ele, index) => {
          setCandidateApplicationIds((prevState) => [
            ...prevState,
            ele.job_inventory_id,
          ]);
        }
      );
      const name =
        getCandidateApplicationsByIdState.data.candidate?.first_name +
        ' ' +
        getCandidateApplicationsByIdState.data.candidate?.last_name;
      setCandidateName(name);
    }
  }, [getCandidateApplicationsByIdState.apiState]);

  useEffect(() => {
    if (addCandidateJobState.apiState === 'success') {
      getCandidateApplicationsById({ candidate_id: id });
    }
  }, [addCandidateJobState.apiState]);

  //   useEffect(() => {
  //     const handleResize = () => {
  //       const shouldShowDrawer = window.innerWidth <= 991;
  //       setIsDrawerVisible(shouldShowDrawer);
  //       if (!shouldShowDrawer && !selectedJob) {
  //         setSelectedJob(jobsData[0]);
  //       }
  //     };

  //     handleResize();
  //     window.addEventListener('resize', handleResize);
  //     return () => window.removeEventListener('resize', handleResize);
  //   }, []);

  return (
    <>
      {hasPermission('recruiter-submission', 'list-jobs') ? (
        <>
          <CaseSearch
            searchOption='reseller'
            title='Resellers'
            filters={[
              <XForm.Item
                name='industry'
                colon={false}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
                label='Industry'
                className='removeFormMargin'>
                <XSelect
                  showSearch
                  size='large'
                  className='removeBg'
                  onFocus={() => getIndustries({})}
                  defaultValue={filters.industry || null}
                  filterOption={filterByLabel}
                  onSearch={(search) => {
                    if (search.length >= 3) {
                      getIndustries({ search });
                    } else {
                      getIndustries({});
                    }
                  }}
                  onChange={(value) => {
                    setFilters({
                      ...filters,
                      industry: value,
                    });
                    getIndustryCategories({ industries: [value] });
                    form.setFieldsValue({ category: null });
                  }}
                  onClear={() => {
                    setFilters({
                      ...filters,
                      industry: '',
                    });
                  }}
                  options={
                    getIndustriesState.apiState === 'success' &&
                    getIndustriesState.data.all_industries.length > 0 &&
                    getIndustriesState.data.all_industries.map((ind) => {
                      return {
                        value: ind._id,
                        label: ind.industry_name,
                        type: 'industry',
                      };
                    })
                  }
                  placeholder='Select Industry'
                />
              </XForm.Item>,
              <XForm.Item
                colon={false}
                name='category'
                label='Category'
                className='selectBox'>
                <XSelect
                  name='category'
                  size='large'
                  placeholder='Search Functions'
                  className='removeShadow ml-2'
                  defaultValue={filters.category || null}
                  showSearch
                  allowClear={true}
                  onSearch={handleCategorySearch}
                  onChange={(category) => {
                    setFilters({
                      ...filters,
                      category,
                    });
                  }}
                  filterOption={filterByLabel}
                  options={
                    getIndustryCategoriesState.apiState === 'success' &&
                    getIndustryCategoriesState.data.all_categories.length > 0
                      ? getIndustryCategoriesState.data.all_categories.map(
                          (item) => {
                            return {
                              value: item.id,
                              label: item.category_name,
                            };
                          }
                        )
                      : []
                  }></XSelect>
              </XForm.Item>,
              <XForm.Item
                name='employer_id'
                label='Employer'
                className='selectBox'>
                <XSelect
                  name='employer_id'
                  className='removeShadow ml-2'
                  onFocus={() => getEmployerOrganizationsList({})}
                  defaultValue={filters.employer_id || null}
                  filterOption={filterByLabel}
                  onSearch={(search) => {
                    if (search.length >= 3) {
                      getEmployerOrganizationsList({ search });
                    } else {
                      getEmployerOrganizationsList({});
                    }
                  }}
                  onChange={(value) => {
                    setFilters({
                      ...filters,
                      employer_id: value,
                    });
                  }}
                  onClear={() => {
                    setFilters({
                      ...filters,
                      employer_id: '',
                    });
                  }}
                  size='large'
                  options={
                    getEmployerOrganizationsListState.apiState === 'success' &&
                    getEmployerOrganizationsListState.data.all_organizations
                      .length > 0
                      ? getEmployerOrganizationsListState.data.all_organizations.map(
                          (item) => {
                            return {
                              value: item._id,
                              label: item.organization_name,
                            };
                          }
                        )
                      : []
                  }></XSelect>
              </XForm.Item>,
              <XForm.Item name='salary' label='Salary' className='selectBox'>
                <Slider
                  range
                  min={10000}
                  max={10000000}
                  step={10000}
                  defaultValue={[
                    filters.salary_min || 2000000,
                    filters.salary_max || 5000000,
                  ]}
                  onChange={(value) => {
                    setFilters({
                      ...filters,
                      salary_min: value[0],
                      salary_max: value[1],
                    });
                  }}
                />
              </XForm.Item>,
              <XForm.Item
                colon={false}
                label='Employment Type'
                className='formLable'
                name='employment_type'>
                <XSelect
                  size='large'
                  placeholder='Employment Type'
                  className='removeBg'
                  onChange={(value) => {
                    setFilters({
                      ...filters,
                      employment_type: value,
                    });
                  }}
                  onClear={() => {
                    setFilters({
                      ...filters,
                      employment_type: '',
                    });
                  }}
                  defaultValue={filters.employment_type || null}
                  options={employment_type_list}
                />
              </XForm.Item>,
              <XForm.Item
                colon={false}
                label='Work Type'
                className='formLable'
                name='work_type'>
                <XSelect
                  size='large'
                  placeholder='Work Type'
                  className='removeBg'
                  onChange={(value) => {
                    setFilters({
                      ...filters,
                      work_type: value,
                    });
                  }}
                  onClear={() => {
                    setFilters({
                      ...filters,
                      work_type: '',
                    });
                  }}
                  defaultValue={filters.work_type || null}
                  options={work_type_list}
                />
              </XForm.Item>,
              <XForm.Item
                name='posted'
                colon={false}
                label='By Date'
                className='removeFormMargin'>
                <XForm.Item colon={false} name='posted' className='selectBox'>
                  <RangePicker
                    defaultValue={filters.posted || null}
                    size='large'
                    className='removeShadow ml-2'
                    presets={rangePresets}
                    onChange={handleDateChange}
                    allowClear
                    style={{
                      borderRadius: '50px',
                      height: '40px',
                    }}></RangePicker>
                </XForm.Item>
              </XForm.Item>,
              <XForm.Item
                colon={false}
                name='sortBy'
                label='Sort By'
                className='selectBox'>
                <Dropdown
                  className='removeShadow w-100  ms-0'
                  trigger={['click']}
                  menu={{
                    items: jobFilters(handleSortChange), // Pass the dynamic function here
                  }}>
                  <Button
                    className='shortBtn'
                    style={{
                      border: 'none',
                      padding: 10,
                      height: '40px',
                      width: '110px',
                      backgroundColor: '#ffffff',
                      border: '1px solid #d9d9d9',
                      color: '#000',
                      display: 'flex',
                      flexDirection: 'row',
                    }}>
                    Sort&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <img className='mt-1' src='/images/short-icon.svg' alt='' />
                  </Button>
                </Dropdown>
              </XForm.Item>,
            ]}
            onClose={onClose}
            open={open}
            applyFilters={applyFilters}
            form={form}
            clearFilters={clearFilters}
          />
          <SearchSection
            setFilters={setFilters}
            filters={filters}
            isRecruiter={true}
            candidateName={candidateName}
          />
          <PageContainer>
            <Header
              className='align-items-center flex-wrap'
              style={{
                margin: '10px 0px',
              }}>
              <JobCount>{totalRecords || 0} Jobs</JobCount>
              <FilterContainer onClick={() => showDrawer()}>
                <FilterButton>
                  <img
                    src='https://cdn.builder.io/api/v1/image/assets/TEMP/d0e11f66e694b5fbdadd8edb8109ba7b24c06cb1f66c477f282ec451dfd237e7?placeholderIfAbsent=true&apiKey=ac9769c5e7eb416e8a74975078a910d6'
                    alt='Filter icon'
                  />
                  Filter
                </FilterButton>
              </FilterContainer>
            </Header>
            <ContentContainer>
              <JobListContainer>
                {currentJobs &&
                  currentJobs.length > 0 &&
                  currentJobs.map((job) => (
                    <JobCard
                      key={job._id}
                      {...job}
                      isSelected={job._id === selectedJob._id}
                      onClick={() => handleJobClick(job)}
                    />
                  ))}
              </JobListContainer>
              {/* {!isDrawerVisible && (
          <JobDetailsContainer>
            <JobDetails job={selectedJob} />
          </JobDetailsContainer>
        )} */}
              <JobDetails
                job={selectedJob}
                handleAddCandidateJob={handleAddCandidateJob}
                candidateApplicationIds={candidateApplicationIds}
              />
            </ContentContainer>
            <PaginationContainer>
              <StyledPagination
                current={filters.page || 1}
                total={totalRecords || 0}
                pageSize={jobsPerPage}
                onChange={handlePageChange}
                showSizeChanger={false}
              />
            </PaginationContainer>
            {/* {isDrawerVisible && (
        <Drawer isVisible={isDrawerVisible && selectedJob !== null}>
          <JobDetails job={selectedJob} onClose={handleCloseDrawer} isDrawer={true} />
        </Drawer>
      )} */}
          </PageContainer>
        </>
      ) : (
        <Result
          status='403'
          title='403'
          subTitle='Sorry, you are not authorized to access this page.'
          extra={<Button type='primary'>Back Home</Button>}
        />
      )}{' '}
    </>
  );
};

const mapStateToProps = (state) => ({
  getCandidateJobListState: state.getCandidateJobList,
  getCandidateApplicationsByIdState: state.getCandidateApplicationsById,
  addCandidateJobState: state.addCandidateJob,
  getIndustryCategoriesState: state.getIndustryCategories,
  getIndustriesState: state.getIndustries,
  getEmployerOrganizationsListState: state.getEmployerOrganizationsList,
});

const mapDispatchToProps = (dispatch) => ({
  getCandidateJobList: (params) => dispatch(getCandidateJobListApi(params)),
  getCandidateJobListReset: () => dispatch(getCandidateJobListReset()),
  getCandidateApplicationsById: (params) =>
    dispatch(getCandidateApplicationsByIdApi(params)),
  addCandidateJob: (params) => dispatch(addCandidateJobApi(params)),
  getIndustryCategories: (params) => dispatch(getIndustryCategoriesApi(params)),
  getIndustries: (params) => dispatch(getIndustriesApi(params)),
  getEmployerOrganizationsList: (params) =>
    dispatch(getEmployerOrganizationsListApi(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(JobListingPage);
