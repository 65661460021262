import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button, Layout, Result } from 'antd';
import JobStatusCards from '../../components/DashboardCards/jobStatusCard';
import JobApplicationsTable from '../../components/DashboardCards/jobapplicationtable';
import ProfileViewChart from '../../components/DashboardCards/profileviewchart';
import WelcomeSection from '../../components/DashboardCards/welcomesection';
import JobApplicationsOverview from '../../components/DashboardCards/recruitementCart';
import UserDistributionChart from '../../components/DashboardCards/userDistributionchart';
import EmployerDistributionChart from '../../components/DashboardCards/employerdistributionchart';
import { hasPermission } from '../../utils/helper';
import JobBoardCards from '../../components/DashboardCards/jobBoards';

const { Content } = Layout;

const Dashboard = () => {
  const [userData, setUserData] = useState({
    companyName: localStorage.getItem('username') || 'Skill Sprints', // Initialize from localStorage or fallback to default
    userRole: localStorage.getItem('role') || 'Super Admin', // Initialize from localStorage or fallback to default
  });

  useEffect(() => {
    const userRole = localStorage.getItem('role');
    const userName = localStorage.getItem('username');

    // Update state if values from localStorage are different from the initial ones
    if (userRole && userName) {
      setUserData({
        companyName: userName, // Set userName as companyName
        userRole: userRole, // Set userRole from localStorage
      });
    }
  }, []); // Empty dependency array ensures this runs once on component mount

  return (
    <DashboardContainer>
      {hasPermission('dashboard') ? (
        <Content>
          <WelcomeSection userData={userData} />
          <Divider />
          <ContentSection>
            {hasPermission('dashboard', 'job-boards') ? (
              <>
                <SectionTitle>External Job Boards</SectionTitle>
                <JobBoardCards />
              </>
            ) : (
              ''
            )}
            {hasPermission('dashboard', 'job-status') ? (
              <>
                <SectionTitle>Job Status</SectionTitle>
                <JobStatusCards />
              </>
            ) : (
              ''
            )}
            {hasPermission('dashboard', 'jobs-applied') ? (
              <ProfileViewChart />
            ) : (
              ''
            )}
            {hasPermission('dashboard', 'job-applications-overview') ? (
              <JobApplicationsOverview />
            ) : (
              ''
            )}
            {hasPermission('dashboard', 'job-count') ? (
              <JobApplicationsTable />
            ) : (
              ''
            )}
            {hasPermission('dashboard', 'user-distribution') ? (
              <ChartContainer>
                <UserDistributionChart />
                <EmployerDistributionChart />
              </ChartContainer>
            ) : (
              ''
            )}
          </ContentSection>
        </Content>
      ) : (
        <Result
          status='403'
          title='403'
          subTitle='Sorry, you are not authorized to access this page.'
          extra={<Button type='primary'>Back Home</Button>}
        />
      )}
    </DashboardContainer>
  );
};

const DashboardContainer = styled(Layout)`
  background-color: #fff;
`;

// const Content = styled.main`
//   padding: 24px;
// `;

const Divider = styled.hr`
  margin: 9px auto;
  width: 100%;
  max-width: 1120px;
  height: 1px;
  border: none;
  background-color: #ced6ff;
`;

const ContentSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1120px;
  margin: 13px auto 0;
  padding: 0 20px;

  @media (max-width: 1200px) {
    max-width: 100%;
  }
`;

const SectionTitle = styled.h2`
  width: 100%;
  color: #1c1c1e;
  font: 400 24px/1 Outfit, sans-serif;
  margin-bottom: 16px;
`;

const ChartContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 24px;
  margin-bottom: 40px;

  @media (max-width: 1200px) {
    flex-direction: column;
  }
`;

export default Dashboard;
