import {
  GET_JOB_COUNTS,
  GET_JOB_COUNTS_SUCCESS,
  GET_JOB_COUNTS_FAIL,
  GET_JOB_COUNTS_RESET,
} from '../actions/getJobCountsAction';

const initialState = {
  apiState: '',
  data: null,
  error: '',
};

const getJobCountsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_JOB_COUNTS:
      return {
        ...state,
        apiState: 'loading',
      };
    case GET_JOB_COUNTS_SUCCESS:
      return {
        ...state,
        apiState: 'success',
        data: action.response.data,
        message: action.response.message ? action.response.message : 'success',
      };
    case GET_JOB_COUNTS_FAIL:
      return {
        ...state,
        apiState: 'error',
        error: action.response.message ? action.response.message : 'error',
      };
    case GET_JOB_COUNTS_RESET:
      return initialState;
    default:
      return state;
  }
};

export default getJobCountsReducer;
