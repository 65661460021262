import axios from "axios";
import moment from "moment";
import config from "../config";
import { Badge } from "antd";

export const isLogin = () => {
  let userAccessToken = localStorage.getItem("accessToken");
  if (userAccessToken) return true;
  // Need to make false later
  return false;
};

export const getRefreshToken = () => {
  return localStorage.getItem("refreshToken") || null;
};

export const getExpiresAt = () => {
  return localStorage.getItem("expiresAt") || null;
};

export const getUserToken = () => {
  return localStorage.getItem("accessToken") || null;
};

export const getUserName = () => {
  return toTitleCase(localStorage.getItem("username")) || "";
};

export const getUserProfile = () => {
  return localStorage.getItem("profile") || "";
};

export const getDate = () => {
  const today = new Date();

  // Define arrays of day names and month names for formatting
  const dayNames = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  // Get the day of the week (0 = Sunday, 1 = Monday, ...)
  const dayOfWeek = today.getDay();
  // Get the month (0 = January, 1 = February, ...)
  const month = today.getMonth();
  // Get the day of the month
  const dayOfMonth = today.getDate();
  // Get the year
  const year = today.getFullYear();

  // Format the date as "Day, Month DD, YYYY"
  const formattedDate = `${dayNames[dayOfWeek]}, ${monthNames[month]} ${dayOfMonth}, ${year}`;

  return formattedDate;
};

export const toTitleCase = (str) => {
  // also replace _ by space
  if (str) {
    str = str.replace(/_/g, " ");
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  } else return;
};

export const checkModulePermissions = (module) => {
  let permissions_response = {
    authorized: false,
    message: "Not Authroized",
    sub_modules: [],
  };
  let permissions = JSON.parse(localStorage.getItem("permissions")) || [];
  let check_module_exists = permissions.filter((item) => {
    if (item.module.module_slug === module) {
      return item;
    }
  });

  if (check_module_exists.length > 0) {
    let sub_modules = [];

    check_module_exists[0].sub_modules.map((item) => {
      sub_modules.push(item.sub_module_slug);
    });

    permissions_response = {
      ...permissions_response,
      authorized: true,
      message: "Authroized",
      sub_modules,
    };
  }

  return permissions_response;
};

export const truncateString = (text, maxLength) => {
  if (!maxLength) {
    const words = text.split("_");
    const capitalizedText = words
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
    return capitalizedText;
  }
  if (text && text.length <= maxLength) {
    return text;
  } else return `${text && text.substring(0, maxLength)}...`;
};

export const urlPagination = (filters, filterKey, filterValue) => {
  const queryParams = new URLSearchParams();

  // Append each filter to the query string
  Object.entries(filters).forEach(([key, value]) => {
    queryParams.append(key, value);
  });
  //Setting page filters: page size, sort, sort direction and limit in the query string
  queryParams.set("page", 1);
  // queryParams.set(filterKey, filterValue);
  // Check if filterKey and filterValue are arrays
  if (Array.isArray(filterKey) && Array.isArray(filterValue)) {
    filterKey.forEach((key, index) => {
      queryParams.set(key, filterValue[index]);
    });
  } else {
    // If they are not arrays, treat them as single values
    queryParams.set(filterKey, filterValue);
  }
  const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
  window.history.pushState({}, "", newUrl);
};

export const fetchListThroughPagination = (pagination, setFilters, filters) => {
  const queryParams = new URLSearchParams();

  // Append each filter to the query string
  Object.entries(filters).forEach(([key, value]) => {
    queryParams.append(key, value);
  });

  //Setting page filters: page size, sort, sort direction and limit in the query string
  queryParams.set("page", pagination || 1);
  // queryParams.set("limit", pagination?.pageSize ?? 10);

  // if (sorter && Object.keys(sorter).length > 0) {
  //   queryParams.set("sort", sorter ? sorter.field : "createdAt");
  //   queryParams.set(
  //     "sortDirection",
  //     sorter ? sorter.order : filters.sortDirection
  //   );
  // } else {
  //   queryParams.set("sort", "createdAt");
  //   queryParams.set("sortDirection", "descend");
  // }

  const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
  window.history.pushState({}, "", newUrl);
  setFilters({
    ...filters,
    // sort: sorter ? sorter.field : "createdAt",
    // sortDirection: sorter ? sorter.order : "descend",
    page: pagination || 1,
    // limit: pagination.pageSize ? pagination.pageSize : 10,
  });
};

export const getFileNameFromDocPath = (doc_path) => {
  return doc_path?.split("/").pop();
};

export const disableFutureDates = (current) => {
  return current && current > moment().endOf("day");
};

export const getSignedUrlForGetObject = async (
  folder_name,
  doc_path,
  currentId
) => {
  if (doc_path) {
    let file = doc_path.split("/").pop();
    let get_file_name = file.split(".");
    let file_name = get_file_name[get_file_name.length - 2];
    let ext = get_file_name[get_file_name.length - 1];

    try {
      const res = await axios.get(
        `${config.api.base_url}/settings/get-signed-url?action=getObject&folder_name=${folder_name}&file_name=${file_name}&ext=${ext}&id=${currentId}`,
        {
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            Authorization: "Bearer " + getUserToken(),
          },
        }
      );
      return res.data.data;
    } catch (err) {
      console.log(err);
    }
  }
};

export const capitalizeAndSplitByDash = (string) => {
  // split by underscore and capitalize first letter of each word
  if (typeof string !== "string") return "";

  const capitalize = (word) => word.charAt(0).toUpperCase() + word.slice(1);

  return string.split("_").map(capitalize).join(" ");
};

export function timeAgo(dateString) {
  const now = new Date();
  const date = new Date(dateString);
  const diffInSeconds = Math.floor((now - date) / 1000);

  const secondsInADay = 86400; // 24 * 60 * 60
  const daysAgo = Math.floor(diffInSeconds / secondsInADay);

  if (daysAgo === 0) {
    return "Today";
  } else if (daysAgo === 1) {
    return "Yesterday";
  } else if (daysAgo > 1 && daysAgo < 7) {
    return `${daysAgo} Days Ago`;
  } else {
    return date.toLocaleDateString(); // Format as 'MM/DD/YYYY' or as per locale
  }
}

export function splitTextIntoBulletPoints(text) {
  // Split the text by full stop, comma, or semicolon followed by a space
  const sentences = text
    .split(/(?<=[\.,;])\s+/)
    .filter((sentence) => sentence.trim() !== "");

  // Return an array of bullet points
  return sentences.map((sentence) => `• ${sentence.trim()}`);
}

// export function isWithinLast7Days(dateToCheck) {
//   // Get the current date
//   const currentDate = new Date();

//   // Calculate the date 7 days ago
//   const sevenDaysAgo = new Date();
//   sevenDaysAgo.setDate(currentDate.getDate() - 7);

//   // Convert the input date to a Date object if it's not already
//   const inputDate = new Date(dateToCheck);

//   // Check if the input date is between the current date and seven days ago
//   return inputDate >= sevenDaysAgo && inputDate <= currentDate;
// }

export function daysUntil(dateString) {
  // Get the current date and time
  const currentDate = new Date();

  // Convert the input date string to a Date object
  const targetDate = new Date(dateString);

  // Calculate the difference in milliseconds
  const diffInMilliseconds = targetDate - currentDate;

  // Convert milliseconds to days
  const diffInDays = Math.ceil(diffInMilliseconds / (1000 * 60 * 60 * 24));

  // Format the result based on the number of days
  if (diffInDays === 0) {
    return "Today";
  } else if (diffInDays === 1) {
    return "Tomorrow";
  } else if (diffInDays > 1) {
    return `${diffInDays} days`;
  } else {
    return "Expired";
  }
}

export function generateRandomColor() {
  // Function to check if the color contrasts well with the base color
  const isContrastingColor = (color) => {
    const baseColor = [39, 93, 245]; // RGB of #275df5
    const contrastThreshold = 128;

    // Calculate the brightness of both colors
    const brightness = (r, g, b) => 0.299 * r + 0.587 * g + 0.114 * b;
    const baseBrightness = brightness(...baseColor);
    const newBrightness = brightness(...color);

    // Check if the brightness difference is significant
    return Math.abs(baseBrightness - newBrightness) > contrastThreshold;
  };

  let color;
  do {
    // Generate a random color in RGB format
    const r = Math.floor(Math.random() * 256);
    const g = Math.floor(Math.random() * 256);
    const b = Math.floor(Math.random() * 256);
    color = [r, g, b];
  } while (
    !isContrastingColor(color) ||
    color.join(",") === "255,255,255" ||
    color.join(",") === "0,0,0"
  );

  return `rgb(${color.join(",")})`;
}

export function extractInitials(fullName) {
  const names = fullName.split(" ");
  const initials = names.map((name) => name.charAt(0).toUpperCase());
  return initials.join("");
}

export function getStatusColor(status) {
  switch (status) {
    case "rejected":
    case "rejected_by_employer":
    case "rejected_in_screening":
      return { backgroundColor: "#ff4d4f", textColor: "#fff" }; // Red for rejection
    case "shortlisted":
    case "eligible":
      return { backgroundColor: "#52c41a", textColor: "#fff" }; // Green for shortlisted/eligible
    case "applied":
      return { backgroundColor: "#275df5", textColor: "#fff" }; // Deep blue for new (closer to #275df5)
    case "hired":
    case "joined":
      return { backgroundColor: "#13c2c2", textColor: "#fff" }; // Cyan for hired/joined
    case "billed":
      return { backgroundColor: "#faad14", textColor: "#fff" }; // Yellow for billed
    case "withdrawn":
      return { backgroundColor: "#8c8c8c", textColor: "#fff" }; // Gray for withdrawn
    case "interview_scheduled":
      return { backgroundColor: "#722ed1", textColor: "#fff" }; // Purple for interview scheduled
    case "application_in_review":
      return { backgroundColor: "#fa8c16", textColor: "#fff" }; // Orange for in review
    case "on_hold":
      return { backgroundColor: "#d3adf7", textColor: "#000" }; // Light purple for on hold
    default:
      return { backgroundColor: "#edf0f5", textColor: "#000" }; // Default color for unknown status
  }
}

export const generateColorVariations = (baseColor, count) => {
  // Helper function to convert HEX to HSL
  const hexToHsl = (hex) => {
    let r = parseInt(hex.slice(1, 3), 16) / 255;
    let g = parseInt(hex.slice(3, 5), 16) / 255;
    let b = parseInt(hex.slice(5, 7), 16) / 255;

    const max = Math.max(r, g, b);
    const min = Math.min(r, g, b);
    let h,
      s,
      l = (max + min) / 2;

    if (max === min) {
      h = s = 0; // Achromatic
    } else {
      const d = max - min;
      s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
      switch (max) {
        case r:
          h = (g - b) / d + (g < b ? 6 : 0);
          break;
        case g:
          h = (b - r) / d + 2;
          break;
        case b:
          h = (r - g) / d + 4;
          break;
      }
      h /= 6;
    }

    return [Math.round(h * 360), Math.round(s * 100), Math.round(l * 100)];
  };

  // Helper function to convert HSL to HEX
  const hslToHex = (h, s, l) => {
    let r, g, b;

    h /= 360;
    s /= 100;
    l /= 100;

    if (s === 0) {
      r = g = b = l; // Achromatic
    } else {
      const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
      const p = 2 * l - q;
      r = hueToRgb(p, q, h + 1 / 3);
      g = hueToRgb(p, q, h);
      b = hueToRgb(p, q, h - 1 / 3);
    }

    return `#${(
      (1 << 24) +
      (Math.round(r * 255) << 16) +
      (Math.round(g * 255) << 8) +
      Math.round(b * 255)
    )
      .toString(16)
      .slice(1)}`;
  };

  const hueToRgb = (p, q, t) => {
    if (t < 0) t += 1;
    if (t > 1) t -= 1;
    if (t < 1 / 6) return p + (q - p) * 6 * t;
    if (t < 1 / 2) return q;
    if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
    return p;
  };

  const hslBase = hexToHsl(baseColor);
  const colors = [];

  for (let i = 0; i < count; i++) {
    const hueOffset = (360 / count) * i; // Spread hues across the circle
    const newH = (hslBase[0] + hueOffset) % 360; // Add offset to base hue
    const newColor = hslToHex(newH, hslBase[1], hslBase[2]); // Maintain saturation and lightness
    colors.push(newColor);
  }

  return colors;
};

// Helper function to check if a user has permission for a module and optionally a submodule
export const hasPermission = (module, submodule) => {
  // Get the permissions for the given module
  const permissionsResponse = checkModulePermissions(module);

  // If the module is not authorized, return false
  if (!permissionsResponse.authorized) {
    return false;
  }

  // If submodule is not provided, return true because module is authorized
  if (!submodule) {
    return true;
  }

  // If submodule is provided, check if it exists in the sub_modules array
  return permissionsResponse.sub_modules.includes(submodule);
};
