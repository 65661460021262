import config from "../config";
import axios from "axios";
import { getUserToken } from "../utils/helper";

export const GET_RESELLERS = "GET_RESELLERS";
export const GET_RESELLERS_SUCCESS = "GET_RESELLERS_SUCCESS";
export const GET_RESELLERS_FAIL = "GET_RESELLERS_FAIL";
export const GET_RESELLERS_RESET = "GET_RESELLERS_RESET";

export const getResellers = (params) => {
  return {
    type: GET_RESELLERS,
    params,
  };
};

export const getResellersSuccess = (response) => {
  return {
    type: GET_RESELLERS_SUCCESS,
    response,
  };
};

export const getResellersFail = (response) => {
  return {
    type: GET_RESELLERS_FAIL,
    response,
  };
};

export const getResellersReset = () => {
  return {
    type: GET_RESELLERS_RESET,
  };
};

export const getResellersApi = (data) => {
  return (dispatch) => {
    dispatch(getResellers());
    axios
      .get(`${config.api.base_url}/resellers`, {
        params: data,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getUserToken()}`,
        },
      })
      .then((response) => {
        const getResellersData = response.data;
        dispatch(getResellersSuccess(getResellersData));
      })
      .catch((error) => {
        const errorMsg = error.message;
        dispatch(getResellersFail(errorMsg));
      });
  };
};
