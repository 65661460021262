import axios from "axios";

import config from "../config";
import { getUserToken } from "../utils/helper";

export const GET_APPLICATION_BY_ID = "GET_APPLICATION_BY_ID";
export const GET_APPLICATION_BY_ID_SUCCESS = "GET_APPLICATION_BY_ID_SUCCESS";
export const GET_APPLICATION_BY_ID_FAIL = "GET_APPLICATION_BY_ID_FAIL";
export const GET_APPLICATION_BY_ID_RESET = "GET_APPLICATION_BY_ID_RESET";

export const getApplicationById = (params) => {
  return {
    type: GET_APPLICATION_BY_ID,
    params,
  };
};

export const getApplicationByIdSuccess = (response) => {
  return {
    type: GET_APPLICATION_BY_ID_SUCCESS,
    response,
  };
};

export const getApplicationByIdFail = (response) => {
  console.log("action response fail=>", response);
  return {
    type: GET_APPLICATION_BY_ID_FAIL,
    response,
  };
};

export const getApplicationByIdReset = () => {
  return {
    type: GET_APPLICATION_BY_ID_RESET,
  };
};

export const getApplicationByIdApi = (params) => {
  return (dispatch) => {
    dispatch(getApplicationById());
    axios
      .get(
        `${config.api.base_url}/application/get-application-by-id/${params}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + getUserToken(),
          },
        }
      )
      .then((response) => {
        const getApplicationByIdData = response.data;
        dispatch(getApplicationByIdSuccess(getApplicationByIdData));
      })
      .catch((error) => {
        const errorData = error.response.data;
        dispatch(getApplicationByIdFail(errorData));
      });
  };
};
