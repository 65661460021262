import React, { useState, useEffect } from "react";
import {
  Button,
  Row,
  Col,
  Modal,
  notification,
  Alert,
  Result,
  Form,
  Input,
} from "antd";
import { connect } from "react-redux";
import { CheckCircleOutlined } from "@ant-design/icons";

import {
  getCandidateDetailsApi,
  getCandidateDetailsReset,
} from "../../actions/getCandidateDetailsAction";
import { useLocation, useParams } from "react-router-dom";
import CandidateDetails from "../../components/CandidateDetails/CandidateDetailsCard";
import {
  updateJobApplicationStatusApi,
  updateJobApplicationStatusReset,
} from "../../actions/updateJobApplicationStatusAction";
import { XContainer } from "../../styles/GlobalStyle";
import { hasPermission } from "../../utils/helper";
const Home = (props) => {
  const {
    getCandidateDetails,
    getCandidateDetailsState,
    getCandidateDetailsReset,
    updateJobApplicationStatus,
    updateJobApplicationStatusState,
    updateJobApplicationStatusReset,
  } = props;

  const { id } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const application_id = queryParams.get("application_id");
  const [modal1, modalHolder] = Modal.useModal();
  const [applicationDetails, setApplicationDetails] = useState({});
  const [rejectReasonModal, setRejectReasonModal] = useState(false);
  const [rejectReason, setRejectReason] = useState("");
  const [currentStatus, setCurrentStatus] = useState("");
  const [futureStatus, setFutureStatus] = useState("");

  // Define the button actions based on the application status
  const statusActions = [
    {
      status: ["shortlisted", "on_hold"],
      label: "Application In Review",
      newStatus: "application_in_review",
      borderColor: "#91caff",
      color: "#e6f4ff",
    },
    {
      status: ["application_in_review"],
      label: "Schedule Interview",
      newStatus: "interview_scheduled",
      borderColor: "#91caff",
      color: "#e6f4ff",
    },
    {
      status: ["interview_scheduled"],
      label: "Hire Candidate",
      newStatus: "hired",
      borderColor: "#b7eb8f",
      color: "#f6ffed",
    },
    {
      status: ["hired"],
      hidden: true,
    },
    {
      status: [
        "on_hold",
        "application_in_review",
        "interview_scheduled",
        "shortlisted",
      ],
      label: "Put On Hold",
      newStatus: "on_hold",
      borderColor: "#ffe58f",
      color: "#fffbe6",
    },
    {
      status: [
        "on_hold",
        "application_in_review",
        "interview_scheduled",
        "shortlisted",
      ],
      label: "Rejected By Employer",
      newStatus: "rejected_by_employer",
      borderColor: "#ffccc7",
      color: "#fff2f0",
    },
  ];

  const statusMessages = {
    shortlisted: {
      message: "This application has been shortlisted",
      type: "success",
      status: "shortlisted",
      description:
        "You have been shortlisted for the next stage of the application process. Our team will contact you soon with further details.",
    },
    rejected: {
      message: "This application has been rejected",
      type: "error",
      status: "rejected",

      description:
        "Unfortunately, your application did not meet our current criteria. Thank you for applying, and feel free to apply to other positions in the future.",

      extra: (
        <div className="customButton mt-3">
          <Button
            className="w-100"
            onClick={() => showUpdateApplicationStatus("shortlisted")}
            style={{
              backgroundColor: "#f6ffed",
              borderColor: "#b7eb8f",
            }}
            size="large">
            Shortlist Despite Rejection
          </Button>
        </div>
      ),
    },
    on_hold: {
      message: "This application is on hold",
      type: "warning",
      status: "on_hold",

      description:
        "Your application is currently on hold. We will review it again at a later stage and inform you about any updates.",
    },
    hired: {
      message: "The Candidate has been hired",
      type: "success",
      status: "hired",

      description:
        "We are thrilled to inform you that you have been selected for the position. Welcome to the team! Our HR team will be in touch with the next steps.",
    },
    application_in_review: {
      message: "This application is under review",
      type: "info",
      status: "application_in_review",

      description:
        "Your application is being reviewed by our team. You will be notified once we reach a decision.",
    },
    interview_scheduled: {
      message: "Interview has been Scheduled",
      type: "info",
      status: "interview_scheduled",

      description:
        "Congratulations! Your interview has been scheduled. Please check your email for the interview details and be prepared.",
    },
    rejected_by_employer: {
      message: "This application has been rejected by the Employer",
      type: "error",
      status: "rejected_by_employer",

      description:
        "Unfortunately, your application did not meet our current criteria. Thank you for applying, and feel free to apply to other positions in the future.",
    },
  };

  const showUpdateApplicationStatus = (status) => {
    let content = "";
    setFutureStatus(status);

    switch (status) {
      case "shortlisted":
        content = (
          <p className="confirmPara">
            On confirmation, the job application will be shortlisted
          </p>
        );
        break;
      case "rejected":
        content = (
          <p className="confirmPara">
            On confirmation, the job application will be rejected
          </p>
        );
        break;
      case "rejected_by_employer":
        content = (
          <p className="confirmPara">
            On confirmation, the job application will be rejected
          </p>
        );
        break;
    }

    modal1.confirm({
      title: "Are you sure you?",
      icon: <CheckCircleOutlined />,
      content,
      okText: "Confirm",
      cancelText: "Cancel",
      onOk: () => {
        if (status === "rejected" || status === "rejected_by_employer") {
          setRejectReasonModal(true);
        } else {
          try {
            updateJobApplicationStatus({
              application_id,
              status,
            });
          } catch (error) {
            console.log(error);
          }
        }
      },
      okButtonProps: { style: { background: "#275df5" } },
    });
  };

  const handleReject = () => {
    if (rejectReason.trim() !== "") {
      //alert(rejectReason);
      let status = futureStatus || "rejected";
      try {
        updateJobApplicationStatus({
          application_id,
          status,
          rejectReason,
        });
      } catch (error) {
        console.log(error);
      }
      setRejectReasonModal(false);
      return;
    }

    return;
  };

  const handleRejectReasonChange = (e) => {
    setRejectReason(e.target.value);
  };

  const handleCloseModal = () => {
    setRejectReason("");
    setRejectReasonModal(false);
  };

  useEffect(() => {
    document.body.classList.add("headerBg");
  });

  useEffect(() => {
    if (id) {
      getCandidateDetails({
        candidate_id: id,
        application_id,
      });
    }
  }, [id]);

  useEffect(() => {
    if (
      getCandidateDetailsState.apiState === "success" &&
      getCandidateDetailsState.data
    ) {
      setApplicationDetails(getCandidateDetailsState.data.candidate);
      setCurrentStatus(
        getCandidateDetailsState.data.candidate.applications[0].status
      );
    } else if (getCandidateDetailsState.apiState === "error") {
      notification.error({
        message: getCandidateDetailsState.message,
      });
    }
    getCandidateDetailsReset();
  }, [getCandidateDetailsState.apiState]);

  useEffect(() => {
    if (updateJobApplicationStatusState.apiState === "success") {
      getCandidateDetails({
        candidate_id: id,
        application_id,
      });
    } else if (updateJobApplicationStatusState.apiState === "error") {
      notification.error({
        message: updateJobApplicationStatusState.message,
      });
    }
    updateJobApplicationStatusReset();
  }, [updateJobApplicationStatusState.apiState]);

  return (
    <>
      {applicationDetails &&
      applicationDetails.applications &&
      applicationDetails.applications.length > 0 ? (
        <>
          <CandidateDetails applicationDetails={applicationDetails} />
          <XContainer fluid>
            <Row
              gutter={[30, 15]}
              style={{
                marginTop: "20px",
                marginBottom: "20px",
              }}>
              {applicationDetails.applications[0].status === "applied" ? (
                hasPermission(
                  "candidate-applications",
                  "recruiter-status-update"
                ) ? (
                  <Col xs={24} sm={24} md={24} lg={24}>
                    <Row gutter={[30, 15]}>
                      <Col xs={24} lg={24}>
                        <div class="customButton">
                          <Button
                            className="w-100"
                            onClick={() =>
                              showUpdateApplicationStatus("shortlisted")
                            }
                            style={{
                              backgroundColor: "#f6ffed",
                              borderColor: "#b7eb8f",
                            }}
                            size="large">
                            Shortlist
                          </Button>
                        </div>
                      </Col>
                      <Col xs={24} lg={24}>
                        <Button
                          className="w-100"
                          onClick={() =>
                            showUpdateApplicationStatus("rejected")
                          }
                          style={{
                            backgroundColor: "#fff2f0",
                            borderColor: "#ffccc7",
                          }}
                          size="large">
                          Reject
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                ) : (
                  <></>
                )
              ) : (
                <Col xs={24} lg={24}>
                  <div
                    style={{
                      width: "100%",
                      alignItems: "center",
                      padding: "1rem",
                    }}>
                    {currentStatus && statusMessages[currentStatus] ? (
                      <>
                        <Alert
                          message={statusMessages[currentStatus].message}
                          description={
                            applicationDetails.applications[0].remarks &&
                            ["rejected", "rejected_by_employer"].includes(
                              statusMessages[currentStatus].status
                            )
                              ? applicationDetails.applications[0].remarks
                              : statusMessages[currentStatus].description
                          }
                          showIcon
                          type={statusMessages[currentStatus].type}
                        />
                        {statusMessages[currentStatus].extra &&
                          statusMessages[currentStatus].extra}
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </Col>
              )}
              {hasPermission(
                "candidate-applications",
                "employer-status-update"
              ) ? (
                statusActions.map(
                  (action) =>
                    action.status.includes(currentStatus) &&
                    !action.hidden && (
                      <Col key={action.newStatus} xs={12} lg={24}>
                        <div className="customButton">
                          <Button
                            className="w-100"
                            size="large"
                            style={{
                              backgroundColor: action.color,
                              borderColor: action.borderColor,
                            }}
                            onClick={() =>
                              showUpdateApplicationStatus(action.newStatus)
                            }>
                            {action.label}
                          </Button>
                        </div>
                      </Col>
                    )
                )
              ) : (
                <></>
              )}
            </Row>
          </XContainer>

          {modalHolder}
          <Modal
            title="Add Reject Reason"
            centered
            open={rejectReasonModal}
            onCancel={() => handleCloseModal()}
            className="centerModal"
            footer={null}>
            <Row className="my-4">
              <Col xs={24} sm={24} md={24} lg={24}>
                <Form.Item
                  label="Enter reject reason"
                  className="formLable"
                  name="reject_reason"
                  rules={[
                    {
                      required: true,
                      message: "Please Input Reject Reason",
                    },
                  ]}>
                  <Input.TextArea
                    className="searchInputBrder addShadow"
                    name="reject_reason"
                    size="large"
                    placeholder="Enter description"
                    onChange={(e) => handleRejectReasonChange(e)}
                    maxLength={100}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[30, 0]} justify="center" className="my-4">
              <Col xs={24} sm={12} md={6} lg={6}>
                <div class="customButton">
                  <Button
                    className="w-100"
                    //htmlType="submit"
                    onClick={() => handleReject()}
                    size="large">
                    Submit
                  </Button>
                </div>
              </Col>
              <Col xs={24} sm={12} md={6} lg={6}>
                <Button
                  className="w-100"
                  onClick={() => handleCloseModal()}
                  size="large">
                  Cancel
                </Button>
              </Col>
            </Row>
          </Modal>
        </>
      ) : (
        <Result
          status="500"
          title="500"
          subTitle="Sorry, something went wrong."
          extra={<Button type="primary">Back Home</Button>}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  getCandidateDetailsState: state.getCandidateDetails,
  updateJobApplicationStatusState: state.updateJobApplicationStatus,
});

const mapDispatchToProps = (dispatch) => ({
  getCandidateDetailsReset: () => dispatch(getCandidateDetailsReset()),
  getCandidateDetails: (params) => dispatch(getCandidateDetailsApi(params)),
  updateJobApplicationStatus: (params) =>
    dispatch(updateJobApplicationStatusApi(params)),
  updateJobApplicationStatusReset: (params) =>
    dispatch(updateJobApplicationStatusReset(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
