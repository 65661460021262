import dayjs from 'dayjs';

export const defaultPageSize = 10;
export const defaultPageNumber = 1;

export const dateFilters = [
  { label: 'Last Month', value: 'last_month' },
  { label: '2 Months', value: 'last_two_months' },
  { label: '3 Months', value: 'last_three_months' },
  { label: '4 Months', value: 'last_four_months' },
];

export const legalEntityList = [
  {
    id: 'public_limited_company',
    name: 'Public Limited Company',
  },
  {
    id: 'private_limited_company',
    name: 'Private Limited Company',
  },
  {
    id: 'partnership_firm',
    name: 'Partnership Firm',
  },
  {
    id: 'sole_proprietorship',
    name: 'Sole proprietorship',
  },
  {
    id: 'non_government_organization',
    name: 'Non Government Organization',
  },
  {
    id: 'limited_liability_partnership',
    name: 'Limited Liability Partnership',
  },
];

export const experience_level_list = [
  {
    value: 'internship_apprenticeship',
    label: 'Internship/Apprenticeship',
    type: 'experience_level',
  },
  {
    value: 'entry_level',
    label: 'Entry-level',
    type: 'experience_level',
  },
  {
    value: 'junior_level',
    label: 'Junior-level',
    type: 'experience_level',
  },
  {
    value: 'mid_level',
    label: 'Mid-level',
    type: 'experience_level',
  },
  {
    value: 'senior_level',
    label: 'Senior-level',
    type: 'experience_level',
  },
  {
    value: 'lead',
    label: 'Lead',
    type: 'experience_level',
  },
  {
    value: 'managerial',
    label: 'Managerial',
    type: 'experience_level',
  },
  {
    value: 'director',
    label: 'Director',
    type: 'experience_level',
  },
  {
    value: 'vice_president',
    label: 'Vice President (VP)',
    type: 'experience_level',
  },
  {
    value: 'executive_c_suite',
    label: 'Executive/C-Suite (CEO, CTO, CFO, etc.)',
    type: 'experience_level',
  },
  {
    value: 'consultant_expert',
    label: 'Consultant/Expert',
    type: 'experience_level',
  },
];

export const employment_type_list = [
  {
    value: 'part_time',
    label: 'Part time',
    type: 'employment_type',
  },
  {
    value: 'full_time',
    label: 'Full time',
    type: 'employment_type',
  },
  {
    value: 'contractual',
    label: 'Contractual',
    type: 'employment_type',
  },
  {
    value: 'temporary',
    label: 'Temporary',
    type: 'employment_type',
  },
  {
    value: 'internship',
    label: 'Internship',
    type: 'employment_type',
  },
];
export const application_status_list = [
  {
    key: 'rejected',
    value: 'rejected',
    label: 'Rejected',
    type: 'application_status',
  },
  {
    key: 'shortlisted',
    value: 'shortlisted',
    label: 'Shortlisted',
    type: 'application_status',
  },
  {
    key: 'applied',
    value: 'applied',
    label: 'Applied',
    type: 'application_status',
  },
  {
    key: 'hired',
    value: 'hired',
    label: 'Hired',
    type: 'application_status',
  },
  {
    key: 'rejected_by_employer',
    value: 'rejected_by_employer',
    label: 'Rejected by Employer',
    type: 'application_status',
  },

  {
    key: 'on_hold',
    value: 'on_hold',
    label: 'On Hold',
    type: 'application_status',
  },
  {
    key: 'interview_scheduled',
    value: 'interview_scheduled',
    label: 'Interview Scheduled',
    type: 'application_status',
  },
  {
    key: 'application_in_review',
    value: 'application_in_review',
    label: 'Application in Review',
    type: 'application_status',
  },
];

export const work_type_list = [
  {
    value: 'wfh',
    label: 'Work From Home',
    type: 'work_type',
  },
  {
    value: 'wfo',
    label: 'Work From Office',
    type: 'work_type',
  },
  {
    value: 'hybrid',
    label: 'Hybrid',
    type: 'work_type',
  },
];

export const salary_type_lsit = [
  {
    value: 'per_hour',
    label: 'Per hour',
    type: 'salary_type',
  },
  {
    value: 'per_day',
    label: 'Per day',
    type: 'salary_type',
  },
  {
    value: 'per_week',
    label: 'Per week',
    type: 'salary_type',
  },
  {
    value: 'per_month',
    label: 'Per month',
    type: 'salary_type',
  },
  {
    value: 'per_year',
    label: 'Per year',
    type: 'salary_type',
  },
];

export const joinedOnOptions = [
  {
    id: 'one_day_ago',
    name: 'One Day Ago',
  },
  {
    id: 'one_week_ago',
    name: 'One Week Ago',
  },
  {
    id: 'last_month',
    name: 'Last Month',
  },
  {
    id: 'last_three_months',
    name: 'Last Three Months',
  },
  {
    id: 'last_six_months',
    name: 'Last Six Months',
  },
  {
    id: 'all',
    name: 'All',
  },
];

export const base_urls = {
  local: 'https://api.skillsprints.in',
};

export const maxDropdownSelections = 10;
export const maxLocationSelections = 20;

export const rangePresets = [
  {
    label: 'Last 7 days',
    value: [dayjs().add(-7, 'd'), dayjs()],
  },
  {
    label: 'Last 30 days',
    value: [dayjs().add(-30, 'd'), dayjs()],
  },
  {
    label: 'Last 60 days',
    value: [dayjs().add(-60, 'd'), dayjs()],
  },
];

export const jobFilters = (handleSortChange) => [
  {
    label: 'Date Ascending',
    key: '1',
    onClick: () => handleSortChange('createdAt', 1),
  },
  {
    label: 'Date Descending',
    key: '2',
    onClick: () => handleSortChange('createdAt', -1),
  },
  {
    label: 'Salary High to Low',
    key: '3',
    onClick: () => handleSortChange('salary_sort', -1),
  },
  {
    label: 'Salary Low to High',
    key: '4',
    onClick: () => handleSortChange('salary_sort', 1),
  },
  {
    label: 'Sort A to Z',
    key: '5',
    onClick: () => handleSortChange('job_title', 1),
  },
  {
    label: 'Sort Z to A',
    key: '6',
    onClick: () => handleSortChange('job_title', -1),
  },
];

export const htmlConsent = `<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Terms and Conditions</title>
    <style>
        body {
            font-family: Arial, sans-serif;
            margin: 0;
            padding: 0;
            line-height: 1.6;
        }
        .container {
            width: 80%;
        }
        h1, h2, h3 {
            color: #333;
        }
        p {
            margin-bottom: 1em;
        }
        .terms {
            margin-top: 20px;
        }
    </style>
</head>
<body>
    <div class="container">
        <h1>Terms and Conditions</h1>
        <p>Welcome to our job board platform. These terms and conditions outline the rules and regulations for the use of our website.</p>

        <div class="terms">
            <h2>1. Introduction</h2>
            <p>By accessing this website, you accept these terms and conditions in full. If you disagree with these terms and conditions or any part of these terms and conditions, you must not use this website.</p>

            <h2>2. Definitions</h2>
            <p>In these terms and conditions:</p>
            <ul>
                <li><strong>"Employer"</strong> refers to any individual or organization creating job listings on our platform.</li>
                <li><strong>"Recruiter"</strong> refers to any individual or organization responsible for managing and recruiting candidates for the jobs posted by employers.</li>
                <li><strong>"Candidate"</strong> refers to any individual applying for jobs posted on our platform.</li>
                <li><strong>"Platform"</strong> refers to our job board website and any associated services.</li>
            </ul><br/>

            <h2>3. Account Registration</h2>
            <p>To use certain features of our platform, you must register for an account. You agree to provide accurate, current, and complete information during the registration process and to update such information to keep it accurate, current, and complete.</p>

            <h2>4. User Responsibilities</h2>
            <p>Employers are responsible for the accuracy and legality of the job listings they create. Employers must not post any job listings that are misleading, fraudulent, or illegal.</p>

            <p>Recruiters are responsible for managing the recruitment process for the jobs posted by employers. Recruiters must ensure that their recruitment activities comply with all applicable laws and regulations.</p>

            <p>Candidates are responsible for the accuracy of the information they provide in their applications. Candidates must not apply for jobs with false or misleading information.</p>

            <h2>5. Prohibited Activities</h2>
            <p>Users are prohibited from:</p>
            <ul>
                <li>Using the platform for any illegal or unauthorized purpose.</li>
                <li>Posting content that is harmful, threatening, abusive, harassing, defamatory, or otherwise objectionable.</li>
                <li>Impersonating any person or entity or falsely stating or otherwise misrepresenting your affiliation with a person or entity.</li>
                <li>Interfering with or disrupting the platform or servers or networks connected to the platform.</li>
            </ul><br/>

            <h2>6. Privacy</h2>
            <p>We are committed to protecting your privacy. Our Privacy Policy outlines how we collect, use, and protect your personal information. By using our platform, you agree to the terms of our Privacy Policy.</p>

            <h2>7. Intellectual Property</h2>
            <p>The platform and its original content, features, and functionality are and will remain the exclusive property of our company. Users must not reproduce, duplicate, copy, sell, or exploit any portion of the platform without express written permission from us.</p>

            <h2>8. Limitation of Liability</h2>
            <p>In no event shall we be liable for any indirect, incidental, special, consequential, or punitive damages arising out of or related to your use of the platform.</p>

            <h2>9. Termination</h2>
            <p>We may terminate or suspend your access to the platform immediately, without prior notice or liability, for any reason whatsoever, including, without limitation, if you breach these terms and conditions.</p>

            <h2>10. Governing Law</h2>
            <p>These terms and conditions shall be governed and construed in accordance with the laws of India, without regard to its conflict of law provisions.</p>

            <h2>11. Changes to Terms and Conditions</h2>
            <p>We reserve the right to modify or replace these terms and conditions at any time. By continuing to access or use our platform after any revisions become effective, you agree to be bound by the revised terms.</p>

            <h2>12. Contact Us</h2>
            <p>If you have any questions about these terms and conditions, please contact us at 8451828462 / +230 5745 0670</p>
        </div>
    </div>
</body>
</html>
`;
