import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import {
  Form,
  notification,
  Modal,
  Row,
  Col,
  Card,
  Button,
  Tag,
  Select,
  Typography,
  Tooltip,
  Result,
  Radio,
} from "antd";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import { useForm } from "antd/es/form/Form";
import {
  capitalizeAndSplitByDash,
  checkModulePermissions,
  getSignedUrlForGetObject,
  hasPermission,
  urlPagination,
} from "../../utils/helper";
import { dateFilters } from "../../utils/constants";
import {
  getResellersApi,
  getResellersReset,
} from "../../actions/getResellersAction";
import { PlusOutlined } from "@ant-design/icons";

import { XButtonImg } from "../Manage/ManageStyle";
import {
  FilterButton,
  XButton2,
  XContainer,
  XSearch,
  XSelect,
} from "../../styles/GlobalStyle";
import { XForm } from "../Login/LoginStyle";
import CaseSearch from "../../components/CaseSearch/CaseSearch";
import DataTable from "../../components/DataTable/DataTable";

const { Option } = Select;

const ResellersTable = (props) => {
  const navigate = useNavigate();
  let modulePermissions = checkModulePermissions("resellers");
  const [form] = useForm();
  const { getResellersState, getResellers, getResellersReset } = props;

  const defaultFilters = {
    page: 1,
    limit: 10,
    search: "",
    location: "",
    category: "",
    joined_on: "all",
    sortDirection: null,
  };
  const getFiltersFromUrl = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const urlFilters = {};

    // Iterate through each query parameter and set it in the state
    for (const [key, value] of urlParams.entries()) {
      urlFilters[key] = value;
    }

    // Merge with default filters or initial state as needed
    const filters = {
      ...defaultFilters, // Replace with your default filters
      ...urlFilters,
    };

    return filters;
  };
  const initialFilters = getFiltersFromUrl();

  const [filters, setFilters] = useState(initialFilters);
  const [tableData, setTableData] = useState([]);
  const [totalRecords, setTotalRecords] = useState("");
  const [open, setOpen] = useState(false);

  const [AgreementModal, setAgreementModal] = useState(false);

  const handleOnClick = async (agreement_url) => {
    const url = await getSignedUrlForGetObject(
      "resellers",
      agreement_url[0],
      agreement_url[1]
    );
    window.open(url, "_blank");
  };

  const columns = [
    {
      title: (
        <Typography.Text className="tableEllipsis" ellipsis={true}>
          RECRUITMENT PARTNER
        </Typography.Text>
      ),
      dataIndex: "resellers",
      render: (reseller) => (
        <div>
          <div style={{ fontWeight: "600" }}>{reseller}</div>
        </div>
      ),
    },
    {
      title: (
        <Typography.Text className="tableEllipsis" ellipsis={true}>
          ONBOARDED ON
        </Typography.Text>
      ),
      dataIndex: "onboarded_on",
      render: (joined_on) => (
        <div style={{ width: "130px" }}>
          <div style={{ fontWeight: "600" }}>
            {moment(joined_on).format("DD-MM-YYYY")}
          </div>
        </div>
      ),
    },
    {
      title: (
        <Typography.Text className="tableEllipsis" ellipsis={true}>
          STATE
        </Typography.Text>
      ),
      dataIndex: "state",
      render: (state) => (
        <div>
          <div style={{ fontWeight: "600" }}>
            {capitalizeAndSplitByDash(state)}
          </div>
        </div>
      ),
    },
    {
      title: (
        <Typography.Text className="tableEllipsis" ellipsis={true}>
          CITY
        </Typography.Text>
      ),
      dataIndex: "city",
      render: (city) => (
        <div>
          <div style={{ fontWeight: "600" }}>
            {capitalizeAndSplitByDash(city)}
          </div>
        </div>
      ),
    },
    {
      title: (
        <Typography.Text className="tableEllipsis" ellipsis={true}>
          AGREEMENT
        </Typography.Text>
      ),
      dataIndex: "agreement_url",
      render: (agreement_url) =>
        agreement_url[0] ? ( // Conditionally render based on agreement_url
          <div onClick={() => handleOnClick(agreement_url)}>
            <Tooltip title="Download Agreement">
              <Button type="link" onClick={(e) => e.preventDefault()}>
                <img src="images/agreement-icon.svg" alt="" />
              </Button>
            </Tooltip>
          </div>
        ) : null,
    },
    {
      title: (
        <Typography.Text className="tableEllipsis" ellipsis={true}>
          ACTION
        </Typography.Text>
      ),
      dataIndex: "buttons",
      render: (buttons) =>
        hasPermission("resellers", "edit-resellers") ? (
          <div>
            <Tooltip title="Edit Recruitment Partner">{buttons}</Tooltip>
          </div>
        ) : (
          <></>
        ),
    },
    {
      title: (
        <Typography.Text className="tableEllipsis" ellipsis={true}>
          STATUS
        </Typography.Text>
      ),
      dataIndex: "status",
      render: (status) => (
        <div>
          <div>
            {status ? (
              <Tag
                color="#CDFFCD"
                style={{
                  color: "#007F00",
                  borderRadius: "12px",
                }}>
                {"Active"}
              </Tag>
            ) : (
              <Tag
                color="#FAEEEE"
                style={{
                  color: "#E44556",
                  borderRadius: "12px",
                }}>
                {"Inactive"}
              </Tag>
            )}
          </div>
        </div>
      ),
    },
  ];

  // functions
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  const onChange = (key) => {
    setFilters({
      ...filters,
      [key.target.name]: key.target.value,
    });
  };

  const applyFilters = () => {
    const queryParams = new URLSearchParams(window.location.search);

    // Iterate through filters and update the query parameters
    Object.entries(filters).forEach(([key, value]) => {
      const currentValue = queryParams.get(key);

      // Check if the parameter exists in the current query and has a value, if yes, update its value
      if (currentValue !== null) {
        queryParams.set(key, value);
      } else if (queryParams.has(key)) {
        // If the parameter exists but has no value, keep its existing value
        queryParams.set(key, queryParams.get(key));
      } else {
        // If the parameter does not exist in the current query, append it with the current value
        queryParams.append(key, value);
      }
    });

    // Set the page to 1
    queryParams.set("page", "1");
    queryParams.set("sort", "createdAt");
    queryParams.set("sortDirection", "descend");
    filters.page = 1;

    // Update the URL
    const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
    window.history.pushState({}, "", newUrl);
  };

  const clearFilters = () => {
    const queryParams = new URLSearchParams(window.location.search);

    // Iterate through defaultFilters and update the query parameters
    Object.entries(defaultFilters).forEach(([key, value]) => {
      const currentValue = queryParams.get(key);

      // Check if the parameter exists in the current query and has a value, if yes, update its value
      if (currentValue !== null) {
        queryParams.set(key, value);
      } else if (queryParams.has(key)) {
        // If the parameter exists but has no value, keep its existing value
        queryParams.set(key, queryParams.get(key));
      } else {
        // If the parameter does not exist in the current query, append it with the default value
        queryParams.append(key, value);
      }
    });

    // Clear the form fields
    form.resetFields();

    // Update the state with default values
    setFilters(defaultFilters);

    // Update the URL
    const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
    window.history.pushState({}, "", newUrl);
  };

  const parseResellerData = async (all_resellers) => {
    var data = [];
    for (var tableRow = 0; tableRow < all_resellers.length; tableRow++) {
      const buttons = modulePermissions.sub_modules.includes(
        "edit-resellers"
      ) && (
        <Link to={`/reseller/edit/${all_resellers[tableRow]._id}`}>
          <XButtonImg
            style={{
              background: "transparent",
              border: "none",
            }}>
            <img src="images/pencil.svg" alt="" />
          </XButtonImg>
        </Link>
      );

      var thisObject = {
        key: tableRow,
        resellers: (
          <Link
            to={`/reseller/${all_resellers[tableRow]._id}`}
            style={{
              color: "rgb(0, 33, 65)",
            }}>
            {capitalizeAndSplitByDash(
              all_resellers[tableRow].first_name +
                " " +
                all_resellers[tableRow].last_name
            )}
          </Link>
        ),

        onboarded_on: all_resellers[tableRow].createdAt,
        state: all_resellers[tableRow].state,
        city: all_resellers[tableRow].city,
        agreement_url: [
          all_resellers[tableRow].agreement_document,
          all_resellers[tableRow].s3_folder_name,
        ],
        status: all_resellers[tableRow].is_active,
        buttons: buttons,
      };

      data.push(thisObject);
    }
    setTableData(data);
  };

  useEffect(() => {
    // Retrieve filters from the updated URL
    const updatedFilters = getFiltersFromUrl();
    setFilters({ ...updatedFilters });

    // Make API call with updated filters and pagination
    if (hasPermission("resellers", "list-resellers"))
      getResellers({ ...updatedFilters });
  }, [window.location.search]);

  useEffect(() => {
    if (getResellersState.apiState === "success") {
      setTotalRecords(getResellersState.data.total_count);
      parseResellerData(getResellersState.data.all_resellers);
      getResellersReset();
    } else if (getResellersState.apiState === "error") {
      console.log("API Error=>", getResellersState.message);
      notification.error({
        message: "Unable to get Resellers",
      });
      getResellersReset();
    }
  }, [getResellersState.apiState]);

  return (
    <>
      {hasPermission("resellers") ? (
        <>
          <XContainer fluid>
            <Card className="card inventoryTable p-0">
              <CaseSearch
                searchOption="reseller"
                title="Resellers"
                filters={[
                  <XForm.Item
                    name="joined_on"
                    colon={false}
                    label="Joined"
                    className="removeFormMargin">
                    <Radio.Group
                      onChange={onChange}
                      name="joined_on"
                      defaultValue={filters.joined_on || null}>
                      <Row>
                        {dateFilters.map((item, index) => (
                          <Col xs={12} lg={12} md={12} xl={12}>
                            <Radio
                              value={item.value}
                              className="filter-sub-item">
                              {item.label}
                            </Radio>
                          </Col>
                        ))}
                      </Row>
                    </Radio.Group>
                  </XForm.Item>,
                ]}
                onClose={onClose}
                open={open}
                applyFilters={applyFilters}
                form={form}
                clearFilters={clearFilters}
              />
              <Row gutter={(15, 30)}>
                <Col
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                  xs={24}
                  md={10}
                  lg={3}
                  xl={3}
                  xxl={3}>
                  <p className="tableHeader mb-3">
                    Total {totalRecords} Resellers
                  </p>
                </Col>
                <Col
                  xs={24}
                  md={14}
                  lg={{ span: 6 }}
                  xl={{ span: 6 }}
                  xxl={{ span: 6 }}>
                  <XForm.Item>
                    <XSearch
                      placeholder="Search Reseller"
                      allowClear
                      enterButton="Search"
                      size="large"
                      defaultValue={filters.search ? filters.search : null}
                      onChange={(v) => {
                        if (v.target.value === "") {
                          urlPagination(filters, "search", "");
                          setFilters({
                            ...filters,
                            search: "",
                          });
                        }
                      }}
                      onSearch={(v) => {
                        urlPagination(filters, "search", v);
                        setFilters({
                          ...filters,
                          search: v,
                        });
                      }}
                    />
                  </XForm.Item>
                </Col>
                <Col
                  xs={24}
                  md={12}
                  lg={{ span: 3, offset: 8 }}
                  xl={{ span: 3, offset: 8 }}
                  xxl={{ span: 3, offset: 8 }}>
                  <XForm.Item className="text-right">
                    <FilterButton onClick={() => showDrawer()}>
                      <img
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/d0e11f66e694b5fbdadd8edb8109ba7b24c06cb1f66c477f282ec451dfd237e7?placeholderIfAbsent=true&apiKey=ac9769c5e7eb416e8a74975078a910d6"
                        alt="Filter icon"
                      />
                      Filter
                    </FilterButton>
                  </XForm.Item>
                </Col>
                <Col
                  xs={24}
                  md={12}
                  lg={{ span: 4 }}
                  xl={{ span: 4 }}
                  xxl={{ span: 4 }}>
                  {hasPermission("resellers", "add-resellers") ? (
                    <XForm.Item className="text-right">
                      <FilterButton
                        onClick={() => {
                          navigate(`/reseller/add`);
                        }}>
                        {" "}
                        <PlusOutlined /> New Reseller
                      </FilterButton>
                    </XForm.Item>
                  ) : (
                    <></>
                  )}
                </Col>
              </Row>
              <DataTable
                scroll={{ x: 991 }}
                columns={columns}
                tableData={tableData}
                pagination={false}
                className="invtTable"
                page={"reseller"}
                setFilters={setFilters}
                filters={filters}
                totalRecords={totalRecords}
                state={getResellersState.apiState}
              />
            </Card>
          </XContainer>
        </>
      ) : (
        <Result
          status="404"
          title="404"
          subTitle="Sorry, the page you visited does not exist."
          extra={
            <Button
              type="primary"
              onClick={() => {
                navigate("/");
              }}>
              Back Home
            </Button>
          }
        />
      )}
      <Modal
        title="Agreement"
        centered
        open={AgreementModal}
        onOk={() => setAgreementModal(false)}
        //okText="Add"
        onCancel={() => setAgreementModal(false)}
        //cancelButtonProps={{ style: { display: "none" } }}
        footer={null}
        className="centerModal"
        style={{ overfloY: "scroll" }}>
        <Form layout="vertical">
          <Row gutter={[30, 0]}>
            <Col span={24} className="mt-3">
              <img width="100%" src="images/graph-2.png" alt="" />
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
  getResellersState: state.getResellers,
});

const mapDispatchToProps = (dispatch) => ({
  getResellers: (params) => dispatch(getResellersApi(params)),
  getResellersReset: (params) => dispatch(getResellersReset(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ResellersTable);
