import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Card, Typography, Space, Dropdown, Menu } from 'antd';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import { MoreOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { getJobApplicationStatisticsMonthlyApi } from '../../actions/getJobApplicationStatisticsMonthlyAction';

const { Title, Text } = Typography;

const JobApplicationsOverview = (props) => {
  const {
    getJobApplicationStatisticsMonthlyState,
    getJobApplicationStatisticsMonthly,
  } = props;

  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [],
  });

  useEffect(() => {
    if (
      //   getJobApplicationStatisticsMonthlyState &&
      getJobApplicationStatisticsMonthlyState.apiState === 'success'
    ) {
      console.log(
        getJobApplicationStatisticsMonthlyState,
        'Statistics fetched successfully'
      );
      // You can update the chart data here
      setChartData({
        labels: getJobApplicationStatisticsMonthlyState.data.labels,
        datasets: getJobApplicationStatisticsMonthlyState.data.datasets,
      });
    } else if (
      getJobApplicationStatisticsMonthlyState &&
      getJobApplicationStatisticsMonthlyState.apiState === 'error'
    ) {
      console.error(
        'Failed to fetch statistics:',
        getJobApplicationStatisticsMonthlyState.error
      );
    }
  }, [getJobApplicationStatisticsMonthlyState]);

  useEffect(() => {
    getJobApplicationStatisticsMonthly();
  }, []);

  const menu = (
    <Menu>
      <Menu.Item key='1'>Monthly</Menu.Item>
      <Menu.Item key='2'>Yearly</Menu.Item>
      <Menu.Item key='3'>Custom Range</Menu.Item>
    </Menu>
  );

  const chartDataLabel = chartData.labels.map((label, index) => ({
    name: label,
    Shortlisted: chartData.datasets[0].data[index],
    Rejected: chartData.datasets[1].data[index],
    Applied: chartData.datasets[2].data[index],
    OnHold: chartData.datasets[3].data[index],
  }));

  return (
    <StyledCard>
      <CardHeader>
        <Space direction='vertical' size={4}>
          <Title level={4}>Job Applications Overview</Title>
          <Text type='secondary'>(Monthly)</Text>
        </Space>
        {/* <Dropdown overlay={menu} trigger={["click"]}>
          <MoreOutlined
            style={{ fontSize: "24px", color: "#1c1c1e", cursor: "pointer" }}
          />
        </Dropdown> */}
      </CardHeader>
      <ChartContainer>
        <ResponsiveContainer width='100%' height={300}>
          <BarChart data={chartDataLabel}>
            <CartesianGrid strokeDasharray='3 3' />
            <XAxis dataKey='name' />
            <YAxis />
            <Tooltip />
            <Legend
              wrapperStyle={{
                paddingTop: '20px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              iconType='circle'
              iconSize={10}
            />
            <Bar dataKey='Shortlisted' fill='#0095FF' barSize={20} />
            <Bar dataKey='Rejected' fill='#FF89C0' barSize={20} />
            <Bar dataKey='Applied' fill='#00E096' barSize={20} />
            <Bar dataKey='OnHold' fill='#884DFF' barSize={20} />
          </BarChart>
        </ResponsiveContainer>
      </ChartContainer>
    </StyledCard>
  );
};

const StyledCard = styled(Card)`
  width: 100%;
  border-radius: 16px;
  background-color: #f6f9ff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-top: 16px;
  margin-bottom: 40px;

  .ant-card-body {
    padding: 24px;
  }
`;

const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 16px;
`;

const ChartContainer = styled.div`
  width: 100%;
  height: 300px;
`;

const mapStateToProps = (state) => ({
  getJobApplicationStatisticsMonthlyState:
    state.getJobApplicationStatisticsMonthly,
});

const mapDispatchToProps = (dispatch) => ({
  getJobApplicationStatisticsMonthly: (params) =>
    dispatch(getJobApplicationStatisticsMonthlyApi(params)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(JobApplicationsOverview);
