import styled, { keyframes } from 'styled-components';

export const Button = styled.button`
  display: block;
  width: 100%;
  text-decoration: none;
  background-color: ${({ theme }) => theme.colors.bgButton};
  border-radius: 10px;
  color: ${({ theme }) => theme.colors.white};
  border: none;
  text-align: center;
  cursor: pointer;
  padding: 15px 0px;
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  &:hover,
  &:active {
    transform: scale(0.96);
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  }
  a {
    text-decoration: none;
    color: rgb(255 255 255);
    font-size: 1.8rem;
  }
`;

export const ButtonBg = styled.button`
  background-color: ${({ theme }) => theme.colors.bgButton};
  color: ${({ theme }) => theme.colors.white};
  border: none;
  padding: 12px 30px;
  cursor: pointer;
  margin-left: 15px;
  font-size: 16px;
  line-height: 16px;
  font-weight: 400;
  box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.08);
  border-radius: 22.5px;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  &:hover,
  &:active {
    transform: scale(0.96);
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  }
  a {
    text-decoration: none;
    color: rgb(255 255 255);
    font-size: 1.8rem;
  }
`;
export const ButtonNoBg = styled.button`
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.text};
  border: none;
  padding: 12px 30px;
  cursor: pointer;
  margin-left: 15px;
  font-size: 16px;
  line-height: 16px;
  font-weight: 500;
  box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.08);
  border-radius: 22.5px;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  &:hover,
  &:active {
    transform: scale(0.96);
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  }
  a {
    text-decoration: none;
    color: rgb(255 255 255);
    font-size: 1.8rem;
  }
`;

export const TabBtnBg = styled.button`
	background-color: ${({ theme }) => theme.colors.bgButton};
	color: ${({ theme }) => theme.colors.white};
	border: none;
	padding: 5px 18.5px;
	cursor: pointer;
	margin-bottom: 10px;
	font-size: 14px;
	line-height: 14px;
	font-weight: 500;
	box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.08);
	border-radius: 5px;
	transition: all 0.3s ease;
	-webkit-transition: all 0.3s ease 0s;
	-moz-transition: all 0.3s ease 0s;
	-o-transition: all 0.3s ease 0s;
	&:hover,
	&:active {
		transform: scale(0.96);
		box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
	}
	a {
		text-decoration: none;
		color: rgb(255 255 255);
		font-size: 1.8rem;
	}
	:disabled {
	  background-color: grey !important;
    cursor: auto !important
`;

export const TabBtnNoBg = styled.button`
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.text};
  border: 1px solid ${({ theme }) => theme.colors.bgButton};
  padding: 5px 15px;
  cursor: pointer;
  margin-bottom: 5px;
  font-size: 14px;
  line-height: 14px;
  font-weight: 500;
  box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.08);
  border-radius: 5px;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  &:hover,
  &:active {
    transform: scale(0.96);
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  }
  a {
    text-decoration: none;
    color: rgb(255 255 255);
    font-size: 1.8rem;
  }
`;
export const ButtonIcon = styled.button`
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.bgButton};
  border: 1px solid ${({ theme }) => theme.colors.border2};
  padding: 7px 10px;
  cursor: pointer;
  margin-bottom: 5px;
  font-size: 14px;
  line-height: 14px;
  font-weight: 500;
  box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.08);
  border-radius: 5px;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  &:hover,
  &:active {
    transform: scale(0.96);
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  }
  a {
    text-decoration: none;
    color: rgb(255 255 255);
    font-size: 1.8rem;
  }
`;

export const TabActiveBtnBg = styled.button`
  background-color: #16a34a;
  color: ${({ theme }) => theme.colors.white};
  border: 1px solid transparent;
  padding: 5px 15px;
  min-width: 130px;
  cursor: pointer;
  font-size: 14px;
  line-height: 14px;
  font-weight: 500;
  box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.08);
  border-radius: 5px;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  &:hover,
  &:active {
    transform: scale(0.96);
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  }
  a {
    text-decoration: none;
    color: rgb(255 255 255);
    font-size: 1.8rem;
  }
`;
export const Wrapper = styled.section`
  display: block;
  padding: 20px 100px;
  @media (max-width: 991px) {
    padding: 20px;
  }
`;

export const SubmitBtn = styled.button`
  &&& {
    font-size: 16px;
    font-weight: 600;
    text-transform: capitalize;
    height: auto;
    padding: 12px 24px;
    border: 1px solid #eeeeee;
    border-radius: 10px;
    display: flex;
    align-items: center;
    gap: 12px;
    color: #eeeeee;
    background-color: #275df5;
    transition: all 0.3s ease;
    &:hover {
      background-color: #275df5;
      transform: scale(0.96);
      box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    }
    .anticon {
      font-size: 24px;
      transition: transform 0.3s ease;
    }
    &:hover .anticon {
      transform: translateX(5px);
    }
  }
`;

export const SubmitNoBgBtn = styled.button`
&&& {
    font-size: 16px;
    font-weight: 600;
    text-transform: capitalize;
    height: auto;
    padding: 12px 24px;
    border: 1px solid #eeeeee;
    border-radius: 10px;
    display: flex;
    align-items: center;
    gap: 12px;
    color:#275df5;
    background-color:white;
    transition: all 0.3s ease;
    .anticon {
      font-size: 24px;
      transition: transform 0.3s ease;
    }
    &:hover,
    &:active {
    transform: scale(0.96);
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  }
`;
