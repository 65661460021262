import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import InventoryDrafts from "./InventoryDrafts";
import InventoryPublished from "./InventoryPublished";
import { Avatar, Button, Result } from "antd";

import { checkModulePermissions, hasPermission } from "../../utils/helper";
import { XCardsTransparentTabs, XTabs } from "../Manage/ManageStyle";
import TabPane from "antd/es/tabs/TabPane";
import { XContainer } from "../../styles/GlobalStyle";

const Inventory = (props) => {
  const navigate = useNavigate();

  const [draftJobCount, setDraftJobCount] = useState(0);
  const [publishedJobCount, setPublishedJobCount] = useState(0);
  const [keys, setKeys] = useState("published");

  const handleTabChange = (key) => {
    // Clear the URL search parameters when changing tabs
    setKeys(key);
    navigate(`/inventory`);
  };

  return (
    <>
      {hasPermission("jobs-inventory") ? (
        <XContainer fluid>
          <XCardsTransparentTabs>
            <XTabs
              defaultActiveKey={keys}
              type="card"
              onChange={handleTabChange}>
              <TabPane
                key="published"
                tab={
                  <div className="gilroy-semiBold">
                    Published
                    <Avatar size={20} className="ms-2">
                      {publishedJobCount}
                    </Avatar>
                  </div>
                }>
                <InventoryPublished
                  setPublishedJobCount={setPublishedJobCount}
                  setDraftJobCount={setDraftJobCount}
                  keys={keys}
                  setKeys={setKeys}
                />
              </TabPane>
              <TabPane
                key="draft"
                tab={
                  <div className="gilroy-semiBold">
                    Drafts
                    <Avatar size={20} className="ms-2">
                      {draftJobCount}
                    </Avatar>
                  </div>
                }>
                <InventoryDrafts
                  setDraftJobCount={setDraftJobCount}
                  setPublishedJobCount={setPublishedJobCount}
                  keys={keys}
                  setKeys={setKeys}
                />
              </TabPane>{" "}
            </XTabs>
          </XCardsTransparentTabs>
        </XContainer>
      ) : (
        <Result
          status="404"
          title="404"
          subTitle="Sorry, the page you visited does not exist."
          extra={
            <Button
              type="primary"
              onClick={() => {
                navigate("/");
              }}>
              Back Home
            </Button>
          }
        />
      )}
    </>
  );
};

export default Inventory;
