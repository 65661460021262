import styled from 'styled-components';
import '../../styles/GlobalStyle';
import { Form, Checkbox } from 'antd';

export const CenterWrapper = styled.section`
  display: flex;
  align-items: center;
  @media only screen and (max-width: 767.99px) {
    display: block !important;
    ${'' /* padding: 20px 0px 0px 0px !important; */}
  }
  ${
    '' /* @media only screen and (max-width: 1440.99px) {
		padding: 60px 0px;
	}
	@media only screen and (min-width: 1920.99px) {
		padding: 80px 0px;
	} */
  }
  @media only screen and (min-width: 2000.99px) {
    display: block !important;
    ${'' /* padding: 20px 0px 0px 0px !important; */}
  }
`;

export const Logo = styled.div`
  width: 100%;
  position: relative;
  padding: 20px 0px 30px 0px;
  @media only screen and (max-width: 767.99px) {
    padding: 20px 0px 0px 0px;
  }
`;
export const Title = styled.h1`
  margin: 0px;
  background: #275df5;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  @media only screen and (max-width: 768.99px) {
    display: flex;
    align-items: center;
    text-align: center;
    font-size: 28px;
    font-size: 20px;
    // margin-top: -20px;
  }
`;
export const XForm = styled(Form)`
  label {
    color: #000 !important;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  input {
    border-radius: 10px;
    border: 1px solid #d8d8d7;
    background: #f7f9fd;
    padding-top: 10px;
    padding-bottom: 10px;
    @media only screen and (max-width: 768.99px) {
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }
  button {
    width: 100%;
    border-radius: 50px !important;
    background: ${({ theme }) => theme.colors.menu2};
    color: #fff;
    font-size: 16px !important;
    padding: 10px 0px;
    height: auto;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    &:hover {
      background: ${({ theme }) => theme.colors.menu2} !important;
      color: #fff !important;
    }
    @media only screen and (max-width: 768.99px) {
      font-size: 14px;
      padding: 10px 0px;
    }
  }
  .ant-input-password {
    border-radius: 10px;
    border: 1px solid #d8d8d7;
    background: #f7f9fd;
    padding-top: 10px;
    padding-bottom: 10px;
    @media only screen and (max-width: 768.99px) {
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }
  .login-form-forgot {
    float: right;
    text-decoration: none;
    color: ${({ theme }) => theme.colors.menu2};
    text-align: right;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    @media only screen and (max-width: 768.99px) {
      font-size: 14px;
      line-height: 22px;
    }
  }
`;
export const XCheckbox = styled(Checkbox)`
  color: #656565;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;
