import styled from "styled-components";
import "../../styles/GlobalStyle";
import { List } from "antd";

export const XSectionBgBorder = styled.section`
  width: 100%;
  position: relative;
  background: #f4f7fe !important;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  @media only screen and (max-width: 769px) {
    border: none;
    background: ${({ theme }) => theme.colors.white} !important;
  }
`;
export const Title = styled.h1`
  color: ${({ theme }) => theme.colors.cta_text_color};
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: 0.448px;
`;
export const Paragraph = styled.p`
  color: ${({ theme }) => theme.colors.text2};
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.8px;
  margin: 0 15%;
`;
export const XList = styled(List)`
  .ant-list-item {
    border: none;
    margin-bottom: 15px;
    border-radius: 8px;
    background: var(--neutral-100, #fff);
    padding: 25px 15px;
    transition: all 0.3s ease 0s;
    &:hover {
      background: var(--neutral-100, #fff);
      box-shadow: 0px 5px 16px 0px rgba(8, 15, 52, 0.06);
    }
  }
  h4.ant-list-item-meta-title {
    color: ${({ theme }) => theme.colors.text1} !important;
    font-size: 20px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 100% !important;
    letter-spacing: 0.2px !important;
  }
  .ant-list-item-meta-description,
  .ant-list-item-meta-description a {
    color: var(--black, #363942) !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 100% !important;
    letter-spacing: 0.16px !important;
  }
`;

const IndexStyle = () => {};

export default IndexStyle;
