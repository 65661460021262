import config from "../config";
import axios from "axios";
import { getUserToken } from "../utils/helper";

export const GET_STATES = "GET_STATES";
export const GET_STATES_SUCCESS = "GET_STATES_SUCCESS";
export const GET_STATES_FAIL = "GET_STATES_FAIL";

export const getStates = (params) => {
  return {
    type: GET_STATES,
    params,
  };
};

export const getStatesSuccess = (response) => {
  return {
    type: GET_STATES_SUCCESS,
    response,
  };
};

export const getStatesFail = (response) => {
  return {
    type: GET_STATES_FAIL,
    response,
  };
};

export const getStatesApi = (params) => {
  return (dispatch) => {
    dispatch(getStates());
    axios
      .get(`${config.api.base_url}/get-states`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getUserToken()}`,
        },
        params,
      })
      .then((response) => {
        console.log(response.data.data);
        const getStatesData = response.data.data;
        dispatch(getStatesSuccess(getStatesData));
      })
      .catch((error) => {
        const errorData = error.response.data;
        dispatch(getStatesFail(errorData));
      });
  };
};
