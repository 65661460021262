import config from "../config";
import axios from "axios";
import { getUserToken } from "../utils/helper";

export const GET_CANDIDATE_LIST = "GET_CANDIDATE_LIST";
export const GET_CANDIDATE_LIST_SUCCESS = "GET_CANDIDATE_LIST_SUCCESS";
export const GET_CANDIDATE_LIST_FAIL = "GET_CANDIDATE_LIST_FAIL";
export const GET_CANDIDATE_LIST_RESET = "GET_CANDIDATE_LIST_RESET";

export const getCandidateList = (params) => {
  return {
    type: GET_CANDIDATE_LIST,
    params,
  };
};

export const getCandidateListSuccess = (response) => {
  return {
    type: GET_CANDIDATE_LIST_SUCCESS,
    response,
  };
};

export const getCandidateListFail = (response) => {
  console.log("action response fail=>", response);
  return {
    type: GET_CANDIDATE_LIST_FAIL,
    response,
  };
};

export const getCandidateListReset = () => {
  return {
    type: GET_CANDIDATE_LIST_RESET,
  };
};

export const getCandidateListApi = (params) => {
  return (dispatch) => {
    dispatch(getCandidateList());
    axios
      .get(`${config.api.base_url}/candidate`, {
        params: params,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getUserToken()}`,
        },
      })
      .then((response) => {
        const getCandidateListData = response.data;
        dispatch(getCandidateListSuccess(getCandidateListData));
      })
      .catch((error) => {
        const errorData = error.response.data;
        dispatch(getCandidateListFail(errorData));
      });
  };
};
