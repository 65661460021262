import config from '../config';
import axios from 'axios';
import { getUserToken } from '../utils/helper';

export const GET_JOB_APPLICATION_STATISTICS = 'GET_JOB_APPLICATION_STATISTICS';
export const GET_JOB_APPLICATION_STATISTICS_SUCCESS =
  'GET_JOB_APPLICATION_STATISTICS_SUCCESS';
export const GET_JOB_APPLICATION_STATISTICS_FAIL =
  'GET_JOB_APPLICATION_STATISTICS_FAIL';
export const GET_JOB_APPLICATION_STATISTICS_RESET =
  'GET_JOB_APPLICATION_STATISTICS_RESET';

export const getJobApplicationStatistics = (params) => {
  return {
    type: GET_JOB_APPLICATION_STATISTICS,
    params,
  };
};

export const getJobApplicationStatisticsSuccess = (response) => {
  return {
    type: GET_JOB_APPLICATION_STATISTICS_SUCCESS,
    response,
  };
};

export const getJobApplicationStatisticsFail = (response) => {
  return {
    type: GET_JOB_APPLICATION_STATISTICS_FAIL,
    response,
  };
};

export const getJobApplicationStatisticsReset = () => {
  return {
    type: GET_JOB_APPLICATION_STATISTICS_RESET,
  };
};

export const getJobApplicationStatisticsApi = (params) => {
  return (dispatch) => {
    dispatch(getJobApplicationStatistics());
    axios
      .get(`${config.api.base_url}/dashboard/job-application-statistics`, {
        params: params,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${getUserToken()}`,
        },
      })
      .then((response) => {
        const getJobApplicationStatisticsData = response.data;
        dispatch(
          getJobApplicationStatisticsSuccess(getJobApplicationStatisticsData)
        );
      })
      .catch((error) => {
        const errorData = error.response.data;
        dispatch(getJobApplicationStatisticsFail(errorData));
      });
  };
};
