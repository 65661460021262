import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { Card, Row, Col, Button, Typography } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

const { Title, Text } = Typography;

const PopularCategories = ({ categories }) => {
  const navigate = useNavigate();

  return (
    <Section>
      <BackgroundImage
        loading='lazy'
        src='https://cdn.builder.io/api/v1/image/assets/TEMP/3f1ee55988c80f0337909b3ec16e4ab2e88e58baaa97330bd57ba6ab4bbee7c2?placeholderIfAbsent=true&apiKey=ac9769c5e7eb416e8a74975078a910d6'
        alt=''
      />
      <Container>
        <BackgroundOverlay
          loading='lazy'
          src='https://cdn.builder.io/api/v1/image/assets/TEMP/99f56fb8daaf1a7db0e9a2e6469301e2114a9da704ba3ba894c05c43428ebad8?placeholderIfAbsent=true&apiKey=ac9769c5e7eb416e8a74975078a910d6'
          alt=''
        />
        <Content>
          <Header>
            <StyledTitle level={2}>
              <PopularText>Popular</PopularText> Category
            </StyledTitle>
            <ViewAllButton onClick={() => navigate('/explore')}>
              View All <ArrowRightOutlined />
            </ViewAllButton>
          </Header>
          <StyledRow
            gutter={[
              { xs: 20, sm: 30, md: 60, lg: 90 },
              { xs: 20, sm: 20, md: 20, lg: 20 },
            ]}>
            {categories?.map((category, index) => (
              <Col xs={24} sm={12} lg={8} key={index}>
                <CategoryCard {...category} />
              </Col>
            ))}
          </StyledRow>
        </Content>
      </Container>
    </Section>
  );
};

const CategoryCard = ({ icon, industry_name, total_positions = 0, _id }) => {
  const navigate = useNavigate();

  const [isHovered, setIsHovered] = useState(false);

  return (
    <StyledCard
      hoverable
      bodyStyle={{ padding: 24 }}
      bordered={false}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={() => navigate(`/explore?industry=${_id}`)}>
      <CardContent>
        <IconWrapper ishighlighted={isHovered}>
          <Icon
            src={icon}
            alt={`${industry_name} icon`}
            ishighlighted={isHovered}
          />
        </IconWrapper>
        <Info>
          <CardTitle ishighlighted={isHovered}>{industry_name}</CardTitle>
          <OpenPositions>{total_positions} Open position</OpenPositions>
        </Info>
      </CardContent>
    </StyledCard>
  );
};

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const slideUp = keyframes`
  from { transform: translateY(20px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
`;

const Section = styled.section`
  display: flex;
  flex-direction: column;
  align-self: stretch;
  position: relative;
  min-height: 631px;
  // margin-top: 104px;
  justify-content: center;
  padding: 1px 0;
  animation: ${fadeIn} 0.5s ease-out;
  // @media (max-width: 1200px) {
  //   margin-top: 80px;
  // }
  // @media (max-width: 768px) {
  //   margin-top: 60px;
  // }
`;

const BackgroundImage = styled.img`
  position: absolute;
  inset: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 10;
  min-height: 631px;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 69px 80px;
  @media (max-width: 1200px) {
    padding: 60px 40px;
  }
  @media (max-width: 768px) {
    padding: 40px 20px;
  }
`;

const BackgroundOverlay = styled.img`
  position: absolute;
  inset: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
`;

const Content = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  max-width: 1117px;
  flex-direction: column;
`;

const Header = styled.header`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 53px;
  animation: ${slideUp} 0.5s ease-out;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 40px;
  }
`;

const StyledTitle = styled(Title)`
  &&& {
    color: #18191c;
    font-family: Inter, sans-serif;
    font-size: 40px;
    font-weight: 600;
    line-height: 1.2;
    margin: 0;
    @media (max-width: 768px) {
      font-size: 32px;
    }
  }
`;

const PopularText = styled.span`
  font-weight: 600;
  color: #275df5;
`;

const pulse = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
`;

const ViewAllButton = styled(Button)`
  &&& {
    font-size: 16px;
    font-weight: 600;
    text-transform: capitalize;
    height: auto;
    padding: 12px 24px;
    border: 1px solid #eeeeee;
    border-radius: 3px;
    display: flex;
    align-items: center;
    gap: 12px;
    color: #275df5;
    background-color: transparent;
    transition: all 0.3s ease;
    &:hover {
      background-color: rgba(39, 93, 245, 0.1);
      animation: ${pulse} 0.5s ease-in-out;
    }
    .anticon {
      font-size: 24px;
      transition: transform 0.3s ease;
    }
    &:hover .anticon {
      transform: translateX(5px);
    }
    @media (max-width: 768px) {
      width: 100%;
      justify-content: center;
    }
  }
`;

const StyledRow = styled(Row)`
  margin-left: -45px;
  margin-right: -45px;
  @media (max-width: 1200px) {
    margin-left: -30px;
    margin-right: -30px;
  }
  @media (max-width: 768px) {
    margin-left: -10px;
    margin-right: -10px;
  }
`;

const StyledCard = styled(Card)`
  &&& {
    border-radius: 12px;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06);
    transition: all 0.3s ease;
    width: 100%;
    background-color: #fff;
    animation: ${slideUp} 0.5s ease-out;
    &:hover {
      box-shadow: 0px 12px 48px 0px rgba(0, 44, 109, 0.1);
      transform: translateY(-5px);
    }
    .ant-card-body {
      padding: 24px;
    }
  }
`;

const CardContent = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const IconWrapper = styled.div`
  border-radius: 50px;
  background-color: rgba(231, 240, 250, 1);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 68px;
  height: 68px;
  flex-shrink: 0;
  transition: background-color 0.3s ease;
  ${StyledCard}:hover & {
    background-color: rgba(39, 93, 245, 1);
  }
`;

const Icon = styled.img`
  width: 32px;
  height: 32px;
  object-fit: contain;
  transition: filter 0.3s ease;
  ${StyledCard}:hover & {
    filter: brightness(0) invert(1);
  }
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  font-family: Inter, sans-serif;
`;

const CardTitle = styled(Text)`
  &&& {
    color: #18191c;
    font-size: 18px;
    font-weight: 500;
    line-height: 28px;
    margin: 0 0 8px 0;
    transition: color 0.3s ease;
    ${StyledCard}:hover & {
      color: #275df5;
    }
  }
`;

const OpenPositions = styled(Text)`
  &&& {
    color: #5e6670;
    font-size: 14px;
    font-weight: 400;
    line-height: 1;
    margin: 0;
  }
`;

export default PopularCategories;
