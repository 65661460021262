import axios from "axios";
import config from "../config";
import { getUserToken } from "../utils/helper";

export const EDIT_USER = "EDIT_USER";
export const EDIT_USER_SUCCESS = "EDIT_USER_SUCCESS";
export const EDIT_USER_FAIL = "EDIT_USER_FAIL";
export const EDIT_USER_RESET = "EDIT_USER_RESET";

export const editUser = (params) => {
  return {
    type: EDIT_USER,
    params,
  };
};

export const editUserSuccess = (response) => {
  return {
    type: EDIT_USER_SUCCESS,
    response,
  };
};

export const editUserFail = (response) => {
  return {
    type: EDIT_USER_FAIL,
    response,
  };
};

export const editUserReset = () => {
  return {
    type: EDIT_USER_RESET,
  };
};

export const editUserApi = (data) => {
  return (dispatch) => {
    dispatch(editUser());
    axios
      .patch(`${config.api.base_url}/settings/edit-user`, data, {
        headers: {
          Authorization: "Bearer " + getUserToken(),
        },
      })
      .then((response) => {
        const editUserData = response.data;
        dispatch(editUserSuccess(editUserData));
      })
      .catch((error) => {
        const errorMsg = error.message;
        dispatch(editUserFail(errorMsg));
      });
  };
};
