import config from '../config';
import axios from 'axios';
import { getUserToken } from '../utils/helper';

export const GET_JOB_APPLICATION_STATISTICS_MONTHLY =
  'GET_JOB_APPLICATION_STATISTICS_MONTHLY';
export const GET_JOB_APPLICATION_STATISTICS_MONTHLY_SUCCESS =
  'GET_JOB_APPLICATION_STATISTICS_MONTHLY_SUCCESS';
export const GET_JOB_APPLICATION_STATISTICS_MONTHLY_FAIL =
  'GET_JOB_APPLICATION_STATISTICS_MONTHLY_FAIL';
export const GET_JOB_APPLICATION_STATISTICS_MONTHLY_RESET =
  'GET_JOB_APPLICATION_STATISTICS_MONTHLY_RESET';

export const getJobApplicationStatisticsMonthly = (params) => {
  return {
    type: GET_JOB_APPLICATION_STATISTICS_MONTHLY,
    params,
  };
};

export const getJobApplicationStatisticsMonthlySuccess = (response) => {
  return {
    type: GET_JOB_APPLICATION_STATISTICS_MONTHLY_SUCCESS,
    response,
  };
};

export const getJobApplicationStatisticsMonthlyFail = (response) => {
  return {
    type: GET_JOB_APPLICATION_STATISTICS_MONTHLY_FAIL,
    response,
  };
};

export const getJobApplicationStatisticsMonthlyReset = () => {
  return {
    type: GET_JOB_APPLICATION_STATISTICS_MONTHLY_RESET,
  };
};

export const getJobApplicationStatisticsMonthlyApi = (params) => {
  return (dispatch) => {
    dispatch(getJobApplicationStatisticsMonthly());
    axios
      .get(
        `${config.api.base_url}/dashboard/job-application-statistics-monthly`,
        {
          params: params,
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getUserToken()}`,
          },
        }
      )
      .then((response) => {
        const getJobApplicationStatisticsMonthlyData = response.data;
        dispatch(
          getJobApplicationStatisticsMonthlySuccess(
            getJobApplicationStatisticsMonthlyData
          )
        );
      })
      .catch((error) => {
        const errorData = error.response.data;
        dispatch(getJobApplicationStatisticsMonthlyFail(errorData));
      });
  };
};
