import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Form,
  Input,
  DatePicker,
  Radio,
  notification,
  Select,
  Button,
  Result,
  message,
  Card,
} from 'antd';

import dayjs from 'dayjs';
import axios from 'axios';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import {
  XButton,
  ProfileGende,
  Title2,
  XDesktopCard,
  XButtonBack,
  XCheckbox,
  XCard,
  XCardInner,
  XButtonNonBg,
  XUpload,
  TitleUpload,
} from './BuildProfileStyle';
import { connect } from 'react-redux';
import { validateCookies } from '../../components/authHelper';

// API imports
import { getCandidateDetailsApi } from '../../actions/getCandidateDetailsAction';
import cookie from 'js-cookie';

import {
  savePersonalInformationApi,
  savePersonalInformationReset,
} from '../../actions/savePersonalInformationAction';

import { getStatesApi } from '../../actions/getStatesAction';
import { v4 as uuidv4 } from 'uuid';

import { getIndustriesApi } from '../../actions/getIndustriesAction';
import { getIndustryCategoriesApi } from '../../actions/getIndustryCategoriesAction';

import { getQualificationApi } from '../../actions/getQualificationAction';

import { useNavigate } from 'react-router-dom';
import {
  filterByLabel,
  handleKeyDown,
  handleSelectChange,
  lessThan18,
} from '../../utils/formFunctions';
import { maxDropdownSelections } from '../../utils/constants';
import {
  checkModulePermissions,
  getFileNameFromDocPath,
  getSignedUrlForGetObject,
  getUserToken,
  hasPermission,
} from '../../utils/helper';
import { getSkillsApi } from '../../actions/getSkillsAction';
import { XContainer } from '../../styles/GlobalStyle';
import './index.css';
import config from '../../config';
import { getCountriesApi } from '../../actions/getCountriesAction';
const moment = require('moment');

dayjs.extend(customParseFormat);
const { TextArea } = Input;
const { Option } = Select;

const PersonalInformation = (props) => {
  const navigate = useNavigate();
  useEffect(() => {
    document.body.classList.remove('headerBg');
    document.body.classList.remove('bodyJobDetails');
    document.body.classList.remove('bodyhomeAfterLogin');
    document.body.classList.add('buildCVBody');
  });
  const {
    getCandidateDetails,
    getCandidateDetailsState,
    savePersonalInformation,
    savePersonalInformationState,
    savePersonalInformationReset,
    getStates,
    getStatesState,
    getCountries,
    getCountriesState,
    uploadCandidateModal,
    getIndustriesState,
    getIndustries,
    getIndustryCategoriesState,
    getIndustryCategories,
    getQualification,
    getQualificationState,
    setUserIcon,
    getSkills,
    getSkillsState,
  } = props;

  const [userData, setUserData] = useState({
    id: '',
    first_name: '',
    last_name: '',
    email_address: '',
    dateOfBirth: '',
    gender: '',
    address: '',
    pincode: null,
    city: '',
    state: '',
    willing_to_relocate: false,
    preferred_job_type: [],
    preferred_work_type: [],
    preferred_industry: [],
    preferred_job_roles: [],
    highest_education: '',
    qualification: '',
    specialization: '',
    college_name: '',
    completion_month_year: '',
    work_experience: [
      {
        company_name: '',
        designation: '',
        is_currently_working: false,
        from: '',
        to: '',
        roles_and_responsibilities: '',
        employment_type: '',
        location: '',
        industry: '',
      },
    ],
    current_ctc_monthly: '',
    current_ctc_yearly: '',
    expected_ctc_monthly: '',
    expected_ctc_yearly: '',
    skills: [],
    resume: '',
    s3_folder_name: '',
  });

  const [form] = Form.useForm();

  const dateFormat = 'DD-MM-YYYY';

  const [loading, setLoading] = useState(false);
  const [showBuildProfile, setShowBuildProfile] = useState(false); //!Set to false later
  const [specializationList, setSpecializationList] = useState([]);
  const [filteredSpecializationList, setFilteredSpecializationList] = useState(
    []
  );
  const [documentsToUpload, setDocumentsToUpload] = useState([]);
  const [triggerUpdate, setTriggerUpdate] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [uploadedDocuments, setUploadedDocuments] = useState([]);
  const [locationDetails, setLocationDetails] = useState({
    country: null,
    state: null,
    city: null,
  });
  const [cities, setCities] = useState([]); // To store cities of the selected state

  useEffect(() => {
    getIndustries({});
    getSkills({});
    getCountries({});

    setUploadedDocuments([{ key: 'resume' }]);
  }, []);

  //!Need to uncomment later
  useEffect(() => {
    if (hasPermission('candidate-profile', 'view-candidate')) {
      localStorage.getItem('role') === 'candidate' && getCandidateDetails();
    }
  }, []);

  useEffect(() => {
    if (getCandidateDetailsState.apiState === 'success') {
      const data = getCandidateDetailsState.data;

      const formData = {
        ...userData,
        id: data._id,
        first_name: data.first_name,
        last_name: data.last_name,
        email_address: data.email_address,
        mobile_number: data.mobile_number,
        dateOfBirth: data.dateOfBirth ? dayjs(data.dateOfBirth) : null,
        gender: data.gender,
        address: data.address || null,
        pincode: data.pincode || null,
        country: data.country || null,
        city: data.city || null,
        state: data.state || null,
        willing_to_relocate: data.willing_to_relocate,
        preferred_job_type: data.preferred_job_type || null,
        preferred_work_type: data.preferred_work_type || null,
        preferred_industry: data.preferred_industry || null,
        preferred_job_roles: data.preferred_job_roles || null,
        highest_education: data.highest_education || '',
        qualification: data.qualification || '',
        specialization: data.specialization || '',
        college_name: data.college_name || '',
        completion_month_year: data.completion_month_year
          ? dayjs(data.completion_month_year)
          : '',
        work_experience:
          data.work_experience?.length > 0
            ? data.work_experience.map((item) => ({
                ...item,
                from: dayjs(item.from),
                to: item.is_currently_working === false ? dayjs(item.to) : null,
              }))
            : [
                {
                  company_name: '',
                  designation: '',
                  is_currently_working: false,
                  from: '',
                  to: '',
                  roles_and_responsibilities: '',
                  employment_type: '',
                  location: '',
                  industry: '',
                },
              ],
        current_ctc_monthly:
          data.current_ctc_monthly > 0 ? data.current_ctc_monthly : '',
        current_ctc_yearly:
          data.current_ctc_yearly > 0 ? data.current_ctc_yearly : '',
        expected_ctc_monthly:
          data.expected_ctc_monthly > 0 ? data.expected_ctc_monthly : '',
        expected_ctc_yearly:
          data.expected_ctc_yearly > 0 ? data.expected_ctc_yearly : '',
        skills: data.skills,
        resume: data.resume,
        s3_folder_name: data.s3_folder_name,
        cover_letter: data.cover_letter,
      };

      form.setFieldsValue(formData);
      setUserData(formData);

      if (data.preferred_industry?.length > 0) {
        getIndustryCategories({ industries: data.preferred_industry });
      }
      if (data.highest_education !== 'other') {
        getQualification({ type: data.highest_education });
      }

      setAllUploadedDocuments(data);

      setShowBuildProfile(true);
    } else if (getCandidateDetailsState.apiState === 'error') {
      notification.error({
        message: 'Unable to get details!',
      });
    }
  }, [getCandidateDetailsState.apiState]);

  useEffect(() => {
    // when roles dropdown is changed -> remove selected tags not exists in new dropdown options
    if (getIndustryCategoriesState.apiState === 'success') {
      const updatedRoles = userData.preferred_job_roles?.filter((role) => {
        return getIndustryCategoriesState.data.all_categories.some(
          (category) => category.id === role
        );
      });
      setUserData({
        ...userData,
        preferred_job_roles: updatedRoles,
      });
      form.setFieldsValue({
        ...form.getFieldsValue(),
        preferred_job_roles: updatedRoles,
      });
    }
  }, [getIndustryCategoriesState.apiState]);

  useEffect(() => {
    if (getQualificationState.apiState === 'success') {
      if (getQualificationState.data[0].type === 'iti')
        setFilteredSpecializationList(
          getQualificationState.data[0].specialization
        );
    }
  }, [getQualificationState.apiState]);

  useEffect(() => {
    if (getCountriesState.apiState === 'success') {
      if (getCountriesState.data) {
        // Format the API data
        const formattedCountryData = getCountriesState.data.map((country) => ({
          value: country.country,
          label: country.country,
          type: 'country',
          id: country.country_abbreviation,
        }));

        setLocationDetails({
          ...locationDetails,
          country: formattedCountryData,
        });
      }
    }
  }, [getCountriesState.apiState]);

  useEffect(() => {
    if (userData.country_code) {
      getStates({ country: userData.country_code });
    }
  }, [userData.country_code]);

  useEffect(() => {
    if (getStatesState.apiState === 'success') {
      if (getStatesState.data) {
        // Format the API data for states
        const formattedStateData = getStatesState.data.all_states.map(
          (state) => ({
            value: state.state,
            label: state.state,
            type: 'state',
            cities: state.cities, // Include cities for later use
          })
        );

        setLocationDetails((prevState) => ({
          ...prevState,
          state: formattedStateData,
        }));
      }
    }
  }, [getStatesState.apiState]);

  useEffect(() => {
    if (savePersonalInformationState.apiState === 'success') {
      localStorage.setItem('gender', savePersonalInformationState.data.gender);
      setUserIcon(localStorage.getItem('gender'));

      setUserIcon(localStorage.getItem('gender'));
      setLoading(false);
      notification.success({
        message: savePersonalInformationState.message,
      });
      navigate(`/candidate-details/${userData.id}`);
    } else if (savePersonalInformationState.apiState === 'error') {
      setLoading(false);
      notification.error({
        message: savePersonalInformationState.message,
      });
    }
    savePersonalInformationReset();
  }, [savePersonalInformationState.apiState]);

  const handleDateOfBirthChange = (val) => {
    console.log(val);
    setUserData({
      ...userData,
      dateOfBirth: dayjs(val).format('YYYY-MM-DD'),
    });
    form.setFieldsValue({
      dateOfBirth: val,
    });
  };

  const handleGenderChange = (event) => {
    setUserData({
      ...userData,
      gender: event.target.value,
    });
    form.setFieldsValue({
      gender: event.target.value,
    });
  };

  const handleChange = (e) => {
    setUserData({
      ...userData,
      [e.target.name]: e.target.value,
    });
  };

  const handleIndustrySearch = (search) => {
    if (search.length >= 3) {
      getIndustries({ search });
    } else if (search.length == 0) {
      getIndustries({});
    }
  };

  const handleCategorySearch = (search) => {
    if (search.length >= 3) {
      getIndustryCategories({
        search: search,
        industries: userData.preferred_industry,
      });
    } else {
      getIndustryCategories({
        industries: userData.preferred_industry,
      });
    }
  };

  const handleOnClick = async (resume, s3_folder_name) => {
    const url = await getSignedUrlForGetObject(
      'candidates',
      resume,
      s3_folder_name
    );
    window.open(url, '_blank');
  };

  function validateDropdownSelects(_, value) {
    if (value && value.length > maxDropdownSelections) {
      return Promise.reject(
        `Please select up to ${maxDropdownSelections} industries`
      );
    }
    return Promise.resolve();
  }
  const handleStateChange = (selectedState) => {
    setUserData((prev) => ({
      ...prev,
      state: selectedState,
    }));

    // Find the selected state from the formatted state data
    const selectedStateData = locationDetails.state.find(
      (state) => state.value === selectedState
    );

    // Set the cities of the selected state
    if (selectedStateData) {
      setCities(
        selectedStateData.cities.map((city) => ({
          value: city,
          label: city,
          type: 'city',
        }))
      );
    }
  };
  const handleExperienceInputChange = (fieldName, fieldIndex, value) => {
    let work_experience = userData.work_experience;

    if (fieldName == 'is_currently_working') {
      const updatedWorkExperiences = work_experience.map(
        (experience, index) => {
          if (work_experience.length > 1) {
            if (fieldIndex === index) {
              return {
                ...experience,
                is_currently_working: true,
              };
            } else {
              return {
                ...experience,
                is_currently_working: false,
              };
            }
          } else {
            return {
              ...experience,
              is_currently_working: value,
            };
          }
        }
      );
      form.setFieldsValue({
        work_experience: updatedWorkExperiences,
      });
      setUserData({
        ...userData,
        work_experience: updatedWorkExperiences,
      });
    } else {
      work_experience[fieldIndex][fieldName] = value;
      setUserData({
        ...userData,
        work_experience,
      });
    }
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const getDocumentObject = async (document_path) => {
    let url = await getSignedUrlForGetObject('candidates', document_path);
    let file_name = getFileNameFromDocPath(document_path);
    return {
      name: file_name,
      url: url,
      status: 'done',
    };
  };

  const setAllUploadedDocuments = async (data) => {
    const documentPromises = uploadedDocuments?.map(async (document) => {
      try {
        const document_path = data[document.key];
        const document_object = await getDocumentObject(document_path);
        document.name = document_object.name;
        document.url = document_object.url;
        document.status = document_object.status;
        return document;
      } catch (error) {
        console.log('Try and catch error => ', error.message);
        return error;
      }
    });
    await Promise.all(documentPromises);
    setDataLoaded(true);
  };

  const beforeResumeUpload = (file) => {
    const allowedTypes = [
      'application/pdf',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    ]; // Allowed MIME types for PDF and Word documents

    // Check file type
    const isValidType = allowedTypes.includes(file.type);
    if (!isValidType) {
      message.error('Only PDF and Word files are allowed');
      return false;
    }

    return true; // Allow upload if file type is valid
  };

  const uploadFiles = async (userName) => {
    // keep document paths in an object
    let documentFields = {};
    // Create an array of promises
    const s3FolderName = userData.s3_folder_name
      ? userData.s3_folder_name
      : uuidv4();

    const uploadPromises = documentsToUpload.map(async (document) => {
      const { fieldName, file, onError, onSuccess, onProgress } = document;
      let folder_name = `candidates`;
      let file_name = `resume_${userName}`;
      let get_ext = file.name.split('.');
      let ext = get_ext[get_ext.length - 1];

      try {
        const res = await axios.get(
          `${config.api.base_url}/settings/get-signed-url?action=putObject&folder_name=${folder_name}&file_name=${file_name}&ext=${ext}&id=${s3FolderName}`,
          {
            headers: {
              'Content-type': 'application/json; charset=UTF-8',
              Authorization: 'Bearer ' + getUserToken(),
            },
          }
        );

        const result = await axios.put(res.data.data, file, {
          onUploadProgress: ({ total, loaded }) => {
            onProgress(
              {
                percent: Math.round((loaded / total) * 100).toFixed(0),
              },
              file
            );
          },
        });

        if (result.status === 200) {
          let documentPath = `/${folder_name}/${s3FolderName}/${file_name}.${ext}`;
          // update document paths
          documentFields = {
            ...documentFields,
            [fieldName]: documentPath,
          };
          onSuccess(result, file);
        }
        return result;
      } catch (error) {
        console.log('Try and catch error => ', error);
        notification.error({
          message: `Unable to upload file - ${fieldName}`,
        });
        return error;
      }
    });

    // Wait for all promises to resolve
    const results = await Promise.all(uploadPromises);
    // if any result status !== 200 , setTriggerUpdate to false

    if (results.some((result) => result.status !== 200)) {
      setTriggerUpdate(false);
    } else {
      let work_experience = userData.work_experience.map((item) => ({
        ...item,
        from: moment(item.from).format('YYYY-MM'),
        to:
          item.is_currently_working === false
            ? moment(item.to).format('YYYY-MM')
            : null,
      }));
      // set document fields, id in form data and trigger upload
      setUserData({
        work_experience,
        ...userData,
        ...documentFields,
        s3_folder_name: s3FolderName, // Ensure this is correctly referenced
      });

      setTriggerUpdate(true);
    }
  };

  const handleSaveResumeDetails = () => {
    form.validateFields().then(() => {
      setLoading(true);
      uploadFiles(userData.first_name);
    });
  };

  useEffect(() => {
    if (triggerUpdate) savePersonalInformation(userData);
  }, [triggerUpdate]);

  return (
    <>
      {localStorage.getItem('role') === 'candidate' &&
      hasPermission('candidate-profile', 'view-candidate') ? (
        <div className='pageFluid bg-transparent mt-3 mt-lg-0'>
          <XDesktopCard>
            <Row align='middle'>
              {showBuildProfile ? (
                <Col xs={24}>
                  <Form
                    form={form}
                    layout='vertical'
                    className='formHeight'
                    initialValues={userData}
                    onFinish={() => {
                      handleSaveResumeDetails();
                    }}>
                    <Card className='mt-4 custom-card-padding'>
                      <Row gutter={[30, 0]}>
                        <Col xs={24}>
                          <Title2 className='mb-3'>Personal Information</Title2>
                        </Col>
                        <Col xs={24} sm={12} lg={8}>
                          <Form.Item
                            label='First Name'
                            className='formLable'
                            name='first_name'
                            rules={[
                              {
                                required: true,
                                message: 'First Name is required',
                              },
                            ]}>
                            <Input
                              className='searchInputBrder removeBg'
                              size='large'
                              placeholder='Enter your First Name'
                              name='first_name'
                              value={
                                userData.first_name ? userData.first_name : ''
                              }
                              onChange={handleChange}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} lg={8}>
                          <Form.Item
                            label='Last Name'
                            className='formLable'
                            name='last_name'
                            rules={[
                              {
                                required: true,
                                message: 'Last name is required',
                              },
                            ]}>
                            <Input
                              className='searchInputBrder removeBg'
                              size='large'
                              name='last_name'
                              placeholder='Enter your Last name'
                              value={
                                userData.last_name ? userData.last_name : ''
                              }
                              onChange={handleChange}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} lg={8}>
                          <Form.Item
                            label='Email'
                            className='formLable'
                            rules={[
                              {
                                required: true,
                                message: 'Email is required',
                              },
                              {
                                type: 'email',
                                message: 'Please enter a valid email',
                              },
                            ]}
                            name='email_address'>
                            <Input
                              className='searchInputBrder removeBg'
                              size='large'
                              name='email_address'
                              placeholder='Enter your email id'
                              value={
                                userData.email_address
                                  ? userData.email_address
                                  : ''
                              }
                              disabled
                              onChange={handleChange}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} lg={8}>
                          <Form.Item
                            label='Mobile Number'
                            className='formLable'
                            name='mobile_number'
                            rules={[
                              {
                                required: true,
                                message: 'Mobile Number is required',
                              },
                            ]}>
                            <Input
                              className='searchInputBrder removeBg'
                              size='large'
                              name='mobile_number'
                              placeholder='Enter your Mobile Number'
                              value={
                                userData.mobile_number
                                  ? userData.mobile_number
                                  : ''
                              }
                              onChange={handleChange}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} lg={8}>
                          <Form.Item
                            label='Date Of Birth'
                            className='formLable'
                            rules={[
                              {
                                required: true,
                                message: 'Date of birth is required',
                              },
                            ]}
                            name='dateOfBirth'>
                            <DatePicker
                              disabledDate={lessThan18}
                              format={dateFormat}
                              size='large'
                              className='searchInputBrder removeBg w-100'
                              placeholder='DD-MM-YYYY'
                              defaultPickerValue={dayjs().subtract(18, 'years')}
                              // Todo: Add Default Value for this if received  from Api
                              onChange={(value) =>
                                handleDateOfBirthChange(value)
                              }
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} lg={8}>
                          <Form.Item
                            label='Gender'
                            className='formLable'
                            name='gender'
                            rules={[
                              {
                                required: true,
                                message: 'Gender is required',
                              },
                            ]}>
                            <ProfileGende>
                              <Radio.Group
                                name='gender'
                                onChange={(e) => handleGenderChange(e)}
                                defaultValue={userData.gender}>
                                <Radio.Button value='male'>Male</Radio.Button>
                                <Radio.Button value='female'>
                                  Female
                                </Radio.Button>
                                <Radio.Button value='other'>Other</Radio.Button>
                              </Radio.Group>
                            </ProfileGende>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Card>
                    <Card className='mt-4 custom-card-padding'>
                      <Row gutter={[30, 0]}>
                        <Col xs={24} sm={24} lg={24}>
                          <Title2 className='mb-3'>Address Information</Title2>
                        </Col>
                        <Col xs={24} sm={12} lg={24}>
                          <Form.Item
                            label='Address'
                            className='formLable'
                            name='address'
                            rules={[
                              {
                                required: true,
                                message: (
                                  <p
                                    style={{
                                      fontSize: '12px',
                                      marginTop: '10px',
                                    }}>
                                    Address is required
                                  </p>
                                ),
                              },
                            ]}>
                            <Input.TextArea
                              className='searchInputBrder removeBg'
                              size='large'
                              placeholder='Enter your address'
                              name='address'
                              value={userData.address ? userData.address : ''}
                              onChange={(e) => {
                                setUserData({
                                  ...userData,
                                  address: e.target.value,
                                });
                                form.setFieldsValue({
                                  address: e.target.value,
                                });
                              }}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={[30, 0]}>
                        <Col xs={24} md={12}>
                          <Form.Item
                            label='Country'
                            className='formLable'
                            name={'country'}
                            rules={[
                              {
                                required: true,
                                message: 'please input country!',
                              },
                            ]}>
                            <Select
                              size='large'
                              placeholder='Select Country'
                              className='removeBg'
                              allowClear
                              showSearch
                              filterOption={filterByLabel}
                              onChange={(e, option) => {
                                setUserData((prev) => ({
                                  ...prev,
                                  country: e,
                                  country_code: option.id,
                                }));
                              }}
                              value={
                                userData.country
                                  ? userData.country
                                  : 'Select Country'
                              }
                              options={locationDetails.country}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                          <Form.Item
                            label='State'
                            className='formLable'
                            name={'state'}
                            rules={[
                              {
                                required: true,
                                message: 'please input state!',
                              },
                            ]}>
                            <Select
                              size='large'
                              placeholder='Select State'
                              className='removeBg'
                              allowClear
                              showSearch
                              filterOption={filterByLabel}
                              onChange={handleStateChange}
                              value={
                                userData.state ? userData.state : 'Select State'
                              }
                              options={locationDetails.state}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                          <Form.Item
                            label='City'
                            className='formLable'
                            name={'city'}
                            rules={[
                              {
                                required: true,
                                message: 'please input city!',
                              },
                            ]}>
                            <Select
                              size='large'
                              placeholder='Select City'
                              className='removeBg'
                              allowClear
                              showSearch
                              filterOption={filterByLabel}
                              onChange={(e) => {
                                setUserData((prev) => ({
                                  ...prev,
                                  city: e,
                                }));
                              }}
                              value={
                                userData.city ? userData.city : 'Select City'
                              }
                              options={cities}
                            />
                          </Form.Item>
                        </Col>{' '}
                        <Col xs={24} md={12}>
                          <Form.Item
                            label='Address'
                            className='formLable'
                            name={'address'}
                            rules={[
                              {
                                required: true,
                                message: 'please input address!',
                              },
                            ]}>
                            <TextArea
                              onKeyDown={handleKeyDown}
                              className='removeBg'
                              placeholder='Address'
                              rows={3}
                              name='address'
                              onChange={handleChange}
                              value={
                                userData.address
                                  ? userData.address
                                  : 'please input address!'
                              }
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                          <Form.Item
                            label='Pincode'
                            name='pincode'
                            className='formLable'
                            rules={[
                              {
                                required: true,
                                message: 'Please enter pincode',
                              },
                            ]}>
                            <Input
                              onKeyDown={handleKeyDown}
                              name='pincode'
                              className='searchInputBrder removeBg'
                              size='large'
                              placeholder='Enter Pincode'
                              onChange={handleChange}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} lg={8}>
                          <Form.Item
                            label='Preferred Job Type'
                            className='formLable'
                            name='preferred_job_type'>
                            <Select
                              size='large'
                              className='removeBg'
                              mode='multiple'
                              value={
                                userData.preferred_work_type
                                  ? userData.preferred_work_type
                                  : []
                              }
                              onChange={(e) => {
                                setUserData({
                                  ...userData,
                                  preferred_job_type: e,
                                });
                                form.setFieldsValue({
                                  preferred_job_type: e,
                                });
                              }}
                              placeholder='Select Preferred Job type'>
                              <Option value='full_time'>FullTime</Option>
                              <Option value='part_time'>PartTime</Option>
                              <Option value='temporary'>Temporary</Option>
                              <Option value='contractual'>Contractual</Option>
                              <Option value='internship'>Internship</Option>
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} lg={8}>
                          <Form.Item
                            label='Preferred Work Type'
                            className='formLable'
                            name='preferred_work_type'>
                            <Select
                              size='large'
                              className='removeBg'
                              mode='multiple'
                              value={
                                userData.preferred_work_type
                                  ? userData.preferred_work_type
                                  : []
                              }
                              onChange={(e) => {
                                setUserData({
                                  ...userData,
                                  preferred_work_type: e,
                                });
                                form.setFieldsValue({
                                  preferred_work_type: e,
                                });
                              }}
                              placeholder='Select Preferred Work Type'>
                              <Option value='wfh'>Work From Home (WFH)</Option>
                              <Option value='wfo'>
                                Work From Office (WFO)
                              </Option>
                              <Option value='hybrid'>Hybrid</Option>
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col xs={24} md={8} lg={8}>
                          <Form.Item
                            name='preferred_industry'
                            label='Preferred Industries'
                            className='formLable'
                            rules={[
                              {
                                validator: validateDropdownSelects,
                              },
                            ]}>
                            <Select
                              name='preferred_industry'
                              placeholder='Select preferred industries'
                              size='large'
                              className='removeBg'
                              mode='multiple'
                              allowClear
                              showSearch
                              onFocus={() => {
                                getIndustries({});
                              }}
                              filterOption={filterByLabel}
                              onSearch={handleIndustrySearch}
                              onChange={(value, options) => {
                                setUserData({
                                  ...userData,
                                  preferred_industry: value,
                                });
                                form.setFieldsValue({
                                  preferred_industry: value,
                                });
                              }}
                              options={
                                getIndustriesState.apiState === 'success' &&
                                getIndustriesState.data.all_industries.length >
                                  0
                                  ? getIndustriesState.data.all_industries.map(
                                      (item) => {
                                        return {
                                          value: item._id,
                                          label: item.industry_name,
                                        };
                                      }
                                    )
                                  : []
                              }></Select>
                          </Form.Item>
                        </Col>
                        <Col xs={24} md={8} lg={8}>
                          <Form.Item
                            name='preferred_job_roles'
                            label='Preferred Job Roles'
                            className='formLable'
                            validateFirst={true}
                            validateTrigger='onChange'
                            rules={[
                              {
                                validator: validateDropdownSelects,
                              },
                            ]}>
                            <Select
                              name='preferred_job_roles'
                              placeholder='Select preferred job roles'
                              size='large'
                              className='removeBg'
                              mode='multiple'
                              allowClear
                              showSearch
                              value={userData.preferred_job_roles || []}
                              onFocus={(e) => {
                                handleCategorySearch(e);
                              }}
                              onSearch={(e) => {
                                handleCategorySearch(e);
                              }}
                              onChange={(value, options) => {
                                setUserData({
                                  ...userData,
                                  preferred_job_roles: value,
                                });
                                form.setFieldsValue({
                                  preferred_job_roles: value,
                                });
                              }}
                              filterOption={filterByLabel}
                              options={
                                getIndustryCategoriesState.apiState ===
                                  'success' &&
                                getIndustryCategoriesState.data.all_categories
                                  .length > 0
                                  ? getIndustryCategoriesState.data.all_categories.map(
                                      (item) => {
                                        return {
                                          value: item.id,
                                          label: item.category_name,
                                        };
                                      }
                                    )
                                  : []
                              }></Select>
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} lg={8}>
                          <XCheckbox
                            defaultChecked={userData.willing_to_relocate}
                            name='willing_to_relocate'
                            onChange={(e) => {
                              setUserData({
                                ...userData,
                                willing_to_relocate: e.target.checked,
                              });
                              form.setFieldsValue({
                                willing_to_relocate: e.target.checked,
                              });
                            }}>
                            Willing to Relocate
                          </XCheckbox>
                        </Col>
                      </Row>
                    </Card>
                    <Card className='mt-4 custom-card-padding'>
                      <Row gutter={[30, 0]}>
                        <Col xs={24} sm={24} lg={24}>
                          <Title2 className='mb-3'>
                            Educational Information
                          </Title2>
                        </Col>
                        <Col xs={24} sm={24} lg={24}>
                          <Form.Item
                            label='Your Highest Education'
                            className='formLable'
                            name='highest_education'>
                            <ProfileGende className='mt-2'>
                              <Radio.Group
                                name='highest_education'
                                defaultValue={userData.highest_education}
                                onChange={async (e) => {
                                  setUserData({
                                    ...userData,
                                    highest_education: e.target.value,
                                    qualification: '',
                                    specialization: '',
                                    college_name: '',
                                    completion_month_year: '',
                                  });
                                  form.setFieldsValue({
                                    highest_education: e.target.value,
                                    qualification: '',
                                    specialization: '',
                                    college_name: '',
                                    completion_month_year: '',
                                  });
                                  setSpecializationList([]);
                                  setFilteredSpecializationList([]);

                                  if (e.target.value === 'iti') {
                                    await getQualification({
                                      type: 'iti',
                                    });
                                  }
                                }}>
                                <Radio.Button
                                  className='mb-2'
                                  value='10th_or_below_10th'>
                                  10th or Below 10th
                                </Radio.Button>
                                <Radio.Button
                                  className='mb-2'
                                  value='12th_pass'>
                                  12th Pass
                                </Radio.Button>
                                <Radio.Button className='mb-2' value='diploma'>
                                  Diploma
                                </Radio.Button>
                                <Radio.Button className='mb-2' value='iti'>
                                  ITI
                                </Radio.Button>
                                <Radio.Button className='mb-2' value='graduate'>
                                  Graduate
                                </Radio.Button>
                                <Radio.Button
                                  className='mb-2'
                                  value='post_graduate'>
                                  Post Graduate
                                </Radio.Button>
                                <Radio.Button className='mb-2' value='other'>
                                  Other
                                </Radio.Button>
                              </Radio.Group>
                            </ProfileGende>
                          </Form.Item>
                        </Col>

                        {(userData.highest_education === 'diploma' ||
                          userData.highest_education === 'graduate' ||
                          userData.highest_education === 'post_graduate') && (
                          <Col xs={24} sm={12} lg={8}>
                            <Form.Item
                              label={
                                userData.highest_education == 'diploma'
                                  ? 'Diploma'
                                  : 'Degree'
                              }
                              className='formLable'
                              name='qualification'>
                              <Select
                                showSearch
                                onFocus={() => {
                                  getQualification({
                                    type: userData.highest_education,
                                  });
                                }}
                                onSearch={(e) => {
                                  getQualification({
                                    type: userData.highest_education,
                                    search: e,
                                  });
                                }}
                                size='medium'
                                className='removeBg'
                                name='qualification'
                                placeholder='Select One'
                                onChange={(e) => {
                                  getQualificationState.data.map((item) => {
                                    if (item._id === e) {
                                      setSpecializationList(
                                        item.specialization
                                      );
                                      setFilteredSpecializationList(
                                        item.specialization
                                      );
                                    }
                                  });
                                  form.setFieldsValue({
                                    qualification: e,
                                  });
                                  setUserData({
                                    ...userData,
                                    qualification: e,
                                  });
                                }}>
                                {getQualificationState.apiState === 'success' &&
                                  getQualificationState.data.length > 0 &&
                                  getQualificationState.data.map(
                                    (item, index) => (
                                      <Option key={index} value={item._id}>
                                        {item.qualification}
                                      </Option>
                                    )
                                  )}
                              </Select>
                            </Form.Item>
                          </Col>
                        )}
                        {(userData.highest_education === 'diploma' ||
                          userData.highest_education === 'graduate' ||
                          userData.highest_education === 'post_graduate' ||
                          userData.highest_education === 'iti') && (
                          <>
                            <Col xs={24} sm={12} lg={8}>
                              <Form.Item
                                label='Specialization'
                                className='formLable'
                                name='specialization'>
                                <Select
                                  showSearch
                                  allowClear
                                  size='medium'
                                  className='removeBg'
                                  name='specialization'
                                  placeholder='Select One'
                                  onSearch={(e) => {
                                    if (e.length > 3) {
                                      const regex = new RegExp(e, 'i');
                                      const filtered =
                                        specializationList.filter((item) =>
                                          regex.test(item)
                                        );
                                      setFilteredSpecializationList(filtered);
                                    } else {
                                      setFilteredSpecializationList(
                                        specializationList
                                      );
                                    }
                                  }}
                                  onChange={(e) => {
                                    form.setFieldsValue({
                                      specialization: e,
                                    });
                                    setUserData({
                                      ...userData,
                                      specialization: e,
                                    });
                                  }}>
                                  {filteredSpecializationList.length > 0 &&
                                    filteredSpecializationList.map(
                                      (item, index) => (
                                        <Option key={index} value={item}>
                                          {item}
                                        </Option>
                                      )
                                    )}
                                </Select>
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={12} lg={8}>
                              <Form.Item
                                label='College Name'
                                className='formLable'
                                name='college_name'>
                                <Input
                                  size='medium'
                                  className='removeBg'
                                  placeholder='Enter College Name'
                                  onChange={(e) => {
                                    form.setFieldsValue({
                                      college_name: e.target.value,
                                    });
                                    setUserData({
                                      ...userData,
                                      college_name: e.target.value,
                                    });
                                  }}
                                />
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={12} lg={8}>
                              <Form.Item
                                label='Completion year (or expected)'
                                className='formLable'
                                name='completion_month_year'>
                                <DatePicker
                                  size='medium'
                                  className='removeBg'
                                  name='completion_month_year'
                                  placeholder='Month Year'
                                  picker='month'
                                  onChange={(e) => {
                                    form.setFieldsValue({
                                      completion_month_year: e,
                                    });
                                    setUserData({
                                      ...userData,
                                      completion_month_year:
                                        moment(e).format('YYYY-MM'),
                                    });
                                  }}
                                />
                              </Form.Item>
                            </Col>
                          </>
                        )}
                        {userData.highest_education === 'other' && (
                          <>
                            <Col xs={24} sm={12} lg={8}>
                              <Form.Item
                                label='Specialization'
                                className='formLable'
                                name='specialization'>
                                <Input
                                  showSearch
                                  allowClear
                                  size='medium'
                                  className='removeBg'
                                  name='specialization'
                                  placeholder='Enter Specialization'
                                  onChange={(e) => {
                                    form.setFieldsValue({
                                      specialization: e.target.value,
                                    });
                                    setUserData({
                                      ...userData,
                                      specialization: e.target.value,
                                    });
                                  }}
                                />
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={12} lg={8}>
                              <Form.Item
                                label='College Name'
                                className='formLable'
                                name='college_name'>
                                <Input
                                  size='medium'
                                  className='removeBg'
                                  placeholder='Enter College Name'
                                  onChange={(e) => {
                                    form.setFieldsValue({
                                      college_name: e.target.value,
                                    });
                                    setUserData({
                                      ...userData,
                                      college_name: e.target.value,
                                    });
                                  }}
                                />
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={12} lg={8}>
                              <Form.Item
                                label='Completion year (or expected)'
                                className='formLable'
                                name='completion_month_year'>
                                <DatePicker
                                  size='medium'
                                  className='removeBg'
                                  name='completion_month_year'
                                  placeholder='Month Year'
                                  picker='month'
                                  onChange={(e) => {
                                    form.setFieldsValue({
                                      completion_month_year: e,
                                    });
                                    setUserData({
                                      ...userData,
                                      completion_month_year:
                                        moment(e).format('YYYY-MM'),
                                    });
                                  }}
                                />
                              </Form.Item>
                            </Col>
                          </>
                        )}
                      </Row>
                    </Card>
                    <Card className='mt-4 custom-card-padding'>
                      <Row gutter={[30, 0]}>
                        <Col xs={24} sm={24} lg={24}>
                          <Title2 className='mb-3'>Work Experience</Title2>
                        </Col>
                        <Col xs={24}>
                          <Form.List
                            name='work_experience'
                            initialValues={
                              userData.work_experience
                                ? userData.work_experience
                                : [
                                    {
                                      company_name: '',
                                      designation: '',
                                      is_currently_working: false,
                                      from: '',
                                      to: '',
                                      roles_and_responsibilities: '',
                                      employment_type: '',
                                      location: '',
                                      industry: '',
                                    },
                                  ]
                            }>
                            {(fields, { add, remove }) => (
                              <>
                                {fields.map((field, index) => (
                                  <XCardInner
                                    key={index}
                                    title='Experience Details'
                                    extra={
                                      index > 0 || fields.length > 1 ? (
                                        <Button
                                          type='text'
                                          ghost
                                          onClick={() => {
                                            remove(field.name);
                                            const work_experience =
                                              userData.work_experience;
                                            work_experience.splice(
                                              field.name,
                                              1
                                            );
                                            setUserData({
                                              ...userData,
                                              work_experience,
                                            });
                                            form.setFieldsValue({
                                              work_experience,
                                            });
                                          }}
                                          style={{
                                            align: 'center',
                                          }}>
                                          <img
                                            src='/images/delete-icon.svg'
                                            alt=''
                                          />
                                        </Button>
                                      ) : (
                                        <></>
                                      )
                                    }>
                                    <Row gutter={[30, 0]}>
                                      <Col xs={24} sm={12} lg={8}>
                                        <Form.Item
                                          label='Current/Last Organization'
                                          className='formLable'
                                          name={[field.name, 'company_name']}>
                                          <Input
                                            size='medium'
                                            className='removeBg'
                                            placeholder='Enter Organization name'
                                            name={[field.name, 'company_name']}
                                            value={
                                              userData.work_experience[
                                                field.name
                                              ]?.company_name
                                            }
                                            onChange={(e) => {
                                              handleExperienceInputChange(
                                                'company_name',
                                                field.name,
                                                e.target.value
                                              );
                                            }}
                                          />
                                        </Form.Item>
                                      </Col>
                                      <Col xs={24} sm={12} lg={8}>
                                        <Form.Item
                                          label='Designation'
                                          className='formLable'
                                          name={[field.name, 'designation']}>
                                          <Input
                                            size='medium'
                                            className='removeBg'
                                            name={[field.name, 'designation']}
                                            placeholder='Enter Designation'
                                            value={
                                              userData.work_experience[
                                                field.name
                                              ]?.designation
                                            }
                                            onChange={(e) => {
                                              handleExperienceInputChange(
                                                'designation',
                                                field.name,
                                                e.target.value
                                              );
                                            }}
                                          />
                                        </Form.Item>
                                      </Col>
                                      <Col xs={24} sm={24} lg={24}>
                                        <Form.Item
                                          className='formLable'
                                          name={[
                                            field.name,
                                            'is_currently_working',
                                          ]}
                                          valuePropName='checked'>
                                          <XCheckbox
                                            name={[
                                              field.name,
                                              'is_currently_working',
                                            ]}
                                            onChange={(e) => {
                                              handleExperienceInputChange(
                                                'is_currently_working',
                                                field.name,
                                                e.target.checked
                                              );
                                            }}
                                            defaultChecked={
                                              userData.work_experience[
                                                field.name
                                              ]?.is_currently_working
                                                ? true
                                                : false
                                            }>
                                            <span
                                              style={{
                                                lineHeight: '24px',
                                              }}>
                                              I am currently working in this
                                              role
                                            </span>
                                          </XCheckbox>
                                        </Form.Item>
                                      </Col>
                                      <Col xs={24} sm={12} lg={8}>
                                        <Form.Item
                                          label='From'
                                          className='formLable'
                                          name={[field.name, 'from']}>
                                          <DatePicker
                                            size='medium'
                                            className='removeBg w-100'
                                            picker='month'
                                            name={[field.name, 'from']}
                                            onChange={(e) => {
                                              handleExperienceInputChange(
                                                'from',
                                                field.name,
                                                moment(e)
                                              );
                                            }}
                                            placeholder='Select Month Year'
                                          />
                                        </Form.Item>
                                      </Col>
                                      {form.getFieldValue('work_experience')[
                                        field.name
                                      ].is_currently_working === false && (
                                        <>
                                          <Col xs={24} sm={12} lg={8}>
                                            <Form.Item
                                              label='To'
                                              className='formLable'
                                              name={[field.name, 'to']}>
                                              <DatePicker
                                                size='medium'
                                                className='removeBg w-100'
                                                picker='month'
                                                name={[field.name, 'to']}
                                                placeholder='Select Month Year'
                                                onChange={(e) => {
                                                  handleExperienceInputChange(
                                                    'to',
                                                    field.name,
                                                    moment(e)
                                                  );
                                                }}
                                              />
                                            </Form.Item>
                                          </Col>
                                        </>
                                      )}
                                      <Col xs={24} sm={12} lg={8}>
                                        <Form.Item
                                          label='Roles and Responsibilities'
                                          className='formLable'
                                          name={[
                                            field.name,
                                            'roles_and_responsibilities',
                                          ]}>
                                          <TextArea
                                            rows={4}
                                            id={index}
                                            size='medium'
                                            className='removeBg'
                                            maxLength={400}
                                            name={[
                                              field.name,
                                              'roles_and_responsibilities',
                                            ]}
                                            placeholder='Enter Roles and Responsibilities'
                                            onChange={(e) => {
                                              handleExperienceInputChange(
                                                'roles_and_responsibilities',
                                                field.name,
                                                e.target.value
                                              );
                                            }}
                                          />
                                        </Form.Item>
                                      </Col>
                                      <Col xs={24} sm={12} lg={8}>
                                        <Form.Item
                                          label='Employment Type'
                                          className='formLable'
                                          name={[
                                            field.name,
                                            'employment_type',
                                          ]}>
                                          <Select
                                            size='medium'
                                            className='removeBg'
                                            name={[
                                              field.name,
                                              'employment_type',
                                            ]}
                                            placeholder='Select Employment type'
                                            onChange={(e) => {
                                              handleExperienceInputChange(
                                                'employment_type',
                                                field.name,
                                                e
                                              );
                                            }}>
                                            <Option value='part_time'>
                                              Part Time
                                            </Option>
                                            <Option value='full_time'>
                                              Full Time
                                            </Option>
                                            <Option value='contractual'>
                                              Contractual
                                            </Option>
                                          </Select>
                                        </Form.Item>
                                      </Col>
                                      <Col xs={24} sm={12} lg={8}>
                                        <Form.Item
                                          label='Location'
                                          className='formLable'
                                          name={[field.name, 'location']}>
                                          <Input
                                            size='medium'
                                            className='removeBg'
                                            name={[field.name, 'location']}
                                            placeholder='Enter Location'
                                            value={
                                              userData.work_experience[
                                                field.name
                                              ]?.location
                                            }
                                            onChange={(e) => {
                                              handleExperienceInputChange(
                                                'location',
                                                field.name,
                                                e.target.value
                                              );
                                            }}
                                          />
                                        </Form.Item>
                                      </Col>{' '}
                                      <Col xs={24} sm={24} lg={8}>
                                        <Form.Item
                                          label='Industry'
                                          className='formLable'
                                          name={[field.name, 'industry']}>
                                          <Select
                                            showSearch
                                            size='medium'
                                            className='removeBg'
                                            onFocus={() => {
                                              getIndustries({});
                                            }}
                                            name={[field.name, 'industry']}
                                            onSearch={(e) => {
                                              getIndustries({
                                                search: e,
                                              });
                                              if (e.length > 3) {
                                                getIndustries({
                                                  search: e,
                                                });
                                              } else if (e.length == 0) {
                                                getIndustries({});
                                              }
                                            }}
                                            onChange={(e) => {
                                              handleExperienceInputChange(
                                                'industry',
                                                field.name,
                                                e
                                              );
                                            }}
                                            placeholder='Select Industry'>
                                            {getIndustriesState.apiState ===
                                              'success' &&
                                              getIndustriesState.data
                                                .all_industries?.length > 0 &&
                                              getIndustriesState.data.all_industries.map(
                                                (item, index) => (
                                                  <Option
                                                    key={index}
                                                    value={item.industry_name}>
                                                    {item.industry_name}
                                                  </Option>
                                                )
                                              )}
                                          </Select>
                                        </Form.Item>
                                      </Col>
                                    </Row>
                                  </XCardInner>
                                ))}
                                <Col
                                  xs={24}
                                  sm={{
                                    span: 12,
                                    offset: 12,
                                  }}
                                  lg={{
                                    span: 6,
                                    offset: 18,
                                  }}
                                  xl={{
                                    span: 4,
                                    offset: 20,
                                  }}
                                  className='text-center'>
                                  <XButtonNonBg
                                    className='mb-4'
                                    onClick={() => {
                                      add();
                                      const work_experience =
                                        userData.work_experience;
                                      work_experience.push({
                                        company_name: '',
                                        designation: '',
                                        is_currently_working: false,
                                        from: '',
                                        to: '',
                                        roles_and_responsibilities: '',
                                        employment_type: '',
                                        location: '',
                                        industry: '',
                                      });
                                      setUserData({
                                        ...userData,
                                        work_experience,
                                      });
                                      form.setFieldsValue({
                                        work_experience,
                                      });
                                    }}>
                                    Add More
                                  </XButtonNonBg>
                                </Col>
                              </>
                            )}
                          </Form.List>
                        </Col>
                      </Row>
                    </Card>
                    <Card className='mt-4 custom-card-padding'>
                      <Row gutter={[30, 0]}>
                        <Col xs={24} sm={24} lg={24}>
                          <Title2 className='mb-3'>CTC Information</Title2>
                        </Col>
                        <Col xs={24} sm={24} lg={12}>
                          <Row gutter={[30, 0]} align='bottom'>
                            <Form.Item
                              // label="Current Salary"
                              className='formLable'
                              name='current_ctc_monthly'>
                              <Input
                                className='searchInputBrder removeBg'
                                size='large'
                                hidden
                                placeholder='Monthly salary'
                                maxLength={16}
                                onChange={(e) => {
                                  setUserData({
                                    ...userData,
                                    current_ctc_monthly:
                                      parseInt(e.target.value) || 0,
                                    current_ctc_yearly: parseInt(e.target.value)
                                      ? parseInt(e.target.value) * 12
                                      : 0,
                                  });
                                  form.setFieldsValue({
                                    current_ctc_monthly:
                                      parseInt(e.target.value) || 0,
                                    current_ctc_yearly: parseInt(e.target.value)
                                      ? parseInt(e.target.value) * 12
                                      : 0,
                                  });
                                }}
                              />
                            </Form.Item>
                            <Col xs={24} sm={12} lg={12}>
                              <Form.Item
                                className='formLable'
                                name='current_ctc_yearly'
                                label='Current Annual salary'>
                                <Input
                                  className='searchInputBrder removeBg'
                                  size='large'
                                  placeholder='Annual salary'
                                  maxLength={16}
                                  onChange={(e) => {
                                    setUserData({
                                      ...userData,
                                      current_ctc_monthly:
                                        e.target.value &&
                                        parseInt(e.target.value) > 0
                                          ? parseInt(e.target.value) / 12
                                          : 0,
                                      current_ctc_yearly:
                                        parseInt(e.target.value) || 0,
                                    });
                                    form.setFieldsValue({
                                      current_ctc_monthly:
                                        e.target.value &&
                                        parseInt(e.target.value) > 0
                                          ? parseInt(e.target.value) / 12
                                          : 0,
                                      current_ctc_yearly:
                                        parseInt(e.target.value) || 0,
                                    });
                                  }}
                                />
                              </Form.Item>
                            </Col>

                            <Form.Item
                              className='formLable'
                              name='expected_ctc_monthly'>
                              <Input
                                hidden
                                className='searchInputBrder removeBg'
                                size='large'
                                placeholder='Monthly salary'
                                maxLength={16}
                                onChange={(e) => {
                                  setUserData({
                                    ...userData,
                                    expected_ctc_monthly:
                                      parseInt(e.target.value) || 0,
                                    expected_ctc_yearly: parseInt(
                                      e.target.value
                                    )
                                      ? parseInt(e.target.value) * 12
                                      : 0,
                                  });
                                  form.setFieldsValue({
                                    expected_ctc_monthly:
                                      parseInt(e.target.value) || 0,
                                    expected_ctc_yearly: parseInt(
                                      e.target.value
                                    )
                                      ? parseInt(e.target.value) * 12
                                      : 0,
                                  });
                                }}
                              />
                            </Form.Item>
                            <Col xs={24} sm={12} lg={12}>
                              <Form.Item
                                className='formLable'
                                name='expected_ctc_yearly'
                                label='Expected Annual Salary'>
                                <Input
                                  className='searchInputBrder removeBg'
                                  size='large'
                                  placeholder='Yearly salary'
                                  maxLength={16}
                                  onChange={(e) => {
                                    setUserData({
                                      ...userData,
                                      expected_ctc_monthly:
                                        parseInt(e.target.value) > 0
                                          ? parseInt(e.target.value) / 12
                                          : 0,
                                      expected_ctc_yearly:
                                        parseInt(e.target.value) || 0,
                                    });
                                    form.setFieldsValue({
                                      expected_ctc_monthly:
                                        parseInt(e.target.value) > 0
                                          ? parseInt(e.target.value) / 12
                                          : 0,
                                      expected_ctc_yearly:
                                        parseInt(e.target.value) || 0,
                                    });
                                  }}
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                        </Col>
                        <Col xs={24} sm={12} lg={12}>
                          <Form.Item
                            label='Skills'
                            className='formLable'
                            name='skills'>
                            <Select
                              showSearch
                              allowClear
                              onFocus={() => {
                                getSkills({});
                              }}
                              size='large'
                              className='removeBg'
                              mode='multiple'
                              placeholder='Enter skills'
                              onSearch={(e) => {
                                if (e.length > 3) {
                                  getSkills({
                                    search: e,
                                  });
                                } else if (e.length === 0) getSkills({});
                              }}
                              filterOption={filterByLabel}
                              onChange={(e) => {
                                setUserData({
                                  ...userData,
                                  skills: e,
                                });
                                form.setFieldsValue({
                                  skills: e,
                                });
                              }}
                              options={
                                getSkillsState.apiState == 'success' &&
                                getSkillsState.data.length > 0
                                  ? getSkillsState.data.map((item) => {
                                      return {
                                        value: item._id,
                                        label: item.skill,
                                        type: 'skill',
                                        id: item._id,
                                      };
                                    })
                                  : []
                              }></Select>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Card>
                    <Card className='mt-4 custom-card-padding'>
                      <Row gutter={[30, 0]}>
                        <Col xs={24} sm={24} lg={24}>
                          <Title2 className='mb-3'>Cover Letter</Title2>
                        </Col>
                        <Col span={24} md={24}>
                          <Form.Item
                            name='cover_letter'
                            className='formLable'
                            style={{ marginBottom: 0 }}>
                            <TextArea
                              onKeyDown={handleKeyDown}
                              className='removeBg'
                              placeholder='Enter Cover Letter'
                              rows={4}
                              onChange={(e) => {
                                setUserData((prev) => ({
                                  ...prev,
                                  cover_letter: e.target.value,
                                }));
                              }}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Card>
                    <Card className='mt-4 custom-card-padding'>
                      <Row gutter={[30, 0]}>
                        <Col xs={24} sm={24} lg={24}>
                          <Title2 className='mb-3'>Update Resume</Title2>
                        </Col>
                        <Col xs={24} sm={24} lg={24} align='center'>
                          <Form.Item
                            className='formLable'
                            name='resume'
                            getValueFromEvent={normFile}
                            style={{ marginBottom: 0 }}>
                            <XUpload
                              className='p-0'
                              maxCount={1}
                              defaultFileList={
                                userData.resume
                                  ? [
                                      {
                                        uid: '-1', // a unique identifier for the file
                                        name: `resume_${userData.first_name}.pdf`, // display name for the file
                                        status: 'done', // status indicates the file is already uploaded
                                        url: userData.resume, // URL from API response for the resume
                                      },
                                    ]
                                  : []
                              }
                              onPreview={() =>
                                handleOnClick(
                                  userData.resume,
                                  userData.s3_folder_name
                                )
                              } // Trigger download on file preview click
                              beforeUpload={beforeResumeUpload}
                              accept='.pdf,.doc,.docx'
                              onRemove={(file) => {
                                const updatedDocuments =
                                  documentsToUpload.filter(
                                    (item) => item.fieldName !== 'resume'
                                  );
                                setDocumentsToUpload(updatedDocuments);
                              }}
                              customRequest={(options) => {
                                const existingIndex =
                                  documentsToUpload.findIndex(
                                    (item) => item.fieldName === 'resume'
                                  );

                                if (existingIndex !== -1) {
                                  // Update existing object
                                  const updatedDocuments = [
                                    ...documentsToUpload,
                                  ];
                                  updatedDocuments[existingIndex] = {
                                    ...options,
                                    fieldName: 'resume',
                                  };
                                  setDocumentsToUpload(updatedDocuments);
                                } else {
                                  // Add new object
                                  setDocumentsToUpload([
                                    ...documentsToUpload,
                                    {
                                      ...options,
                                      fieldName: 'resume',
                                    },
                                  ]);
                                }

                                options.onSuccess();
                              }}>
                              <p className='ant-upload-drag-icon'>
                                <img src='/images/upload-cloud.svg' alt='' />
                              </p>
                              <TitleUpload>
                                Browse
                                <span> your file</span>
                              </TitleUpload>
                              <p
                                className='para4'
                                style={{
                                  color: 'rgba(10, 16, 47, 0.70)',
                                }}>
                                Support Formats: PDF, Word
                              </p>
                            </XUpload>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Card>
                    <div className='bottomButtons'>
                      <Row gutter={[30, 0]} className='my-5 mb-lg-0'>
                        <Col
                          xs={24}
                          sm={{ span: 12, offset: 12 }}
                          lg={{ span: 6, offset: 18 }}
                          xl={{ span: 6, offset: 18 }}>
                          {loading ? (
                            <Row>
                              <Col xs={24}>
                                <XButton className='w-100' loading>
                                  Saving...
                                </XButton>
                              </Col>
                            </Row>
                          ) : (
                            <>
                              <Row gutter={[30, 0]} align='middle'>
                                <Col xs={24} lg={24}>
                                  <XButton htmlType='submit' className='w-100'>
                                    Save and Next
                                  </XButton>
                                </Col>
                              </Row>
                            </>
                          )}
                        </Col>
                      </Row>
                    </div>
                  </Form>
                </Col>
              ) : (
                <Col xs={24}>
                  <p>Loading Personal Information...</p>
                </Col>
              )}
            </Row>
          </XDesktopCard>
        </div>
      ) : (
        <Result
          status='404'
          title='404'
          subTitle='Sorry, You are not a candidate.'
          extra={
            <Button
              type='primary'
              onClick={() => {
                navigate('/');
              }}>
              Back Home
            </Button>
          }
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  getCandidateDetailsState: state.getCandidateDetails,
  savePersonalInformationState: state.savePersonalInformation,
  getStatesState: state.getStates,
  getCountriesState: state.getCountries,
  saveAddressDetailsState: state.saveAddressDetails,
  getIndustriesState: state.getIndustries,
  getIndustryCategoriesState: state.getIndustryCategories,
  getQualificationState: state.getQualification,
  saveEducationInformationState: state.saveEducationInformation,
  getSkillsState: state.getSkills,
});

const mapDispatchToProps = (dispatch) => ({
  getCandidateDetails: (params) => dispatch(getCandidateDetailsApi(params)),
  savePersonalInformation: (params) =>
    dispatch(savePersonalInformationApi(params)),
  savePersonalInformationReset: (params) =>
    dispatch(savePersonalInformationReset(params)),
  getStates: (params) => dispatch(getStatesApi(params)),
  getCountries: (params) => dispatch(getCountriesApi(params)),
  getIndustries: (params) => dispatch(getIndustriesApi(params)),
  getIndustryCategories: (params) => dispatch(getIndustryCategoriesApi(params)),
  getQualification: (params) => dispatch(getQualificationApi(params)),
  getSkills: (params) => dispatch(getSkillsApi(params)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PersonalInformation);
