import config from '../config';
import axios from 'axios';
import { getUserToken } from '../utils/helper';

export const GET_JOB_COUNT_BY_INDUSTRY = 'GET_JOB_COUNT_BY_INDUSTRY';
export const GET_JOB_COUNT_BY_INDUSTRY_SUCCESS =
  'GET_JOB_COUNT_BY_INDUSTRY_SUCCESS';
export const GET_JOB_COUNT_BY_INDUSTRY_FAIL = 'GET_JOB_COUNT_BY_INDUSTRY_FAIL';
export const GET_JOB_COUNT_BY_INDUSTRY_RESET =
  'GET_JOB_COUNT_BY_INDUSTRY_RESET';

export const getJobCountByIndustry = (params) => {
  return {
    type: GET_JOB_COUNT_BY_INDUSTRY,
    params,
  };
};

export const getJobCountByIndustrySuccess = (response) => {
  return {
    type: GET_JOB_COUNT_BY_INDUSTRY_SUCCESS,
    response,
  };
};

export const getJobCountByIndustryFail = (response) => {
  return {
    type: GET_JOB_COUNT_BY_INDUSTRY_FAIL,
    response,
  };
};

export const getJobCountByIndustryReset = () => {
  return {
    type: GET_JOB_COUNT_BY_INDUSTRY_RESET,
  };
};

export const getJobCountByIndustryApi = (params) => {
  return (dispatch) => {
    dispatch(getJobCountByIndustry());
    axios
      .get(`${config.api.base_url}/dashboard/job-count-by-industry`, {
        params: params,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${getUserToken()}`,
        },
      })
      .then((response) => {
        const getJobCountByIndustryData = response.data;
        dispatch(getJobCountByIndustrySuccess(getJobCountByIndustryData));
      })
      .catch((error) => {
        const errorData = error.response.data;
        dispatch(getJobCountByIndustryFail(errorData));
      });
  };
};
