import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Card, Typography } from 'antd';
import {
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
  Legend,
  Tooltip,
} from 'recharts';
import { getUserCountsApi } from '../../actions/getUserCountsAction';
import { connect } from 'react-redux';
const { Title } = Typography;

const COLORS = ['#0088FE', '#00C49F', '#FFBB28'];

const UserDistributionChart = (props) => {
  const { getUserCountsState, getUserCounts } = props;
  const [chartData, setChartData] = useState(null);
  const [totalUsers, setTotalUsers] = useState(null);

  console.log(chartData);
  // Fetch data from the API
  useEffect(() => {
    if (
      //   getUserCountsState &&
      getUserCountsState.apiState === 'success'
    ) {
      console.log(getUserCountsState, 'Statistics fetched successfully');
      // You can update the chart data here
      const data = getUserCountsState.data;
      setChartData([
        { name: 'Employers', value: data.employers },
        { name: 'Recruiters', value: data.recruiters },
        { name: 'Candidates', value: data.candidates },
      ]);
      setTotalUsers(data.totalUsers);
    } else if (getUserCountsState && getUserCountsState.apiState === 'error') {
      console.error('Failed to fetch statistics:', getUserCountsState.error);
    }
  }, [getUserCountsState]);

  useEffect(() => {
    getUserCounts();
  }, []);

  return (
    <StyledCard>
      <Title level={4}>User Distribution</Title>
      <ResponsiveContainer width='100%' height={300}>
        <PieChart>
          <Pie
            data={chartData && chartData}
            cx='50%'
            cy='50%'
            labelLine={false}
            outerRadius={80}
            fill='#8884d8'
            dataKey='value'>
            {chartData &&
              chartData.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
          </Pie>
          <Tooltip />
          <Legend />
        </PieChart>
      </ResponsiveContainer>
      <TotalUsers>Total Users: {totalUsers && totalUsers}</TotalUsers>
    </StyledCard>
  );
};

const StyledCard = styled(Card)`
  width: 48%;
  margin-bottom: 24px;

  @media (max-width: 1200px) {
    width: 100%;
  }
`;

const TotalUsers = styled.div`
  text-align: center;
  margin-top: 16px;
  font-weight: bold;
`;

const mapStateToProps = (state) => ({
  getUserCountsState: state.getUserCounts,
});

const mapDispatchToProps = (dispatch) => ({
  getUserCounts: (params) => dispatch(getUserCountsApi(params)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserDistributionChart);
