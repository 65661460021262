import axios from "axios";
import config from "../config";
import { getUserToken } from "../utils/helper";

export const GET_DEGREE_LIST = "GET_DEGREE_LIST";
export const GET_DEGREE_LIST_SUCCESS = "GET_DEGREE_LIST_SUCCESS";
export const GET_DEGREE_LIST_FAIL = "GET_DEGREE_LIST_FAIL";
export const GET_DEGREE_LIST_RESET = "GET_DEGREE_LIST_RESET";

export const getDegreeList = (params) => {
  return {
    type: GET_DEGREE_LIST,
    params,
  };
};

export const getDegreeListSuccess = (response) => {
  return {
    type: GET_DEGREE_LIST_SUCCESS,
    response,
  };
};

export const getDegreeListFail = (response) => {
  return {
    type: GET_DEGREE_LIST_FAIL,
    response,
  };
};

export const getDegreeListReset = () => {
  return {
    type: GET_DEGREE_LIST_RESET,
  };
};

export const getDegreeListApi = (params) => {
  return (dispatch) => {
    dispatch(getDegreeList());
    axios
      .get(`${config.api.base_url}/get-qualification`, {
        params,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getUserToken()}`,
        },
      })
      .then((response) => {
        const getDegreeListData = response.data;
        dispatch(getDegreeListSuccess(getDegreeListData));
      })
      .catch((error) => {
        const errorData = error.response.data;
        dispatch(getDegreeListFail(errorData));
      });
  };
};
