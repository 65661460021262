import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const RegistrationOptions = () => {
  const navigate = useNavigate();

  const registrationData = [
    {
      title: "Become a Employer",
      description:
        "Join our platform to find top talent for your organization. Create job listings, manage applications, and streamline your hiring process all in one place.",
      buttonText: "Register now",
      backgroundColor: "#015FCA",
      textColor: "#FFFFFF",
      descriptionColor: "#CEDCED",
      buttonColor: "#FFFFFF",
      buttonTextColor: "#275DF5",
      link: "/calendar",
    },
    {
      title: "Become a Candidate",
      description:
        "Start your journey towards a rewarding career. Explore job opportunities, apply for positions, and connect with top employers looking for talent like you.",
      backgroundColor: "#d3deff",
      buttonText: "Register now",
      descriptionColor: "rgba(99, 106, 128, 1)",
      buttonColor: "#FFFFFF",
      buttonTextColor: "#275DF5",
      link: "/sign-up",
      textColor: "#191f33",
    },
  ];

  return (
    <StyledRegistrationOptions>
      <OptionsWrapper>
        {registrationData.map((data, index) => (
          <StyledCard key={index} backgroundColor={data.backgroundColor}>
            <CardContent>
              <CardTitle textColor={data.textColor}>{data.title}</CardTitle>
              <CardDescription descriptioncolor={data.descriptionColor}>
                {data.description}
              </CardDescription>
            </CardContent>
            <RegisterButton
              buttonColor={data.buttonColor}
              buttonTextColor={data.buttonTextColor}
              onClick={() => navigate(data.link)}
              hoverColor={
                data.backgroundColor === "#015FCA" ? "#0052B3" : "#C2D1FF"
              }>
              <ButtonText>{data.buttonText}</ButtonText>
              <ButtonIcon
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/6c980235069caa3b73469f347b19d303fc58d7a82a9f38786a170fff6fe2537f?apiKey=e27c6d2dfca54bdabb20ac8325bd9dca&&apiKey=e27c6d2dfca54bdabb20ac8325bd9dca"
                alt=""
              />
            </RegisterButton>
          </StyledCard>
        ))}
      </OptionsWrapper>
    </StyledRegistrationOptions>
  );
};

const StyledRegistrationOptions = styled.section`
  background-color: #ffffff;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  font-family: Inter, sans-serif;
  padding: 25px 80px 49px;

  @media (max-width: 991px) {
    padding: 25px 20px 49px;
  }
`;

const OptionsWrapper = styled.div`
  display: flex;
  width: 100%;
  max-width: 932px;
  flex-direction: column;
`;

const StyledCard = styled.article`
  border-radius: 12px;
  background-color: ${(props) => props.backgroundColor};
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 50px;
  margin-top: 36px;
  @media (max-width: 991px) {
    padding: 30px 20px;
  }
`;

const CardContent = styled.div`
  display: flex;
  width: 100%;
  max-width: 548px;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: flex-start;
`;

const CardTitle = styled.h2`
  color: ${(props) => props.textColor};
  font-size: 32px;
  font-weight: 500;
  line-height: 1.2;
  margin: 0 0 16px 0;

  @media (max-width: 991px) {
    font-size: 28px;
  }
`;

const CardDescription = styled.p`
  color: ${(props) => props.descriptioncolor};
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin: 0;

  @media (max-width: 991px) {
    font-size: 13px;
  }
`;

const RegisterButton = styled.button`
  border-radius: 3px;
  background-color: ${(props) => props.buttonColor};
  display: flex;
  margin-top: 26px;
  width: 100%;
  max-width: 548px;
  align-items: center;
  gap: 12px;
  font-size: 16px;
  color: ${(props) => props.buttonTextColor};
  font-weight: 600;
  text-transform: capitalize;
  justify-content: center;
  padding: 12px 24px;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background-color: ${(props) => props.hoverColor};
    transform: translateY(-2px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }

  &:active {
    transform: translateY(0);
    box-shadow: none;
  }

  @media (max-width: 991px) {
    padding: 10px 20px;
    font-size: 14px;
  }
`;

const ButtonText = styled.span`
  flex: 1;
`;

const ButtonIcon = styled.img`
  width: 24px;
  height: 24px;
  object-fit: contain;
  transition: transform 0.3s ease;

  ${RegisterButton}:hover & {
    transform: translateX(4px);
  }
`;

export default RegistrationOptions;
