import {
  GET_JOB_COUNT_BY_INDUSTRY,
  GET_JOB_COUNT_BY_INDUSTRY_SUCCESS,
  GET_JOB_COUNT_BY_INDUSTRY_FAIL,
  GET_JOB_COUNT_BY_INDUSTRY_RESET,
} from '../actions/getJobCountByIndustryAction';

const initialState = {
  apiState: '',
  data: null,
  error: '',
};

const getJonCountByIndustryReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_JOB_COUNT_BY_INDUSTRY:
      return {
        ...state,
        apiState: 'loading',
      };
    case GET_JOB_COUNT_BY_INDUSTRY_SUCCESS:
      return {
        ...state,
        apiState: 'success',
        data: action.response.data,
        message: action.response.message ? action.response.message : 'success',
      };
    case GET_JOB_COUNT_BY_INDUSTRY_FAIL:
      return {
        ...state,
        apiState: 'error',
        error: action.response.message ? action.response.message : 'error',
      };
    case GET_JOB_COUNT_BY_INDUSTRY_RESET:
      return initialState;
    default:
      return state;
  }
};

export default getJonCountByIndustryReducer;
