import axios from "axios";
import config from "../config";
import { getUserToken } from "../utils/helper";

export const GET_CONFIGURATION_BY_ID = "GET_CONFIGURATION_BY_ID";
export const GET_CONFIGURATION_BY_ID_SUCCESS =
  "GET_CONFIGURATION_BY_ID_SUCCESS";
export const GET_CONFIGURATION_BY_ID_FAIL = "GET_CONFIGURATION_BY_ID_FAIL";
export const GET_CONFIGURATION_BY_ID_RESET = "GET_CONFIGURATION_BY_ID_RESET";

export const getConfigurationById = (params) => {
  return {
    type: GET_CONFIGURATION_BY_ID,
    params,
  };
};

export const getConfigurationByIdSuccess = (response) => {
  return {
    type: GET_CONFIGURATION_BY_ID_SUCCESS,
    response,
  };
};

export const getConfigurationByIdFail = (response) => {
  return {
    type: GET_CONFIGURATION_BY_ID_FAIL,
    response,
  };
};

export const getConfigurationByIdReset = () => {
  return {
    type: GET_CONFIGURATION_BY_ID_RESET,
  };
};

export const getConfigurationByIdApi = (data) => {
  return (dispatch) => {
    dispatch(getConfigurationById());
    axios
      .get(`${config.api.base_url}/configurations/${data.id}`, {
        headers: {
          Authorization: "Bearer " + getUserToken(),
        },
      })
      .then((response) => {
        const getConfigurationByIdData = response.data;
        dispatch(getConfigurationByIdSuccess(getConfigurationByIdData));
      })
      .catch((error) => {
        const errorMsg = error.message;
        dispatch(getConfigurationByIdFail(errorMsg));
      });
  };
};
