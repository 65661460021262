import React, { useEffect, useState } from "react";
import styled from "styled-components";
import SearchSection from "../../components/SearchSection/index";
import {
  Button,
  DatePicker,
  Dropdown,
  notification,
  Pagination,
  Result,
  Select,
  Slider,
} from "antd";
import {
  checkModulePermissions,
  fetchListThroughPagination,
  hasPermission,
} from "../../utils/helper";
import { useForm } from "antd/es/form/Form";
import {
  getCandidateListApi,
  getCandidateListReset,
} from "../../actions/getCandidateListAction";
import { connect } from "react-redux";
import CandidateApplicationCards from "../../components/CandidateApplicationCards";
import { useNavigate, useParams } from "react-router-dom";
import { XForm } from "../Login/LoginStyle";
import CaseSearch from "../../components/CaseSearch/CaseSearch";
import {
  application_status_list,
  employment_type_list,
  jobFilters,
} from "../../utils/constants";
import { filterByLabel } from "../../utils/formFunctions";
import { XSelect } from "../../styles/GlobalStyle";
import { getSkillsApi } from "../../actions/getSkillsAction";
import {
  updateJobApplicationStatusApi,
  updateJobApplicationStatusReset,
} from "../../actions/updateJobApplicationStatusAction";
import { getIndustryCategoriesApi } from "../../actions/getIndustryCategoriesAction";
import { getIndustriesApi } from "../../actions/getIndustriesAction";
import { getQualificationApi } from "../../actions/getQualificationAction";
const { Option } = Select;

const Application = (props) => {
  const defaultFilters = {
    page: 1,
    limit: 10,
    state: "",
    category: "",
    joined_on: "all",
    sortDirection: null,
    candidate_search: "",
    application_status: "",
    skills: [],
  };
  const id = useParams().id;
  const navigate = useNavigate();

  const {
    getCandidateList,
    getCandidateListState,
    getCandidateListReset,
    getSkills,
    getSkillsState,
    updateJobApplicationStatusState,
    updateJobApplicationStatus,
    updateJobApplicationStatusReset,
    getIndustryCategoriesState,
    getIndustryCategories,
    getIndustriesState,
    getIndustries,
    getQualificationState,
    getQualification,
  } = props;

  const [form] = useForm();
  const [selectedCandidate, setSelectedCandidate] = useState(null);
  // const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const getFiltersFromUrl = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const urlFilters = {};

    // Iterate through each query parameter and set it in the state
    for (const [key, value] of urlParams.entries()) {
      urlFilters[key] = value;
    }

    // Merge with default filters or initial state as needed
    const filters = {
      ...defaultFilters, // Replace with your default filters
      ...urlFilters,
    };

    return filters;
  };
  const initialFilters = getFiltersFromUrl();
  const [filters, setFilters] = useState(initialFilters);

  const candidatesPerPage = 10;

  const indexOfLastCandidate = filters.page * candidatesPerPage;
  const indexOfFirstandidate = indexOfLastCandidate - candidatesPerPage;
  const [totalRecords, setTotalRecords] = useState("");
  const [open, setOpen] = useState(false);
  const [currentCandidate, setCurrentCandidate] = useState(null);

  const handlePageChange = (page) => {
    fetchListThroughPagination(page, setFilters, filters);
    setFilters((prev) => ({ ...prev, page: page }));
  };

  const handleCandidateClick = (candidate_id) => {
    setSelectedCandidate(candidate_id);
    navigate(`/candidate-details/${candidate_id}`);
  };

  const applyFilters = () => {
    const queryParams = new URLSearchParams(window.location.search);

    // Iterate through filters and update the query parameters
    Object.entries(filters).forEach(([key, value]) => {
      const currentValue = queryParams.get(key);

      // Check if the parameter exists in the current query and has a value, if yes, update its value
      if (currentValue !== null) {
        queryParams.set(key, value);
      } else if (queryParams.has(key)) {
        // If the parameter exists but has no value, keep its existing value
        queryParams.set(key, queryParams.get(key));
      } else {
        // If the parameter does not exist in the current query, append it with the current value
        queryParams.append(key, value);
      }
    });

    // Set the page to 1
    queryParams.set("page", "1");
    queryParams.set("sort", "createdAt");
    filters.page = 1;

    // Update the URL
    const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
    window.history.pushState({}, "", newUrl);
  };

  const clearFilters = () => {
    const queryParams = new URLSearchParams(window.location.search);

    // Iterate through defaultFilters and update the query parameters
    Object.entries(defaultFilters).forEach(([key, value]) => {
      const currentValue = queryParams.get(key);

      // Check if the parameter exists in the current query and has a value, if yes, update its value
      if (currentValue !== null) {
        queryParams.set(key, value);
      } else if (queryParams.has(key)) {
        // If the parameter exists but has no value, keep its existing value
        queryParams.set(key, queryParams.get(key));
      } else {
        // If the parameter does not exist in the current query, append it with the default value
        queryParams.append(key, value);
      }
    });

    // Clear the form fields
    form.resetFields();

    // Update the state with default values
    setFilters(defaultFilters);

    // Update the URL
    const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
    window.history.pushState({}, "", newUrl);
  };

  const showDrawer = () => {
    getIndustries({});
    getQualification({});
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  const handleSortChange = (value, direction) => {
    setFilters({
      ...filters,
      sortBy: value,
      sortDirection: direction,
    });
  };
  const handleStatusUpdate = (status, id) => {
    updateJobApplicationStatus({
      application_id: id,
      status,
      // rejectReason,
    });
  };

  const handleCategorySearch = (search) => {
    if (search.length >= 3) {
      getIndustryCategories({
        search: search,
        industries: filters.industry ? [filters.industry] : null,
      });
    } else if (search.length == 0) {
      getIndustryCategories({
        industries: [filters.industry],
      });
    }
  };

  useEffect(() => {
    // Retrieve filters from the updated URL
    const updatedFilters = getFiltersFromUrl();
    setFilters({ ...updatedFilters });

    // Make API call with updated filters and pagination
    if (hasPermission("candidates", "list-candidates")) {
      getCandidateList({ ...updatedFilters, job_inventory_id: id });
    }
  }, [window.location.search]);

  useEffect(() => {
    if (getCandidateListState.apiState === "success") {
      setTotalRecords(getCandidateListState.data.total_count);
      setSelectedCandidate(getCandidateListState.data.candidates[0]);
      if (getCandidateListState.data.candidates.length === 1) {
        setCurrentCandidate(getCandidateListState.data.candidates); // Return the whole list with one job
      } else {
        setCurrentCandidate(
          getCandidateListState.data.candidates.slice(
            indexOfFirstandidate,
            Math.min(
              indexOfLastCandidate,
              getCandidateListState.data.candidates.length
            )
          )
        );
      }
      getCandidateListReset();
    } else if (getCandidateListState.apiState === "error") {
      console.log("API Error=>", getCandidateListState.message);
      notification.error({
        message: "Unable to get Candidate List",
      });
      getCandidateListReset();
    }
  }, [getCandidateListState.apiState]);

  return (
    <>
      {hasPermission("candidates", "list-candidates") ? (
        <>
          <CaseSearch
            searchOption="reseller"
            title="Resellers"
            filters={[
              <XForm.Item
                name="industry"
                colon={false}
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
                label="Industry"
                className="removeFormMargin">
                <XSelect
                  showSearch
                  size="large"
                  className="removeBg"
                  onFocus={() => getIndustries({})}
                  defaultValue={filters.industry || null}
                  filterOption={filterByLabel}
                  onSearch={(search) => {
                    if (search.length >= 3) {
                      getIndustries({ search });
                    } else {
                      getIndustries({});
                    }
                  }}
                  onChange={(value) => {
                    setFilters({
                      ...filters,
                      industry: value,
                    });
                    getIndustryCategories({ industries: [value] });
                    form.setFieldsValue({ category: null });
                  }}
                  onClear={() => {
                    setFilters({
                      ...filters,
                      industry: "",
                    });
                  }}
                  options={
                    getIndustriesState.apiState === "success" &&
                    getIndustriesState.data.all_industries.length > 0 &&
                    getIndustriesState.data.all_industries.map((ind) => {
                      return {
                        value: ind._id,
                        label: ind.industry_name,
                        type: "industry",
                      };
                    })
                  }
                  placeholder="Select Industry"
                />
              </XForm.Item>,
              <XForm.Item
                colon={false}
                name="category"
                label="Category"
                className="selectBox">
                <XSelect
                  name="category"
                  size="large"
                  placeholder="Search Functions"
                  className="removeShadow ml-2"
                  showSearch
                  allowClear={true}
                  defaultValue={filters.category || null}
                  onSearch={handleCategorySearch}
                  onChange={(category) => {
                    setFilters({
                      ...filters,
                      category,
                    });
                  }}
                  filterOption={filterByLabel}
                  options={
                    getIndustryCategoriesState.apiState === "success" &&
                    getIndustryCategoriesState.data.all_categories.length > 0
                      ? getIndustryCategoriesState.data.all_categories.map(
                          (item) => {
                            return {
                              value: item.id,
                              label: item.category_name,
                            };
                          }
                        )
                      : []
                  }></XSelect>
              </XForm.Item>,
              <XForm.Item colon={false} label="Skills" name="skills">
                <XSelect
                  showSearch
                  allowClear
                  onFocus={() => {
                    getSkills({});
                  }}
                  size="large"
                  className="removeBg"
                  mode="multiple"
                  placeholder="Select skills"
                  filterOption={filterByLabel}
                  defaultValue={filters.skills || null}
                  onChange={(value) => {
                    setFilters({
                      ...filters,
                      skills: value,
                    });
                  }}
                  onClear={() => {
                    setFilters({
                      ...filters,
                      skills: "",
                    });
                  }}
                  options={
                    getSkillsState.apiState == "success" &&
                    getSkillsState.data.length > 0
                      ? getSkillsState.data.map((item) => {
                          return {
                            value: item._id,
                            label: item.skill,
                            type: "skill",
                            id: item._id,
                          };
                        })
                      : []
                  }></XSelect>
              </XForm.Item>,
              <XForm.Item
                colon={false}
                name="education_qualification"
                label="Qualification">
                <XSelect
                  showSearch
                  onFocus={() => {
                    getQualification({});
                  }}
                  onSearch={(e) => {
                    getQualification({
                      search: e,
                    });
                  }}
                  size="medium"
                  className="removeBg"
                  name="qualification"
                  placeholder="Select One"
                  onChange={(e) =>
                    setFilters((prev) => ({
                      ...prev,
                      education_qualification: e,
                    }))
                  }>
                  {getQualificationState.apiState === "success" &&
                    getQualificationState.data.length > 0 &&
                    getQualificationState.data.map((item, index) => (
                      <Option key={index} value={item._id}>
                        {item.qualification}
                      </Option>
                    ))}
                </XSelect>
              </XForm.Item>,
              <XForm.Item name="salary" label="Salary" className="selectBox">
                <Slider
                  range
                  min={10000}
                  max={10000000}
                  step={10000}
                  defaultValue={[
                    filters.salary_min || 2000000,
                    filters.salary_max || 5000000,
                  ]}
                  onChange={(value) => {
                    setFilters({
                      ...filters,
                      salary_min: value[0],
                      salary_max: value[1],
                    });
                  }}
                />
              </XForm.Item>,
              <XForm.Item
                colon={false}
                label="Employment Type"
                className="formLable"
                name="employment_type">
                <XSelect
                  size="large"
                  placeholder="Employment Type"
                  className="removeBg"
                  onChange={(value) => {
                    setFilters({
                      ...filters,
                      employment_type: value,
                    });
                  }}
                  onClear={() => {
                    setFilters({
                      ...filters,
                      employment_type: "",
                    });
                  }}
                  defaultValue={filters.employment_type || null}
                  options={employment_type_list}
                />
              </XForm.Item>,
              <XForm.Item
                colon={false}
                name="sortBy"
                label="Sort By"
                className="selectBox">
                <Dropdown
                  className="removeShadow w-100  ms-0"
                  trigger={["click"]}
                  menu={{
                    items: jobFilters(handleSortChange), // Pass the dynamic function here
                  }}>
                  <Button
                    className="shortBtn"
                    style={{
                      border: "none",
                      padding: 10,
                      height: "40px",
                      width: "110px",
                      backgroundColor: "#ffffff",
                      border: "1px solid #d9d9d9",
                      color: "#000",
                      display: "flex",
                      flexDirection: "row",
                    }}>
                    <p>Sort</p>
                    <img className="mt-1" src="/images/short-icon.svg" alt="" />
                  </Button>
                </Dropdown>
              </XForm.Item>,
            ]}
            onClose={onClose}
            open={open}
            applyFilters={applyFilters}
            form={form}
            clearFilters={clearFilters}
          />
          <SearchSection
            application={true}
            setFilters={setFilters}
            filters={filters}
          />
          <PageContainer>
            <Header
              className="align-items-center flex-wrap"
              style={{
                margin: "10px 0px",
              }}>
              <JobCount>{totalRecords} Candidates</JobCount>
              <FilterContainer onClick={() => showDrawer()}>
                <FilterButton>
                  <img
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/d0e11f66e694b5fbdadd8edb8109ba7b24c06cb1f66c477f282ec451dfd237e7?placeholderIfAbsent=true&apiKey=ac9769c5e7eb416e8a74975078a910d6"
                    alt="Filter icon"
                  />
                  Filter
                </FilterButton>
              </FilterContainer>
            </Header>
            <ContentContainer>
              <JobListContainer>
                {currentCandidate &&
                  currentCandidate.length > 0 &&
                  currentCandidate.map((candidate) => (
                    <CandidateApplicationCards
                      key={candidate._id}
                      {...candidate}
                      isSelected={candidate._id === selectedCandidate._id}
                      onClick={() => handleCandidateClick(candidate._id)}
                      handleStatusUpdate={handleStatusUpdate}
                    />
                  ))}
              </JobListContainer>
            </ContentContainer>
            <PaginationContainer>
              <StyledPagination
                current={filters.page || 1}
                total={totalRecords || 0}
                pageSize={candidatesPerPage}
                onChange={handlePageChange}
                showSizeChanger={false}
              />
            </PaginationContainer>
          </PageContainer>
        </>
      ) : (
        <Result
          status="403"
          title="403"
          subTitle="Sorry, you are not authorized to access this page."
          extra={<Button type="primary">Back Home</Button>}
        />
      )}{" "}
    </>
  );
};

const StyledPagination = styled(Pagination)`
  margin-top: 40px;

  .ant-pagination-item {
    border-radius: 50%;
  }

  .ant-pagination-item-active {
    background-color: #275df5;
    border-color: #275df5;
  }

  .ant-pagination-item-active a {
    color: #fff;
  }

  .ant-pagination-prev,
  .ant-pagination-next {
    .ant-pagination-item-link {
      border-radius: 50%;
    }
  }
`;

const PaginationContainer = styled.nav`
  align-self: center;
  justify-content: center;
  display: flex;
  margin-top: 40px;
  align-items: center;
  gap: 8px;
`;

const PageContainer = styled.div`
  max-width: 1150px;
  margin: 0 auto;
  padding: 40px 20px;
`;

const Header = styled.header`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
  row-gap: 15px;
`;

const JobCount = styled.h2`
  font-size: 32px;
  font-weight: 700;
  color: #141414;
`;

const FilterContainer = styled.div`
  display: flex;
  gap: 16px;
  @media (max-width: 480px) {
    width: 100%;
    justify-content: center;
  }
`;

const FilterButton = styled.button`
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: #fff;
  border: 1px solid rgba(20, 20, 20, 0.1);
  border-radius: 4px;
  padding: 12px 16px;
  font-size: 16px;
  color: #141414;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background-color: #f5f5f5;
  }

  img {
    width: 24px;
    height: 24px;
  }
  @media (max-width: 480px) {
    width: 100%;
    justify-content: center;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  gap: 40px;

  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;

const JobListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  max-width: 100%;

  @media (max-width: 1024px) {
    max-width: 100%;
  }
`;

const mapStateToProps = (state) => ({
  getCandidateListState: state.getCandidateList,
  getSkillsState: state.getSkills,
  updateJobApplicationStatusState: state.updateJobApplicationStatus,
  getIndustryCategoriesState: state.getIndustryCategories,
  getIndustriesState: state.getIndustries,
  getQualificationState: state.getQualification,
});

const mapDispatchToProps = (dispatch) => ({
  getCandidateList: (params) => dispatch(getCandidateListApi(params)),
  getSkills: (params) => dispatch(getSkillsApi(params)),
  updateJobApplicationStatus: (params) =>
    dispatch(updateJobApplicationStatusApi(params)),
  updateJobApplicationStatusReset: (params) =>
    dispatch(updateJobApplicationStatusReset(params)),
  getCandidateListReset: (params) => dispatch(getCandidateListReset()),
  getIndustryCategories: (params) => dispatch(getIndustryCategoriesApi(params)),
  getIndustries: (params) => dispatch(getIndustriesApi(params)),
  getQualification: (params) => dispatch(getQualificationApi(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Application);
