import axios from "axios";
import config from "../config";
import { getUserToken } from "../utils/helper";

export const DELETE_USER = "DELETE_USER";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_FAIL = "DELETE_USER_FAIL";
export const DELETE_USER_RESET = "DELETE_USER_RESET";

export const deleteUser = (params) => {
  return {
    type: DELETE_USER,
    params,
  };
};

export const deleteUserSuccess = (response) => {
  return {
    type: DELETE_USER_SUCCESS,
    response,
  };
};

export const deleteUserFail = (response) => {
  return {
    type: DELETE_USER_FAIL,
    response,
  };
};

export const deleteUserReset = () => {
  return {
    type: DELETE_USER_RESET,
  };
};

export const deleteUserApi = (data) => {
  return (dispatch) => {
    dispatch(deleteUser());
    axios
      .post(`${config.api.base_url}/settings/delete-user`, data, {
        headers: {
          Authorization: "Bearer " + getUserToken(),
        },
      })
      .then((response) => {
        const deleteUserData = response.data;
        dispatch(deleteUserSuccess(deleteUserData));
      })
      .catch((error) => {
        const errorMsg = error.message;
        dispatch(deleteUserFail(errorMsg));
      });
  };
};
