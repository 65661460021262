import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Card, Typography, Space, Dropdown, Menu } from 'antd';
import { MoreOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { getJobStatisticsApi } from '../../actions/getJobStatisticsAction';

const { Title, Text } = Typography;

const JobStatusCards = (props) => {
  const { getJobStatisticsState, getJobStatistics } = props;

  const [jobApplicationData, setJobApplicationData] = useState([]);

  useEffect(() => {
    if (
      //   getJobStatisticsState &&
      getJobStatisticsState.apiState === 'success'
    ) {
      console.log(getJobStatisticsState, 'Statistics fetched successfully');
      // You can update the chart data here
      setJobApplicationData(getJobStatisticsState.data);
    } else if (
      getJobStatisticsState &&
      getJobStatisticsState.apiState === 'error'
    ) {
      console.error('Failed to fetch statistics:', getJobStatisticsState.error);
    }
  }, [getJobStatisticsState]);

  useEffect(() => {
    getJobStatistics();
  }, []);

  const getStatusColor = (status) => {
    const colors = {
      rejected: '#FFE7EC',
      shortlisted: '#FFF2E4',
      applied: '#FFE8FA',
      hired: '#E4FDFF',
      rejected_by_employer: '#FFE7EC',
      interview_scheduled: '#E1FBFF',
      application_in_review: '#ECE7FF',
      on_hold: '#FEFFE5',
    };
    return colors[status] || '#F6F9FF';
  };

  const getStatusTitle = (status) => {
    const titles = {
      rejected: 'Rejected',
      shortlisted: 'Shortlisted',
      applied: 'Applied',
      hired: 'Hired',
      rejected_by_employer: 'Rejected by Employer',
      interview_scheduled: 'Interview Scheduled',
      application_in_review: 'In Review',
      on_hold: 'On Hold',
    };
    return titles[status] || status;
  };

  const menu = (
    <Menu>
      <Menu.Item key='1'>Monthly</Menu.Item>
      <Menu.Item key='2'>Yearly</Menu.Item>
      <Menu.Item key='3'>Custom Range</Menu.Item>
    </Menu>
  );

  return (
    <CardContainer>
      {jobApplicationData &&
        jobApplicationData.map((status, index) => (
          <StyledCard key={index} $color={getStatusColor(status.status)}>
            <CardHeader>
              <Space direction='vertical' size={4}>
                <StyledTitle level={4}>
                  {getStatusTitle(status.status)}
                </StyledTitle>
                <Text type='secondary'>(This Month)</Text>
              </Space>
              {/* <Dropdown overlay={menu} trigger={['click']}>
                <MoreOutlined
                  style={{
                    fontSize: '24px',
                    color: '#1c1c1e',
                    cursor: 'pointer',
                  }}
                />
              </Dropdown> */}
            </CardHeader>
            <StatusCount>{status.count}</StatusCount>
          </StyledCard>
        ))}
    </CardContainer>
  );
};

const CardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  width: 100%;
  justify-content: center;
  margin-bottom: 40px;
`;

const StyledCard = styled(Card)`
  flex: 1;
  min-width: 240px;
  border-radius: 16px;
  background-color: ${(props) => props.$color};
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  .ant-card-body {
    padding: 24px;
  }

  @media (max-width: 576px) {
    min-width: 100%;
  }
`;

const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

const StyledTitle = styled(Title)`
  && {
    color: #1c1c1e;
    margin-bottom: 0;
  }
`;

const StatusCount = styled.div`
  color: #1c1c1e;
  font-size: 40px;
  font-weight: 700;
  line-height: 1.2;
  margin-top: 16px;
`;

const mapStateToProps = (state) => ({
  getJobStatisticsState: state.getJobStatistics,
});

const mapDispatchToProps = (dispatch) => ({
  getJobStatistics: (params) => dispatch(getJobStatisticsApi(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(JobStatusCards);
