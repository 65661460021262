import axios from "axios";
import config from "../config";
import { getUserToken } from "../utils/helper";

export const GET_CANDIDATE_DETAILS = "GET_CANDIDATE_DETAILS";
export const GET_CANDIDATE_DETAILS_SUCCESS = "GET_CANDIDATE_DETAILS_SUCCESS";
export const GET_CANDIDATE_DETAILS_FAIL = "GET_CANDIDATE_DETAILS_FAIL";
export const GET_CANDIDATE_DETAILS_RESET = "GET_CANDIDATE_DETAILS_RESET";

export const getCandidateDetails = (params) => {
  return {
    type: GET_CANDIDATE_DETAILS,
    params,
  };
};

export const getCandidateDetailsSuccess = (response) => {
  return {
    type: GET_CANDIDATE_DETAILS_SUCCESS,
    response,
  };
};

export const getCandidateDetailsFail = (response) => {
  return {
    type: GET_CANDIDATE_DETAILS_FAIL,
    response,
  };
};

export const getCandidateDetailsReset = () => {
  return {
    type: GET_CANDIDATE_DETAILS_RESET,
  };
};

export const getCandidateDetailsApi = (params) => {
  return (dispatch) => {
    dispatch(getCandidateDetails());
    axios
      .get(`${config.api.base_url}/candidate/get-candidate-details`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + getUserToken(),
        },
        params,
      })
      .then((response) => {
        const getCandidateDetailsData = response.data;
        dispatch(getCandidateDetailsSuccess(getCandidateDetailsData));
      })
      .catch((error) => {
        const errorData = error.response.data;
        dispatch(getCandidateDetailsFail(errorData));
      });
  };
};
