import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { signUpApi, signUpReset } from "../../actions/signUpAction";
import { handleInputChange } from "../../utils/formFunctions";
import { getExpiresAt, isLogin } from "../../utils/helper";
import styled from "styled-components";
import { notification, Form, Input, Button as AntButton } from "antd";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";

const SignUp = (props) => {
  const { signUp, signUpReset, signUpState } = props;
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isLogin()) {
      let expiresAt = getExpiresAt();
      if (expiresAt !== null && new Date() < new Date(expiresAt)) navigate("/");
    }
  }, []);

  useEffect(() => {
    if (signUpState.apiState === "success") {
      setLoading(false);
      // localStorage.setItem('accessToken', signUpState.data.accessToken);
      // localStorage.setItem('refreshToken', signUpState.data.refreshToken);
      // localStorage.setItem('username', signUpState.data.username);
      // localStorage.setItem('email', signUpState.data.email);
      // localStorage.setItem('profile', signUpState.data.profile_image);
      // localStorage.setItem('expiresAt', signUpState.data.expiresAt);
      // localStorage.setItem('role', signUpState.data.role.role);
      // localStorage.setItem(
      //   'org_logo',
      //   signUpState.data.organization_logo || ''
      // );
      // localStorage.setItem('changePassword', signUpState.data.changePassword);
      // localStorage.setItem(
      //   'permissions',
      //   JSON.stringify(signUpState.data.permissions)
      // );
      signUpReset();
      navigate("/login");
    } else if (signUpState.apiState === "error") {
      setLoading(false);
      notification.error({ message: signUpState.message });
      signUpReset();
    }
    setLoading(false);
  }, [signUpState]);

  return (
    <LoginContainer>
      <LoginWrapper>
        <BackgroundImage
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/27fb07fc02cff20cc788735d53684dd469e0f9b6e67090771ba6c797883cc595?placeholderIfAbsent=true&apiKey=ac9769c5e7eb416e8a74975078a910d6"
          alt="Background"
        />
        <LoginForm
          form={form}
          name="login"
          onFinish={() => {
            setLoading(true);
            signUp(formData);
          }}>
          <LoginContent>
            <LoginHeader>Welcome to Skillsprints Job Platform</LoginHeader>
            <LoginFormWrapper>
              <LoginFormContent>
                <LoginOptions>
                  <Divider />
                  <OrSignInText>Sign Up</OrSignInText>
                  <Divider />
                </LoginOptions>
                <InputFieldsWrapper className="mb-4 pb-3">
                  <InputField>
                    <Label>Full Name</Label>
                    <StyledFormItem
                      name="name"
                      rules={[
                        { required: true, message: "Please enter full name" },
                      ]}>
                      <StyledInput
                        name="name"
                        onChange={(e) =>
                          handleInputChange(e, formData, setFormData)
                        }
                        aria-label="Name"
                      />
                    </StyledFormItem>
                  </InputField>
                  <InputField>
                    <Label>Mobile Number</Label>
                    <StyledFormItem
                      name="mobile"
                      rules={[
                        {
                          required: true,
                          message: "Please enter mobile number",
                        },
                      ]}>
                      <StyledInput
                        name="mobile"
                        onChange={(e) =>
                          handleInputChange(e, formData, setFormData)
                        }
                        aria-label="Mobile"
                      />
                    </StyledFormItem>
                  </InputField>
                  <InputField>
                    <Label>Email</Label>
                    <StyledFormItem
                      name="email"
                      rules={[
                        { required: true, message: "Please input your Email!" },
                        {
                          type: "email",
                          message: "The input is not valid E-mail!",
                        },
                      ]}>
                      <StyledInput
                        name="email"
                        onChange={(e) =>
                          handleInputChange(e, formData, setFormData)
                        }
                        aria-label="Email"
                      />
                    </StyledFormItem>
                  </InputField>
                  <InputField>
                    <Label>Password</Label>
                    <StyledFormItem
                      name="password"
                      rules={[
                        {
                          required: true,
                          message: "Please input your Password!",
                        },
                        {
                          min: 8,
                          message:
                            "Password must be at least 8 characters long!",
                        },
                        {
                          pattern:
                            /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/,
                          message:
                            "Password must include at least one letter, one number, and one special character!",
                        },
                      ]}>
                      <StyledPassword
                        name="password"
                        onChange={(e) =>
                          handleInputChange(e, formData, setFormData)
                        }
                        iconRender={(visible) =>
                          visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                        }
                        aria-label="Password"
                      />
                    </StyledFormItem>
                  </InputField>
                  <InputField>
                    <Label>Confirm Password</Label>
                    <StyledFormItem
                      dependencies={["password"]}
                      name="confirm_password"
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: "Please confirm your new password",
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (!value || getFieldValue("password") === value) {
                              return Promise.resolve();
                            }
                            return Promise.reject(
                              new Error(
                                "The two passwords that you entered do not match!"
                              )
                            );
                          },
                        }),
                      ]}>
                      <StyledPassword
                        name="confirm_password"
                        onChange={(e) =>
                          handleInputChange(e, formData, setFormData)
                        }
                        iconRender={(visible) =>
                          visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                        }
                        aria-label="ConfirmPassword"
                      />
                    </StyledFormItem>
                  </InputField>
                </InputFieldsWrapper>
                {loading ? (
                  <SignInButton loading={loading} type="primary">
                    Signing Up...
                  </SignInButton>
                ) : (
                  <SignInButton type="primary" htmlType="submit">
                    Sign Up
                  </SignInButton>
                )}
              </LoginFormContent>
            </LoginFormWrapper>
          </LoginContent>
        </LoginForm>
      </LoginWrapper>
    </LoginContainer>
  );
};

const LoginContainer = styled.main`
  background-color: #fff;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  min-height: 100vh;
`;

const LoginWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 100vh;
  align-items: flex-start;
  justify-content: center;
  @media (max-width: 991px) {
    max-width: 100%;
  }
  @media (max-width: 850px) {
    align-items: center;
  }
`;

const BackgroundImage = styled.img`
  position: absolute;
  inset: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
  @media (max-width: 850px) {
    display: none;
  }
`;

const LoginForm = styled(Form)`
  position: relative;
  background-color: #fff;
  display: flex;
  width: 50%;
  min-height: 100vh;
  max-width: 100%;
  flex-direction: column;
  overflow: hidden;
  padding: 40px 54px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  @media (max-width: 991px) {
    padding: 30px;
  }
  @media (max-width: 850px) {
    width: 100%;
    margin: auto;
    box-shadow: none;
  }
`;

const Logo = styled.h1`
  color: #275df5;
  align-self: flex-start;
  font: 600 22px/40px "Inter", sans-serif;
  margin-bottom: 40px;
`;

const LoginContent = styled.div`
  display: flex;
  width: 100%;
  max-width: 410px;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
`;

const LoginHeader = styled.h2`
  width: 100%;
  color: #141c24;
  text-align: center;
  font: 600 28px/1.2 "Inter", sans-serif;
  margin-bottom: 36px;
`;

const LoginFormWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
`;

const LoginFormContent = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const LoginOptions = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  gap: 16px;
  color: #637083;
  text-align: center;
  justify-content: center;
  font: 400 14px "Inter", sans-serif;
  margin-bottom: 32px;
`;

const Divider = styled.div`
  height: 1px;
  flex: 1;
  background-color: #e4e7ec;
`;

const OrSignInText = styled.span`
  white-space: nowrap;
`;

const InputFieldsWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 16px;
`;

const InputField = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const Label = styled.label`
  color: #253142;
  font: 500 14px/1.5 "Inter", sans-serif;
  margin-bottom: 6px;
`;

const StyledFormItem = styled(Form.Item)`
  margin-bottom: 0;
`;

const StyledInput = styled(Input)`
  border-radius: 8px;
  background-color: #fff;
  width: 100%;
  padding: 12px;
  border: 1px solid #ced2da;
  font: 400 16px/1.5 "Inter", sans-serif;
  &:focus {
    border-color: #275df5;
    box-shadow: 0 0 0 2px rgba(39, 93, 245, 0.2);
  }
`;

const StyledPassword = styled(Input.Password)`
  border-radius: 8px;
  background-color: #fff;
  width: 100%;
  padding: 12px;
  border: 1px solid #ced2da;
  font: 400 16px/1.5 "Inter", sans-serif;
  &:focus {
    border-color: #275df5;
    box-shadow: 0 0 0 2px rgba(39, 93, 245, 0.2);
  }
`;

const ForgotPasswordLink = styled(Link)`
  align-self: flex-end;
  color: #637083;
  font: 400 14px/1.5 "Inter", sans-serif;
  cursor: pointer;
  text-decoration: none;
  margin-bottom: 24px;
  &:hover,
  &:focus {
    text-decoration: underline;
  }
`;
const SignUpLink = styled(Link)`
  align-self: flex-center;
  text-align: center;
  color: #637083;
  font: 400 14px/1.5 "Inter", sans-serif;
  cursor: pointer;
  text-decoration: none;
  margin-bottom: 24px;
  &:hover,
  &:focus {
    text-decoration: underline;
  }
`;

const SignInButton = styled(AntButton)`
  width: 100%;
  height: auto;
  padding: 12px;
  font: 600 16px/1.5 "Inter", sans-serif;
  border-radius: 8px;
  margin-bottom: 16px;
  &:hover,
  &:focus {
    opacity: 0.9;
  }
`;

const GoogleSignInButton = styled.button`
  border-radius: 8px;
  background-color: #f2f4f7;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  gap: 8px;
  color: #253142;
  padding: 12px;
  font: 600 16px/1.5 "Inter", sans-serif;
  border: none;
  cursor: pointer;
  &:hover,
  &:focus {
    background-color: #e4e7ec;
  }
`;

const GoogleIcon = styled.img`
  width: 20px;
  height: 20px;
`;

const mapStateToProps = (state) => ({
  signUpState: state.signUp,
});

const mapDispatchToProps = (dispatch) => ({
  signUp: (params) => dispatch(signUpApi(params)),
  signUpReset: () => dispatch(signUpReset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
