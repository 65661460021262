import React from "react";
import { XContainer } from "../../styles/GlobalStyle";
import { XCardsTransparentTabs, XTabs } from "./ManageStyle";
import TabPane from "antd/es/tabs/TabPane";
import { useNavigate } from "react-router-dom";

import UserManagement from "./UserManagement";

import ModuleManagement from "./ModuleManagement";
import DefaultRoleManagement from "./DefaultRoleManagement";
import { hasPermission } from "../../utils/helper";
import { Result, Button } from "antd";

const Manage = (props) => {
  const navigate = useNavigate();

  const handleTabChange = (key) => {
    // Clear the URL search parameters when changing tabs
    navigate(`/manage`);
  };

  return (
    <>
      <XContainer fluid>
        <XCardsTransparentTabs>
          {hasPermission("manage") ? (
            <XTabs defaultActiveKey="1" type="card" onChange={handleTabChange}>
              <TabPane key="1" tab="User Management">
                <UserManagement />
              </TabPane>
              <TabPane key="2" tab="Role Management">
                <DefaultRoleManagement />
              </TabPane>
              <TabPane key="4" tab="Modules">
                <ModuleManagement />
              </TabPane>
            </XTabs>
          ) : (
            <Result
              status="403"
              title="403"
              subTitle="Sorry, you are not authorized to access this page."
              extra={<Button type="primary">Back Home</Button>}
            />
          )}
        </XCardsTransparentTabs>
      </XContainer>
    </>
  );
};

export default Manage;
