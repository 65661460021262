import config from "../config";
import axios from "axios";
import { getUserToken } from "../utils/helper";

export const UPDATE_RESELLER = "UPDATE_RESELLER";
export const UPDATE_RESELLER_SUCCESS = "UPDATE_RESELLER_SUCCESS";
export const UPDATE_RESELLER_FAIL = "UPDATE_RESELLER_FAIL";
export const UPDATE_RESELLER_RESET = "UPDATE_RESELLER_RESET";

export const updateReseller = (params) => {
  return {
    type: UPDATE_RESELLER,
    params,
  };
};

export const updateResellerSuccess = (response) => {
  return {
    type: UPDATE_RESELLER_SUCCESS,
    response,
  };
};

export const updateResellerFail = (response) => {
  return {
    type: UPDATE_RESELLER_FAIL,
    response,
  };
};

export const updateResellerReset = () => {
  return {
    type: UPDATE_RESELLER_RESET,
  };
};

export const updateResellerApi = (data) => {
  return (dispatch) => {
    dispatch(updateReseller());
    axios
      .put(`${config.api.base_url}/resellers/${data.id}`, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getUserToken()}`,
        },
      })
      .then((response) => {
        const updateResellerData = response.data;
        dispatch(updateResellerSuccess(updateResellerData));
      })
      .catch((error) => {
        const errorMsg = error.response.data;
        dispatch(updateResellerFail(errorMsg));
      });
  };
};
