import styled from "styled-components";
import "../../styles/GlobalStyle";

export const CenterWrapper = styled.section`
  display: block;
  align-items: center;
  @media only screen and (max-width: 767.99px) {
    display: flex;
  }
`;
export const XPara1 = styled.div`
  color: ${({ theme }) => theme.colors.menu2};
  color: rgba(0, 33, 65, 0.7);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  @media only screen and (max-width: 769px) {
    font-size: 14px;
  }
`;
