import config from "../config";
import axios from "axios";
import { getUserToken } from "../utils/helper";

export const UPDATE_EMPLOYER = "UPDATE_EMPLOYER";
export const UPDATE_EMPLOYER_SUCCESS = "UPDATE_EMPLOYER_SUCCESS";
export const UPDATE_EMPLOYER_FAIL = "UPDATE_EMPLOYER_FAIL";
export const UPDATE_EMPLOYER_RESET = "UPDATE_EMPLOYER_RESET";

export const updateEmployer = (params) => {
  return {
    type: UPDATE_EMPLOYER,
    params,
  };
};

export const updateEmployerSuccess = (response) => {
  return {
    type: UPDATE_EMPLOYER_SUCCESS,
    response,
  };
};

export const updateEmployerFail = (response) => {
  return {
    type: UPDATE_EMPLOYER_FAIL,
    response,
  };
};

export const updateEmployerReset = () => {
  return {
    type: UPDATE_EMPLOYER_RESET,
  };
};

export const updateEmployerApi = (data) => {
  return (dispatch) => {
    dispatch(updateEmployer());
    axios
      .put(`${config.api.base_url}/employers/${data.id}`, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getUserToken()}`,
        },
      })
      .then((response) => {
        const updateEmployerData = response.data;
        dispatch(updateEmployerSuccess(updateEmployerData));
      })
      .catch((error) => {
        const errorMsg = error.response.data;
        dispatch(updateEmployerFail(errorMsg));
      });
  };
};
