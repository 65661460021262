import {
  GET_JOB_APPLICATION_STATISTICS_MONTHLY,
  GET_JOB_APPLICATION_STATISTICS_MONTHLY_SUCCESS,
  GET_JOB_APPLICATION_STATISTICS_MONTHLY_FAIL,
  GET_JOB_APPLICATION_STATISTICS_MONTHLY_RESET,
} from '../actions/getJobApplicationStatisticsMonthlyAction';

const initialState = {
  apiState: '',
  data: null,
  error: '',
};

const getJonApplicationStatisticsMonthlyReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case GET_JOB_APPLICATION_STATISTICS_MONTHLY:
      return {
        ...state,
        apiState: 'loading',
      };
    case GET_JOB_APPLICATION_STATISTICS_MONTHLY_SUCCESS:
      return {
        ...state,
        apiState: 'success',
        data: action.response.data,
        message: action.response.message ? action.response.message : 'success',
      };
    case GET_JOB_APPLICATION_STATISTICS_MONTHLY_FAIL:
      return {
        ...state,
        apiState: 'error',
        error: action.response.message ? action.response.message : 'error',
      };
    case GET_JOB_APPLICATION_STATISTICS_MONTHLY_RESET:
      return initialState;
    default:
      return state;
  }
};

export default getJonApplicationStatisticsMonthlyReducer;
