import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { XContainer, XForm } from '../../styles/GlobalStyle';
import { Row, Col, Button, Input, notification, Radio, Result } from 'antd';
import { XCardsProfileBg } from './ProfileStyle';
import axios from 'axios';
// actions
import {
  updateProfileApi,
  updateProfileReset,
} from '../../actions/updateProfileAction';
import {
  getUserByIdApi,
  getUserByIdReset,
} from '../../actions/getUserByIdAction';
// utils
import { handleInputChange } from '../../utils/formFunctions';
import config from '../../config';
import { checkModulePermissions, getUserToken } from '../../utils/helper';
import { getConfigurationOptionsApi } from '../../actions/getConfigurationOptionsAction';
import { SubmitBtn, SubmitNoBgBtn } from '../../styles/Button';

const Profile = (props) => {
  const {
    updateProfile,
    updateProfileReset,
    updateProfileState,
    getUserById,
    getUserByIdReset,
    getUserByIdState,
    getConfigurationOptions,
    getConfigurationOptionsState,
    setUserIcon,
  } = props;
  const navigate = useNavigate();
  const [form] = XForm.useForm();
  const [formData, setFormData] = useState({});

  // use effects

  useEffect(() => {
    getUserById({ profile: true });
    getConfigurationOptions({
      key: 'gender',
    });
  }, []);

  useEffect(() => {
    if (getUserByIdState.apiState === 'success') {
      var fname = getUserByIdState.data.name.split(' ')[0];
      var lname = getUserByIdState.data.name.split(' ')[1] ?? '';
      setFormData({
        id: getUserByIdState.data._id,
        first_name: fname,
        last_name: lname,
        email: getUserByIdState.data.email,
        mobile: getUserByIdState.data.mobile,
        gender: getUserByIdState.data.gender,
      });
      form.setFieldsValue({
        first_name: fname,
        last_name: lname,
        email: getUserByIdState.data.email,
        mobile: getUserByIdState.data.mobile,
        gender: getUserByIdState.data.gender,
      });
      getUserByIdReset();
    }
  }, [getUserByIdState]);

  useEffect(() => {
    if (updateProfileState.apiState === 'success') {
      localStorage.setItem('gender', updateProfileState.data.gender);
      setUserIcon(localStorage.getItem('gender'));
      notification.success({ message: updateProfileState.message });
      updateProfileReset();
    } else if (updateProfileState.apiState === 'error') {
      notification.error({ message: updateProfileState.message });
      updateProfileReset();
    }
  }, [updateProfileState]);

  return (
    <>
      {checkModulePermissions('user-profile').sub_modules.includes(
        'view-profile'
      ) ? (
        <XContainer fluid className='mb-5'>
          <XCardsProfileBg>
            <div className='profileHead'></div>
            <XForm
              form={form}
              name='loginForm'
              layout='vertical'
              autoComplete='off'
              onFinish={() => {
                updateProfile({
                  ...formData,
                });
              }}>
              <div className='innerBody'>
                <Row gutter={(15, 30)}>
                  <Col xs={24} sm={12} md={12} lg={8}>
                    <XForm.Item
                      name='first_name'
                      label='First Name'
                      rules={[
                        {
                          required: true,
                          message: 'Please input First Name!',
                        },
                      ]}>
                      <Input
                        size='large'
                        placeholder='Enter first name'
                        name='first_name'
                        onChange={(e) => {
                          handleInputChange(e, formData, setFormData);
                        }}
                      />
                    </XForm.Item>
                  </Col>
                  <Col xs={24} sm={12} md={12} lg={8}>
                    <XForm.Item
                      name='last_name'
                      label='Last Name'
                      rules={[
                        {
                          required: true,
                          message: 'Please input Last Name!',
                        },
                      ]}>
                      <Input
                        size='large'
                        placeholder='Enter Last name'
                        name='last_name'
                        onChange={(e) => {
                          handleInputChange(e, formData, setFormData);
                        }}
                      />
                    </XForm.Item>
                  </Col>
                  <Col xs={24} sm={12} md={12} lg={8}>
                    <XForm.Item
                      name='email'
                      label='Email Address'
                      rules={[
                        {
                          required: true,
                          message: 'Please input Email Address!',
                        },
                      ]}>
                      <Input
                        size='large'
                        placeholder='Enter email address'
                        name='email'
                        onChange={(e) => {
                          handleInputChange(e, formData, setFormData);
                        }}
                        readOnly
                      />
                    </XForm.Item>
                  </Col>

                  <Col xs={24} sm={12} md={12} lg={8}>
                    <XForm.Item
                      name='gender'
                      label='Gender'
                      rules={[
                        {
                          required: true,
                          message: 'Please select Gender!',
                        },
                      ]}>
                      <Radio.Group
                        onChange={(e) => {
                          handleInputChange(e, formData, setFormData);
                        }}
                        // value={value}
                        name='gender'>
                        {getConfigurationOptionsState.apiState === 'success' &&
                          getConfigurationOptionsState.data
                            .configuration_options?.length > 0 &&
                          getConfigurationOptionsState.data.configuration_options.map(
                            (itm) => (
                              <Radio value={itm.value}>{itm.label}</Radio>
                            )
                          )}
                      </Radio.Group>
                    </XForm.Item>
                  </Col>
                </Row>
                <Row className='text-end px-3' align='right'>
                  <Col
                    xs={24}
                    md={24}
                    className='d-flex justify-content-end align-items-center'>
                    <SubmitNoBgBtn
                      className='mr-5'
                      type='primary'
                      onClick={() => navigate(-1)}>
                      Cancel
                    </SubmitNoBgBtn>
                    <SubmitBtn
                      type='primary'
                      htmlType='submit'
                      className='ms-2'>
                      Submit
                    </SubmitBtn>
                  </Col>
                </Row>
              </div>
            </XForm>
          </XCardsProfileBg>
        </XContainer>
      ) : (
        <Result
          status='403'
          title='403'
          subTitle='Sorry, you are not authorized to access this page.'
          extra={
            <Button
              type='primary'
              onClick={() => {
                navigate('/');
              }}>
              Back Home
            </Button>
          }
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  updateProfileState: state.updateProfile,
  getUserByIdState: state.getUserById,
  getConfigurationOptionsState: state.getConfigurationOptions,
});

const mapDispatchToProps = (dispatch) => ({
  updateProfile: (data) => dispatch(updateProfileApi(data)),
  updateProfileReset: () => dispatch(updateProfileReset()),
  getUserById: (params) => dispatch(getUserByIdApi(params)),
  getUserByIdReset: () => dispatch(getUserByIdReset()),
  getConfigurationOptions: (params) =>
    dispatch(getConfigurationOptionsApi(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
