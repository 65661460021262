import axios from "axios";
import config from "../config";
import { getUserToken } from "../utils/helper";

export const CREATE_ROLE = "CREATE_ROLE";
export const CREATE_ROLE_SUCCESS = "CREATE_ROLE_SUCCESS";
export const CREATE_ROLE_FAIL = "CREATE_ROLE_FAIL";
export const CREATE_ROLE_RESET = "CREATE_ROLE_RESET";

export const createRole = (params) => {
  return {
    type: CREATE_ROLE,
    params,
  };
};

export const createRoleSuccess = (response) => {
  return {
    type: CREATE_ROLE_SUCCESS,
    response,
  };
};

export const createRoleFail = (response) => {
  return {
    type: CREATE_ROLE_FAIL,
    response,
  };
};

export const createRoleReset = () => {
  return {
    type: CREATE_ROLE_RESET,
  };
};

export const createRoleApi = (data) => {
  return (dispatch) => {
    dispatch(createRole());
    axios
      .post(`${config.api.base_url}/settings/add-role`, data, {
        headers: {
          Authorization: "Bearer " + getUserToken(),
        },
      })
      .then((response) => {
        const createRoleData = response.data;
        dispatch(createRoleSuccess(createRoleData));
      })
      .catch((error) => {
        const errorMsg = error.message;
        dispatch(createRoleFail(errorMsg));
      });
  };
};
