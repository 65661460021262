import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  Typography,
  Button,
  Tooltip,
  notification,
  Result,
  Radio,
  Select,
  Tag,
} from "antd";
import { connect } from "react-redux";
import moment from "moment";
import {
  capitalizeAndSplitByDash,
  checkModulePermissions,
  getSignedUrlForGetObject,
  hasPermission,
  urlPagination,
} from "../../utils/helper";
import { PlusOutlined } from "@ant-design/icons";
import DataTable from "../../components/DataTable/DataTable";
import {
  getEmployersApi,
  getEmployersReset,
} from "../../actions/getEmployersAction";
import { useForm } from "antd/es/form/Form";
import { Link, useNavigate } from "react-router-dom";
import { XButtonImg } from "../Manage/ManageStyle";
import CaseSearch from "../../components/CaseSearch/CaseSearch";
import {
  FilterButton,
  XButton2,
  XContainer,
  XForm,
  XSearch,
  XSelect,
} from "../../styles/GlobalStyle";
import { dateFilters } from "../../utils/constants";
import { filterByLabel } from "../../utils/formFunctions";
import { getIndustriesApi } from "../../actions/getIndustriesAction";
import { getStatesApi } from "../../actions/getStatesAction";

// API Action

const EmployersTable = (props) => {
  const navigate = useNavigate();
  const defaultFilters = {
    page: 1,
    limit: 10,
    search: "",
    state: "",
    industry: "",
    joined_on: "all",
  };

  let modulePermissions = checkModulePermissions("employers");
  const {
    getEmployers,
    getEmployersState,
    getEmployersReset,
    getStates,
    getStatesState,
    getIndustries,
    getIndustriesState,
  } = props;
  const [form] = useForm();
  const [tableData, setTableData] = useState([]);
  const [totalRecords, setTotalRecords] = useState("");
  const [open, setOpen] = useState(false);

  const getFiltersFromUrl = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const urlFilters = {};

    // Iterate through each query parameter and set it in the state
    for (const [key, value] of urlParams.entries()) {
      urlFilters[key] = value;
    }

    // Merge with default filters or initial state as needed
    const filters = {
      ...defaultFilters, // Replace with your default filters
      ...urlFilters,
    };

    return filters;
  };
  const initialFilters = getFiltersFromUrl();

  const [filters, setFilters] = useState(initialFilters);
  const handleOnClick = async (agreement_url) => {
    const url = await getSignedUrlForGetObject(
      "employers",
      agreement_url[0],
      agreement_url[1]
    );
    window.open(url, "_blank");
  };

  const columns = [
    {
      title: (
        <Typography.Text className="tableEllipsis" ellipsis={true}>
          EMPLOYER
        </Typography.Text>
      ),
      dataIndex: "employer",
      render: (employers) => (
        <div>
          <div style={{ fontWeight: "600" }}>{employers}</div>
        </div>
      ),
    },
    {
      title: (
        <Typography.Text className="tableEllipsis" ellipsis={true}>
          INDUSTRY
        </Typography.Text>
      ),
      dataIndex: "industry",
      render: (industry) => (
        <div>
          <div style={{ fontWeight: "600" }}>
            {capitalizeAndSplitByDash(industry)}
          </div>
        </div>
      ),
    },
    {
      title: (
        <Typography.Text className="tableEllipsis" ellipsis={true}>
          STATE
        </Typography.Text>
      ),
      dataIndex: "state",
      render: (state) => (
        <div>
          <div style={{ fontWeight: "600" }}>
            {capitalizeAndSplitByDash(state)}
          </div>
        </div>
      ),
    },
    {
      title: (
        <Typography.Text className="tableEllipsis" ellipsis={true}>
          CITY
        </Typography.Text>
      ),
      dataIndex: "city",
      render: (city) => (
        <div>
          <div style={{ fontWeight: "600" }}>
            {capitalizeAndSplitByDash(city)}
          </div>
        </div>
      ),
    },

    {
      title: (
        <Typography.Text className="tableEllipsis" ellipsis={true}>
          ONBOARDED ON
        </Typography.Text>
      ),
      dataIndex: "agreement_expiry_date",
      render: (joinedOn) => (
        <div style={{ width: "120px" }}>
          <div style={{ fontWeight: "600" }}>
            {moment(joinedOn).format("DD-MM-YYYY")}
          </div>
        </div>
      ),
    },
    {
      title: (
        <Typography.Text className="tableEllipsis" ellipsis={true}>
          ACTIVE JOBS
        </Typography.Text>
      ),
      dataIndex: "active_jobs",
      render: (active_jobs) => (
        <div style={{ width: "100px" }}>
          <div style={{ fontWeight: "600" }}>{active_jobs}</div>
        </div>
      ),
    },
    {
      title: (
        <Typography.Text className="tableEllipsis" ellipsis={true}>
          ACTIVE OPENINGS
        </Typography.Text>
      ),
      dataIndex: "active_openings",
      render: (active_openings) => (
        <div style={{ width: "120px" }}>
          <div style={{ fontWeight: "600" }}>{active_openings}</div>
        </div>
      ),
    },
    {
      title: (
        <Typography.Text className="tableEllipsis" ellipsis={true}>
          INACTIVE JOBS
        </Typography.Text>
      ),
      dataIndex: "inactive_jobs",
      render: (inactive_jobs) => (
        <div style={{ width: "120px" }}>
          <div style={{ fontWeight: "600" }}>{inactive_jobs}</div>
        </div>
      ),
    },
    {
      title: (
        <Typography.Text className="tableEllipsis" ellipsis={true}>
          INACTIVE OPENINGS
        </Typography.Text>
      ),
      dataIndex: "inactive_openings",
      render: (inactive_openings) => (
        <div style={{ width: "140px" }}>
          <div style={{ fontWeight: "600" }}>{inactive_openings}</div>
        </div>
      ),
    },

    {
      title: (
        <Typography.Text className="tableEllipsis" ellipsis={true}>
          REJECTED
        </Typography.Text>
      ),
      dataIndex: "candidates_rejected",
      render: (candidates_rejected) => (
        <div>
          <div>{candidates_rejected}</div>
        </div>
      ),
    },
    {
      title: (
        <Typography.Text className="tableEllipsis" ellipsis={true}>
          HIRED
        </Typography.Text>
      ),
      dataIndex: "candidates_hired",
      render: (hired) => (
        <div>
          <div>{hired}</div>
        </div>
      ),
    },

    {
      title: (
        <Typography.Text className="tableEllipsis" ellipsis={true}>
          AGREEMENT
        </Typography.Text>
      ),
      dataIndex: "agreement_url",
      render: (agreement_url) =>
        agreement_url[0] ? (
          <div onClick={() => handleOnClick(agreement_url)}>
            <Tooltip title="Download Agreement">
              <Button type="link" onClick={(e) => e.preventDefault()}>
                <img src="images/agreement-icon.svg" alt="" />
              </Button>
            </Tooltip>
          </div>
        ) : null,
    },
    {
      title: (
        <Typography.Text className="tableEllipsis" ellipsis={true}>
          STATUS
        </Typography.Text>
      ),
      dataIndex: "status",
      render: (status) => (
        <div>
          <div>
            {status ? (
              <Tag
                color="#CDFFCD"
                style={{
                  color: "#007F00",
                  borderRadius: "12px",
                }}>
                {"Active"}
              </Tag>
            ) : (
              <Tag
                color="#FAEEEE"
                style={{
                  color: "#E44556",
                  borderRadius: "12px",
                }}>
                {"Inactive"}
              </Tag>
            )}
          </div>
        </div>
      ),
    },
    {
      title: (
        <Typography.Text className="tableEllipsis" ellipsis={true}>
          ACTION
        </Typography.Text>
      ),
      dataIndex: "buttons",
      render: (buttons) =>
        hasPermission("employers", "edit-employers") ? (
          <div>
            <Tooltip title="Edit Recruitment Partner">{buttons}</Tooltip>
          </div>
        ) : (
          <></>
        ),
    },
  ];

  const showDrawer = () => {
    getIndustries({});
    getStates({});
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  const onChange = (key) => {
    setFilters({
      ...filters,
      [key.target.name]: key.target.value,
    });
  };

  const applyFilters = () => {
    const queryParams = new URLSearchParams(window.location.search);

    // Iterate through filters and update the query parameters
    Object.entries(filters).forEach(([key, value]) => {
      const currentValue = queryParams.get(key);

      // Check if the parameter exists in the current query and has a value, if yes, update its value
      if (currentValue !== null) {
        queryParams.set(key, value);
      } else if (queryParams.has(key)) {
        // If the parameter exists but has no value, keep its existing value
        queryParams.set(key, queryParams.get(key));
      } else {
        // If the parameter does not exist in the current query, append it with the current value
        queryParams.append(key, value);
      }
    });

    // Set the page to 1
    queryParams.set("page", "1");
    queryParams.set("sort", "createdAt");
    queryParams.set("sortDirection", "descend");
    filters.page = 1;

    // Update the URL
    const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
    window.history.pushState({}, "", newUrl);
  };

  useEffect(() => {
    // Retrieve filters from the updated URL
    const updatedFilters = getFiltersFromUrl();
    setFilters({ ...updatedFilters });

    // Make API call with updated filters and pagination
    if (hasPermission("employers", "list-employers"))
      getEmployers({ ...updatedFilters });
  }, [window.location.search]);

  const clearFilters = () => {
    const queryParams = new URLSearchParams(window.location.search);

    // Iterate through defaultFilters and update the query parameters
    Object.entries(defaultFilters).forEach(([key, value]) => {
      const currentValue = queryParams.get(key);

      // Check if the parameter exists in the current query and has a value, if yes, update its value
      if (currentValue !== null) {
        queryParams.set(key, value);
      } else if (queryParams.has(key)) {
        // If the parameter exists but has no value, keep its existing value
        queryParams.set(key, queryParams.get(key));
      } else {
        // If the parameter does not exist in the current query, append it with the default value
        queryParams.append(key, value);
      }
    });

    // Clear the form fields
    form.resetFields();

    // Update the state with default values
    setFilters(defaultFilters);

    // Update the URL
    const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
    window.history.pushState({}, "", newUrl);
  };

  const parseEmployersData = async (all_employers) => {
    var data = [];
    for (var tableRow = 0; tableRow < all_employers.length; tableRow++) {
      const buttons = modulePermissions.sub_modules.includes(
        "view-employers"
      ) && (
        <Link to={`/employers/edit/${all_employers[tableRow]._id}`}>
          <XButtonImg
            style={{
              background: "transparent",
              border: "none",
            }}>
            <img src="images/pencil.svg" alt="" />
          </XButtonImg>
        </Link>
      );

      var thisObject = {
        key: tableRow,
        employer: (
          <Link
            to={`/employers/${all_employers[tableRow]._id}`}
            style={{
              color: "rgb(0, 33, 65)",
            }}>
            {capitalizeAndSplitByDash(
              all_employers[tableRow].organization_name
            )}
          </Link>
        ),
        industry: all_employers[tableRow].industry,
        state: all_employers[tableRow].state,
        city: all_employers[tableRow].city,
        joinedOn: all_employers[tableRow].joined_on,
        signed_by_both: all_employers[tableRow].signed_by_both,
        date_of_agreement: all_employers[tableRow].date_of_agreement,
        tenure_of_agreement: all_employers[tableRow].tenure_of_agreement,
        active_jobs: all_employers[tableRow].active_jobs,
        active_openings: all_employers[tableRow].active_openings,
        inactive_jobs: all_employers[tableRow].inactive_jobs,
        inactive_openings: all_employers[tableRow].inactive_openings,
        candidates_shared: all_employers[tableRow].candidates_shared,
        candidates_hired: all_employers[tableRow].candidates_hired,
        candidates_rejected: all_employers[tableRow].candidates_rejected,
        earning_potential: all_employers[tableRow].earning_potential,
        status: all_employers[tableRow].is_active,
        agreement_url: [
          all_employers[tableRow].agreement_document,
          all_employers[tableRow].s3_folder_name,
        ],
        buttons: buttons,
      };

      data.push(thisObject);
    }
    setTableData(data);
  };

  useEffect(() => {
    if (getEmployersState.apiState === "success") {
      setTotalRecords(getEmployersState.data.total_count);
      parseEmployersData(getEmployersState.data.all_employers);
    } else if (getEmployersState.apiState === "error") {
      console.log("API Error=>", getEmployersState.message);
      notification.error({
        message: "Unable to getEmployers",
      });
    }
    getEmployersReset();
  }, [getEmployersState.apiState]);

  return (
    <>
      {hasPermission("employers") ? (
        <>
          <XContainer fluid>
            <Card styles={{ padding: "10px" }}>
              <CaseSearch
                searchOption="employer"
                title="Employers"
                filters={[
                  <XForm.Item
                    name="state"
                    colon={false}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                    label="State"
                    className="removeFormMargin">
                    <XSelect
                      size="large"
                      showSearch
                      allowClear
                      placeholder="Select State"
                      filterOption={filterByLabel}
                      defaultValue={filters.location || null}
                      onChange={(value) => {
                        setFilters({
                          ...filters,
                          state: value,
                        });
                      }}
                      onClear={() => {
                        setFilters({
                          ...filters,
                          state: "",
                        });
                      }}
                      options={
                        getStatesState.apiState === "success" &&
                        getStatesState.data.all_states.length > 0 &&
                        getStatesState.data.all_states.map((state) => {
                          return {
                            value: state.state,
                            label: state.state,
                            type: "state",
                            key: state.state,
                          };
                        })
                      }></XSelect>
                  </XForm.Item>,
                  <XForm.Item
                    name="industry"
                    colon={false}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                    label="Industry"
                    className="removeFormMargin">
                    <Select
                      showSearch
                      size="large"
                      className="removeBg"
                      onFocus={() => getIndustries({})}
                      defaultValue={filters.industry || null}
                      filterOption={filterByLabel}
                      onSearch={(search) => {
                        if (search.length >= 3) {
                          getIndustries({ search });
                        } else {
                          getIndustries({});
                        }
                      }}
                      onChange={(value) => {
                        setFilters({
                          ...filters,
                          industry: value,
                        });
                      }}
                      onClear={() => {
                        setFilters({
                          ...filters,
                          industry: "",
                        });
                      }}
                      options={
                        getIndustriesState.apiState === "success" &&
                        getIndustriesState.data.all_industries.length > 0 &&
                        getIndustriesState.data.all_industries.map((ind) => {
                          return {
                            value: ind._id,
                            label: ind.industry_name,
                            type: "industry",
                          };
                        })
                      }
                      placeholder="Select Industry"
                    />
                  </XForm.Item>,
                  <XForm.Item
                    name="joined_on"
                    colon={false}
                    label="Joined"
                    className="removeFormMargin">
                    <Radio.Group
                      onChange={onChange}
                      name="joined_on"
                      defaultValue={filters.joined_on || null}>
                      <Row>
                        {dateFilters.map((item, index) => (
                          <Col xs={12} lg={12} md={12} xl={12}>
                            <Radio
                              value={item.value}
                              className="filter-sub-item">
                              {item.label}
                            </Radio>
                          </Col>
                        ))}
                      </Row>
                    </Radio.Group>
                  </XForm.Item>,
                ]}
                onClose={onClose}
                open={open}
                applyFilters={applyFilters}
                form={form}
                clearFilters={clearFilters}
              />
              <Row gutter={(15, 30)}>
                <Col
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                  xs={24}
                  md={10}
                  lg={3}
                  xl={3}
                  xxl={3}>
                  <p className="tableHeader mb-3">
                    Total {totalRecords} Employers
                  </p>
                </Col>
                <Col
                  xs={24}
                  md={14}
                  lg={{ span: 6 }}
                  xl={{ span: 6 }}
                  xxl={{ span: 6 }}
                  className="mb-2">
                  <XSearch
                    placeholder="Search Employer"
                    allowClear
                    enterButton="Search"
                    size="large"
                    defaultValue={filters.search ? filters.search : null}
                    onChange={(v) => {
                      if (v.target.value === "") {
                        urlPagination(filters, "search", "");
                        setFilters({
                          ...filters,
                          search: "",
                        });
                      }
                    }}
                    onSearch={(v) => {
                      urlPagination(filters, "search", v);
                      setFilters({
                        ...filters,
                        search: v,
                      });
                    }}
                  />
                </Col>
                <Col
                  xs={24}
                  md={12}
                  lg={{ span: 3, offset: 8 }}
                  xl={{ span: 3, offset: 8 }}
                  xxl={{ span: 3, offset: 8 }}>
                  <XForm.Item className="text-right">
                    <FilterButton onClick={() => showDrawer()}>
                      <img
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/d0e11f66e694b5fbdadd8edb8109ba7b24c06cb1f66c477f282ec451dfd237e7?placeholderIfAbsent=true&apiKey=ac9769c5e7eb416e8a74975078a910d6"
                        alt="Filter icon"
                      />
                      Filter
                    </FilterButton>
                  </XForm.Item>
                </Col>
                <Col
                  xs={24}
                  md={12}
                  lg={{ span: 4 }}
                  xl={{ span: 4 }}
                  xxl={{ span: 4 }}>
                  {hasPermission("employers", "add-employers") ? (
                    <XForm.Item className="text-right">
                      <FilterButton
                        onClick={() => {
                          navigate(`/employers/add`);
                        }}>
                        <PlusOutlined /> New Employer
                      </FilterButton>
                    </XForm.Item>
                  ) : (
                    <></>
                  )}
                </Col>
              </Row>
              <DataTable
                scroll={{ x: 991 }}
                columns={columns}
                tableData={tableData}
                pagination={false}
                className="invtTable"
                page={"employer"}
                setFilters={setFilters}
                filters={filters}
                totalRecords={totalRecords}
                state={getEmployersState.apiState}
              />
            </Card>
          </XContainer>
        </>
      ) : (
        <Result
          status="404"
          title="404"
          subTitle="Sorry, the page you visited does not exist."
          extra={
            <Button
              type="primary"
              onClick={() => {
                navigate("/");
              }}>
              Back Home
            </Button>
          }
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  getEmployersState: state.getEmployers,
  getStatesState: state.getStates,
  getIndustriesState: state.getIndustries,
});

const mapDispatchToProps = (dispatch) => ({
  getEmployers: (params) => dispatch(getEmployersApi(params)),
  getEmployersReset: (params) => dispatch(getEmployersReset()),
  getStates: (params) => dispatch(getStatesApi(params)),
  getIndustries: (params) => dispatch(getIndustriesApi(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EmployersTable);
