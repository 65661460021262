import React, { useState } from 'react';
import Login from './pages/Login/Login';
import { GlobalStyle, XContainer, Theme, XLayout } from './styles/GlobalStyle';
import { BrowserRouter, Routes, Route, Outlet } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import Page404 from './pages/Page404';
import PrivateRoute from './components/PrivateRoute';
import { Layout } from 'antd';
import 'bootstrap/dist/css/bootstrap.min.css';
import ForgotEmailPassword from './pages/ForgotEmailPassword/ForgotEmailPassword';
import VerifyOtp from './pages/VerifyOtp/VerifyOtp';
import ResetPassword from './pages/ResetPassword/ResetPassword';
import ChangePassword from './pages/ChangePassword/ChangePassword';
import Manage from './pages/Manage/Manage';
import AddEditUser from './pages/Manage/AddEditUser';
import ModuleForm from './pages/ModuleForm/ModuleForm';
import Consent from './pages/Consent/Consent';
import Home from './pages/Home/index';
import SignUp from './pages/SignUp/SignUp';
import AddEditJobInventory from './pages/Inventory/AddEditJobInventory';
import AddEditEmployers from './pages/Employers/AddEditEmployers';
import Employers from './pages/Employers/Employers';
import EmployerView from './pages/Employers/EmployerView';
import AddEditResellers from './pages/Resellers/AddEditResellers';
import Resellers from './pages/Resellers/Resellers';
import ResellerView from './pages/Resellers/ResellerView';
import PersonalInformation from './pages/BuildProfile/PersonalInformation';
import Explore from './pages/Explore/Explore';
import Calendar from './pages/Calendar/Calendar';
import Profile from './pages/Profile/Profile';
import Application from './pages/Application/Application';
import MyApplication from './pages/MyApplication/MyApplication';
import CandidateDetails from './pages/CandidateDetails/CandidateDetails';
import Footer from './components/Footer/index';
import Header from './components/Header/index';
import Inventory from './pages/Inventory/Inventory';
import CandidateApplicationDetails from './pages/CandidateDetails/CandidateApplicationDetails';
import Candidates from './pages/Candidates/Candidates';
import RecruiterJobList from './pages/RecruiterJobList/RecruiterJobList';
import DashboardChart from './pages/DashboardChart/DashboardChart';
import ExploreDetails from './pages/Explore/ExploreDetails';
import AboutUs from './pages/About Us/AboutUs';
import Contact from './pages/Contact Us/Contact';
const { Content } = Layout;

function App(props) {
  const [userIcon, setUserIcon] = useState(false);

  return (
    <ThemeProvider theme={Theme}>
      <GlobalStyle />
      <BrowserRouter>
        <Header userIcon={userIcon} setUserIcon={setUserIcon} />
        <XLayout>
          <Routes>
            <Route exact path='/' element={<Home />} />
            <Route path='/about-us' element={<AboutUs />}></Route>
            <Route path='/contact-us' element={<Contact />}></Route>
            <Route path='/calendar' element={<Calendar />}></Route>
            <Route path='/login' element={<Login />}></Route>
            <Route path='/sign-up' element={<SignUp />}></Route>
            <Route
              path='/forgot-email-password'
              element={<ForgotEmailPassword />}></Route>
            <Route path='/reset-password' element={<ResetPassword />}></Route>
            <Route path='/verify-otp' element={<VerifyOtp />}></Route>
            <Route exact path='/explore' element={<Explore />} />
            <Route exact path='/explore/:jobId' element={<ExploreDetails />} />
            <Route
              path='/'
              element={
                <Layout hasSider>
                  <Layout className='p-0'>
                    <Content className='mainContent'>
                      <div
                        style={{
                          marginLeft: '0px',
                        }}>
                        <Outlet />
                      </div>
                    </Content>
                  </Layout>
                </Layout>
              }>
              <Route path='/consent' element={<Consent />}></Route>
              <Route
                exact
                path='/change-password'
                element={
                  <PrivateRoute>
                    <ChangePassword />
                  </PrivateRoute>
                }></Route>
              <Route
                exact
                path='/dashboard'
                element={
                  <PrivateRoute>
                    <DashboardChart />
                  </PrivateRoute>
                }></Route>
              <Route
                exact
                path='/manage'
                element={
                  <PrivateRoute>
                    <Manage />
                  </PrivateRoute>
                }
              />
              <Route
                exact
                path='/manage/user/:id'
                element={
                  <PrivateRoute>
                    <AddEditUser />
                  </PrivateRoute>
                }></Route>
              <Route
                exact
                path='/manage/user'
                element={
                  <PrivateRoute>
                    <AddEditUser />
                  </PrivateRoute>
                }></Route>
              <Route
                exact
                path='/module/add'
                element={
                  <PrivateRoute>
                    <ModuleForm />
                  </PrivateRoute>
                }></Route>
              <Route
                exact
                path='/module/edit/:id'
                element={
                  <PrivateRoute>
                    <ModuleForm />
                  </PrivateRoute>
                }></Route>
              <Route
                exact
                path='/inventory'
                element={
                  <PrivateRoute>
                    <Inventory />
                  </PrivateRoute>
                }></Route>
              <Route
                exact
                path='/inventory/add'
                element={
                  <PrivateRoute>
                    <AddEditJobInventory />
                  </PrivateRoute>
                }></Route>
              <Route
                exact
                path='/inventory/edit/:id'
                element={
                  <PrivateRoute>
                    <AddEditJobInventory />
                  </PrivateRoute>
                }></Route>
              <Route
                exact
                path='/employers'
                element={
                  <PrivateRoute>
                    <Employers />
                  </PrivateRoute>
                }></Route>
              <Route
                exact
                path='/employers/:id'
                element={
                  <PrivateRoute>
                    <EmployerView />
                  </PrivateRoute>
                }></Route>
              <Route
                exact
                path='/employers/add'
                element={
                  <PrivateRoute>
                    <AddEditEmployers />
                  </PrivateRoute>
                }></Route>
              <Route
                exact
                path='/employers/edit/:id'
                element={
                  <PrivateRoute>
                    <AddEditEmployers />
                  </PrivateRoute>
                }></Route>
              <Route
                exact
                path='/reseller'
                element={
                  <PrivateRoute>
                    <Resellers />
                  </PrivateRoute>
                }></Route>
              <Route
                exact
                path='/reseller/:id'
                element={
                  <PrivateRoute>
                    <ResellerView />
                  </PrivateRoute>
                }></Route>
              <Route
                exact
                path='/reseller/add'
                element={
                  <PrivateRoute>
                    <AddEditResellers />
                  </PrivateRoute>
                }></Route>
              <Route
                exact
                path='/reseller/edit/:id'
                element={
                  <PrivateRoute>
                    <AddEditResellers />
                  </PrivateRoute>
                }></Route>
              <Route
                exact
                path='/applications'
                element={
                  <PrivateRoute>
                    <MyApplication />
                  </PrivateRoute>
                }
              />
              <Route
                exact
                path='/applications/:id'
                element={
                  <PrivateRoute>
                    <Application />
                  </PrivateRoute>
                }
              />
              <Route
                exact
                path='/my-applications'
                element={
                  <PrivateRoute>
                    <MyApplication />
                  </PrivateRoute>
                }
              />
              <Route
                exact
                path='/candidates'
                element={
                  <PrivateRoute>
                    <Candidates />
                  </PrivateRoute>
                }></Route>
              <Route
                exact
                path='/candidates/:id'
                element={
                  <PrivateRoute>
                    <RecruiterJobList />
                  </PrivateRoute>
                }></Route>
              <Route
                exact
                path='/candidate-profile'
                element={
                  <PrivateRoute>
                    <PersonalInformation setUserIcon={setUserIcon} />
                  </PrivateRoute>
                }></Route>
              <Route
                exact
                path='/candidate-details/:id'
                element={
                  <PrivateRoute>
                    <CandidateDetails />
                  </PrivateRoute>
                }></Route>
              <Route
                exact
                path='/applications/candidate-details/:id'
                element={
                  <PrivateRoute>
                    <CandidateApplicationDetails />
                  </PrivateRoute>
                }></Route>
              <Route
                exact
                path='/profile'
                element={
                  <PrivateRoute>
                    <Profile setUserIcon={setUserIcon} />
                  </PrivateRoute>
                }></Route>
            </Route>
            <Route path='/*' element={<Page404 />} />
          </Routes>
        </XLayout>
      </BrowserRouter>
      <Footer />
    </ThemeProvider>
  );
}

export default App;
