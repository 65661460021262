import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Row, Col, notification, Input, Form, Button as AntButton } from 'antd';
import { Title, XForm, XLottie } from '../Login/LoginStyle';
import { CenterWrapper } from '../ForgotEmailPassword/ForgotEmailPasswordStyle';
import { XPinInput, XPara1 } from './VerifyOtpStyle';
import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import Navbar from 'react-bootstrap/Navbar';
import { XContainer } from '../../styles/GlobalStyle';
// Actions
import { verifyOtpApi, verifyOtpReset } from '../../actions/verifyOtpAction';
import {
  sendPasswordResetEmailApi,
  sendPasswordResetEmailReset,
} from '../../actions/sendPasswordResetEmailAction';
import styled from 'styled-components';

const VerifyOtp = (props) => {
  const {
    verifyOtp,
    verifyOtpReset,
    verifyOtpState,
    sendPasswordResetEmail,
    sendPasswordResetEmailState,
    sendPasswordResetEmailReset,
  } = props;
  const [form] = XForm.useForm();
  const [formData, setFormData] = useState({
    email: localStorage.getItem('email'),
  });
  const navigate = useNavigate();

  useEffect(() => {
    if (sendPasswordResetEmailState.apiState === 'success') {
      notification.success({
        message: sendPasswordResetEmailState.message,
      });
      sendPasswordResetEmailReset();
    } else if (sendPasswordResetEmailState.apiState === 'error') {
      notification.error({
        message: sendPasswordResetEmailState.message,
      });
      sendPasswordResetEmailReset();
    }
  }, [sendPasswordResetEmailState]);

  useEffect(() => {
    if (verifyOtpState.apiState === 'success') {
      localStorage.setItem('otpVerified', true);
      verifyOtpReset();
      navigate('/reset-password');
    } else if (verifyOtpState.apiState === 'error') {
      notification.error({
        message: verifyOtpState.message,
      });
      verifyOtpReset();
    }
  }, [verifyOtpState]);

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  // Update `isMobile` state when window is resized
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const inputStyle = {
    borderRadius: '10px',
    borderColor: '#D8D8D7',
    background: '#F7F9FD',
    padding: '5px',
    width: isMobile ? '35px' : '50px', // Smaller width for mobile
    height: isMobile ? '35px' : '50px', // Smaller height for mobile
    fontSize: isMobile ? '18px' : '25px', // Smaller font size for mobile
    marginRight: isMobile ? '5px' : '15px', // Adjust margin for mobile
  };

  return (
    <>
      <LoginContainer>
        <LoginWrapper>
          <BackgroundImage
            loading='lazy'
            src='https://cdn.builder.io/api/v1/image/assets/TEMP/27fb07fc02cff20cc788735d53684dd469e0f9b6e67090771ba6c797883cc595?placeholderIfAbsent=true&apiKey=ac9769c5e7eb416e8a74975078a910d6'
            alt='Background'
          />
          <LoginForm
            form={form}
            name='loginForm'
            layout='vertical'
            autoComplete='off'
            onFinish={() => verifyOtp(formData)}>
            <LoginContent>
              <LoginHeader>OTP Verification</LoginHeader>
              <XPara1 className='mb-5 text-center'>
                We sent a verification code to
                <span> {formData.email}</span>
              </XPara1>
              <LoginFormWrapper>
                <LoginFormContent>
                  <InputFieldsWrapper className='mb-4 pb-4'>
                    <InputField>
                      {/* <Label>Email</Label> */}
                      <StyledFormItem
                        name='otp'
                        className='text-center'
                        rules={[
                          {
                            required: true,
                            message: 'Please enter OTP',
                          },
                        ]}>
                        <StyledInput
                          focus='true'
                          length={6}
                          initialvalue=''
                          onChange={(value, index) => {
                            setFormData({
                              ...formData,
                              otp: value,
                            });
                          }}
                          type='numeric'
                          inputMode='number'
                          style={{ padding: '0px' }}
                          inputStyle={inputStyle} // Apply the responsive input style here
                          inputFocusStyle={{
                            borderColor: '#D8D8D7',
                            background: '#F7F9FD',
                          }}
                          onComplete={(value, index) => {}}
                          autoSelect={true}
                          regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                        />
                      </StyledFormItem>
                    </InputField>
                  </InputFieldsWrapper>
                  <StyledFormItem name='otp' className='text-center'>
                    {/* <XPara1 className='mb-1'>00:20</XPara1> */}
                    <XPara1 className='mb-0'>
                      Don't Receive the OTP?{' '}
                      <span
                        style={{
                          color: '#275df5',
                        }}
                        role='button'
                        onClick={() => {
                          sendPasswordResetEmail({
                            email: localStorage.getItem('email'),
                          });
                        }}>
                        RESEND
                      </span>
                    </XPara1>
                  </StyledFormItem>
                  <SignInButton
                    className='mt-5'
                    type='primary'
                    htmlType='submit'>
                    Submit
                  </SignInButton>
                </LoginFormContent>
              </LoginFormWrapper>
            </LoginContent>
          </LoginForm>
        </LoginWrapper>
      </LoginContainer>
    </>
  );
};

const LoginContainer = styled.main`
  background-color: #fff;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  min-height: 100vh;
`;

const LoginWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 100vh;
  align-items: flex-start;
  justify-content: center;
  @media (max-width: 991px) {
    max-width: 100%;
  }
  @media (max-width: 850px) {
    align-items: center;
  }
`;

const BackgroundImage = styled.img`
  position: absolute;
  inset: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
  @media (max-width: 850px) {
    display: none;
  }
`;

const LoginForm = styled(Form)`
  position: relative;
  background-color: #fff;
  display: flex;
  width: 50%;
  min-height: 100vh;
  max-width: 100%;
  flex-direction: column;
  overflow: hidden;
  padding: 40px 54px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  @media (max-width: 991px) {
    padding: 30px;
  }
  @media (max-width: 850px) {
    width: 100%;
    margin: auto;
    box-shadow: none;
  }
`;

const LoginContent = styled.div`
  display: flex;
  width: 100%;
  max-width: 510px;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
`;

const LoginHeader = styled.h2`
  width: 100%;
  color: #141c24;
  text-align: center;
  font: 600 28px/1.2 'Inter', sans-serif;
  margin-bottom: 36px;
`;

const LoginFormWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
`;

const LoginFormContent = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const InputFieldsWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 16px;
`;

const InputField = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const StyledFormItem = styled(Form.Item)`
  margin-bottom: 0;
`;

const StyledInput = styled(XPinInput)`
  border-radius: 8px;
  background-color: #fff;
  width: 100%;
  padding: 12px;
  border: 1px solid #ced2da;
  font: 400 16px/1.5 'Inter', sans-serif;
  &:focus {
    border-color: #275df5;
    box-shadow: 0 0 0 2px rgba(39, 93, 245, 0.2);
  }
`;

const SignInButton = styled(AntButton)`
  width: 100%;
  height: auto;
  padding: 12px;
  font: 600 16px/1.5 'Inter', sans-serif;
  border-radius: 8px;
  margin-bottom: 16px;
  &:hover,
  &:focus {
    opacity: 0.9;
  }
`;

const mapStateToProps = (state) => ({
  verifyOtpState: state.verifyOtp,
  sendPasswordResetEmailState: state.sendPasswordResetEmail,
});

const mapDispatchToProps = (dispatch) => ({
  verifyOtp: (params) => dispatch(verifyOtpApi(params)),
  verifyOtpReset: () => dispatch(verifyOtpReset()),
  sendPasswordResetEmail: (params) =>
    dispatch(sendPasswordResetEmailApi(params)),
  sendPasswordResetEmailReset: () => dispatch(sendPasswordResetEmailReset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(VerifyOtp);
