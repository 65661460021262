import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { XContainer, XForm } from '../../styles/GlobalStyle';
// actions
import {
  changePasswordApi,
  changePasswordReset,
} from '../../actions/changePasswordAction';
import { handleInputChange } from '../../utils/formFunctions';
import styled from 'styled-components';
import {
  Row,
  Col,
  Button,
  notification,
  Form,
  Input,
  Button as AntButton,
} from 'antd';

const ChangePassword = (props) => {
  const { changePassword, changePasswordReset, changePasswordState } = props;
  const navigate = useNavigate();
  const [form] = XForm.useForm();
  const [formData, setFormData] = useState({});

  // use effects
  useEffect(() => {
    if (changePasswordState.apiState === 'success') {
      notification.success({ message: changePasswordState.message });
      changePasswordReset();
      form.resetFields();
    } else if (changePasswordState.apiState === 'error') {
      notification.error({ message: changePasswordState.message });
      changePasswordReset();
    }
  }, [changePasswordState]);
  return (
    <>
      <LoginContainer>
        <LoginWrapper>
          <BackgroundImage
            loading='lazy'
            src='https://cdn.builder.io/api/v1/image/assets/TEMP/27fb07fc02cff20cc788735d53684dd469e0f9b6e67090771ba6c797883cc595?placeholderIfAbsent=true&apiKey=ac9769c5e7eb416e8a74975078a910d6'
            alt='Background'
          />
          <LoginForm
            form={form}
            name='loginForm'
            layout='vertical'
            autoComplete='off'
            onFinish={() => changePassword(formData)}>
            <LoginContent>
              <LoginHeader>Change Password</LoginHeader>
              <LoginFormWrapper>
                <LoginFormContent>
                  <InputFieldsWrapper className='mb-4 pb-4'>
                    <InputField>
                      <Label>Old Password*</Label>
                      <StyledFormItem
                        name='password'
                        //  label="Old Password"
                        rules={[
                          {
                            required: true,
                            message: 'Please enter your old password',
                          },
                        ]}>
                        <StyledInput
                          size='large'
                          placeholder='Enter your old password'
                          name='password'
                          type='password'
                          onChange={(e) =>
                            handleInputChange(e, formData, setFormData)
                          }
                        />
                      </StyledFormItem>
                    </InputField>
                  </InputFieldsWrapper>
                  <InputFieldsWrapper className='mb-4 pb-4'>
                    <InputField>
                      <Label>New Password*</Label>
                      <StyledFormItem
                        name='new_password'
                        // label='New Password'
                        rules={[
                          {
                            required: true,
                            message: 'Please input your password!',
                          },
                          {
                            min: 8,
                            message:
                              'Password must be at least 8 characters long!',
                          },
                          {
                            pattern:
                              /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/,
                            message:
                              'Password must include at least one letter, one number, and one special character!',
                          },
                        ]}>
                        <StyledInput
                          size='large'
                          placeholder='Enter your password'
                          name='new_password'
                          type='password'
                          onChange={(e) =>
                            handleInputChange(e, formData, setFormData)
                          }
                        />
                      </StyledFormItem>
                    </InputField>
                  </InputFieldsWrapper>
                  <InputFieldsWrapper className='mb-4 pb-4'>
                    <InputField>
                      <Label>Confirm New Password*</Label>
                      <StyledFormItem
                        name='confirm_password'
                        // label='Confirm New Password'
                        dependencies={['new_password']}
                        hasFeedback
                        rules={[
                          {
                            required: true,
                            message: 'Please confirm your new password',
                          },
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (
                                !value ||
                                getFieldValue('new_password') == value
                              ) {
                                return Promise.resolve();
                              }
                              return Promise.reject(
                                new Error(
                                  'The two passwords that you entered do not match!'
                                )
                              );
                            },
                          }),
                        ]}>
                        <StyledInput
                          size='large'
                          placeholder='Confirm new password'
                          name='confirm_password'
                          type='password'
                          onChange={(e) =>
                            handleInputChange(e, formData, setFormData)
                          }
                        />
                      </StyledFormItem>
                    </InputField>
                  </InputFieldsWrapper>
                  <SignInButton onClick={() => navigate(-1)} className='cancel'>
                    Cancel
                  </SignInButton>
                  <SignInButton type='primary' htmlType='submit'>
                    Submit
                  </SignInButton>
                </LoginFormContent>
              </LoginFormWrapper>
            </LoginContent>
          </LoginForm>
        </LoginWrapper>
      </LoginContainer>
    </>
  );
};

const LoginContainer = styled.main`
  background-color: #fff;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  min-height: 100vh;
`;

const LoginWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 100vh;
  align-items: flex-start;
  justify-content: center;
  @media (max-width: 991px) {
    max-width: 100%;
  }
  @media (max-width: 850px) {
    align-items: center;
  }
`;

const BackgroundImage = styled.img`
  position: absolute;
  inset: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
  @media (max-width: 850px) {
    display: none;
  }
`;

const LoginForm = styled(Form)`
  position: relative;
  background-color: #fff;
  display: flex;
  width: 50%;
  min-height: 100vh;
  max-width: 100%;
  flex-direction: column;
  overflow: hidden;
  padding: 40px 54px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  @media (max-width: 991px) {
    padding: 30px;
  }
  @media (max-width: 850px) {
    width: 100%;
    margin: auto;
    box-shadow: none;
  }
`;

const LoginContent = styled.div`
  display: flex;
  width: 100%;
  max-width: 410px;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
`;

const LoginHeader = styled.h2`
  width: 100%;
  color: #141c24;
  text-align: center;
  font: 600 28px/1.2 'Inter', sans-serif;
  margin-bottom: 36px;
`;

const LoginFormWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
`;

const LoginFormContent = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const LoginOptions = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  gap: 16px;
  color: #637083;
  text-align: center;
  justify-content: center;
  font: 400 14px 'Inter', sans-serif;
  margin-bottom: 32px;
`;

const Divider = styled.div`
  height: 1px;
  flex: 1;
  background-color: #e4e7ec;
`;

const OrSignInText = styled.span`
  white-space: nowrap;
`;

const InputFieldsWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 16px;
`;

const InputField = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const Label = styled.label`
  color: #253142;
  font: 500 14px/1.5 'Inter', sans-serif;
  margin-bottom: 6px;
`;

const StyledFormItem = styled(Form.Item)`
  margin-bottom: 0;
`;

const StyledInput = styled(Input.Password)`
  border-radius: 8px;
  background-color: #fff;
  width: 100%;
  padding: 12px;
  border: 1px solid #ced2da;
  font: 400 16px/1.5 'Inter', sans-serif;
  &:focus {
    border-color: #275df5;
    box-shadow: 0 0 0 2px rgba(39, 93, 245, 0.2);
  }
`;

const SignInButton = styled(AntButton)`
  width: 100%;
  height: auto;
  padding: 12px;
  font: 600 16px/1.5 'Inter', sans-serif;
  border-radius: 8px;
  margin-bottom: 16px;
  &:hover,
  &:focus {
    opacity: 0.9;
  }
`;

const mapStateToProps = (state) => ({
  changePasswordState: state.changePassword,
});

const mapDispatchToProps = (dispatch) => ({
  changePassword: (data) => dispatch(changePasswordApi(data)),
  changePasswordReset: () => dispatch(changePasswordReset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
