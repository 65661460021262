import axios from "axios";
import config from "../config";
import { getUserToken } from "../utils/helper";

export const ADD_CONFIGURATION = "ADD_CONFIGURATION";
export const ADD_CONFIGURATION_SUCCESS = "ADD_CONFIGURATION_SUCCESS";
export const ADD_CONFIGURATION_FAIL = "ADD_CONFIGURATION_FAIL";
export const ADD_CONFIGURATION_RESET = "ADD_CONFIGURATION_RESET";

export const addConfiguration = (params) => {
  return {
    type: ADD_CONFIGURATION,
    params,
  };
};

export const addConfigurationSuccess = (response) => {
  return {
    type: ADD_CONFIGURATION_SUCCESS,
    response,
  };
};

export const addConfigurationFail = (response) => {
  return {
    type: ADD_CONFIGURATION_FAIL,
    response,
  };
};

export const addConfigurationReset = () => {
  return {
    type: ADD_CONFIGURATION_RESET,
  };
};

export const addConfigurationApi = (data) => {
  return (dispatch) => {
    dispatch(addConfiguration());
    axios
      .post(`${config.api.base_url}/configurations/create`, data, {
        headers: {
          Authorization: "Bearer " + getUserToken(),
        },
      })
      .then((response) => {
        const addConfigurationData = response.data;
        dispatch(addConfigurationSuccess(addConfigurationData));
      })
      .catch((error) => {
        const errorMsg = error.message;
        dispatch(addConfigurationFail(errorMsg));
      });
  };
};
