import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Row, Col, notification, Radio, Switch, Tag, Form } from "antd";
import { Router, useNavigate } from "react-router-dom";
import {
  XSelect,
  XForm,
  XButton2,
  FilterButton,
  XSearch,
} from "../../styles/GlobalStyle";
import { XButtonImg } from "./ManageStyle";
import { PlusOutlined } from "@ant-design/icons";
import moment from "moment";
// Actions
import { getRolesApi, getRolesReset } from "../../actions/getRolesAction";
import {
  getRolesDropdownApi,
  getRolesDropdownReset,
} from "../../actions/getRolesDropdownAction";
import { getUsersApi, getUsersReset } from "../../actions/getUsersAction";
import { dateFilters } from "../../utils/constants";
// utils
import CaseSearch from "../../components/CaseSearch/CaseSearch";
import DataTable from "../../components/DataTable/DataTable";
import { hasPermission, urlPagination } from "../../utils/helper";

const UserManagement = (props) => {
  const {
    getUsers,
    getUsersReset,
    getUsersState,
    getRoles,
    getRolesState,
    getRolesDropdownState,
    getRolesDropdown,
  } = props;
  const navigate = useNavigate();
  const [form] = XForm.useForm();
  const [form2] = XForm.useForm();

  const defaultFilters = {
    search: "",
    page: 1,
    patient_name: "",
    role: "",
    created_on: "",
    limit: 10,
  };
  const [tableData, setTableData] = useState([]);
  const [totalRecords, setTotalRecords] = useState("");
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [open, setOpen] = useState(false);
  const getFiltersFromUrl = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const urlFilters = {};

    // Iterate through each query parameter and set it in the state
    for (const [key, value] of urlParams.entries()) {
      urlFilters[key] = value;
    }

    // Merge with default filters or initial state as needed
    const filters = {
      ...defaultFilters, // Replace with your default filters
      ...urlFilters,
    };

    return filters;
  };
  const initialFilters = getFiltersFromUrl();

  const [filters, setFilters] = useState(initialFilters);
  const showDrawer = () => {
    getRolesDropdown({ type: "super-admin" });
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const onChange = (key) => {
    setFilters({
      ...filters,
      [key.target.name]: key.target.value,
    });
  };
  const columns = [
    {
      title: "User Name",
      dataIndex: "userName",
    },
    {
      title: "Role",
      dataIndex: "role",
    },
    {
      title: "Organization",
      dataIndex: "organization",
    },
    {
      title: "Active",
      dataIndex: "is_active",
    },
    {
      title: "Joined On",
      dataIndex: "createdAt",
      sorter: (a, b) => a.createdAt - b.createdAt,
      sortDirections: ["ascend", "descend"],
    },
    {
      title: <div className="text-center">Action</div>,
      dataIndex: "action",
      width: "10%",
    },
  ];

  const applyFilters = () => {
    const queryParams = new URLSearchParams(window.location.search);

    // Iterate through filters and update the query parameters
    Object.entries(filters).forEach(([key, value]) => {
      const currentValue = queryParams.get(key);

      // Check if the parameter exists in the current query and has a value, if yes, update its value
      if (currentValue !== null) {
        queryParams.set(key, value);
      } else if (queryParams.has(key)) {
        // If the parameter exists but has no value, keep its existing value
        queryParams.set(key, queryParams.get(key));
      } else {
        // If the parameter does not exist in the current query, append it with the current value
        queryParams.append(key, value);
      }
    });

    // Set the page to 1
    queryParams.set("page", "1");
    queryParams.set("sort", "createdAt");
    queryParams.set("sortDirection", "descend");
    filters.page = 1;

    // Update the URL
    const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
    window.history.pushState({}, "", newUrl);
  };

  useEffect(() => {
    // Retrieve filters from the updated URL
    const updatedFilters = getFiltersFromUrl();
    setFilters({ ...updatedFilters });

    // Make API call with updated filters and pagination
    if (hasPermission("manage")) getUsers({ ...updatedFilters });
  }, [window.location.search]);

  const clearFilters = () => {
    const queryParams = new URLSearchParams(window.location.search);

    // Iterate through defaultFilters and update the query parameters
    Object.entries(defaultFilters).forEach(([key, value]) => {
      const currentValue = queryParams.get(key);

      // Check if the parameter exists in the current query and has a value, if yes, update its value
      if (currentValue !== null) {
        queryParams.set(key, value);
      } else if (queryParams.has(key)) {
        // If the parameter exists but has no value, keep its existing value
        queryParams.set(key, queryParams.get(key));
      } else {
        // If the parameter does not exist in the current query, append it with the default value
        queryParams.append(key, value);
      }
    });

    // Clear the form fields
    form2.resetFields();

    // Update the state with default values
    setFilters(defaultFilters);

    // Update the URL
    const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
    window.history.pushState({}, "", newUrl);
  };
  const handleUserEdit = (item) => {
    navigate(`user/${item._id}`);
  };

  // use effects

  useEffect(() => {
    if (getUsersState.apiState === "success") {
      if (getUsersState.data.all_users.length > 0) {
        setTotalRecords(getUsersState.data.total_count);
        const updatedUsers = [];
        getUsersState.data.all_users.map((item, index) => {
          updatedUsers.push({
            key: item._id,
            _id: item._id,
            userName: item.name,
            name: item.name,
            is_active: item.is_active ? (
              <Tag color="green">Active</Tag>
            ) : (
              <Tag color="red">Suspended</Tag>
            ),
            createdAt: moment(item.createdAt).format("DD-MM-YYYY"),
            role: item.role.display_name,
            action: (
              <div className="text-right">
                <Row>
                  <Col xs={{ span: 6, offset: 6 }}>
                    {hasPermission("manage", "update-users") ? (
                      <XButtonImg
                        onClick={() => {
                          handleUserEdit(item);
                        }}
                        type="text">
                        <img src="./images/pencil.svg" alt="" />
                      </XButtonImg>
                    ) : (
                      <></>
                    )}
                  </Col>
                </Row>
              </div>
            ),
          });
        });
        setTableData(updatedUsers);
      } else {
        setTableData([]);
        setTotalRecords(0);
      }
      getUsersReset();
    }
  }, [getUsersState]);

  // Functions

  return (
    <>
      <CaseSearch
        searchOption="userManagement"
        title="Users"
        filters={[
          <XForm.Item
            name="role"
            colon={false}
            style={{
              display: "flex",
              flexDirection: "column",
            }}
            label="Roles"
            className="removeFormMargin">
            <XSelect
              size="large"
              showSearch
              allowClear
              placeholder="Select Role"
              defaultValue={filters.role || null}
              onFocus={() => getRolesDropdown({ type: "super-admin" })}
              onChange={(value) => {
                setFilters({
                  ...filters,
                  role: value,
                });
              }}
              onClear={() => {
                setFilters({
                  ...filters,
                  role: "",
                });
              }}
              options={
                getRolesDropdownState.apiState === "success" &&
                getRolesDropdownState.data.all_roles.length > 0 &&
                getRolesDropdownState.data.all_roles.map((ele) => {
                  return {
                    key: ele._id,
                    value: ele.role,
                    label: ele.display_name,
                  };
                })
              }></XSelect>
          </XForm.Item>,
          <XForm.Item
            name="created_on"
            colon={false}
            label="Date Within"
            className="removeFormMargin">
            <Radio.Group
              onChange={onChange}
              name="created_on"
              defaultValue={filters.created_on || null}>
              <Row>
                {dateFilters.map((item, index) => (
                  <Col xs={12} lg={12} md={12} xl={12}>
                    <Radio value={item.value} className="filter-sub-item">
                      {item.label}
                    </Radio>
                  </Col>
                ))}
              </Row>
            </Radio.Group>
          </XForm.Item>,
        ]}
        onClose={onClose}
        open={open}
        applyFilters={applyFilters}
        form={form2}
        clearFilters={clearFilters}
      />

      <Row gutter={(15, 30)}>
        <Col
          style={{
            display: "flex",
            alignItems: "center",
          }}
          xs={24}
          md={10}
          lg={3}
          xl={3}
          xxl={3}>
          <p className="tableHeader mb-4">Total {totalRecords} Users</p>
        </Col>
        <Col
          xs={24}
          md={14}
          lg={{ span: 6 }}
          xl={{ span: 6 }}
          xxl={{ span: 6 }}>
          <XForm.Item>
            <XSearch
              placeholder="Search By User Name"
              allowClear
              enterButton="Search"
              size="large"
              defaultValue={filters.patient_name ? filters.patient_name : null}
              onChange={(v) => {
                if (v.target.value === "") {
                  urlPagination(filters, "patient_name", "");
                  setFilters({
                    ...filters,
                    patient_name: "",
                  });
                }
              }}
              onSearch={(v) => {
                urlPagination(filters, "patient_name", v);
                setFilters({
                  ...filters,
                  patient_name: v,
                });
              }}
            />
          </XForm.Item>
        </Col>
        <Col
          xs={24}
          md={12}
          lg={{ span: 3, offset: 8 }}
          xl={{ span: 3, offset: 8 }}
          xxl={{ span: 3, offset: 8 }}>
          <XForm.Item className="text-right">
            <FilterButton onClick={() => showDrawer()}>
              <img
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/d0e11f66e694b5fbdadd8edb8109ba7b24c06cb1f66c477f282ec451dfd237e7?placeholderIfAbsent=true&apiKey=ac9769c5e7eb416e8a74975078a910d6"
                alt="Filter icon"
              />
              Filter
            </FilterButton>
          </XForm.Item>
        </Col>
        <Col
          xs={24}
          md={12}
          lg={{ span: 4 }}
          xl={{ span: 4 }}
          xxl={{ span: 4 }}>
          {hasPermission("manage", "create-users") ? (
            <XForm.Item className="text-right">
              <FilterButton
                onClick={() => {
                  navigate(`user`);
                }}>
                <PlusOutlined />
                New User
              </FilterButton>
            </XForm.Item>
          ) : (
            <></>
          )}
        </Col>
      </Row>
      <DataTable
        columns={columns}
        tableData={tableData}
        setFilters={setFilters}
        filters={filters}
        totalRecords={totalRecords}
        state={getUsersState.apiState}
        page={"user-management"}
        handleEdit={handleUserEdit}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  getUsersState: state.getUsers,
  getRolesState: state.getRoles,
  getRolesDropdownState: state.getRolesDropdown,
});

const mapDispatchToProps = (dispatch) => ({
  getUsers: (params) => dispatch(getUsersApi(params)),
  getUsersReset: () => dispatch(getUsersReset()),
  getRoles: (params) => dispatch(getRolesApi(params)),
  getRolesDropdown: (params) => dispatch(getRolesDropdownApi(params)),
  getRolesReset: () => dispatch(getRolesReset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserManagement);
