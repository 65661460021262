import config from "../config";
import axios from "axios";
import { getUserToken } from "../utils/helper";

export const ADD_RESELLER = "ADD_RESELLER";
export const ADD_RESELLER_SUCCESS = "ADD_RESELLER_SUCCESS";
export const ADD_RESELLER_FAIL = "ADD_RESELLER_FAIL";
export const ADD_RESELLER_RESET = "ADD_RESELLER_RESET";

export const addReseller = (params) => {
  return {
    type: ADD_RESELLER,
    params,
  };
};

export const addResellerSuccess = (response) => {
  return {
    type: ADD_RESELLER_SUCCESS,
    response,
  };
};

export const addResellerFail = (response) => {
  return {
    type: ADD_RESELLER_FAIL,
    response,
  };
};

export const addResellerReset = () => {
  return {
    type: ADD_RESELLER_RESET,
  };
};

export const addResellerApi = (data) => {
  return (dispatch) => {
    dispatch(addReseller());
    axios
      .post(`${config.api.base_url}/resellers`, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getUserToken()}`,
        },
      })
      .then((response) => {
        const addResellerData = response.data;
        dispatch(addResellerSuccess(addResellerData));
      })
      .catch((error) => {
        const errorBody = error.response.data;
        dispatch(addResellerFail(errorBody));
      });
  };
};
