import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Card, Typography } from 'antd';
import {
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
  Legend,
  Tooltip,
} from 'recharts';
import { getJobCountsApi } from '../../actions/getJobCountsAction';
import { connect } from 'react-redux';

const { Title } = Typography;

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#AF19FF'];

const EmployerDistributionChart = (props) => {
  const { getJobCountsState, getJobCounts } = props;
  const [chartData, setChartData] = useState();

  // Fetch data from the API
  useEffect(() => {
    if (
      //   getJobCountsState &&
      getJobCountsState.apiState === 'success'
    ) {
      // You can update the chart data here
      const jobCounts = getJobCountsState.data;

      // Prepare labels and data for the pie chart
      const data = jobCounts.map((employer) => ({
        name: employer.employerName,
        value: employer.jobCount,
      }));

      // Update chart data
      setChartData(data);
    } else if (getJobCountsState && getJobCountsState.apiState === 'error') {
      console.error('Failed to fetch statistics:', getJobCountsState.error);
    }
  }, [getJobCountsState]);

  useEffect(() => {
    getJobCounts();
  }, []);

  return (
    <StyledCard>
      <Title level={4}>Employer Job Distribution</Title>
      <ResponsiveContainer width='100%' height={300}>
        <PieChart>
          <Pie
            data={chartData}
            cx='50%'
            cy='50%'
            labelLine={false}
            outerRadius={80}
            fill='#8884d8'
            dataKey='value'>
            {chartData &&
              chartData.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
          </Pie>
          <Tooltip />
          <Legend />
        </PieChart>
      </ResponsiveContainer>
      <TotalJobs>
        Total Jobs:{' '}
        {chartData &&
          chartData.reduce((sum, employer) => sum + employer.value, 0)}
      </TotalJobs>
    </StyledCard>
  );
};

const StyledCard = styled(Card)`
  width: 48%;
  margin-bottom: 24px;

  @media (max-width: 1200px) {
    width: 100%;
  }
`;

const TotalJobs = styled.div`
  text-align: center;
  margin-top: 16px;
  font-weight: bold;
`;

const mapStateToProps = (state) => ({
  getJobCountsState: state.getJobCounts,
});

const mapDispatchToProps = (dispatch) => ({
  getJobCounts: (params) => dispatch(getJobCountsApi(params)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EmployerDistributionChart);
