import React from "react";
import styled from "styled-components";
import { Avatar } from "antd";
import { capitalizeAndSplitByDash } from "../../utils/helper";

const WelcomeSection = ({ userData }) => {
  return (
    <WelcomeSectionContainer>
      <WelcomeText>Welcome {userData.companyName},</WelcomeText>
      <ProfileInfo>
        <CompanyLogo
          src={`/images/${userData.userRole}.png`}
          alt="Company Logo"
        />
        <ProfileDetails>
          <CompanyName>{userData.companyName}</CompanyName>
          <UserRole>{capitalizeAndSplitByDash(userData.userRole)}</UserRole>
        </ProfileDetails>
      </ProfileInfo>
    </WelcomeSectionContainer>
  );
};

const WelcomeSectionContainer = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1120px;
  margin: 35px auto 0;
  padding: 0 20px;
  font-family: Poppins, sans-serif;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  }
`;

const WelcomeText = styled.h2`
  color: #275df5;
  font-size: 40px;
  font-weight: 600;
  line-height: 1.4;

  @media (max-width: 768px) {
    font-size: 32px;
  }
`;

const ProfileInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 22px;

  @media (max-width: 480px) {
    gap: 12px;
  }
`;

const CompanyLogo = styled.img`
  width: 60px;
  height: 60px;
  border-radius: 16px;
  object-fit: contain;

  @media (max-width: 480px) {
    width: 48px;
    height: 48px;
  }
`;

const ProfileDetails = styled.div`
  display: flex;
  flex-direction: column;
`;

const CompanyName = styled.span`
  color: #151d48;
  font-size: 16px;
  font-weight: 500;
`;

const UserRole = styled.span`
  color: #737791;
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  margin-top: 4px;
`;

export default WelcomeSection;
