import React, { useEffect, useState } from "react";
import {
  capitalizeAndSplitByDash,
  daysUntil,
  getSignedUrlForGetObject,
} from "../../../utils/helper.js";
import styled, { keyframes } from "styled-components";
import { useNavigate } from "react-router-dom";
import { ArrowRightOutlined } from "@ant-design/icons";
import { Button } from "antd";

const FeaturedJobs = ({ selectedJobs }) => {
  const navigate = useNavigate();
  const [hoveredCard, setHoveredCard] = useState(null);
  const [isViewAllHovered, setIsViewAllHovered] = useState(false);

  return (
    <FeaturedJobsWrapper>
      <FeaturedJobsContainer>
        <Header>
          <Title>
            Featured <span className="highlight">Jobs</span>
          </Title>
          <ViewAllButton onClick={() => navigate("/explore?featured=1")}>
            View All <ArrowRightOutlined />
          </ViewAllButton>
          {/* <ViewAllButton onClick={() => navigate("/explore?featured=1")}>
            <span>View All</span>
            <ArrowRightOutlined />
          </ViewAllButton> */}
        </Header>
        <JobGrid>
          {selectedJobs &&
            selectedJobs.length > 0 &&
            selectedJobs.map((job, index) => (
              <JobCard
                key={index}
                {...job}
                isHovered={hoveredCard === index}
                onMouseEnter={() => setHoveredCard(index)}
                onMouseLeave={() => setHoveredCard(null)}
              />
            ))}
        </JobGrid>
      </FeaturedJobsContainer>
    </FeaturedJobsWrapper>
  );
};

const JobCard = ({
  logo,
  _id,
  job_title,
  employment_type,
  city,
  salary,
  salary_defined_type,
  salary_max,
  salary_min,
  expiry_date,
  skills,
  job_description,
  isHovered,
  onMouseEnter,
  onMouseLeave,
  s3_folder_name,
  country,
}) => {
  const navigate = useNavigate();

  const [isApplyHovered, setIsApplyHovered] = useState(false);
  const [logoUrl, setLogoUrl] = useState("");

  useEffect(() => {
    const fetchLogoUrl = async () => {
      const url = await getSignedUrlForGetObject(
        "employers",
        logo,
        s3_folder_name
      );
      setLogoUrl(url);
    };

    fetchLogoUrl();
  }, [logo, s3_folder_name]);
  return (
    <JobCardWrapper
      isHovered={isHovered}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}>
      <JobInfo>
        <EmployerLogo backgroundColor={logo.backgroundColor}>
          <img src={logoUrl} alt={"Logo"} />
        </EmployerLogo>
        <JobDetails>
          <JobTitle>
            <h3>{job_title}</h3>
            <JobType>{capitalizeAndSplitByDash(employment_type)}</JobType>
          </JobTitle>
          <JobMetadata>
            <MetadataItem>
              <img
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/3d3bcde6297965994169154628627b7bda17f95fc22518345cb7951d20fa4021?placeholderIfAbsent=true&apiKey=ac9769c5e7eb416e8a74975078a910d6"
                alt="Location icon"
              />
              <span>{city}</span>
            </MetadataItem>
            <MetadataItem>
              {country === "India" ? (
                <img
                  src="/images/greyrupee.svg"
                  alt="Salary icon"
                  style={{ height: "14.45px", width: "11px" }}
                />
              ) : (
                <img
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/546ba1844e9359eb057f6b1ceab80c4166fdf78e1d7ab24002e0b28509f7e7f8?placeholderIfAbsent=true&apiKey=ac9769c5e7eb416e8a74975078a910d6"
                  alt="Salary icon"
                />
              )}
              <span>
                {salary_defined_type === "fixed"
                  ? salary
                  : `${salary_min} - ${salary_max}`}
              </span>
            </MetadataItem>
            <MetadataItem>
              <img
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/72a1dab8a6cbe1838b4ceacd3e200ce10f08201440beb6c8ed12e23e93a5ebd3?placeholderIfAbsent=true&apiKey=ac9769c5e7eb416e8a74975078a910d6"
                alt="Time icon"
              />
              <span>{daysUntil(expiry_date)}</span>
            </MetadataItem>
          </JobMetadata>
        </JobDetails>
      </JobInfo>
      <ApplyButton
        onClick={() => navigate(`/explore/${_id}`)}
        isHovered={isHovered}
        onMouseEnter={() => setIsApplyHovered(true)}
        onMouseLeave={() => setIsApplyHovered(false)}>
        <span>Apply Now</span>
        <ArrowRightOutlined />
      </ApplyButton>
      <SkillTags>
        {skills.map((skill, index) => (
          <SkillTag key={index}>{skill.skill}</SkillTag>
        ))}
      </SkillTags>
      <JobDescription>{job_description}</JobDescription>
    </JobCardWrapper>
  );
};

const FeaturedJobsWrapper = styled.section`
  background-color: #fff;
  display: flex;
  margin-top: 4px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 104px 80px;
  @media (max-width: 1200px) {
    padding: 80px 60px;
  }
  @media (max-width: 991px) {
    padding: 60px 40px;
  }
  @media (max-width: 768px) {
    padding: 40px 20px;
  }
`;

const FeaturedJobsContainer = styled.div`
  display: flex;
  width: 100%;
  max-width: 1120px;
  flex-direction: column;
  @media (max-width: 1200px) {
    max-width: 960px;
  }
  @media (max-width: 991px) {
    max-width: 720px;
  }
  @media (max-width: 768px) {
    max-width: 540px;
  }
`;

const Header = styled.header`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const Title = styled.h2`
  text-align: center;
  font-size: 40px;
  font-weight: 500;
  line-height: 1.2;
  color: #191f33;
  margin: 0;
  .highlight {
    font-weight: 600;
    color: #275df5;
  }
  @media (max-width: 991px) {
    font-size: 36px;
  }
  @media (max-width: 768px) {
    font-size: 32px;
  }
`;

const pulse = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
`;

const ViewAllButton = styled(Button)`
  &&& {
    font-size: 16px;
    font-weight: 600;
    text-transform: capitalize;
    height: auto;
    padding: 12px 24px;
    border: 1px solid #eeeeee;
    border-radius: 3px;
    display: flex;
    align-items: center;
    gap: 12px;
    color: #275df5;
    background-color: transparent;
    transition: all 0.3s ease;
    &:hover {
      background-color: #275df5;
      color: #fff;
      animation: ${pulse} 0.5s ease-in-out;
    }
    .anticon {
      font-size: 24px;
      transition: transform 0.3s ease;
    }
    &:hover .anticon {
      transform: translateX(5px);
    }
    @media (max-width: 768px) {
      width: 100%;
      justify-content: center;
    }
  }
`;

const JobGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  margin-top: 58px;
  @media (max-width: 991px) {
    grid-template-columns: 1fr;
    margin-top: 40px;
  }
`;

const JobCardWrapper = styled.article`
  border-radius: 12px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 32px;
  border: 1px solid ${(props) => (props.isHovered ? "#275df5" : "#edefF5")};
  box-shadow: ${(props) =>
    props.isHovered ? "0px 12px 48px 0px rgba(0, 44, 109, 0.1)" : "none"};
  transition: all 0.3s ease;
  &:hover {
    box-shadow: 0px 12px 48px 0px rgba(0, 44, 109, 0.1);
  }
  @media (max-width: 768px) {
    padding: 24px;
  }
`;

const JobInfo = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 20px;
  @media (max-width: 576px) {
    flex-direction: column;
  }
`;

const EmployerLogo = styled.div`
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 68px;
  height: 68px;
  flex-shrink: 0;
  img {
    border-radius: 6px;
    width: 100%; /* Make the image fully fill the container */
    height: 100%; /* Adjust height to fit container */
    object-fit: contain; /* Keep the aspect ratio and ensure it fits */
  }
`;

const SkillTags = styled.div`
  display: flex;
  margin-top: 24px;
  flex-wrap: wrap;
  gap: 7px;
`;

const SkillTag = styled.span`
  border-radius: 3px;
  background-color: #efefef;
  color: #656565;
  font-size: 10px;
  font-weight: 500;
  padding: 4px 8px;
  white-space: nowrap;
`;

const JobDescription = styled.p`
  color: rgba(20, 20, 20, 0.7);
  margin-top: 24px;
  font: 400 16px/24px DM Sans, sans-serif;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const JobDetails = styled.div`
  display: flex;
  flex-direction: column;
  font-family: Inter, sans-serif;
`;

const JobTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  flex-wrap: wrap;
  h3 {
    color: #191f33;
    font-size: 20px;
    font-weight: 500;
    line-height: 1.6;
    margin: 0;
  }
  @media (max-width: 576px) {
    h3 {
      font-size: 18px;
    }
  }
`;

const JobType = styled.span`
  border-radius: 52px;
  background-color: #e8f1ff;
  font-size: 14px;
  color: #275df5;
  font-weight: 400;
  padding: 3px 12px;
  @media (max-width: 576px) {
    font-size: 12px;
  }
`;

const JobMetadata = styled.div`
  display: flex;
  margin-top: 14px;
  align-items: center;
  gap: 16px;
  font-size: 14px;
  color: #636a80;
  font-weight: 400;
  flex-wrap: wrap;
  @media (max-width: 576px) {
    font-size: 12px;
    gap: 12px;
  }
`;

const MetadataItem = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  img {
    width: 22px;
    height: 22px;
  }
  @media (max-width: 576px) {
    img {
      width: 18px;
      height: 18px;
    }
  }
`;

const ApplyButton = styled.button`
  border-radius: 3px;
  background-color: ${(props) => (props.isHovered ? "#275df5" : "#e7f0fa")};
  color: ${(props) => (props.isHovered ? "#fff" : "#275df5")};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  font-size: 16px;
  font-weight: 600;
  text-transform: capitalize;
  margin-top: 24px;
  padding: 12px 24px;
  border: none;
  cursor: pointer;
  width: 100%;
  transition: all 0.3s ease;
  &:hover {
    background-color: #275df5;
    color: #fff;
  }
  @media (max-width: 576px) {
    font-size: 14px;
    padding: 10px 20px;
  }
`;

export default FeaturedJobs;
