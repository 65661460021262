import config from "../config";
import axios from "axios";
import { getUserToken } from "../utils/helper";

export const GET_JOB_LIST = "GET_JOB_LIST";
export const GET_JOB_LIST_SUCCESS = "GET_JOB_LIST_SUCCESS";
export const GET_JOB_LIST_FAIL = "GET_JOB_LIST_FAIL";
export const GET_JOB_LIST_RESET = "GET_JOB_LIST_RESET";

export const getJobList = (params) => {
  return {
    type: GET_JOB_LIST,
    params,
  };
};

export const getJobListSuccess = (response) => {
  return {
    type: GET_JOB_LIST_SUCCESS,
    response,
  };
};

export const getJobListFail = (response) => {
  return {
    type: GET_JOB_LIST_FAIL,
    response,
  };
};

export const getJobListReset = () => {
  return {
    type: GET_JOB_LIST_RESET,
  };
};

export const getJobListApi = (params) => {
  return (dispatch) => {
    dispatch(getJobList());
    axios
      .get(`${config.api.base_url}/job-inventory`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getUserToken()}`,
        },
        params,
      })
      .then((response) => {
        const getJobListData = response.data;
        dispatch(getJobListSuccess(getJobListData));
      })
      .catch((error) => {
        const errorData = error.response.data;
        dispatch(getJobListFail(errorData));
      });
  };
};
