import axios from "axios";
import config from "../config";
import { getUserToken } from "../utils/helper";

export const GET_JOB_APPLICATIONS = "GET_JOB_APPLICATIONS";
export const GET_JOB_APPLICATIONS_SUCCESS = "GET_JOB_APPLICATIONS_SUCCESS";
export const GET_JOB_APPLICATIONS_FAIL = "GET_JOB_APPLICATIONS_FAIL";
export const GET_JOB_APPLICATIONS_RESET = "GET_JOB_APPLICATIONS_RESET";

export const getJobApplications = (params) => {
  return {
    type: GET_JOB_APPLICATIONS,
    params,
  };
};

export const getJobApplicationsSuccess = (response) => {
  return {
    type: GET_JOB_APPLICATIONS_SUCCESS,
    response,
  };
};

export const getJobApplicationsFail = (response) => {
  console.log("action response fail=>", response);
  return {
    type: GET_JOB_APPLICATIONS_FAIL,
    response,
  };
};

export const getJobApplicationsReset = () => {
  return {
    type: GET_JOB_APPLICATIONS_RESET,
  };
};

export const getJobApplicationsApi = (params) => {
  console.log(params);
  return (dispatch) => {
    dispatch(getJobApplications());
    axios
      .get(`${config.api.base_url}/application/get-job-applications`, {
        params,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + getUserToken(),
        },
      })
      .then((response) => {
        const getJobApplicationsData = response.data;
        dispatch(getJobApplicationsSuccess(getJobApplicationsData));
      })
      .catch((error) => {
        const errorData = error.response.data;
        dispatch(getJobApplicationsFail(errorData));
      });
  };
};
