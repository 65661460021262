import React from "react";
import { Navigate } from "react-router-dom";
import { isLogin } from "../utils/helper";
import { connect } from "react-redux";

const PrivateRoute = (props) => {
  const { children } = props;
  let loggedIn = isLogin();

  if (!loggedIn) {
    return <Navigate to="/login"></Navigate>;
  }
  return children;
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute);
