import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Form,
  Input,
  Select,
  Card,
  Radio,
  notification,
  TimePicker,
  Result,
  Button,
} from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Wrapper } from './../../styles/Button';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { SubmitBtn, SubmitNoBgBtn } from '../../styles/Button';
import { connect } from 'react-redux';
import {
  addJobToInventoryApi,
  addJobToInventoryReset,
} from '../../actions/addJobToInventoryAction';
import { getJobFromInventoryApi } from '../../actions/getJobFromInventoryAction';
import {
  updateJobInventoryApi,
  updateJobInventoryReset,
} from '../../actions/updateJobInventoryAction';
import { getIndustriesApi } from '../../actions/getIndustriesAction';
import {
  getIndustryCategoriesApi,
  getIndustryCategoriesReset,
} from '../../actions/getIndustryCategoriesAction';
import { getStatesApi } from '../../actions/getStatesAction';
import { getCountriesApi } from '../../actions/getCountriesAction';
import dayjs from 'dayjs';
import { getSkillsApi } from '../../actions/getSkillsAction';
import { getQualificationApi } from '../../actions/getQualificationAction';
import { getJobFromInventoryReset } from '../../actions/getJobFromInventoryAction';
import { filterByLabel, handleKeyDown } from '../../utils/formFunctions';
import {
  employment_type_list,
  experience_level_list,
  salary_type_lsit,
  work_type_list,
} from '../../utils/constants';
import { hasPermission } from '../../utils/helper';
import { Title } from '../Login/LoginStyle';
import { XCardsTransparent } from '../../styles/GlobalStyle';

const AddUpdateJobInventory = (props) => {
  const { TextArea } = Input;
  const id = useParams().id;
  const navigate = useNavigate();
  const location = useLocation();

  const {
    addJobToInventoryState,
    addJobToInventory,
    getJobFromInventoryState,
    getJobFromInventory,
    updateJobInventory,
    updateJobInventoryState,
    getIndustries,
    getIndustriesState,
    getIndustryCategories,
    getIndustryCategoriesState,
    getIndustryCategoriesReset,
    getStates,
    getStatesState,
    getCountries,
    getCountriesState,
    getSkillsState,
    getSkills,
    getQualificationState,
    getQualification,
    getJobFromInventoryReset,
    addJobToInventoryReset,
    updateJobInventoryReset,
  } = props;

  const [form] = Form.useForm();

  const [industry, setIndustry] = useState([]);
  const [industryOptions, setIndustryOptions] = useState([]);
  const [qualificationOptions, setQualificationOptions] = useState([]);
  const [qualification, setQualification] = useState([]);
  const [title, setTitle] = useState('Edit Job');
  const [locationDetails, setLocationDetails] = useState({
    country: null,
    state: null,
    city: null,
  });
  const [cities, setCities] = useState([]); // To store cities of the selected state

  const [formData, setFormData] = useState({
    address: '',
    category_id: '',
    category: '',
    city: '',
    industry_id: '',
    industry: [],
    job_description: '',
    employment_type: '',
    number_of_openings: '',
    pincode: null,
    salary: '',
    salary_type: 'per_hour',
    special_requirements: '',
    state: '',
    country: '',
    country_code: '',
    job_title: '',
    work_type: '',
    salary_defined_type: 'range',
    salary_min: '',
    salary_max: '',
    skills: [],
    education_qualification: [],
  });

  useEffect(() => {
    getIndustries();
    getCountries({});
    getIndustryCategoriesReset();
    if (id) {
      getJobFromInventory({ id });
    } else {
      form.setFieldsValue(formData);
    }
  }, []);

  useEffect(() => {
    if (location.pathname === '/inventory/add') setTitle('Add New Job');
  }, [location]);

  useEffect(() => {
    if (getJobFromInventoryState.apiState === 'success' && id) {
      setFormData({
        ...getJobFromInventoryState.data,
        pincode: getJobFromInventoryState.data?.pincode.toString(),
        // pincode: toString(getJobFromInventoryState.data.pincode),
        skills: getJobFromInventoryState.data.skills.map((skill) => skill._id),
        education_qualification:
          getJobFromInventoryState.data.Qualification.map(
            (qualification) => qualification._id
          ),
        expiry_date: getJobFromInventoryState.data.expiry_date,
      });
      form.setFieldsValue({
        ...getJobFromInventoryState.data,
        skills: getJobFromInventoryState.data.skills.map(
          (skill) => skill.skill
        ),
        education_qualification:
          getJobFromInventoryState.data.Qualification.map(
            (qualification) => qualification.qualification
          ),
        expiry_date: dayjs(getJobFromInventoryState.data.expiry_date),
      });
    } else if (getJobFromInventoryState.apiState === 'error') {
      notification.error({
        message: 'Unable to Load Job Details',
      });
    }
    getJobFromInventoryReset();
  }, [getJobFromInventoryState]);

  useEffect(() => {
    if (addJobToInventoryState.apiState === 'success') {
      notification.success({
        message: 'Job Added Successfully',
      });
      navigate('/inventory');
      addJobToInventoryReset();
    } else if (addJobToInventoryState.apiState === 'error') {
      notification.error({
        message: addJobToInventoryState.message ?? 'Error',
      });
      // Handle field-specific validation errors
      const fieldErrors = addJobToInventoryState.error;
      const fields = Object.keys(fieldErrors).map((key) => {
        const message = fieldErrors[key]
          .replace(/_/g, ' ')
          .replace(/\b\w/g, (char) => char.toUpperCase());

        return {
          name: key,
          errors: [message],
        };
      });
      form.setFields(fields);
    }
  }, [addJobToInventoryState.apiState]);

  useEffect(() => {
    if (updateJobInventoryState.apiState === 'success') {
      notification.success({
        message: 'Job Updated Successfully',
      });
      navigate('/inventory');
      updateJobInventoryReset();
    } else if (updateJobInventoryState.apiState === 'error') {
      notification.error({
        message: updateJobInventoryState.error.message ?? 'Error',
      });
      // Handle field-specific validation errors
      const fieldErrors = updateJobInventoryState.error.errors;
      const fields = Object.keys(fieldErrors).map((key) => {
        const message = fieldErrors[key]
          .replace(/_/g, ' ')
          .replace(/\b\w/g, (char) => char.toUpperCase());

        return {
          name: key,
          errors: [message],
        };
      });
      form.setFields(fields);
    }
  }, [updateJobInventoryState.apiState, navigate]);

  //Get Industry list
  useEffect(() => {
    if (getIndustriesState.apiState === 'success') {
      setIndustry(getIndustriesState.data.all_industries);
      const industries = industry.map((ind) => {
        return {
          value: ind._id,
          label: ind.industry_name,
          type: 'industry',
        };
      });
      setIndustryOptions(industries);
    }
  }, [getIndustriesState.apiState, industry]);

  //Get Qualification list
  useEffect(() => {
    if (
      getQualificationState.apiState === 'success' &&
      getQualificationState.data.length > 0
    ) {
      setQualification(getQualificationState.data);
      const qualifications = getQualificationState.data.map((item) => {
        return {
          value: item._id,
          label: item.qualification,
          type: 'education_qualification',
        };
      });
      setQualificationOptions(qualifications);
    }
  }, [getQualificationState.apiState, qualification]);

  useEffect(() => {
    if (getCountriesState.apiState === 'success') {
      if (getCountriesState.data) {
        // Format the API data
        const formattedCountryData = getCountriesState.data.map((country) => ({
          value: country.country,
          label: country.country,
          type: 'country',
          id: country.country_abbreviation,
        }));

        setLocationDetails({
          ...locationDetails,
          country: formattedCountryData,
        });
      }
    }
  }, [getCountriesState.apiState]);

  useEffect(() => {
    if (formData.country_code) {
      getStates({ country: formData.country_code });
    }
  }, [formData.country_code]);

  useEffect(() => {
    if (getStatesState.apiState === 'success') {
      if (getStatesState.data) {
        // Format the API data for states
        const formattedStateData = getStatesState.data.all_states.map(
          (state) => ({
            value: state.state,
            label: state.state,
            type: 'state',
            cities: state.cities, // Include cities for later use
          })
        );

        setLocationDetails((prevState) => ({
          ...prevState,
          state: formattedStateData,
        }));
      }
    }
  }, [getStatesState.apiState]);

  //API getStates
  const handleStateChange = (selectedState) => {
    setFormData((prev) => ({
      ...prev,
      state: selectedState,
    }));

    // Find the selected state from the formatted state data
    const selectedStateData = locationDetails.state.find(
      (state) => state.value === selectedState
    );

    // Set the cities of the selected state
    if (selectedStateData) {
      setCities(
        selectedStateData.cities.map((city) => ({
          value: city,
          label: city,
          type: 'city',
        }))
      );
    }
  };

  const handleInputChange = (e, name) => {
    const { value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSelectChange = (e, option) => {
    if (option.type === 'category' || option.type === 'industry') {
      setFormData({
        ...formData,
        [`${option.type}_id`]: e,
        [option.type]: option.label,
      });

      return;
    }
    setFormData({
      ...formData,
      [option.type]: e,
    });
  };

  const onFinish = () => {
    if (id) updateJobInventory({ ...formData, id: id });
    else addJobToInventory(formData);
  };

  useEffect(() => {
    if (formData.industry.length > 0) {
      const industry_data = [formData.industry_id];
      getIndustryCategories({ industries: industry_data });
    }
  }, [formData.industry]);

  return (
    <>
      {hasPermission('jobs-inventory', 'list-jobs') ? (
        <>
          <div className='mainContent'>
            <div className='pageWrapper'>
              <Wrapper>
                <Card className='mb-3'>
                  <Row>
                    <Col xs={24} className='d-flex gap-3'>
                      <Button
                        onClick={() => navigate(-1)}
                        icon={<ArrowLeftOutlined />}
                        size='large'
                      />
                      {id ? <Title>Edit Job</Title> : <Title>Add Job</Title>}
                    </Col>
                  </Row>
                </Card>
                <XCardsTransparent>
                  <Form
                    form={form}
                    name='add_job'
                    layout='vertical'
                    autoComplete='off'
                    onFinish={onFinish}>
                    <Row gutter={[30, 0]} className='m-0'>
                      <Col xs={24} md={12}>
                        <Form.Item
                          label='Job Title'
                          className='formLable'
                          name='job_title'
                          rules={[
                            {
                              required: true,
                              message: 'Please input Job Title!',
                            },
                          ]}>
                          <Input
                            onKeyDown={handleKeyDown}
                            name='job_title'
                            className='searchInputBrder removeBg'
                            size='large'
                            placeholder='Enter job tiltle'
                            onChange={(e) => {
                              handleInputChange(e, 'job_title');
                            }}
                          />
                        </Form.Item>
                      </Col>
                      {/* add job type start */}
                      <Col span={24} md={12}>
                        <Form.Item
                          colon={false}
                          label='Employment Type'
                          className='formLable'
                          rules={[
                            {
                              required: true,
                              message: 'Please input Job Type!',
                            },
                          ]}
                          name='employment_type'>
                          <Select
                            size='large'
                            placeholder='Select Employment Type'
                            className='removeBg'
                            onChange={handleSelectChange}
                            value={
                              formData.employment_type
                                ? formData.employment_type
                                : null
                            }
                            options={employment_type_list}
                          />
                        </Form.Item>
                      </Col>
                      {/* add job type end */}
                      <Col xs={24} md={12}>
                        <Form.Item
                          colon={false}
                          label='Industry'
                          className='formLable'
                          name='industry'
                          rules={[
                            {
                              required: true,
                              message: 'Please input Industry!',
                            },
                          ]}>
                          <Select
                            showSearch
                            filterOption={(input, option) =>
                              option.label
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                            size='large'
                            className='removeBg'
                            onChange={handleSelectChange}
                            value={
                              formData.industry
                                ? formData.industry
                                : 'Select Industry'
                            }
                            options={industryOptions}
                            placeholder='Select Industry'
                          />
                        </Form.Item>
                      </Col>
                      {/* add category start */}
                      <Col span={24} md={12}>
                        <Form.Item
                          colon={false}
                          label='Category'
                          rules={[
                            {
                              required: true,
                              message: 'Please input Category!',
                            },
                          ]}
                          name='category'>
                          <Select
                            size='large'
                            placeholder='Select Category'
                            className='removeBg'
                            onChange={handleSelectChange}
                            disabled={
                              getIndustryCategoriesState.apiState == 'success'
                                ? false
                                : true
                            }
                            value={
                              formData.category
                                ? formData.category
                                : 'Select Category'
                            }
                            options={
                              getIndustryCategoriesState.apiState ==
                                'success' &&
                              getIndustryCategoriesState.data.all_categories
                                .length > 0
                                ? getIndustryCategoriesState.data.all_categories.map(
                                    (item) => {
                                      return {
                                        value: item.id,
                                        label: item.category_name,
                                        type: 'category',
                                      };
                                    }
                                  )
                                : []
                            }
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} md={12}>
                        <Form.Item
                          label='Country'
                          className='formLable'
                          name={'country'}
                          rules={[
                            {
                              required: true,
                              message: 'please input country!',
                            },
                          ]}>
                          <Select
                            size='large'
                            placeholder='Select Country'
                            className='removeBg'
                            allowClear
                            showSearch
                            filterOption={filterByLabel}
                            onChange={(e, option) => {
                              handleSelectChange(e, option);
                              setFormData((prev) => ({
                                ...prev,
                                country_code: option.id,
                              }));
                            }}
                            value={
                              formData.country
                                ? formData.country
                                : 'Select Country'
                            }
                            options={locationDetails.country}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} md={12}>
                        <Form.Item
                          label='State'
                          className='formLable'
                          name={'state'}
                          rules={[
                            {
                              required: true,
                              message: 'please input state!',
                            },
                          ]}>
                          <Select
                            size='large'
                            placeholder='Select State'
                            className='removeBg'
                            allowClear
                            showSearch
                            filterOption={filterByLabel}
                            onChange={handleStateChange}
                            value={
                              formData.state ? formData.state : 'Select State'
                            }
                            options={locationDetails.state}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} md={12}>
                        <Form.Item
                          label='City'
                          className='formLable'
                          name={'city'}
                          rules={[
                            {
                              required: true,
                              message: 'please input city!',
                            },
                          ]}>
                          <Select
                            size='large'
                            placeholder='Select City'
                            className='removeBg'
                            allowClear
                            showSearch
                            filterOption={filterByLabel}
                            onChange={handleSelectChange}
                            value={
                              formData.city ? formData.city : 'Select City'
                            }
                            options={cities}
                          />
                        </Form.Item>
                      </Col>{' '}
                      <Col xs={24} md={12}>
                        <Form.Item
                          label='Address'
                          className='formLable'
                          name={'address'}
                          rules={[
                            {
                              required: true,
                              message: 'please input address!',
                            },
                          ]}>
                          <TextArea
                            onKeyDown={handleKeyDown}
                            className='removeBg'
                            placeholder='Address'
                            rows={3}
                            name='address'
                            onChange={(e) => {
                              handleInputChange(e, 'address');
                            }}
                            value={
                              formData.address
                                ? formData.address
                                : 'please input address!'
                            }
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} md={12}>
                        <Form.Item
                          label='Pincode'
                          name='pincode'
                          className='formLable'
                          rules={[
                            {
                              required: true,
                              message: 'Please enter pincode',
                            },
                          ]}>
                          <Input
                            onKeyDown={handleKeyDown}
                            name='pincode'
                            className='searchInputBrder removeBg'
                            size='large'
                            placeholder='Enter Pincode'
                            onChange={(e) => {
                              handleInputChange(e, 'pincode');
                            }}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} lg={12}>
                        <Form.Item
                          colon={false}
                          label='Skills'
                          name='skills'
                          rules={[
                            {
                              required: true,
                              message: 'Please Input your Skills!',
                            },
                          ]}>
                          <Select
                            showSearch
                            allowClear
                            onFocus={() => {
                              getSkills({});
                            }}
                            size='large'
                            className='removeBg'
                            mode='multiple'
                            placeholder='Enter skills'
                            onSearch={(e) => {
                              if (e.length > 2) {
                                getSkills({
                                  search: e,
                                });
                              } else if (e.length === 0) getSkills({});
                            }}
                            filterOption={filterByLabel}
                            onChange={(e) => {
                              setFormData({
                                ...formData,
                                skills: e,
                              });
                              form.setFieldsValue({
                                skills: e,
                              });
                            }}
                            value={formData.skills}
                            options={
                              getSkillsState.apiState == 'success' &&
                              getSkillsState.data.length > 0
                                ? getSkillsState.data.map((item) => {
                                    return {
                                      value: item._id,
                                      label: item.skill,
                                      type: 'skill',
                                      id: item._id,
                                    };
                                  })
                                : []
                            }></Select>
                        </Form.Item>
                      </Col>
                      {/* work space start */}
                      <Col span={24} md={12}>
                        <Form.Item
                          colon={false}
                          label='Work Type'
                          rules={[
                            {
                              required: true,
                              message: 'Please Select Work Type!',
                            },
                          ]}
                          name='work_type'>
                          <Select
                            size='large'
                            placeholder='Select Work Type'
                            className='removeBg'
                            onChange={handleSelectChange}
                            value={
                              formData.work_type
                                ? formData.work_type
                                : 'Select Work Type'
                            }
                            options={work_type_list}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={24} md={12}>
                        <Form.Item
                          colon={false}
                          label='Experience Level'
                          rules={[
                            {
                              required: true,
                              message: 'Please Select Experience Level!',
                            },
                          ]}
                          name='experience_level'>
                          <Select
                            mode='multiple'
                            size='large'
                            className='removeBg'
                            onChange={handleSelectChange}
                            placeholder='Select Experience Level'
                            value={
                              formData.experience_level
                                ? formData.experience_level
                                : 'Select Experience Level'
                            }
                            options={experience_level_list}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={24} md={12}>
                        <Form.Item
                          colon={false}
                          label='Interview Process'
                          rules={[
                            {
                              required: true,
                              message: 'Please Enter Interview Process!',
                            },
                          ]}
                          name='interview_process'>
                          <Input
                            onKeyDown={handleKeyDown}
                            className='removeBg'
                            placeholder='Interview Process'
                            size='large'
                            name='interview_process'
                            onChange={(e) => {
                              handleInputChange(e, 'interview_process');
                            }}
                            value={
                              formData.interview_process
                                ? formData.interview_process
                                : 'Please Enter Interview Process!'
                            }
                          />
                        </Form.Item>
                      </Col>
                      <Col span={24} md={12}>
                        <Form.Item
                          colon={false}
                          label='Company Website'
                          rules={[
                            {
                              required: true,
                              message: 'Please Enter Company Website!',
                            },
                          ]}
                          name='company_website'>
                          <Input
                            addonBefore='https://'
                            onKeyDown={handleKeyDown}
                            className='removeBg'
                            placeholder='Company Website'
                            size='large'
                            name='company_website'
                            onChange={(e) => {
                              handleInputChange(e, 'company_website');
                            }}
                            value={
                              formData.company_website
                                ? formData.company_website
                                : 'Please Enter Company Website!'
                            }
                          />
                        </Form.Item>
                      </Col>
                      <Col span={24} md={12}>
                        <Form.Item
                          colon={false}
                          label='Job Reference Number'
                          rules={[
                            {
                              required: true,
                              message: 'Please Enter Job Reference Number!',
                            },
                          ]}
                          name='job_reference_number'>
                          <Input
                            onKeyDown={handleKeyDown}
                            className='removeBg'
                            placeholder='Job Reference Number'
                            size='large'
                            name='job_reference_number'
                            onChange={(e) => {
                              handleInputChange(e, 'job_reference_number');
                            }}
                            value={
                              formData.job_reference_number
                                ? formData.job_reference_number
                                : 'Please Enter Job Reference Number!'
                            }
                          />
                        </Form.Item>
                      </Col>
                      {/* work space end */}
                      <Col xs={24} lg={12}>
                        <Form.Item
                          colon={false}
                          label='Education Qualification'
                          name='education_qualification'
                          rules={[
                            {
                              required: true,
                              message: 'please input Highest Qualification!',
                            },
                          ]}>
                          <Select
                            showSearch
                            allowClear
                            mode='multiple'
                            className=' removeBg'
                            placeholder='Enter Qualification'
                            size='large'
                            name='education_qualification'
                            onChange={(e, value) => {
                              setFormData({
                                ...formData,
                                education_qualification: e,
                              });
                              form.setFieldsValue({
                                education_qualification: e,
                              });
                            }}
                            filterOption={filterByLabel}
                            onSearch={(search) => {
                              if (search.length > 2) {
                                getQualification({
                                  search,
                                });
                              } else if (search.length === 0)
                                getQualification({});
                            }}
                            onFocus={() => {
                              getQualification({});
                            }}
                            value={
                              formData.education_qualification
                                ? formData.education_qualification
                                : 'Select Qualification'
                            }
                            options={
                              qualificationOptions.length > 0
                                ? qualificationOptions
                                : []
                            }
                          />
                        </Form.Item>
                      </Col>
                      {/* add no. of openings start */}
                      <Col span={24} md={12}>
                        <Form.Item
                          colon={false}
                          name='number_of_openings'
                          label='No. of Openings'
                          rules={[
                            {
                              required: true,
                              message: 'Please input No. of Openings!',
                              pattern: new RegExp(/^[0-9]+$/),
                            },
                          ]}>
                          <Input
                            onKeyDown={handleKeyDown}
                            className='searchInputBrder removeBg'
                            controls={false}
                            size='large'
                            placeholder='Eg. 4'
                            onChange={(e) => {
                              handleInputChange(e, 'number_of_openings');
                            }}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={24} md={12}>
                        <Form.Item colon={false} label='Salary'>
                          <Card className='formCard'>
                            <Form.Item
                              colon={false}
                              name='salary_defined_type'
                              style={{
                                marginBottom: 0,
                              }}
                              rules={[
                                {
                                  required: true,
                                  message: 'Please Salary Type!',
                                },
                              ]}>
                              <Radio.Group
                                className='customRadio mb-4'
                                onChange={(e) => {
                                  handleInputChange(e, 'salary_defined_type');
                                }}
                                defaultValue={formData.salary_defined_type}>
                                <Radio value={'range'}>Range</Radio>
                                <Radio value={'fixed'}>Fixed</Radio>
                              </Radio.Group>
                            </Form.Item>
                            <Row align='middle' gutter={[30, 30]}>
                              {formData.salary_defined_type === 'range' && (
                                <>
                                  <Col xs={24} lg={8}>
                                    {/* <Row align='middle' gutter={[15, 15]}> */}
                                    <Form.Item
                                      colon={false}
                                      label='From (Annually)'
                                      name='salary_min'
                                      style={{
                                        marginBottom: 0,
                                      }}
                                      rules={[
                                        {
                                          required:
                                            formData.salary_defined_type ===
                                            'range'
                                              ? true
                                              : false,
                                          message:
                                            'Please input minimum salary!',
                                          pattern: new RegExp(/^[0-9]+$/),
                                        },
                                      ]}>
                                      <Input
                                        onKeyDown={handleKeyDown}
                                        className='searchInputBrder'
                                        size='large'
                                        placeholder='Enter Amount'
                                        prefix={
                                          formData.country === 'India'
                                            ? '₹'
                                            : '$'
                                        }
                                        controls={false}
                                        onChange={(e) => {
                                          handleInputChange(e, 'salary_min');
                                        }}
                                      />
                                    </Form.Item>
                                  </Col>
                                  <Col xs={24} lg={8}>
                                    <Form.Item
                                      colon={false}
                                      label='To (Annually)'
                                      name='salary_max'
                                      rules={[
                                        {
                                          required:
                                            formData.salary_defined_type ===
                                            'range'
                                              ? true
                                              : false,
                                          message:
                                            'Please input maximum salary!',
                                          pattern: new RegExp(/^[0-9]+$/),
                                        },
                                      ]}
                                      style={{
                                        marginBottom: '0',
                                      }}>
                                      <Input
                                        onKeyDown={handleKeyDown}
                                        className='searchInputBrder'
                                        size='large'
                                        placeholder='Enter Amount'
                                        controls={false}
                                        prefix={
                                          formData.country === 'India'
                                            ? '₹'
                                            : '$'
                                        }
                                        onChange={(e) => {
                                          handleInputChange(e, 'salary_max');
                                        }}
                                      />
                                    </Form.Item>
                                    {/* </Row> */}
                                  </Col>
                                </>
                              )}
                              {formData.salary_defined_type === 'fixed' && (
                                <Col xs={24} lg={8}>
                                  <Form.Item
                                    label='Enter Salary'
                                    colon={false}
                                    name='salary'
                                    rules={[
                                      {
                                        required:
                                          formData.salary_defined_type ===
                                          'fixed'
                                            ? true
                                            : false,
                                        message: 'Please input Salary!',
                                        pattern: new RegExp(/^[0-9]+$/),
                                      },
                                    ]}
                                    style={{
                                      marginBottom: 0,
                                    }}>
                                    <Input
                                      onKeyDown={handleKeyDown}
                                      className='searchInputBrder'
                                      size='large'
                                      placeholder='Enter Amount'
                                      prefix={
                                        formData.country === 'India' ? '₹' : '$'
                                      }
                                      controls={false}
                                      onChange={(e) => {
                                        handleInputChange(e, 'salary');
                                      }}
                                    />
                                  </Form.Item>
                                </Col>
                              )}
                              <Col xs={24} lg={8}>
                                <Form.Item
                                  colon={false}
                                  label='Salary Type'
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Please input Salary Type!',
                                    },
                                  ]}
                                  style={{
                                    marginBottom: 0,
                                  }}
                                  name='salary_type'>
                                  <Select
                                    size='large'
                                    className='removeBg searchInputBrder'
                                    onChange={handleSelectChange}
                                    value={
                                      formData.salary_type
                                        ? formData.salary_type
                                        : 'Select Salary Type'
                                    }
                                    options={salary_type_lsit}
                                  />
                                </Form.Item>
                              </Col>
                            </Row>
                          </Card>
                        </Form.Item>
                      </Col>
                      {/* limited period job end */}
                      <Col span={24} md={12}>
                        <Form.Item
                          label='Job Description'
                          name='job_description'
                          className='formLable'
                          rules={[
                            {
                              required: true,
                              message: 'Please input Job Description!',
                            },
                          ]}>
                          <TextArea
                            onKeyDown={handleKeyDown}
                            className='removeBg'
                            placeholder='Enter Job description'
                            rows={4}
                            onChange={(e) => {
                              handleInputChange(e, 'job_description');
                            }}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={24} md={12}>
                        <Form.Item
                          label='Special Requirements'
                          name='special_requirements'
                          className='formLable'>
                          <TextArea
                            onKeyDown={handleKeyDown}
                            className='removeBg'
                            placeholder='Enter special requirements'
                            rows={4}
                            onChange={(e) => {
                              handleInputChange(e, 'special_requirements');
                            }}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={24} md={12}>
                        <Form.Item
                          label='Desirable'
                          name='desirable'
                          className='formLable'
                          rules={[
                            {
                              required: true,
                              message: 'Please input Desirable!',
                            },
                          ]}>
                          <TextArea
                            onKeyDown={handleKeyDown}
                            className='removeBg'
                            placeholder='Enter Desirable'
                            rows={4}
                            onChange={(e) => {
                              handleInputChange(e, 'desirable');
                            }}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={24} md={12}>
                        <Form.Item
                          label='Benefits'
                          name='benefits'
                          className='formLable'
                          rules={[
                            {
                              required: true,
                              message: 'Please input Benefits!',
                            },
                          ]}>
                          <TextArea
                            onKeyDown={handleKeyDown}
                            className='removeBg'
                            placeholder='Enter Benefits'
                            rows={4}
                            onChange={(e) => {
                              handleInputChange(e, 'benefits');
                            }}
                          />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row className='text-end px-3' align='right'>
                      <Col
                        xs={24}
                        md={24}
                        className='d-flex justify-content-end align-items-center'>
                        <SubmitNoBgBtn
                          type='primary'
                          onClick={() => {
                            navigate('/inventory');
                          }}>
                          Cancel
                        </SubmitNoBgBtn>
                        <SubmitBtn
                          type='primary'
                          htmlType='submit'
                          style={{
                            marginLeft: '10px',
                          }}>
                          Save Changes
                        </SubmitBtn>
                      </Col>
                    </Row>
                  </Form>
                </XCardsTransparent>
              </Wrapper>
            </div>
          </div>
        </>
      ) : (
        <Result
          status='403'
          title='403'
          subTitle='Sorry, you are not authorized to access this page.'
          extra={<Button type='primary'>Back Home</Button>}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  addJobToInventoryState: state.addJobToInventory,
  getJobFromInventoryState: state.getJobFromInventory,
  updateJobInventoryState: state.updateJobInventory,
  getIndustriesState: state.getIndustries,
  getIndustryCategoriesState: state.getIndustryCategories,
  getStatesState: state.getStates,
  getCountriesState: state.getCountries,
  getSkillsState: state.getSkills,
  getQualificationState: state.getQualification,
  getJobFromInventoryResetState: state.getJobFromInventoryReset,
  addJobToInventoryResetState: state.addJobToInventoryReset,
  updateJobInventoryResetState: state.updateJobInventoryReset,
});

const mapDispatchToProps = (dispatch) => ({
  addJobToInventory: (params) => dispatch(addJobToInventoryApi(params)),
  getJobFromInventory: (params) => dispatch(getJobFromInventoryApi(params)),
  updateJobInventory: (params) => dispatch(updateJobInventoryApi(params)),
  getIndustries: (params) => dispatch(getIndustriesApi(params)),
  getIndustryCategories: (params) => dispatch(getIndustryCategoriesApi(params)),
  getIndustryCategoriesReset: () => dispatch(getIndustryCategoriesReset()),
  getStates: (params) => dispatch(getStatesApi(params)),
  getCountries: (params) => dispatch(getCountriesApi(params)),
  getSkills: (params) => dispatch(getSkillsApi(params)),
  getQualification: (params) => dispatch(getQualificationApi(params)),
  getJobFromInventoryReset: (params) =>
    dispatch(getJobFromInventoryReset(params)),
  addJobToInventoryReset: (params) => dispatch(addJobToInventoryReset(params)),
  updateJobInventoryReset: (params) =>
    dispatch(updateJobInventoryReset(params)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddUpdateJobInventory);
