import axios from "axios";
import config from "../config";
import { getUserToken } from "../utils/helper";

export const GET_CONFIGURATIONS = "GET_CONFIGURATIONS";
export const GET_CONFIGURATIONS_SUCCESS = "GET_CONFIGURATIONS_SUCCESS";
export const GET_CONFIGURATIONS_FAIL = "GET_CONFIGURATIONS_FAIL";
export const GET_CONFIGURATIONS_RESET = "GET_CONFIGURATIONS_RESET";

export const getConfigurations = (params) => {
  return {
    type: GET_CONFIGURATIONS,
    params,
  };
};

export const getConfigurationsSuccess = (response) => {
  return {
    type: GET_CONFIGURATIONS_SUCCESS,
    response,
  };
};

export const getConfigurationsFail = (response) => {
  return {
    type: GET_CONFIGURATIONS_FAIL,
    response,
  };
};

export const getConfigurationsReset = () => {
  return {
    type: GET_CONFIGURATIONS_RESET,
  };
};

export const getConfigurationsApi = (data) => {
  return (dispatch) => {
    dispatch(getConfigurations());
    axios
      .get(`${config.api.base_url}/configurations/all`, {
        params: data,
        headers: {
          Authorization: "Bearer " + getUserToken(),
        },
      })
      .then((response) => {
        const getConfigurationsData = response.data;
        dispatch(getConfigurationsSuccess(getConfigurationsData));
      })
      .catch((error) => {
        const errorMsg = error.message;
        dispatch(getConfigurationsFail(errorMsg));
      });
  };
};
