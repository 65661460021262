import {
  DELETE_CONFIGURATION,
  DELETE_CONFIGURATION_SUCCESS,
  DELETE_CONFIGURATION_FAIL,
  DELETE_CONFIGURATION_RESET,
} from "../actions/deleteConfigurationAction";

const initialState = {
  apiState: "",
  message: "",
  data: null,
  error: "",
};

const deleteConfigurationReducer = (state = initialState, action) => {
  switch (action.type) {
    case DELETE_CONFIGURATION:
      return {
        ...state,
        apiState: "loading",
      };
    case DELETE_CONFIGURATION_SUCCESS:
      return {
        ...state,
        apiState: "success",
        data: action.response.data,
        message: action.response.message ? action.response.message : "success",
      };
    case DELETE_CONFIGURATION_FAIL:
      return {
        ...state,
        apiState: "error",
        error: action.response.errors,
        message: action.response.message ? action.response.message : "error",
      };
    case DELETE_CONFIGURATION_RESET:
      return initialState;
    default:
      return state;
  }
};

export default deleteConfigurationReducer;
