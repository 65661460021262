import {
  GET_CANDIDATE_JOB_LIST_GUEST,
  GET_CANDIDATE_JOB_LIST_GUEST_SUCCESS,
  GET_CANDIDATE_JOB_LIST_GUEST_FAIL,
  GET_CANDIDATE_JOB_LIST_GUEST_RESET,
} from "../actions/getCandidateJobListGuestAction";

const initialState = {
  apiState: "",
  data: null,
  error: "",
};

const getCandidateJobListGuestReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CANDIDATE_JOB_LIST_GUEST:
      return {
        ...state,
        apiState: "loading",
      };
    case GET_CANDIDATE_JOB_LIST_GUEST_SUCCESS:
      return {
        ...state,
        apiState: "success",
        data: action.response.data,
        message: action.response.message ? action.response.message : "success",
      };
    case GET_CANDIDATE_JOB_LIST_GUEST_FAIL:
      return {
        ...state,
        apiState: "error",
        error: action.response.message ? action.response.message : "error",
      };
    case GET_CANDIDATE_JOB_LIST_GUEST_RESET:
      return initialState;
    default:
      return state;
  }
};

export default getCandidateJobListGuestReducer;
