import styled from "styled-components";
import "../../styles/GlobalStyle";
import { Tabs, Card, Button, Avatar } from "antd";

export const XCardsTransparentTabs = styled(Card)`
  border: none;
  background: none !important;
  .ant-card-body {
    padding: 0px !important;
    .ant-tabs-nav-wrap {
      background: var(
        --Linear,
        linear-gradient(
          90deg,
          rgba(104, 144, 229, 1) 0%,
          /* Lighter shade of #1e4fd0 */ rgba(72, 112, 218, 0.85) 35%,
          /* Medium-light shade */ rgba(45, 90, 213, 0.75) 65%,
          /* Medium shade */ rgba(30, 79, 208, 1) 100%
        )
      );
      border-radius: 20px;
      padding: 10px;
    }
    .ant-tabs-nav::before {
      display: none;
    }
    .ant-tabs-content-holder {
      border-radius: 20px;
      border: 1px solid ${({ theme }) => theme.colors.color3};
      background: rgba(255, 255, 255, 0.7) !important;
      padding: 30px;
      @media only screen and (max-width: 768.99px) {
        padding: 0px !important;
        border:none;
      }
    }
    .ant-tabs-tab {
      border-radius: 15px !important;
      border: 1px solid ${({ theme }) => theme.colors.color3};
      background: rgba(255, 255, 255, 0.25);
      padding: 10px 20px;
      margin-right: 15px;
      .ant-tabs-tab-btn {
        color: ${({ theme }) => theme.colors.color2};
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.32px;
      }
    }
    .ant-tabs-tab.ant-tabs-tab-active {
      background: ${({ theme }) => theme.colors.white};
      box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.05);
      .ant-tabs-tab-btn {
        color: ${({ theme }) => theme.colors.menu2} !important;
      }
    }
    @media only screen and (max-width: 768.99px) {
      padding: 0px !important;
    }
  }
`;
export const XTabs = styled(Tabs)`
  .ant-tabs-tabpane {
  }
  @media only screen and (max-width: 768.99px) {
    font-size: 14px;
  }
`;
export const XButtonImg = styled(Button)`
  svg {
    margin-top: -2px;
  }
  box-shadow: none;
`;

export const XButtonClose = styled(Button)`
  svg {
    margin-top: -2px;
  }
  width: 100% !important;
  display: flex;
  width: 140px;
  height: 40px;
  padding: 6px 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 30px;
  border: 1px solid #dadee3;
  background: var(--White, #fff);
  margin-bottom: 10px;

  /* sd-button/hover */
  box-shadow: 0px 0px 0px 1px rgba(70, 79, 96, 0.32),
    0px 1px 1px 0px rgba(0, 0, 0, 0.1);
`;
export const XAvatar = styled(Avatar)`
  background: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.menu2};
  outline: 1px solid ${({ theme }) => theme.colors.white};
`;
