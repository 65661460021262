import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Modal, Button, Select, Radio, Tag, Switch } from 'antd';
import { Flex, Input, Space, notification, Form } from 'antd';
import { useNavigate } from 'react-router-dom';
import {
  XTable,
  XModal,
  XForm,
  XButton2,
  XSearch,
  FilterButton,
} from '../../styles/GlobalStyle';
import { XButtonImg } from './ManageStyle';
import CaseSearch from '../../components/CaseSearch/CaseSearch';
import { EditOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import moment from 'moment';
// Actions
import {
  getDefaultRolesApi,
  getDefaultRolesReset,
} from '../../actions/getDefaultRolesAction';
import { getModulesApi, getModulesReset } from '../../actions/getModulesAction';
import {
  getRoleByIdApi,
  getRoleByIdReset,
} from '../../actions/getRoleByIdAction';
import { createRoleApi, createRoleReset } from '../../actions/createRoleAction';
import { editRoleApi, editRoleReset } from '../../actions/editRoleAction';
import { deleteRoleApi, deleteRoleReset } from '../../actions/deleteRoleAction';
// utils
import { handleInputChange } from '../../utils/formFunctions';
import DataTable from '../../components/DataTable/DataTable';
import {
  checkModulePermissions,
  hasPermission,
  urlPagination,
} from '../../utils/helper';
import { dateFilters } from '../../utils/constants';

const DefaultRoleManagement = (props) => {
  const {
    getDefaultRoles,
    getDefaultRolesReset,
    getDefaultRolesState,
    getModules,
    getModulesReset,
    getModulesState,
    getRoleById,
    getRoleByIdReset,
    getRoleByIdState,
    createRole,
    createRoleReset,
    createRoleState,
    deleteRole,
    deleteRoleReset,
    deleteRoleState,
    editRole,
    editRoleReset,
    editRoleState,
  } = props;

  const defaultFilters = {
    search: '',
    page: 1,
    role: '',
    created_on: '',
    limit: 10,
  };
  const [form] = XForm.useForm();
  const [formData, setFormData] = useState({});
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [modalOpen, setModalOpen] = useState([false, '']);
  const [tableData, setTableData] = useState([]);
  const [moduleList, setModuleList] = useState([]);
  const [editRoleActive, setEditRoleActive] = useState(false);
  const [totalRecords, setTotalRecords] = useState('');
  const [currentPermissions, setCurrentPermissions] = useState([]);
  const [displayOrganization, setDisplayOrganization] = useState(false);

  const getFiltersFromUrl = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const urlFilters = {};

    // Iterate through each query parameter and set it in the state
    for (const [key, value] of urlParams.entries()) {
      urlFilters[key] = value;
    }

    // Merge with default filters or initial state as needed
    const filters = {
      ...defaultFilters, // Replace with your default filters
      ...urlFilters,
    };

    return filters;
  };
  const initialFilters = getFiltersFromUrl();

  const [filters, setFilters] = useState(initialFilters);

  useEffect(() => {
    // Retrieve filters from the updated URL
    const updatedFilters = getFiltersFromUrl();
    setFilters({ ...updatedFilters });

    // Make API call with updated filters and pagination
    if (hasPermission('manage'))
      getDefaultRoles({ type: 'super-admin', ...updatedFilters });
  }, [window.location.search]);

  useEffect(() => {
    if (getDefaultRolesState.apiState === 'success') {
      if (getDefaultRolesState.data.all_roles.length > 0) {
        const updatedTableData = [];
        setTotalRecords(getDefaultRolesState.data.total_count);
        getDefaultRolesState.data.all_roles.map((item, index) => {
          console.log(item);
          updatedTableData.push({
            key: item._id,
            _id: item._id,
            role: item.display_name,
            modules:
              item.permissions.map((mod) => {
                return (
                  <Tag style={{ margin: '2px' }}>{mod.module?.module_name}</Tag>
                );
              }) || '-',
            display_name: item.display_name,
            createdOn: (
              <div className='showDate'>
                {moment(item.createdAt).format('DD-MM-YYYY')}
              </div>
            ),
            action: (
              <div className='text-center'>
                {hasPermission('manage', 'update-roles') ? (
                  <XButtonImg
                    onClick={() => {
                      handleEdit(item);
                    }}
                    type='text'>
                    <img src='./images/pencil.svg' alt='' />
                  </XButtonImg>
                ) : (
                  <></>
                )}
              </div>
            ),
          });
        });
        setTableData(updatedTableData);
        getDefaultRolesReset();
      } else {
        setTableData([]);
        setTotalRecords(0);
      }
    }
  }, [getDefaultRolesState]);

  useEffect(() => {
    if (getModulesState.apiState === 'success') {
      if (getModulesState.data.length > 0) {
        let module_list = [];

        getModulesState.data.map((item) => {
          let sub_modules = [];

          item.sub_modules.map((itm) => {
            let sub_mod = {
              id: itm._id,
              name: itm.sub_module_name,
              included: false,
            };

            if (editRole) {
              let subModuleCheck = currentPermissions?.filter((mod) => {
                if (item._id === mod.module[0]._id) {
                  if (mod.sub_modules?.includes(itm._id)) {
                    return mod;
                  }
                }
              });
              if (subModuleCheck.length > 0) {
                sub_mod.included = true;
              }
            }
            sub_modules.push(sub_mod);
          });

          module_list.push({
            id: item._id,
            name: item.module_name,
            sub_modules,
          });
        });

        setModuleList(module_list);
      }
      // else {
      // 	notification.error({
      // 		message: 'No Modules defined for Super admin',
      // 	});
      // }
      getModulesReset();
    }
  }, [getModulesState]);

  useEffect(() => {
    if (createRoleState.apiState === 'success') {
      form.resetFields();
      setFormData({});
      createRoleReset();
      setModuleList([]);
      getDefaultRoles({ type: 'super-admin' });
      setCurrentPermissions([]);
      notification.success({
        message: createRoleState.message,
      });
      setModalOpen([false, '']);
    }
    if (createRoleState.apiState === 'error') {
      notification.error({
        message: createRoleState.message,
      });
      createRoleReset();
    }
  }, [createRoleState]);

  useEffect(() => {
    if (editRoleState.apiState === 'success') {
      form.resetFields();
      setFormData({});
      editRoleReset();
      setModuleList([]);
      getDefaultRoles({ type: 'super-admin' });
      setCurrentPermissions([]);
      notification.success({
        message: editRoleState.message,
      });
      setModalOpen([false, '']);
      setEditRoleActive(false);
    }
    if (editRoleState.apiState === 'error') {
      notification.error({
        message: editRoleState.message,
      });
      editRoleReset();
      setEditRoleActive(false);
    }
  }, [editRoleState]);

  useEffect(() => {
    if (deleteRoleState.apiState === 'success') {
      notification.success({
        message: deleteRoleState.message,
      });
      deleteRoleReset();
      setEditRoleActive(false);
      getDefaultRoles({ type: 'super-admin' });
    }
  }, [deleteRoleState]);

  useEffect(() => {
    if (getRoleByIdState.apiState === 'success') {
      setCurrentPermissions(getRoleByIdState.data.permissions);
      setFormData({
        id: getRoleByIdState.data._id,
        role_name: getRoleByIdState.data.display_name,
      });
      form.setFieldsValue({
        id: getRoleByIdState.data._id,
        role_name: getRoleByIdState.data.display_name,
      });
      getRoleByIdReset();
    }
  }, [getRoleByIdState]);

  // Functions
  const showDeleteConfirm = (_id) => {
    setModalOpen(false);
    XModal.confirm({
      title: 'Delete Role?',
      content: (
        <p className='modalPara1'>Are you sure you want to delete this role?</p>
      ),
      icon: <img className='me-3' src='/images/delete-icon.svg' alt='' />,
      okText: 'Yes, Confirm',
      okType: 'danger',
      cancelText: 'No, Cancel',
      className: 'confirmModal',
      onOk() {
        deleteRole({ id: _id });
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  const subModuleSelected = (checked, moduleKey, subModuleKey) => {
    const updatedModuleList = [...moduleList];
    updatedModuleList[moduleKey] = {
      ...updatedModuleList[moduleKey],
      sub_modules: [...updatedModuleList[moduleKey].sub_modules],
    };
    updatedModuleList[moduleKey].sub_modules[subModuleKey] = {
      ...updatedModuleList[moduleKey].sub_modules[subModuleKey],
      included: checked,
    };
    setModuleList(updatedModuleList);
  };

  const moduleSelected = (value, moduleKey) => {
    const updatedModuleList = moduleList.map((module, index) => {
      if (index === moduleKey) {
        const updatedSubModules = module.sub_modules.map((subModule) => {
          return { ...subModule, included: value };
        });
        return { ...module, sub_modules: updatedSubModules };
      }
      return module;
    });

    setModuleList(updatedModuleList);
  };

  const isAnySubModuleUnchecked = (moduleKey) => {
    const selectedModule = moduleList[moduleKey];
    const uncheckedSubModule = selectedModule.sub_modules.find(
      (subModule) => !subModule.included
    );
    return !!uncheckedSubModule;
  };

  const updateRoleDetails = (id) => {
    editRole({
      id: formData.id,
      role: formData.role_name,
      permissions: moduleList,
    });
  };

  const saveRole = () => {
    form
      .validateFields()
      .then(() => {
        const allIncludedFalse = moduleList.every((module) =>
          module.sub_modules.every((subModule) => !subModule.included)
        );
        if (allIncludedFalse) {
          form.setFields([
            {
              name: 'permissions',
              errors: ['Please give access to atleast one module'],
            },
          ]);
          return;
        } else {
          createRole({
            role: formData.role_name,
            permissions: moduleList,
          });
        }
      })
      .catch((err) => {});
  };

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const handleEdit = (item) => {
    setEditRoleActive(true);
    getRoleById({ id: item._id });
    getModules({
      type: 'super-admin',
    });
    setFormData({
      _id: item._id,
      role: item.display_name,
    });
    // setModalOpen([true, `Edit ${item.display_name}`]);
  };

  //Edit Modal display only after the state has changed so that the data get loaded completly
  useEffect(() => {
    if (editRoleActive) setModalOpen([true, `Edit ${formData.role}`]);
  }, [editRoleActive]);

  useEffect(() => {
    if (localStorage.getItem('role') === 'super-admin')
      setDisplayOrganization(true);
  }, []);

  const columns = [
    {
      title: 'Role',
      dataIndex: 'role',
      width: 200,
    },
    {
      title: 'Modules',
      dataIndex: 'modules',
      width: 700,
    },
    {
      title: 'Created on',
      dataIndex: 'createdOn',
    },
    {
      title: <div className='text-center'>Action</div>,
      dataIndex: 'action',
      width: '10%',
    },
  ];

  return (
    <>
      <Row gutter={(15, 30)}>
        <Col
          style={{
            display: 'flex',
            alignItems: 'center',
            paddingBottom: '20px',
          }}
          xs={24}
          md={10}
          lg={4}
          xl={4}
          xxl={4}>
          <p className='tableHeader'>Total {totalRecords} Roles</p>
        </Col>
        <Col
          xs={24}
          md={14}
          lg={{ span: 6 }}
          xl={{ span: 6 }}
          xxl={{ span: 6 }}>
          <XForm.Item>
            <XSearch
              placeholder='Search By Role Name'
              allowClear
              enterButton='Search'
              size='large'
              defaultValue={filters.role_name ? filters.role_name : null}
              onChange={(v) => {
                if (v.target.value === '') {
                  urlPagination(filters, 'role_name', '');
                  setFilters({
                    ...filters,
                    role_name: '',
                  });
                }
              }}
              onSearch={(v) => {
                urlPagination(filters, 'role_name', v);
                setFilters({
                  ...filters,
                  role_name: v,
                });
              }}
            />
          </XForm.Item>
        </Col>

        <Col
          xs={24}
          md={12}
          lg={{ span: 4, offset: 10 }}
          xl={{ span: 4, offset: 10 }}
          xxl={{ span: 4, offset: 10 }}>
          {hasPermission('manage', 'create-roles') ? (
            <FilterButton
              className='text-right'
              onClick={() => {
                getModules({ type: 'super-admin' });
                setModalOpen([true, 'Add Role']);
              }}
              style={{ marginBottom: '20px' }}>
              <PlusOutlined /> New Role
            </FilterButton>
          ) : (
            <></>
          )}
        </Col>
      </Row>
      <DataTable
        columns={columns}
        tableData={tableData}
        state={getDefaultRolesState.apiState}
        totalRecords={totalRecords}
        setFilters={setFilters}
        filters={filters}
        page={'role-management'}
        handleEdit={handleEdit}
        showDeleteConfirm={showDeleteConfirm}
      />
      <XModal
        title={modalOpen[1]}
        centered
        open={modalOpen[0]}
        // onOk={() => {
        // 	editRoleActive ? updateRoleDetails() : saveRole();
        // }}
        onCancel={() => {
          form.resetFields();
          setFormData({});
          setCurrentPermissions([]);
          setModalOpen([false, '']);
          setEditRoleActive(false);
        }}
        cancelButtonProps={{ hidden: 'none' }}
        okButtonProps={{ hidden: 'none' }}
        className='modalButtonBoth'
        okText='Submit'
        zIndex={1100}
        width={850}>
        <XForm form={form} name='roleForm' layout='vertical' autoComplete='off'>
          <Row gutter={(15, 0)} className='mt-4'>
            <Col xs={24} lg={12}>
              <XForm.Item
                name='role_name'
                label='Enter Role Name'
                rules={[
                  {
                    required: true,
                    message: 'Please enter role name',
                  },
                ]}>
                <Input
                  size='large'
                  placeholder='Enter Role Name'
                  name='role_name'
                  onChange={(e) => handleInputChange(e, formData, setFormData)}
                />
              </XForm.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={24}>
              <XForm.Item name='permissions'>
                <div className='customLabel mb-2'>Give Access</div>
              </XForm.Item>
            </Col>
          </Row>
          <div
            className='customScroll'
            style={{
              overflowX: 'hidden',
              overflowY: 'scroll',
              height: 300,
            }}>
            {moduleList.length > 0 &&
              moduleList.map((item, module_key) => (
                <>
                  <Row>
                    <Col xs={24}>
                      <div className='customHeader'>
                        <Flex justify='space-between' align='flex-start'>
                          <span>{item.name}</span>
                          <span>
                            <Switch
                              defaultChecked
                              size='small'
                              onChange={(value) =>
                                moduleSelected(value, module_key)
                              }
                              checked={!isAnySubModuleUnchecked(module_key)}
                            />
                          </span>
                        </Flex>
                      </div>
                    </Col>
                  </Row>
                  <Row gutter={(15, 20)} className='mt-4'>
                    <Col xs={24} sm={24} md={24} className='my-3'>
                      <Row>
                        {item.sub_modules.length > 0 &&
                          item.sub_modules.map((itm, sub_module_key) => (
                            <>
                              <Col xs={18} lg={3} md={4} className='mb-4'>
                                <span>{itm.name}</span>
                              </Col>
                              <Col xs={6} lg={3} md={4} className='mb-4'>
                                <Switch
                                  size='small'
                                  checked={itm.included === true}
                                  onChange={(checked) =>
                                    subModuleSelected(
                                      checked,
                                      module_key,
                                      sub_module_key
                                    )
                                  }
                                />
                              </Col>
                            </>
                          ))}
                      </Row>
                    </Col>
                  </Row>
                </>
              ))}
          </div>
          <Row
            className='mt-4'
            gutter={[15, 15]}
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}>
            <Col xs={12} md={6} lg={5}>
              {hasPermission('manage', 'delete-roles') ? (
                <Button
                  onClick={() => showDeleteConfirm(formData.id)}
                  type='text'
                  className='modalButton'
                  style={{
                    background: '#fff',
                    color: '#000',
                  }}>
                  Delete
                </Button>
              ) : (
                <></>
              )}
            </Col>
            <Col xs={12} md={6} lg={5}>
              {hasPermission('manage', 'update-roles') ? (
                <Button
                  onClick={() =>
                    editRoleActive ? updateRoleDetails() : saveRole()
                  }
                  type='text'
                  className='modalButton'>
                  Save
                </Button>
              ) : (
                <></>
              )}
            </Col>
          </Row>
        </XForm>
      </XModal>
    </>
  );
};

const mapStateToProps = (state) => ({
  getDefaultRolesState: state.getDefaultRoles,
  getModulesState: state.getModules,
  getRoleByIdState: state.getRoleById,
  createRoleState: state.createRole,
  deleteRoleState: state.deleteRole,
  editRoleState: state.editRole,
});

const mapDispatchToProps = (dispatch) => ({
  getDefaultRoles: (params) => dispatch(getDefaultRolesApi(params)),
  getDefaultRolesReset: () => dispatch(getDefaultRolesReset()),
  getModules: (params) => dispatch(getModulesApi(params)),
  getModulesReset: () => dispatch(getModulesReset()),
  getRoleById: (params) => dispatch(getRoleByIdApi(params)),
  getRoleByIdReset: () => dispatch(getRoleByIdReset()),
  createRole: (params) => dispatch(createRoleApi(params)),
  createRoleReset: () => dispatch(createRoleReset()),
  deleteRole: (params) => dispatch(deleteRoleApi(params)),
  deleteRoleReset: () => dispatch(deleteRoleReset()),
  editRole: (params) => dispatch(editRoleApi(params)),
  editRoleReset: () => dispatch(editRoleReset()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DefaultRoleManagement);
