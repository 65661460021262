import axios from "axios";
import config from "../config";
import { getUserToken } from "../utils/helper";

export const GET_MODULE_BY_ID = "GET_MODULE_BY_ID";
export const GET_MODULE_BY_ID_SUCCESS = "GET_MODULE_BY_ID_SUCCESS";
export const GET_MODULE_BY_ID_FAIL = "GET_MODULE_BY_ID_FAIL";
export const GET_MODULE_BY_ID_RESET = "GET_MODULE_BY_ID_RESET";

export const getModuleById = (params) => {
  return {
    type: GET_MODULE_BY_ID,
    params,
  };
};

export const getModuleByIdSuccess = (response) => {
  return {
    type: GET_MODULE_BY_ID_SUCCESS,
    response,
  };
};

export const getModuleByIdFail = (response) => {
  return {
    type: GET_MODULE_BY_ID_FAIL,
    response,
  };
};

export const getModuleByIdReset = () => {
  return {
    type: GET_MODULE_BY_ID_RESET,
  };
};

export const getModuleByIdApi = (data) => {
  return (dispatch) => {
    dispatch(getModuleById());
    axios
      .get(`${config.api.base_url}/modules/${data.id}`, {
        headers: {
          Authorization: "Bearer " + getUserToken(),
        },
      })
      .then((response) => {
        const getModuleByIdData = response.data;
        dispatch(getModuleByIdSuccess(getModuleByIdData));
      })
      .catch((error) => {
        const errorMsg = error.message;
        dispatch(getModuleByIdFail(errorMsg));
      });
  };
};
