import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

const TESTIMONIALS = [
  {
    name: "Sandeep Sharma",
    role: "UI/UX Designer",
    image: "/images/sandeep.jpg",
    rating: 5,
    content:
      "SkillSprints got me connected with my current job in just a few days! The entire process was seamless, and I didn’t have to worry about constant follow-ups. Their recruiters were quick to push my profile to top companies, and the job application process was so easy to manage.",
  },
  {
    name: "Cyril Lopes",
    role: "Software Engineer",
    image: "/images/cyril.jpg",
    rating: 5,
    content:
      "I was amazed at how quickly SkillSprints helped me find the perfect role. I applied through the platform, and within days, recruiters were already pushing my profile. It’s the most efficient job search experience I’ve had, and managing my applications couldn’t have been easier!",
  },
  {
    name: "Danish Khan",
    role: "Software Engineer",
    image: "/images/danish.png",
    rating: 4,
    content:
      "SkillSprints’s platform is incredibly efficient. I was able to apply to multiple jobs with just a few clicks, and their team was on it, pushing my profile to employers. I got interviews lined up within days, saving me tons of time and effort!",
  },
  {
    name: "Jay Patel",
    role: "Team Lead",
    image: "/images/jay.png",
    rating: 5,
    content:
      "Thanks to SkillSprints, I secured a fantastic job in record time. The platform made it easy to track applications and get updates quickly. I couldn’t believe how fast recruiters were able to connect me with the right opportunities. Highly recommend for anyone serious about finding a job fast!",
  },
];

const ClientTestimonials = () => {
  const containerRef = useRef(null);
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    const scrollContainer = containerRef.current;
    if (!scrollContainer) return;

    const scrollContent = Array.from(scrollContainer.children);
    scrollContent.forEach((item) => {
      const duplicatedItem = item.cloneNode(true);
      duplicatedItem.setAttribute("aria-hidden", "true");
      scrollContainer.appendChild(duplicatedItem);
    });

    const scrollWidth = scrollContainer.scrollWidth;
    const animationDuration = scrollWidth / 100;
    scrollContainer.style.setProperty("--scroll-width", `${scrollWidth}px`);
    scrollContainer.style.setProperty(
      "--animation-duration",
      `${animationDuration}s`
    );

    return () => {
      scrollContainer.classList.remove("animate");
    };
  }, []);

  useEffect(() => {
    const scrollContainer = containerRef.current;
    if (!scrollContainer) return;

    if (isHovered) {
      scrollContainer.style.animationPlayState = "paused";
    } else {
      scrollContainer.style.animationPlayState = "running";
    }
  }, [isHovered]);

  return (
    <TestimonialSection>
      <TestimonialHeader>
        <Heading>
          <span className="highlight">Clients</span> Testimonial
        </Heading>
      </TestimonialHeader>
      <TestimonialCarouselWrapper>
        <BlurOverlay left />
        <TestimonialCarousel
          ref={containerRef}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}>
          {TESTIMONIALS.map((testimonial, index) => (
            <TestimonialCard key={index}>
              <CardHeader>
                <CustomerInfo>
                  <CustomerImage
                    src={testimonial.image}
                    alt={testimonial.name}
                  />
                  <CustomerDetails>
                    <CustomerName>{testimonial.name}</CustomerName>
                    <CustomerRole>{testimonial.role}</CustomerRole>
                  </CustomerDetails>
                </CustomerInfo>
                <QuoteIcon
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/bb4b8ab92ba99517a3e7a5c1973bf774a22dc0ffc0e7e55c7ba675ac5a0080b0?placeholderIfAbsent=true&apiKey=ac9769c5e7eb416e8a74975078a910d6"
                  alt=""
                />
              </CardHeader>
              <Rating>
                {[...Array(testimonial.rating)].map((_, i) => (
                  <StarIcon
                    key={i}
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/136e88ef60b5ef1494808a5cfea5c8cebed445e05a5d8921dd702278330a962b?placeholderIfAbsent=true&apiKey=ac9769c5e7eb416e8a74975078a910d6"
                    alt="Star"
                  />
                ))}
              </Rating>
              <TestimonialContent>{testimonial.content}</TestimonialContent>
            </TestimonialCard>
          ))}
        </TestimonialCarousel>
        <BlurOverlay right />
      </TestimonialCarouselWrapper>
    </TestimonialSection>
  );
};

const TestimonialSection = styled.section`
  background-color: #f5f8ff;
  padding: 76px 0;
  // margin-top: 96px;
  overflow: hidden;
  @media (max-width: 991px) {
    padding: 40px 20px;
    margin-top: 40px;
  }
`;

const TestimonialHeader = styled.header`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
`;

const Heading = styled.h2`
  color: #191f33;
  font: 500 40px/1.2 Inter, sans-serif;
  text-align: center;
  .highlight {
    color: #275df5;
    font-weight: 600;
  }
`;

const TestimonialCarouselWrapper = styled.div`
  position: relative;
  overflow: hidden;
`;

const BlurOverlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100px;
  z-index: 1;
  pointer-events: none;
  ${(props) => (props.left ? "left: 0;" : "right: 0;")}
  background: linear-gradient(
    to ${(props) => (props.left ? "right" : "left")},
    rgba(245, 248, 255, 1) 0%,
    rgba(245, 248, 255, 0) 100%
  );
`;

const TestimonialCarousel = styled.div`
  display: flex;
  gap: 20px;
  width: fit-content;
  animation: scroll var(--animation-duration) linear infinite;
  &:hover {
    animation-play-state: paused;
  }
  @keyframes scroll {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(calc(-1 * var(--scroll-width) / 2));
    }
  }
`;

const TestimonialCard = styled.article`
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0px 12px 80px 0px rgba(0, 44, 109, 0.05);
  padding: 24px;
  width: 400px;
  flex-shrink: 0;
  @media (max-width: 991px) {
    padding: 20px;
    width: 300px;
  }
`;

const CardHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CustomerInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const CustomerImage = styled.img`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  object-fit: cover;
`;

const CustomerDetails = styled.div`
  display: flex;
  flex-direction: column;
`;

const CustomerName = styled.h3`
  color: #191f33;
  font-size: 16px;
  font-weight: 500;
`;

const CustomerRole = styled.p`
  color: #767e94;
  font-size: 14px;
  margin-top: 4px;
`;

const QuoteIcon = styled.img`
  width: 48px;
  height: 48px;
`;

const Rating = styled.div`
  display: flex;
  gap: 2px;
  margin-top: 16px;
`;

const StarIcon = styled.img`
  width: 28px;
  height: 28px;
`;

const TestimonialContent = styled.p`
  color: #464d61;
  font: 400 16px/24px Inter, sans-serif;
  margin-top: 16px;
`;

export default ClientTestimonials;
