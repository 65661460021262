import React, { useState } from "react";
import styled from "styled-components";
import JobSearchForm from "../JobSearchForm";
import { urlPagination } from "../../utils/helper";
import { useLocation, useParams } from "react-router-dom";

const SearchSection = ({
  application = null,
  setFilters,
  filters,
  isRecruiter = null,
  candidateName = "candidates",
  options,
}) => {
  const id = useParams().id;
  const path = useLocation();
  const [jobTitle, setJobTitle] = useState("");
  const [location, setLocation] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form submitted:", { jobTitle, location });

    if (!id) {
      setFilters((prev) => ({ ...prev, search: jobTitle, state: location }));
      urlPagination(filters, ["search", "state"], [jobTitle, location]);
    } else {
      setFilters((prev) => ({
        ...prev,
        candidate_search: jobTitle,
        state: location,
      }));
      urlPagination(
        filters,
        ["candidate_search", "state"],
        [jobTitle, location]
      );
    }
  };

  const renderHeader = (title, description) => (
    <HeaderWrapper>
      <Title>
        <TitleText>{title}</TitleText>
      </Title>
      <Description>{description}</Description>
    </HeaderWrapper>
  );

  const renderContent = () => {
    if (!application && !isRecruiter) {
      return renderHeader(
        <>
          Find your <HighlightedSpan>new</HighlightedSpan> job today
        </>,
        "Thousands of jobs in the computer, engineering, and technology sectors are waiting for you."
      );
    }

    if (isRecruiter) {
      return renderHeader(
        <>
          Connect <HighlightedSpan>{candidateName}</HighlightedSpan> with their
          next opportunity
        </>,
        "Identify and apply to the best-fit roles for your candidates across various industries. Your expertise ensures they find the perfect job."
      );
    }

    switch (path.pathname) {
      case "/my-applications":
        return renderHeader(
          <>
            Your <HighlightedSpan>Job Applications</HighlightedSpan> at a Glance
          </>,
          "Easily track and manage all your job applications. View application statuses, interview updates, and feedback in one place as you move closer to landing your dream job!"
        );

      case "/applications":
        return renderHeader(
          <>
            Manage <HighlightedSpan>Job Applications</HighlightedSpan> at a
            Glance
          </>,
          "Stay organized by managing all applications for your job postings. Easily evaluate candidates and track their progress to find the perfect fit for your team."
        );

      case "/candidates":
        return renderHeader(
          <>
            Manage <HighlightedSpan>Candidates</HighlightedSpan> at a Glance
          </>,
          "Explore the comprehensive list of candidates. Easily view candidate profiles, qualifications, and skills to help you identify the best talent for your team!"
        );

      default:
        return renderHeader(
          <>
            Manage <HighlightedSpan>Candidate Applications</HighlightedSpan> at
            a Glance
          </>,
          "Effortlessly review and manage all candidates who have applied for your job postings. Track application statuses, view candidate profiles, and access feedback to make informed hiring decisions as you find the perfect fit for your team!"
        );
    }
  };

  return (
    <SearchContainer>
      <SearchContent>
        {renderContent()}
        <JobSearchForm
          setFilters={setFilters}
          jobTitle={jobTitle}
          setJobTitle={setJobTitle}
          location={location}
          setLocation={setLocation}
          handleSubmit={handleSubmit}
          application={application}
        />
      </SearchContent>
    </SearchContainer>
  );
};

const HeaderWrapper = styled.header`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
`;

const Title = styled.h1`
  color: #18191c;
  font-family: "Inter", sans-serif;
  font-size: 60px;
  font-weight: 600;
  line-height: 1.21;
  text-align: left;
  @media (max-width: 1200px) {
    font-size: 48px;
    line-height: 1.2;
  }
  @media (max-width: 768px) {
    font-size: 36px;
    line-height: 1.22;
    margin-bottom: 10px;
  }
`;

const TitleText = styled.span`
  display: inline;
`;

const HighlightedSpan = styled.span`
  color: #0050ff;
  position: relative;
  display: inline-block;
  margin-bottom: 15px;
`;

const Description = styled.p`
  color: #505050;
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.56;
  text-align: left;
  // max-width: 548px;
  @media (max-width: 991px) {
    max-width: 100%;
  }
  @media (max-width: 768px) {
    font-size: 16px;
    line-height: 1.5;
  }
`;

const SearchContainer = styled.section`
  background-color: #f5f8ff;
  padding: 80px 160px;
  @media (max-width: 991px) {
    padding: 40px 20px;
  }
`;

const SearchContent = styled.div`
  max-width: 1120px;
  margin: 0 auto;
`;

export default SearchSection;
