import axios from "axios";
import config from "../config";
import { getUserToken } from "../utils/helper";

export const GET_JOB_APPLICATION_COUNT = "GET_JOB_APPLICATION_COUNT";
export const GET_JOB_APPLICATION_COUNT_SUCCESS =
  "GET_JOB_APPLICATION_COUNT_SUCCESS";
export const GET_JOB_APPLICATION_COUNT_FAIL = "GET_JOB_APPLICATION_COUNT_FAIL";
export const GET_JOB_APPLICATION_COUNT_RESET =
  "GET_JOB_APPLICATION_COUNT_RESET";

export const getJobApplicationCount = (params) => {
  return {
    type: GET_JOB_APPLICATION_COUNT,
    params,
  };
};

export const getJobApplicationCountSuccess = (response) => {
  return {
    type: GET_JOB_APPLICATION_COUNT_SUCCESS,
    response,
  };
};

export const getJobApplicationCountFail = (response) => {
  console.log("action response fail=>", response);
  return {
    type: GET_JOB_APPLICATION_COUNT_FAIL,
    response,
  };
};

export const getJobApplicationCountReset = () => {
  return {
    type: GET_JOB_APPLICATION_COUNT_RESET,
  };
};

export const getJobApplicationCountApi = (params) => {
  return (dispatch) => {
    dispatch(getJobApplicationCount());
    axios
      .get(`${config.api.base_url}/application/get-job-application-count`, {
        params,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + getUserToken(),
        },
      })
      .then((response) => {
        const getJobApplicationCountData = response.data;
        dispatch(getJobApplicationCountSuccess(getJobApplicationCountData));
      })
      .catch((error) => {
        const errorData = error.response.data;
        dispatch(getJobApplicationCountFail(errorData));
      });
  };
};
