import axios from "axios";
import config from "../config";
import { getUserToken } from "../utils/helper";

export const ADD_MODULE = "ADD_MODULE";
export const ADD_MODULE_SUCCESS = "ADD_MODULE_SUCCESS";
export const ADD_MODULE_FAIL = "ADD_MODULE_FAIL";
export const ADD_MODULE_RESET = "ADD_MODULE_RESET";

export const addModule = (params) => {
  return {
    type: ADD_MODULE,
    params,
  };
};

export const addModuleSuccess = (response) => {
  return {
    type: ADD_MODULE_SUCCESS,
    response,
  };
};

export const addModuleFail = (response) => {
  return {
    type: ADD_MODULE_FAIL,
    response,
  };
};

export const addModuleReset = () => {
  return {
    type: ADD_MODULE_RESET,
  };
};

export const addModuleApi = (data) => {
  return (dispatch) => {
    dispatch(addModule());
    axios
      .post(`${config.api.base_url}/modules/create`, data, {
        headers: {
          Authorization: "Bearer " + getUserToken(),
        },
      })
      .then((response) => {
        const addModuleData = response.data;
        dispatch(addModuleSuccess(addModuleData));
      })
      .catch((error) => {
        const errorMsg = error.message;
        dispatch(addModuleFail(errorMsg));
      });
  };
};
