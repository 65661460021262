import axios from "axios";
import config from "../config";
import { getUserToken } from "../utils/helper";

export const GET_CONFIGURATION_OPTIONS = "GET_CONFIGURATION_OPTIONS";
export const GET_CONFIGURATION_OPTIONS_SUCCESS =
  "GET_CONFIGURATION_OPTIONS_SUCCESS";
export const GET_CONFIGURATION_OPTIONS_FAIL = "GET_CONFIGURATION_OPTIONS_FAIL";
export const GET_CONFIGURATION_OPTIONS_RESET =
  "GET_CONFIGURATION_OPTIONS_RESET";

export const getConfigurationOptions = (params) => {
  return {
    type: GET_CONFIGURATION_OPTIONS,
    params,
  };
};

export const getConfigurationOptionsSuccess = (response) => {
  return {
    type: GET_CONFIGURATION_OPTIONS_SUCCESS,
    response,
  };
};

export const getConfigurationOptionsFail = (response) => {
  return {
    type: GET_CONFIGURATION_OPTIONS_FAIL,
    response,
  };
};

export const getConfigurationOptionsReset = () => {
  return {
    type: GET_CONFIGURATION_OPTIONS_RESET,
  };
};

export const getConfigurationOptionsApi = (data) => {
  return (dispatch) => {
    dispatch(getConfigurationOptions());
    axios
      .get(`${config.api.base_url}/configurations/options`, {
        params: data,
        headers: {
          Authorization: "Bearer " + getUserToken(),
        },
      })
      .then((response) => {
        const getConfigurationOptionsData = response.data;
        dispatch(getConfigurationOptionsSuccess(getConfigurationOptionsData));
      })
      .catch((error) => {
        const errorMsg = error.message;
        dispatch(getConfigurationOptionsFail(errorMsg));
      });
  };
};
