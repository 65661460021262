import React, { useEffect } from "react";
import { InlineWidget } from "react-calendly";

const BookAppointment = (props) => {
  return (
    <>
      <main>
        <div style={{ marginBottom: "60px" }}>
          <InlineWidget url="https://calendly.com/info-skillsprints/30min" />
        </div>
      </main>
    </>
  );
};

export default BookAppointment;
