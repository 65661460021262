import config from '../config';
import axios from 'axios';
import { getUserToken } from '../utils/helper';

export const GET_USER_COUNTS = 'GET_USER_COUNTS';
export const GET_USER_COUNTS_SUCCESS = 'GET_USER_COUNTS_SUCCESS';
export const GET_USER_COUNTS_FAIL = 'GET_USER_COUNTS_FAIL';
export const GET_USER_COUNTS_RESET = 'GET_USER_COUNTS_RESET';

export const getUserCounts = (params) => {
  return {
    type: GET_USER_COUNTS,
    params,
  };
};

export const getUserCountsSuccess = (response) => {
  return {
    type: GET_USER_COUNTS_SUCCESS,
    response,
  };
};

export const getUserCountsFail = (response) => {
  return {
    type: GET_USER_COUNTS_FAIL,
    response,
  };
};

export const getUserCountsReset = () => {
  return {
    type: GET_USER_COUNTS_RESET,
  };
};

export const getUserCountsApi = (params) => {
  return (dispatch) => {
    dispatch(getUserCounts());
    axios
      .get(`${config.api.base_url}/dashboard/user-counts`, {
        params: params,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${getUserToken()}`,
        },
      })
      .then((response) => {
        const getUserCountsData = response.data;
        dispatch(getUserCountsSuccess(getUserCountsData));
      })
      .catch((error) => {
        const errorData = error.response.data;
        dispatch(getUserCountsFail(errorData));
      });
  };
};
