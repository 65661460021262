import axios from "axios";
import config from "../config";
import { getUserToken } from "../utils/helper";

export const DELETE_CONFIGURATION = "DELETE_CONFIGURATION";
export const DELETE_CONFIGURATION_SUCCESS = "DELETE_CONFIGURATION_SUCCESS";
export const DELETE_CONFIGURATION_FAIL = "DELETE_CONFIGURATION_FAIL";
export const DELETE_CONFIGURATION_RESET = "DELETE_CONFIGURATION_RESET";

export const deleteConfiguration = (params) => {
  return {
    type: DELETE_CONFIGURATION,
    params,
  };
};

export const deleteConfigurationSuccess = (response) => {
  return {
    type: DELETE_CONFIGURATION_SUCCESS,
    response,
  };
};

export const deleteConfigurationFail = (response) => {
  return {
    type: DELETE_CONFIGURATION_FAIL,
    response,
  };
};

export const deleteConfigurationReset = () => {
  return {
    type: DELETE_CONFIGURATION_RESET,
  };
};

export const deleteConfigurationApi = (data) => {
  return (dispatch) => {
    dispatch(deleteConfiguration());
    axios
      .delete(`${config.api.base_url}/configurations/${data.id}`, {
        headers: {
          Authorization: "Bearer " + getUserToken(),
        },
      })
      .then((response) => {
        const deleteConfigurationData = response.data;
        dispatch(deleteConfigurationSuccess(deleteConfigurationData));
      })
      .catch((error) => {
        const errorMsg = error.message;
        dispatch(deleteConfigurationFail(errorMsg));
      });
  };
};
