import config from "../config";
import axios from "axios";
import { getUserToken } from "../utils/helper";

export const GET_COUNTRIES = "GET_COUNTRIES";
export const GET_COUNTRIES_SUCCESS = "GET_COUNTRIES_SUCCESS";
export const GET_COUNTRIES_FAIL = "GET_COUNTRIES_FAIL";

export const getCountries = (params) => {
  return {
    type: GET_COUNTRIES,
    params,
  };
};

export const getCountriesSuccess = (response) => {
  return {
    type: GET_COUNTRIES_SUCCESS,
    response,
  };
};

export const getCountriesFail = (response) => {
  return {
    type: GET_COUNTRIES_FAIL,
    response,
  };
};

export const getCountriesApi = () => {
  return (dispatch) => {
    dispatch(getCountries());
    axios
      .get(`${config.api.base_url}/get-countries`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getUserToken()}`,
        },
      })
      .then((response) => {
        const getCountriesData = response.data.data;
        dispatch(getCountriesSuccess(getCountriesData));
      })
      .catch((error) => {
        const errorData = error.response.data;
        dispatch(getCountriesFail(errorData));
      });
  };
};
