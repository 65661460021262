import config from "../config";
import axios from "axios";
import { getUserToken } from "../utils/helper";

export const GET_INDUSTRIES = "GET_INDUSTRIES";
export const GET_INDUSTRIES_SUCCESS = "GET_INDUSTRIES_SUCCESS";
export const GET_INDUSTRIES_FAIL = "GET_INDUSTRIES_FAIL";

export const getIndustries = (params) => {
  return {
    type: GET_INDUSTRIES,
    params,
  };
};

export const getIndustriesSuccess = (response) => {
  return {
    type: GET_INDUSTRIES_SUCCESS,
    response,
  };
};

export const getIndustriesFail = (response) => {
  return {
    type: GET_INDUSTRIES_FAIL,
    response,
  };
};

export const getIndustriesApi = (params) => {
  return (dispatch) => {
    dispatch(getIndustries());
    axios
      .get(`${config.api.base_url}/get-industries`, {
        params, // Ensure you're passing params correctly
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getUserToken()}`,
        },
      })
      .then((response) => {
        const getIndustriesData = response.data;
        dispatch(getIndustriesSuccess(getIndustriesData));
      })
      .catch((error) => {
        const errorMsg = error.message;
        dispatch(getIndustriesFail(errorMsg));
      });
  };
};
