import React, { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import { Input, Button } from "antd";
import JobSearchForm from "../../JobSearchForm/index";
import { useNavigate } from "react-router-dom";

const JobSearchPage = ({ filters, setFilters }) => {
  const navigate = useNavigate();
  const [suggestions, setSuggestions] = useState([
    { text: "Designer", highlighted: false },
    { text: "Programming", highlighted: false },
    { text: "Digital Marketing", highlighted: true },
    { text: "Video", highlighted: false },
    { text: "Animation", highlighted: false },
  ]);

  const [jobTitle, setJobTitle] = useState("");
  const [location, setLocation] = useState(null);

  useEffect(() => {
    document.body.style.overflow = "hidden";
    setTimeout(() => {
      document.body.style.overflow = "visible";
    }, 1000);
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    navigate(`/explore?search=${jobTitle}&location=${location}`);
  };

  return (
    <PageWrapper>
      <ContentContainer>
        <MainContent>
          <LeftColumn>
            <HeaderWrapper>
              <Title>
                <TitleText>
                  Explore the Job of<>&nbsp;</>
                </TitleText>
                <HighlightedSpan>
                  Your Dreams!!
                  <UnderlineImage
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/b6a59109f76498a89075540c1df5f6adbe3a7c0f23754276c6b6d611781652b7?placeholderIfAbsent=true&apiKey=ac9769c5e7eb416e8a74975078a910d6"
                    alt=""
                  />
                </HighlightedSpan>
              </Title>
              <Subtitle>5 lakh+ jobs for you to explore</Subtitle>
              <Description>
                Discover top opportunities with Skillsprints. Connect with
                leading employers and take the next step in your career today.
              </Description>
            </HeaderWrapper>
            <JobSearchForm
              jobTitle={jobTitle}
              setJobTitle={setJobTitle}
              location={location}
              setLocation={setLocation}
              handleSubmit={handleSubmit}
            />
            <SuggestionsWrapper>
              <SuggestionLabel>Suggestion:</SuggestionLabel>
              {suggestions.map((suggestion, index) => (
                <SuggestionItem
                  key={index}
                  ishighlighted={suggestion.highlighted}>
                  {suggestion.text}
                  {index !== suggestions.length - 1 ? "," : "."}
                </SuggestionItem>
              ))}
            </SuggestionsWrapper>
          </LeftColumn>
          <RightColumn>
            <StyledImage
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/a775b3b1fa9d5ccfe637e1e3ded90da3f2a576352707e7c68a947eb18295d1d0?placeholderIfAbsent=true&apiKey=ac9769c5e7eb416e8a74975078a910d6"
              alt="Job search illustration"
            />
          </RightColumn>
        </MainContent>
      </ContentContainer>
    </PageWrapper>
  );
};

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const PageWrapper = styled.main`
  background-color: #f5f8ff;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  padding: 85px 70px 0;
  animation: ${fadeIn} 1s ease-out;
  @media (max-width: 1200px) {
    padding: 60px 40px 0;
  }
  @media (max-width: 768px) {
    padding: 40px 20px 0;
  }
`;

const ContentContainer = styled.div`
  width: 100%;
  max-width: 1123px;
  @media (max-width: 1200px) {
    max-width: 100%;
  }
`;

const MainContent = styled.div`
  display: flex;
  gap: 20px;
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: stretch;
    gap: 40px;
  }
`;

const LeftColumn = styled.section`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 65%;
  margin-left: 0;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const RightColumn = styled.aside`
  display: flex;
  flex-direction: column;
  width: 35%;
  margin-left: 20px;
  @media (max-width: 991px) {
    width: 100%;
    margin-left: 0;
  }
`;

const StyledImage = styled.img`
  width: 100%;
  height: auto;
  object-fit: contain;
  @media (max-width: 991px) {
    max-width: 50%;
    margin: 0 auto;
  }
  @media (max-width: 768px) {
    max-width: 70%;
  }
`;

const HeaderWrapper = styled.header`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
`;

const Title = styled.h1`
  color: #18191c;
  font-family: "Inter", sans-serif;
  font-size: 60px;
  font-weight: 600;
  line-height: 1.21;
  text-align: left;
  margin-bottom: 20px;
  @media (max-width: 1200px) {
    font-size: 48px;
    line-height: 1.2;
  }
  @media (max-width: 768px) {
    font-size: 36px;
    line-height: 1.22;
  }
`;

const TitleText = styled.span`
  display: inline;
  @media (min-width: 992px) {
    display: block;
  }
`;

const HighlightedSpan = styled.span`
  color: #0050ff;
  position: relative;
  display: inline-block;
  margin-bottom: 15px;
`;

const UnderlineImage = styled.img`
  width: 100%;
  max-width: 393px;
  height: auto;
  position: absolute;
  bottom: -15px;
  left: 0;
`;

const Subtitle = styled.h2`
  background-color: #c2d5ff;
  color: #000e2b;
  font-family: "Nunito Sans", sans-serif;
  font-size: 24px;
  font-weight: 400;
  line-height: 1.36;
  text-align: left;
  padding: 10px 15px;
  border-radius: 4px;
  margin-bottom: 28px;
  margin-top: 20px;
  @media (max-width: 768px) {
    font-size: 20px;
    line-height: 1.4;
  }
`;

const Description = styled.p`
  color: #505050;
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.56;
  text-align: left;
  max-width: 548px;
  @media (max-width: 991px) {
    max-width: 100%;
  }
  @media (max-width: 768px) {
    font-size: 16px;
    line-height: 1.5;
  }
`;

const SuggestionsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 24px;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  @media (max-width: 768px) {
    justify-content: center;
  }
`;

const SuggestionLabel = styled.span`
  color: #9199a3;
  margin-right: 5px;
`;

const SuggestionItem = styled.span`
  color: ${(props) => (props.ishighlighted ? "#275df5" : "#474c54")};
  font-weight: ${(props) => (props.ishighlighted ? "500" : "400")};
  line-height: 1.43;
  text-align: center;
  margin-right: 4px;
`;

export default JobSearchPage;
