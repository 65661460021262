import React, { useEffect, useState } from "react";
import {
  Select,
  DatePicker,
  Dropdown,
  Button,
  notification,
  Pagination,
  Modal,
  Slider,
  Result,
} from "antd";
import {
  ContentContainer,
  FilterButton,
  FilterContainer,
  Header,
  JobCount,
  JobListContainer,
  PageContainer,
  PaginationContainer,
  StyledPagination,
  XButton2,
  XSelect,
} from "../../styles/GlobalStyle";
import { connect } from "react-redux";
import dayjs from "dayjs";
import { EditOutlined, DeleteOutlined, PlusOutlined } from "@ant-design/icons";

import { getJobListApi, getJobListReset } from "../../actions/getJobListAction";
import { getIndustriesApi } from "../../actions/getIndustriesAction";
import { getIndustryCategoriesApi } from "../../actions/getIndustryCategoriesAction";
import {
  updateJobStatusApi,
  updateJobStatusReset,
} from "../../actions/updateJobStatusAction";
import { filterByLabel } from "../../utils/formFunctions";
import {
  checkModulePermissions,
  fetchListThroughPagination,
  hasPermission,
  urlPagination,
} from "../../utils/helper";
import { useForm } from "antd/es/form/Form";
import CaseSearch from "../../components/CaseSearch/CaseSearch";
import { XForm } from "../Login/LoginStyle";
import { employment_type_list, jobFilters } from "../../utils/constants";
import JobCard from "../../components/JobCards/index";
import JobDetails from "../../components/JobDescription/index";
import styled from "styled-components";
import JobSearchForm from "../../components/JobSearchForm";
import { getEmployerOrganizationsListApi } from "../../actions/getEmployerOrganizationsListAction";
import { useNavigate } from "react-router-dom";

const { RangePicker } = DatePicker;

const InventoryPublished = (props) => {
  const rangePresets = [
    {
      label: "Last 7 days",
      value: [dayjs().add(-7, "d"), dayjs()],
    },
    {
      label: "Last 30 days",
      value: [dayjs().add(-30, "d"), dayjs()],
    },
    {
      label: "Last 60 days",
      value: [dayjs().add(-60, "d"), dayjs()],
    },
  ];

  const defaultFilters = {
    page: 1,
    limit: 10,
    search: "",
    state: "",
    industry: "",
    category: "",
    posted: [],
    sortBy: "",
    sortDirection: -1,
    jobStatus: "",
    salary_max: "",
    salary_min: "",
    employment_type: "",
  };

  const {
    getJobList,
    getJobListState,
    getJobListReset,
    getIndustryCategories,
    getIndustryCategoriesState,
    getIndustries,
    setDraftJobCount,
    setPublishedJobCount,
    getIndustriesState,
    modulePermissions,
    updateJobStatusState,
    updateJobStatus,
    getEmployerOrganizationsList,
    getEmployerOrganizationsListState,
    keys,
    setKeys,
  } = props;
  const navigate = useNavigate();

  const [form] = useForm();
  const [selectedJob, setSelectedJob] = useState(null);
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const [jobTitle, setJobTitle] = useState("");
  const [modal1, modalHolder] = Modal.useModal();
  const [location, setLocation] = useState(null);

  const getFiltersFromUrl = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const urlFilters = {};

    // Iterate through each query parameter and set it in the state
    for (const [key, value] of urlParams.entries()) {
      urlFilters[key] = value;
    }

    // Merge with default filters or initial state as needed
    const filters = {
      ...defaultFilters, // Replace with your default filters
      ...urlFilters,
    };

    return filters;
  };
  const initialFilters = getFiltersFromUrl();

  const [filters, setFilters] = useState(initialFilters);
  // const [tableData, setTableData] = useState([]);
  const [totalRecords, setTotalRecords] = useState("");
  const [open, setOpen] = useState(false);
  const [currentJobs, setCurrentJobs] = useState(null);

  const jobsPerPage = 10;

  const indexOfLastJob = filters.page * jobsPerPage;
  const indexOfFirstJob = indexOfLastJob - jobsPerPage;

  const handlePageChange = (page) => {
    fetchListThroughPagination(page, setFilters, filters);
    setFilters((prev) => ({ ...prev, page: page }));
  };

  const handleJobClick = (job) => {
    setSelectedJob(job);
    if (isDrawerVisible) {
      setIsDrawerVisible(true);
    }
  };

  const handleSortChange = (value, direction) => {
    setFilters({
      ...filters,
      sortBy: value,
      sortDirection: direction,
    });
  };

  const handleDateChange = (dateRange) => {
    if (dateRange) {
      setFilters({
        ...filters,
        page: 1,
        posted: [
          dateRange[0].format("YYYY-MM-DD"),
          dateRange[1].format("YYYY-MM-DD"),
        ],
      });
    } else {
      setFilters({
        ...filters,
        posted: [],
      });
    }
  };

  const handleCategorySearch = (search) => {
    if (search.length >= 3) {
      getIndustryCategories({
        search: search,
        industries: filters.industry ? [filters.industry] : null,
      });
    } else if (search.length == 0) {
      getIndustryCategories({
        industries: [filters.industry],
      });
    }
  };

  const showDrawer = () => {
    getIndustries();
    getEmployerOrganizationsList();
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  const updateStatus = (id, status) => {
    let title = "";
    let icon = "";
    let content = "";

    switch (status) {
      case "published":
        title = "Are you sure you want to publish the job?";
        icon = (
          <img
            width="40"
            className="mr-2"
            style={{ float: "left" }}
            src="/images/confirm-icon.svg"
            alt=""
          />
        );
        content = (
          <p className="confirmPara">
            On confirmation, this job will be visible to the Candidates
          </p>
        );
        break;
      case "draft":
        title = "Are you sure you want shift the job to draft?";
        icon = (
          <img
            width="40"
            className="mr-2"
            style={{ float: "left" }}
            src="/images/confirm-icon.svg"
            alt=""
          />
        );
        content = (
          <p className="confirmPara">
            On confirmation, the job will not be visible to Candidates
          </p>
        );
        break;
    }

    modal1.confirm({
      title,
      icon: icon,
      content: content,
      okText: "Confirm",
      cancelText: "Cancel",
      className: "confirmbox",
      onOk: () => {
        updateJobStatus({
          id,
          status,
        });
      },
    });
  };

  const applyFilters = () => {
    const queryParams = new URLSearchParams(window.location.search);

    // Iterate through filters and update the query parameters
    Object.entries(filters).forEach(([key, value]) => {
      const currentValue = queryParams.get(key);

      // Check if the parameter exists in the current query and has a value, if yes, update its value
      if (currentValue !== null) {
        queryParams.set(key, value);
      } else if (queryParams.has(key)) {
        // If the parameter exists but has no value, keep its existing value
        queryParams.set(key, queryParams.get(key));
      } else {
        // If the parameter does not exist in the current query, append it with the current value
        queryParams.append(key, value);
      }
    });

    // Set the page to 1
    queryParams.set("page", "1");
    filters.page = 1;

    // Update the URL
    const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
    window.history.pushState({}, "", newUrl);
  };

  const clearFilters = () => {
    const queryParams = new URLSearchParams(window.location.search);

    // Iterate through defaultFilters and update the query parameters
    Object.entries(defaultFilters).forEach(([key, value]) => {
      const currentValue = queryParams.get(key);

      // Check if the parameter exists in the current query and has a value, if yes, update its value
      if (currentValue !== null) {
        queryParams.set(key, value);
      } else if (queryParams.has(key)) {
        // If the parameter exists but has no value, keep its existing value
        queryParams.set(key, queryParams.get(key));
      } else {
        // If the parameter does not exist in the current query, append it with the default value
        queryParams.append(key, value);
      }
    });

    // Clear the form fields
    form.resetFields();

    // Update the state with default values
    setFilters(defaultFilters);

    // Update the URL
    const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
    window.history.pushState({}, "", newUrl);
  };

  useEffect(() => {
    // Retrieve filters from the updated URL
    const updatedFilters = getFiltersFromUrl();
    setFilters({ ...updatedFilters });

    // Make API call with updated filters and pagination
    if (hasPermission("jobs-inventory", "list-jobs")) {
      getJobList({ ...updatedFilters, status: keys });
    }
  }, [window.location.search, keys]);

  useEffect(() => {
    if (getJobListState.apiState === "success") {
      setTotalRecords(getJobListState.data.total_count);
      setDraftJobCount(getJobListState.data.draft_job_count);
      setPublishedJobCount(getJobListState.data.published_job_count);
      setSelectedJob(getJobListState.data?.list[0]);
      if (getJobListState.data?.list.length === 1) {
        setCurrentJobs(getJobListState.data.list); // Return the whole list with one job
      } else {
        setCurrentJobs(
          getJobListState.data?.list.slice(
            indexOfFirstJob,
            Math.min(indexOfLastJob, getJobListState.data?.list.length)
          )
        );
      }
      getJobListReset();
    } else if (getJobListState.apiState === "error") {
      console.log("API Error=>", getJobListState.message);
      notification.error({
        message: "Unable to get Job List",
      });
      getJobListReset();
    }
  }, [getJobListState.apiState]);

  useEffect(() => {
    if (updateJobStatusState.apiState === "success") {
      notification.success({
        message: updateJobStatusState.message,
      });

      getJobList({ ...filters, status: keys });
      Modal.destroyAll();

      updateJobStatusReset();
    } else if (updateJobStatusState.apiState === "error") {
      notification.error({
        message: updateJobStatusState.message,
      });
      updateJobStatusReset();
    }
  }, [updateJobStatusState.apiState]);

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form submitted:", { jobTitle, location });

    urlPagination(filters, "search", jobTitle);
    urlPagination(filters, "state", location);
    setFilters((prev) => ({ ...prev, search: jobTitle, state: location }));
  };
  return (
    <>
      {hasPermission("jobs-inventory", "list-jobs") ? (
        <>
          <CaseSearch
            searchOption="reseller"
            title="Resellers"
            filters={[
              <XForm.Item
                name="industry"
                colon={false}
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
                label="Industry"
                className="removeFormMargin">
                <Select
                  showSearch
                  size="large"
                  className="removeBg"
                  onFocus={() => getIndustries({})}
                  defaultValue={filters.industry || null}
                  filterOption={filterByLabel}
                  onSearch={(search) => {
                    if (search.length >= 3) {
                      getIndustries({ search });
                    }
                  }}
                  onChange={(value) => {
                    setFilters({
                      ...filters,
                      industry: value,
                    });
                    getIndustryCategories({ industries: [value] });
                    form.setFieldsValue({ category: null });
                  }}
                  onClear={() => {
                    setFilters({
                      ...filters,
                      industry: "",
                    });
                  }}
                  options={
                    getIndustriesState.apiState === "success" &&
                    getIndustriesState.data.all_industries.length > 0 &&
                    getIndustriesState.data.all_industries.map((ind) => {
                      return {
                        value: ind._id,
                        label: ind.industry_name,
                        type: "industry",
                      };
                    })
                  }
                  placeholder="Select Industry"
                />
              </XForm.Item>,
              <XForm.Item
                colon={false}
                name="category"
                label="Category"
                className="selectBox">
                <Select
                  name="category"
                  size="large"
                  placeholder="Search Functions"
                  className="removeShadow ml-2"
                  showSearch
                  defaultValue={filters.category || null}
                  allowClear={true}
                  onSearch={handleCategorySearch}
                  onChange={(category) => {
                    setFilters({
                      ...filters,
                      category,
                    });
                  }}
                  filterOption={filterByLabel}
                  options={
                    getIndustryCategoriesState.apiState === "success" &&
                    getIndustryCategoriesState.data.all_categories.length > 0
                      ? getIndustryCategoriesState.data.all_categories.map(
                          (item) => {
                            return {
                              value: item.id,
                              label: item.category_name,
                            };
                          }
                        )
                      : []
                  }></Select>
              </XForm.Item>,
              <XForm.Item
                name="employer_id"
                label="Employer"
                className="selectBox">
                <XSelect
                  name="employer_id"
                  className="removeShadow ml-2"
                  onFocus={() => getEmployerOrganizationsList({})}
                  defaultValue={filters.employer_id || null}
                  filterOption={filterByLabel}
                  onSearch={(search) => {
                    if (search.length >= 3) {
                      getEmployerOrganizationsList({ search });
                    } else {
                      getEmployerOrganizationsList({});
                    }
                  }}
                  onChange={(value) => {
                    setFilters({
                      ...filters,
                      employer_id: value,
                    });
                  }}
                  onClear={() => {
                    setFilters({
                      ...filters,
                      employer_id: "",
                    });
                  }}
                  size="large"
                  options={
                    getEmployerOrganizationsListState.apiState === "success" &&
                    getEmployerOrganizationsListState.data.all_organizations
                      .length > 0
                      ? getEmployerOrganizationsListState.data.all_organizations.map(
                          (item) => {
                            return {
                              value: item._id,
                              label: item.organization_name,
                            };
                          }
                        )
                      : []
                  }></XSelect>
              </XForm.Item>,
              <XForm.Item name="salary" label="Salary" className="selectBox">
                <Slider
                  range
                  min={10000}
                  max={10000000}
                  step={10000}
                  defaultValue={[
                    filters.salary_min || 2000000,
                    filters.salary_max || 5000000,
                  ]}
                  onChange={(value) => {
                    setFilters({
                      ...filters,
                      salary_min: value[0],
                      salary_max: value[1],
                    });
                  }}
                />
              </XForm.Item>,
              <XForm.Item
                colon={false}
                label="Employment Type"
                className="formLable"
                name="employment_type">
                <XSelect
                  size="large"
                  placeholder="Employment Type"
                  className="removeBg"
                  onChange={(value) => {
                    setFilters({
                      ...filters,
                      employment_type: value,
                    });
                  }}
                  onClear={() => {
                    setFilters({
                      ...filters,
                      employment_type: "",
                    });
                  }}
                  defaultValue={filters.employment_type || null}
                  options={employment_type_list}
                />
              </XForm.Item>,
              <XForm.Item
                name="posted"
                colon={false}
                label="By Date"
                className="removeFormMargin">
                <XForm.Item colon={false} name="posted" className="selectBox">
                  <RangePicker
                    size="large"
                    className="removeShadow ml-2"
                    presets={rangePresets}
                    onChange={handleDateChange}
                    defaultValue={filters.posted || null}
                    allowClear
                    style={{
                      borderRadius: "50px",
                      height: "40px",
                    }}></RangePicker>
                </XForm.Item>
              </XForm.Item>,
              <XForm.Item
                colon={false}
                name="sortBy"
                label="Sort By"
                className="selectBox">
                <Dropdown
                  className="removeShadow w-100  ms-0"
                  trigger={["click"]}
                  menu={{
                    items: jobFilters(handleSortChange), // Pass the dynamic function here
                  }}>
                  <Button
                    className="shortBtn"
                    style={{
                      border: "none",
                      padding: 10,
                      height: "40px",
                      width: "110px",
                      backgroundColor: "#ffffff",
                      border: "1px solid #d9d9d9",
                      color: "#000",
                      display: "flex",
                      flexDirection: "row",
                    }}>
                    <p>Sort</p>
                    <img className="mt-1" src="/images/short-icon.svg" alt="" />
                  </Button>
                </Dropdown>
              </XForm.Item>,
            ]}
            onClose={onClose}
            open={open}
            applyFilters={applyFilters}
            form={form}
            clearFilters={clearFilters}
          />{" "}
          <JobSearchForm
            setFilters={setFilters}
            jobTitle={jobTitle}
            setJobTitle={setJobTitle}
            location={location}
            setLocation={setLocation}
            handleSubmit={handleSubmit}
          />
          <PageContainer style={{ padding: "40px 0" }}>
            <Header
              className="align-items-center flex-wrap"
              style={{
                margin: "10px 0px",
              }}>
              <JobCount>{totalRecords} Jobs</JobCount>
              <FilterContainer onClick={() => showDrawer()}>
                <FilterButton>
                  <img
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/d0e11f66e694b5fbdadd8edb8109ba7b24c06cb1f66c477f282ec451dfd237e7?placeholderIfAbsent=true&apiKey=ac9769c5e7eb416e8a74975078a910d6"
                    alt="Filter icon"
                  />
                  Filter
                </FilterButton>
                {hasPermission("jobs-inventory", "add-jobs") ? (
                  <FilterButton
                    className="text-right"
                    onClick={() => {
                      navigate("/inventory/add");
                    }}>
                    <PlusOutlined /> Add New Job{" "}
                  </FilterButton>
                ) : (
                  <></>
                )}
              </FilterContainer>
            </Header>
            <ContentContainer>
              <JobListContainer>
                {currentJobs &&
                  currentJobs.length > 0 &&
                  currentJobs.map((job) => (
                    <JobCard
                      key={job._id}
                      {...job}
                      isSelected={job._id == selectedJob._id}
                      onClick={() => handleJobClick(job)}
                    />
                  ))}
              </JobListContainer>
              {/* {!isDrawerVisible && (
          <JobDetailsContainer>
            <JobDetails job={selectedJob} />
          </JobDetailsContainer>
        )} */}
              <JobDetails
                job={selectedJob}
                updateStatus={updateStatus} // Pass the function here
                // handleAddCandidateJob={handleAddCandidateJob}
                // candidateApplicationIds={candidateApplicationIds}
              />
            </ContentContainer>
            <PaginationContainer>
              <StyledPagination
                current={filters.page || 1}
                total={totalRecords || 0}
                pageSize={jobsPerPage}
                onChange={handlePageChange}
                showSizeChanger={false}
              />
            </PaginationContainer>
            {/* {isDrawerVisible && (
        <Drawer isVisible={isDrawerVisible && selectedJob !== null}>
          <JobDetails job={selectedJob} onClose={handleCloseDrawer} isDrawer={true} />
        </Drawer>
      )} */}
          </PageContainer>
          {modalHolder}
        </>
      ) : (
        <Result
          status="403"
          title="403"
          subTitle="Sorry, you are not authorized to access this page."
          extra={<Button type="primary">Back Home</Button>}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  getJobListState: state.getJobList,
  getIndustriesState: state.getIndustries,
  getIndustryCategoriesState: state.getIndustryCategories,
  updateJobStatusState: state.updateJobStatus,
  getEmployerOrganizationsListState: state.getEmployerOrganizationsList,
});

const mapDispatchToProps = (dispatch) => ({
  getJobList: (params) => dispatch(getJobListApi(params)),
  getJobListReset: (params) => dispatch(getJobListReset(params)),
  getIndustries: (params) => dispatch(getIndustriesApi(params)),
  getIndustryCategories: (params) => dispatch(getIndustryCategoriesApi(params)),
  updateJobStatus: (params) => dispatch(updateJobStatusApi(params)),
  getEmployerOrganizationsList: (params) =>
    dispatch(getEmployerOrganizationsListApi(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(InventoryPublished);
