import axios from "axios";
import config from "../config";
import { getUserToken } from "../utils/helper";

export const GET_JOB_APPLICATION_STATUS_COUNT =
  "GET_JOB_APPLICATION_STATUS_COUNT";
export const GET_JOB_APPLICATION_STATUS_COUNT_SUCCESS =
  "GET_JOB_APPLICATION_STATUS_COUNT_SUCCESS";
export const GET_JOB_APPLICATION_STATUS_COUNT_FAIL =
  "GET_JOB_APPLICATION_STATUS_COUNT_FAIL";
export const GET_JOB_APPLICATION_STATUS_COUNT_RESET =
  "GET_JOB_APPLICATION_STATUS_COUNT_RESET";

export const getJobApplicationStatusCount = (params) => {
  return {
    type: GET_JOB_APPLICATION_STATUS_COUNT,
    params,
  };
};

export const getJobApplicationStatusCountSuccess = (response) => {
  return {
    type: GET_JOB_APPLICATION_STATUS_COUNT_SUCCESS,
    response,
  };
};

export const getJobApplicationStatusCountFail = (response) => {
  console.log("action response fail=>", response);
  return {
    type: GET_JOB_APPLICATION_STATUS_COUNT_FAIL,
    response,
  };
};

export const getJobApplicationStatusCountReset = () => {
  return {
    type: GET_JOB_APPLICATION_STATUS_COUNT_RESET,
  };
};

export const getJobApplicationStatusCountApi = (params) => {
  return (dispatch) => {
    dispatch(getJobApplicationStatusCount());
    axios
      .get(
        `${config.api.base_url}/application/get-job-application-status-count`,
        {
          params,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + getUserToken(),
          },
        }
      )
      .then((response) => {
        const getJobApplicationStatusCountData = response.data;
        dispatch(
          getJobApplicationStatusCountSuccess(getJobApplicationStatusCountData)
        );
      })
      .catch((error) => {
        const errorData = error.response.data;
        dispatch(getJobApplicationStatusCountFail(errorData));
      });
  };
};
