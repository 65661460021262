import axios from "axios";
import config from "../config";

export const CONSENT = "CONSENT";
export const CONSENT_SUCCESS = "CONSENT_SUCCESS";
export const CONSENT_FAIL = "CONSENT_FAIL";
export const CONSENT_RESET = "CONSENT_RESET";

export const consent = (params) => {
  return {
    type: CONSENT,
    params,
  };
};

export const consentSuccess = (response) => {
  return {
    type: CONSENT_SUCCESS,
    response,
  };
};

export const consentFail = (response) => {
  return {
    type: CONSENT_FAIL,
    response,
  };
};

export const consentReset = () => {
  return {
    type: CONSENT_RESET,
  };
};

export const consentApi = (data) => {
  return (dispatch) => {
    dispatch(consent());
    axios
      .post(`${config.api.base_url}/auth/consent`, data)
      .then((response) => {
        const consentData = response.data;
        dispatch(consentSuccess(consentData));
      })
      .catch((error) => {
        dispatch(consentFail(error.response.data));
      });
  };
};
