import axios from "axios";
import config from "../config";
import { getUserToken } from "../utils/helper";

export const SAVE_PERSONAL_INFORMATION = "SAVE_PERSONAL_INFORMATION";
export const SAVE_PERSONAL_INFORMATION_SUCCESS =
  "SAVE_PERSONAL_INFORMATION_SUCCESS";
export const SAVE_PERSONAL_INFORMATION_FAIL = "SAVE_PERSONAL_INFORMATION_FAIL";
export const SAVE_PERSONAL_INFORMATION_RESET =
  "SAVE_PERSONAL_INFORMATION_RESET";

export const savePersonalInformation = (params) => {
  return {
    type: SAVE_PERSONAL_INFORMATION,
    params,
  };
};

export const savePersonalInformationSuccess = (response) => {
  return {
    type: SAVE_PERSONAL_INFORMATION_SUCCESS,
    response,
  };
};

export const savePersonalInformationFail = (response) => {
  return {
    type: SAVE_PERSONAL_INFORMATION_FAIL,
    response,
  };
};

export const savePersonalInformationReset = () => {
  return {
    type: SAVE_PERSONAL_INFORMATION_RESET,
  };
};

export const savePersonalInformationApi = (data) => {
  return (dispatch) => {
    dispatch(savePersonalInformation());
    axios
      .post(
        `${config.api.base_url}/candidate/save-personal-information`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + getUserToken(),
          },
        }
      )
      .then((response) => {
        const savePersonalInformationData = response.data;
        dispatch(savePersonalInformationSuccess(savePersonalInformationData));
      })
      .catch((error) => {
        console.log(error.response);
        const errorData = error.response.data;
        dispatch(savePersonalInformationFail(errorData));
      });
  };
};
