import styled from "styled-components";
import "../../styles/GlobalStyle";
import { Avatar, Card, Upload } from "antd";

export const XCardsProfileBg = styled(Card)`
  .profileHead {
    width: 100%;
    position: relative;
    // background: var(
    //   --Linear,
    //   linear-gradient(
    //     90deg,
    //     rgba(105, 234, 203, 0.15) 0%,
    //     rgba(234, 204, 248, 0.15) 48%,
    //     rgba(102, 84, 241, 0.15) 100%
    //   )
    // );
    background: var(
      --Linear,
      linear-gradient(
        90deg,
        rgba(104, 144, 229, 1) 0%,
        /* Lighter shade of #1e4fd0 */ rgba(72, 112, 218, 0.85) 35%,
        /* Medium-light shade */ rgba(45, 90, 213, 0.75) 65%,
        /* Medium shade */ rgba(30, 79, 208, 1) 100%
      )
    );
    border-radius: 20px 20px 0px 0px;
    padding: 10px;
    height: 100px;
    @media only screen and (max-width: 768.99px) {
      height: 65px;
    }
  }
  border: none;
  background: none !important;
  .ant-card-body {
    background: #fff;
    border-radius: 20px;
    padding: 0px;
    padding-bottom: 2%;
    box-shadow: 0px 2px 10px 0px rgba(25, 15, 64, 0.05);
    .innerBody {
      width: 100%;
      position: relative;
      padding: 15px 30px;
    }
    @media only screen and (max-width: 768.99px) {
      padding: 0px !important;
    }
  }
`;
