import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Row, Col, notification, Checkbox } from "antd";
import { Logo, Title, XForm } from "../Login/LoginStyle";
import { XContainer } from "../../styles/GlobalStyle";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import { isLogin } from "../../utils/helper";
import { consentApi, consentReset } from "../../actions/consentAction";
import { XCardsGrdBG, XPara1 } from "../../styles/GlobalStyle";
import { htmlConsent } from "../../utils/constants";

const Consent = (props) => {
  const { consent, consentReset, consentState } = props;

  const [form] = XForm.useForm();
  const [formData, setFormData] = useState({
    email: localStorage.getItem("email"),
    userName: localStorage.getItem("username"),
  });
  const [loading, setLoading] = useState(false);
  const [agreed, setAgreed] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (consentState.apiState === "success") {
      setLoading(false);
      localStorage.setItem("consentGiven", true);
      consentReset();
      if (localStorage.getItem("changePassword") === "true") {
        navigate("/reset-password");
      } else {
        navigate("/");
      }
    } else if (consentState.apiState === "error") {
      setLoading(false);
      notification.error({ message: consentState.message });
      consentReset();
    }
  }, [consentState]);

  useEffect(() => {
    if (localStorage.getItem("consentGiven") === "true" && isLogin()) {
      navigate("/");
    }
  }, []);

  const handleConsent = () => {
    if (!agreed) {
      notification.error({
        message: "Please agree to the terms and conditions",
      });
      return;
    }

    setLoading(true);
    consent(formData);
  };

  return (
    <XCardsGrdBG className="align-items-center">
      <XContainer fluid>
        <Col lg={{ span: 24 }}>
          <XForm
            form={form}
            name="consentForm"
            layout="vertical"
            autoComplete="off"
            onFinish={handleConsent}
            style={{
              padding: "5% 0",
            }}>
            <div
              style={{
                padding: "5% 0",
                background: "#fff",
                borderRadius: "15px",
              }}>
              <div
                style={{
                  textAlign: "center",
                }}>
                <Title className="my-4 mb-lg-5 text-center justify-content-center">
                  Consent to Terms and Conditions
                </Title>
              </div>
              <XPara1
                style={{
                  lineHeight: "1.5rem",
                  color: "#505052",
                }}
                dangerouslySetInnerHTML={{
                  __html: htmlConsent,
                }}></XPara1>
              <Row
                className="mt-5 px-3"
                style={{
                  textAlign: "center",
                }}>
                <Col
                  xs={{ span: 24 }}
                  md={{ span: 24, offset: 0 }}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}>
                  <Checkbox
                    checked={agreed}
                    onChange={(e) => setAgreed(e.target.checked)}></Checkbox>
                  <p className="mb-0 ms-2">
                    I have read and agree to the terms and conditions.
                  </p>
                </Col>

                <Col
                  xs={24}
                  md={24}
                  style={{
                    textAlign: "center",
                    padding: "0 20%",
                  }}>
                  <XForm.Item className="mt-3">
                    {loading ? (
                      <Button type="primary" loading>
                        Submitting...
                      </Button>
                    ) : (
                      <Button htmlType="submit" disabled={!agreed}>
                        I Agree
                      </Button>
                    )}
                  </XForm.Item>
                </Col>
              </Row>
            </div>
          </XForm>
        </Col>
      </XContainer>
    </XCardsGrdBG>
  );
};

const mapStateToProps = (state) => ({
  consentState: state.consent,
});

const mapDispatchToProps = (dispatch) => ({
  consent: (params) => dispatch(consentApi(params)),
  consentReset: () => dispatch(consentReset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Consent);
