import config from "../config";
import axios from "axios";
import { getUserToken } from "../utils/helper";

export const UPDATE_JOB_INVENTORY = "UPDATE_JOB_INVENTORY";
export const UPDATE_JOB_INVENTORY_SUCCESS = "UPDATE_JOB_INVENTORY_SUCCESS";
export const UPDATE_JOB_INVENTORY_FAIL = "UPDATE_JOB_INVENTORY_FAIL";
export const UPDATE_JOB_INVENTORY_RESET = "UPDATE_JOB_INVENTORY_RESET";

export const updateJobInventory = (params) => {
  return {
    type: UPDATE_JOB_INVENTORY,
    params,
  };
};

export const updateJobInventorySuccess = (response) => {
  return {
    type: UPDATE_JOB_INVENTORY_SUCCESS,
    response,
  };
};

export const updateJobInventoryFail = (response) => {
  return {
    type: UPDATE_JOB_INVENTORY_FAIL,
    response,
  };
};
export const updateJobInventoryReset = (response) => {
  return {
    type: UPDATE_JOB_INVENTORY_RESET,
  };
};

export const updateJobInventoryApi = (data) => {
  return (dispatch) => {
    dispatch(updateJobInventory());
    axios
      .patch(`${config.api.base_url}/job-inventory/${data.id}`, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getUserToken()}`,
        },
      })
      .then((response) => {
        const updateJobInventoryData = response.data;
        dispatch(updateJobInventorySuccess(updateJobInventoryData));
      })
      .catch((error) => {
        const errorData = error.response.data;
        dispatch(updateJobInventoryFail(errorData));
      });
  };
};
