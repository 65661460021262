import React from 'react';
import styled from 'styled-components';
import {
  capitalizeAndSplitByDash,
  extractInitials,
  generateRandomColor,
  getStatusColor,
  hasPermission,
  timeAgo,
} from '../../utils/helper';
import { Button, Dropdown, Menu } from 'antd';
import { application_status_list } from '../../utils/constants';
import { useNavigate, useParams } from 'react-router-dom';
const ApplicationCard = ({
  _id,
  city,
  first_name,
  last_name,
  mobile_number,
  email_address,
  specialization,
  state,
  skills_candidate,
  skills,
  work_experience,
  createdAt,
  isSelected,
  status_change_log,
  status,
  onClick,
  handleStatusUpdate,
}) => {
  const { id } = useParams();
  const navigate = useNavigate();

  const skills_list = skills_candidate || skills;
  const statusMenu = (
    <Menu>
      {application_status_list.map((status) => (
        <Menu.Item
          key={status.key}
          value={status.key}
          onClick={() => handleStatusUpdate(status.key, _id)}>
          {status.label}
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <CandidateCardWrapper isSelected={isSelected}>
      {status ? (
        <StatusTag className='mb-3' status={status}>
          {capitalizeAndSplitByDash(status)}
        </StatusTag>
      ) : (
        <></>
      )}
      <CandidateInfo>
        <EmployerLogo backgroundColor={generateRandomColor()}>
          <h1> {extractInitials(`${first_name} ${last_name}`)}</h1>
        </EmployerLogo>
        <CandidateDetails
          className='w-100'
          onClick={
            hasPermission('candidate-applications', 'view-application') ||
            hasPermission('candidates', 'view-candidates')
              ? onClick
              : null
          }>
          <CandidateTitle>
            <h3>
              {first_name} {last_name}
            </h3>
            <CandidateBadges className='mt-1'>
              <Badge>{capitalizeAndSplitByDash(specialization)}</Badge>
            </CandidateBadges>
          </CandidateTitle>
          <MetadataItem>
            <img
              src='https://cdn.builder.io/api/v1/image/assets/TEMP/3d3bcde6297965994169154628627b7bda17f95fc22518345cb7951d20fa4021?placeholderIfAbsent=true&apiKey=ac9769c5e7eb416e8a74975078a910d6'
              alt='city icon'
            />
            <span>
              {work_experience?.designation} at {work_experience?.company_name}
            </span>
          </MetadataItem>
          <CandidateMetadata className='mt-0'>
            <MetadataItem>
              <img
                src='https://cdn.builder.io/api/v1/image/assets/TEMP/3d3bcde6297965994169154628627b7bda17f95fc22518345cb7951d20fa4021?placeholderIfAbsent=true&apiKey=ac9769c5e7eb416e8a74975078a910d6'
                alt='city icon'
              />
              <span>
                {city}, {state}
              </span>
            </MetadataItem>
            <MetadataItem>
              <img
                src='https://cdn.builder.io/api/v1/image/assets/TEMP/9fc64845774fea26c0d4c54e5f0d876aaa9e8fef56f49b923a2a12d603e38501?placeholderIfAbsent=true&apiKey=be6d7b83ddd5411abafb02dd52ea7ce1'
                alt='phone icon'
              />
              <span>{mobile_number}</span>
            </MetadataItem>
            <MetadataItem>
              <img
                src='https://cdn.builder.io/api/v1/image/assets/TEMP/21672383ca2307455b27bf330aceb8820af157e0c750c4c0585a0ee0e590d05e?placeholderIfAbsent=true&apiKey=be6d7b83ddd5411abafb02dd52ea7ce1'
                alt='email icon'
              />
              <span>{email_address}</span>
            </MetadataItem>
            <MetadataItem>
              <img
                src='https://cdn.builder.io/api/v1/image/assets/TEMP/72a1dab8a6cbe1838b4ceacd3e200ce10f08201440beb6c8ed12e23e93a5ebd3?placeholderIfAbsent=true&apiKey=ac9769c5e7eb416e8a74975078a910d6'
                alt='Time icon'
              />
              <span>{timeAgo(createdAt)}</span>
            </MetadataItem>
          </CandidateMetadata>
          {/* Skills Tags */}
          <SkillTags>
            {skills_list?.map((skill, index) => (
              <SkillTag key={index}>{skill.skill}</SkillTag>
            ))}
          </SkillTags>
        </CandidateDetails>
        <CandidateDetails>
          {/* Checks for id and permissions  */}
          {id &&
          hasPermission(
            'candidate-applications',
            'update-application-status'
          ) ? (
            <></>
          ) : hasPermission('recruiter-submission', 'apply-job') ? (
            <Button
              type='primary'
              ghost
              onClick={() => {
                navigate(`/candidates/${_id}`);
              }}>
              Apply Jobs For {first_name} {last_name}
            </Button>
          ) : (
            <></>
          )}
        </CandidateDetails>{' '}
      </CandidateInfo>
      {id ? (
        <AppliedSessionTag className='justify-content-end'>
          <img
            src='https://cdn.builder.io/api/v1/image/assets/TEMP/72a1dab8a6cbe1838b4ceacd3e200ce10f08201440beb6c8ed12e23e93a5ebd3?placeholderIfAbsent=true&apiKey=ac9769c5e7eb416e8a74975078a910d6'
            alt='Time icon'
          />
          <span>Applied {timeAgo(status_change_log?.status_changed_at)}</span>
        </AppliedSessionTag>
      ) : (
        ''
      )}
    </CandidateCardWrapper>
  );
};

const AppliedSessionTag = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  img {
    width: 22px;
    height: 22px;
  }
  color: rgba(20, 20, 20, 0.7);
  margin-top: 24px;
  font: 400 16px/24px DM Sans, sans-serif;
  overflow: hidden;
  text-overflow: ellipsis;
  @media (max-width: 576px) {
    img {
      width: 18px;
      height: 18px;
    }
  }
`;

const MetadataItem = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  img {
    width: 22px;
    height: 22px;
  }
  @media (max-width: 576px) {
    img {
      width: 18px;
      height: 18px;
    }
  }
`;

const SkillTags = styled.div`
  display: flex;
  margin-top: 24px;
  flex-wrap: wrap;
  gap: 7px;
`;

const SkillTag = styled.span`
  border-radius: 3px;
  background-color: #efefef;
  color: #656565;
  font-size: 10px;
  font-weight: 500;
  padding: 4px 8px;
  white-space: nowrap;
`;

const Badge = styled.span`
  border-radius: 52px;
  padding: 4px 12px;
  font-size: 14px;
  font-weight: 400;
  background-color: ${(props) => (props.featured ? '#ffeded' : '#e8f1ff')};
  color: ${(props) => (props.featured ? '#e05151' : '#275df5')};
`;

// Styled Components
const CandidateInfo = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 20px;
  @media (max-width: 576px) {
    flex-direction: column;
  }
`;

const CandidateMetadata = styled.div`
  display: flex;
  margin-top: 14px;
  align-items: center;
  gap: 16px;
  font-size: 14px;
  color: #636a80;
  font-weight: 400;
  flex-wrap: wrap;
  @media (max-width: 576px) {
    font-size: 12px;
    gap: 12px;
  }
`;

const CandidateTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  flex-wrap: wrap;
  h3 {
    color: #191f33;
    font-size: 20px;
    font-weight: 500;
    line-height: 1.6;
    margin: 0;
  }
  @media (max-width: 576px) {
    h3 {
      font-size: 18px;
    }
  }
`;

const CandidateDetails = styled.div`
  display: flex;
  flex-direction: column;
  font-family: Inter, sans-serif;
  gap: 10px;
`;

const CandidateCardWrapper = styled.article`
  border-radius: 12px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 32px;
  cursor: pointer;
  border: 1px solid ${(props) => (props.isSelected ? '#275df5' : '#edf0f5')};
  box-shadow: ${(props) =>
    props.isSelected ? '0 12px 48px 0 rgba(0, 44, 109, 0.1)' : 'none'};
  transition: all 0.3s ease;
  &:hover {
    box-shadow: 0px 12px 48px 0px rgba(0, 44, 109, 0.1);
    border-color: #275df5;
  }
  @media (max-width: 768px) {
    padding: 24px;
  }
`;

const StatusTag = styled.span`
  border-radius: 6px;
  background-color: ${(props) => getStatusColor(props.status).backgroundColor};
  color: ${(props) => getStatusColor(props.status).textColor};
  font-size: 12px;
  font-weight: 500;
  padding: 4px 8px;
  white-space: nowrap;
  height: 22px;
  width: fit-content; /* Ensures width adapts to the text */
`;

const ExperienceLevel = styled.p`
  color: rgba(20, 20, 20, 0.7);
  margin-top: 24px;
  font: 400 16px/24px DM Sans, sans-serif;
`;

const CandidateBadges = styled.div`
  display: flex;
  gap: 8px;
  @media (max-width: 768px) {
    margin-top: 10px;
  }
`;

const EmployerLogo = styled.div`
  border-radius: 6px;
  background-color: ${(props) => props.backgroundColor || 'blue'};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 68px;
  height: 68px;
  padding: 20px;
  flex-shrink: 0;
  font-size: 30px;
`;

export default ApplicationCard;
