import {
  CONSENT,
  CONSENT_SUCCESS,
  CONSENT_FAIL,
  CONSENT_RESET,
} from "../actions/consentAction";

const initialState = {
  apiState: "",
  message: "",
  data: null,
  error: "",
};

const consentReducer = (state = initialState, action) => {
  switch (action.type) {
    case CONSENT:
      return {
        ...state,
        apiState: "loading",
      };
    case CONSENT_SUCCESS:
      return {
        ...state,
        apiState: "success",
        data: action.response.data,
        message: action.response.message ? action.response.message : "success",
      };
    case CONSENT_FAIL:
      return {
        ...state,
        apiState: "error",
        error: action.response.errors ? action.response.errors : null,
        message: action.response.message ? action.response.message : "error",
      };
    case CONSENT_RESET:
      return initialState;
    default:
      return state;
  }
};

export default consentReducer;
