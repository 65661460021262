import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  capitalizeAndSplitByDash,
  daysUntil,
  hasPermission,
  isLogin,
  splitTextIntoBulletPoints,
  timeAgo,
  truncateString,
} from '../../utils/helper';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import {
  ApplyButton,
  Badge,
  CopyLinkButton,
  Divider,
  InfoItem,
  InfoLabel,
  InfoValue,
  JobBadges,
  JobDescription,
  JobDetailsContainer,
  JobDetailsContent,
  JobDetailsScrollable,
  JobHeader,
  JobInfoGrid,
  JobMetadata,
  JobSticky,
  JobTimes,
  JobTitle,
  JobTitleWrapper,
  MetadataContent,
  MetadataItem,
  MetadataLabel,
  MetadataSubtext,
  MetadataValue,
  SaveButton,
  SectionTitle,
  ShareJob,
  SkillTag,
  SkillTags,
  SocialButton,
  SocialButtons,
} from '../../styles/GlobalStyle';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { Button } from 'antd';
const JobDescriptions = ({
  job,
  onClose,
  isDrawer,
  handleAddCandidateJob,
  candidateApplicationIds,
  updateStatus,
  onBookmarkToggle,
  is_bookmarked,
}) => {
  const { id } = useParams();
  const location = useLocation();
  const [isCopied, setIsCopied] = useState(false);
  const navigate = useNavigate();

  if (!job) return null;
  const jobUrl = `${window.location.protocol}//${window.location.host}${location.pathname}/${job._id}`;
  // const toggleBookmark = (id) => {
  //   addRemoveSavedJobs({ job_id: id });
  // };

  return (
    <JobDetailsContainer
      isDrawer={isDrawer}
      className={isDrawer ? 'mt-0 py-0' : ''}>
      <JobDetailsContent>
        <JobHeader>
          <JobTitleWrapper>
            <JobTitle>{job.job_title}</JobTitle>
            <JobBadges>
              {job.featured === 1 ? <Badge featured>Featured</Badge> : ''}
              <Badge>{capitalizeAndSplitByDash(job.employment_type)}</Badge>
            </JobBadges>
          </JobTitleWrapper>
          {hasPermission('jobs-inventory', 'update-jobs') &&
          location.pathname === '/inventory' ? (
            <SaveButton
              aria-label='Save job'
              onClick={() => {
                navigate(`/inventory/edit/${job._id}`);
              }}>
              <img src='./images/pencil.svg' alt='' />
            </SaveButton>
          ) : location.pathname === '/explore' ? (
            <SaveButton
              aria-label='Save job'
              onClick={() => onBookmarkToggle(job._id)} // Pass correctly here
            >
              <img
                src={
                  is_bookmarked
                    ? './images/saved.svg'
                    : 'https://cdn.builder.io/api/v1/image/assets/TEMP/916b0de0c0bfe058401d20aa5d3b7439adaacff3c02c5dcb461a9a598082cb3f?placeholderIfAbsent=true&apiKey=ac9769c5e7eb416e8a74975078a910d6'
                }
                alt='Save icon'
              />
            </SaveButton>
          ) : (
            <></>
          )}
        </JobHeader>
        <JobMetadata>
          <MetadataItem>
            {job.country === 'India' ? (
              <img src='/images/rupee.svg' alt='Salary icon' />
            ) : (
              <img
                src='https://cdn.builder.io/api/v1/image/assets/TEMP/8e60d29f437c248e6dad622631cbb5df8541aa5420ea5599012a61b288d5a368?placeholderIfAbsent=true&apiKey=ac9769c5e7eb416e8a74975078a910d6'
                alt='Salary icon'
              />
            )}
            <MetadataContent>
              <MetadataLabel>Salary (USD)</MetadataLabel>
              <MetadataValue>
                {job.salary_defined_type === 'fixed' ? (
                  <span>
                    {job.country === 'India' ? '₹ ' : '$ '}
                    {job.salary}
                  </span>
                ) : (
                  <span>
                    {job.country === 'India' ? '₹ ' : '$ '}
                    {`${job.salary_min} - ${job.salary_max}`}
                  </span>
                )}
              </MetadataValue>
              <MetadataSubtext>
                {capitalizeAndSplitByDash(job.salary_type)}
              </MetadataSubtext>
            </MetadataContent>
          </MetadataItem>
          {/* <Divider /> */}
          <MetadataItem>
            <img
              src='https://cdn.builder.io/api/v1/image/assets/TEMP/f68ddc6848b9a838ef94755cddd5b372264e38805235159872d90327a42548ca?placeholderIfAbsent=true&apiKey=ac9769c5e7eb416e8a74975078a910d6'
              alt='Location icon'
            />
            <MetadataContent>
              <MetadataLabel>Job Location - </MetadataLabel>
              <MetadataSubtext>{job.city}</MetadataSubtext>
            </MetadataContent>
          </MetadataItem>
        </JobMetadata>
        <SkillTags>
          {job.skills.map((skill, index) => (
            <SkillTag key={index}>{skill.skill}</SkillTag>
          ))}
        </SkillTags>
        <JobInfoGrid>
          <InfoItem>
            <img
              src='https://cdn.builder.io/api/v1/image/assets/TEMP/6061c746003fd82856eed64dd9332ec9420627b21f9d29d98a97af61775e4740?placeholderIfAbsent=true&apiKey=ac9769c5e7eb416e8a74975078a910d6'
              alt='Calendar icon'
            />
            <JobTimes>
              <InfoLabel>Job Posted:</InfoLabel>
              <InfoValue>{timeAgo(job.updatedAt)}</InfoValue>
            </JobTimes>
          </InfoItem>
          <InfoItem>
            <img
              src='https://cdn.builder.io/api/v1/image/assets/TEMP/d03425c54e0985f37fc77af20fdc0abcbdd4d5163cdfec83438a12dc1876eef9?placeholderIfAbsent=true&apiKey=ac9769c5e7eb416e8a74975078a910d6'
              alt='Clock icon'
            />
            <JobTimes>
              <InfoLabel>Job expire in:</InfoLabel>
              <InfoValue
                style={{
                  color: `${
                    daysUntil(job.expiry_date) === 'Expired' ? '#e05151' : ''
                  }`,
                }}>
                {daysUntil(job.expiry_date)}
              </InfoValue>
            </JobTimes>
          </InfoItem>
          <InfoItem>
            <img
              src='https://cdn.builder.io/api/v1/image/assets/TEMP/118b7b75f6de2bbdc2d68cb8b541a46c6b11eb0a7c8475ec821cc62287af6991?placeholderIfAbsent=true&apiKey=ac9769c5e7eb416e8a74975078a910d6'
              alt='Level icon'
            />
            <JobTimes>
              <InfoLabel>Job Level:</InfoLabel>
              <InfoValue>
                {job.experience_level.map((exp, index) =>
                  capitalizeAndSplitByDash(exp)
                )}
              </InfoValue>
            </JobTimes>
          </InfoItem>
          <InfoItem>
            <img
              src='https://cdn.builder.io/api/v1/image/assets/TEMP/16dadfcf1a715979e1a17a5d1d88bc51541408226146f858440d6c0ce461a762?placeholderIfAbsent=true&apiKey=ac9769c5e7eb416e8a74975078a910d6'
              alt='Education icon'
            />
            <JobTimes>
              <InfoLabel>Education</InfoLabel>
              {job.education_qualification.map((edu, index) => (
                <InfoValue key={index}>{edu.qualification}</InfoValue>
              ))}
            </JobTimes>
          </InfoItem>
        </JobInfoGrid>
        {isLogin() ? (
          candidateApplicationIds ? (
            candidateApplicationIds.includes(job._id) ? (
              <p className='w-100'>Applied</p>
            ) : daysUntil(job.expiry_date) === 'Expired' ? (
              <></>
            ) : id ? (
              <ApplyButton
                onClick={() => {
                  handleAddCandidateJob({
                    job_id: job._id,
                    employer_id: job.employer_id,
                    candidate_id: id,
                    employer_name: job.employer,
                    job_title: job.job_title,
                  });
                }}>
                Apply For Candidate
                <img
                  src='https://cdn.builder.io/api/v1/image/assets/TEMP/72c910bdc0ab047ea5955f38a3ad60282ede593a9cd52829af6c667f07736419?placeholderIfAbsent=true&apiKey=ac9769c5e7eb416e8a74975078a910d6'
                  alt='Apply icon'
                />
              </ApplyButton>
            ) : (
              <ApplyButton
                onClick={() =>
                  handleAddCandidateJob({
                    job_id: job._id,
                    employer_id: job.employer_id,
                    employer_name: job.employer,
                    job_title: job.job_title,
                  })
                }>
                Apply now
                <img
                  src='https://cdn.builder.io/api/v1/image/assets/TEMP/72c910bdc0ab047ea5955f38a3ad60282ede593a9cd52829af6c667f07736419?placeholderIfAbsent=true&apiKey=ac9769c5e7eb416e8a74975078a910d6'
                  alt='Apply icon'
                />
              </ApplyButton>
            )
          ) : job.status === 'published' ? (
            <>
              <ApplyButton
                onClick={() => {
                  updateStatus(job._id, 'draft'); // Correctly passing job._id
                }}>
                Move To Draft
                <img
                  src='https://cdn.builder.io/api/v1/image/assets/TEMP/72c910bdc0ab047ea5955f38a3ad60282ede593a9cd52829af6c667f07736419?placeholderIfAbsent=true&apiKey=ac9769c5e7eb416e8a74975078a910d6'
                  alt='Apply icon'
                />
              </ApplyButton>
            </>
          ) : (
            <>
              <ApplyButton
                onClick={() => {
                  updateStatus(job._id, 'published');
                }}>
                Publish Job
                <img
                  src='https://cdn.builder.io/api/v1/image/assets/TEMP/72c910bdc0ab047ea5955f38a3ad60282ede593a9cd52829af6c667f07736419?placeholderIfAbsent=true&apiKey=ac9769c5e7eb416e8a74975078a910d6'
                  alt='Apply icon'
                />
              </ApplyButton>
            </>
          )
        ) : (
          <ApplyButton
            onClick={() => {
              navigate('/login');
            }}>
            Sign In/Sign Up To Apply
            <img
              src='https://cdn.builder.io/api/v1/image/assets/TEMP/72c910bdc0ab047ea5955f38a3ad60282ede593a9cd52829af6c667f07736419?placeholderIfAbsent=true&apiKey=ac9769c5e7eb416e8a74975078a910d6'
              alt='Apply icon'
            />
          </ApplyButton>
        )}

        <Divider />
        <JobSticky />
        {/* Scrollable Section */}
        <JobDetailsScrollable className={isDrawer ? 'pb-4' : ''}>
          <JobDescription>
            <SectionTitle>Job Description</SectionTitle>
            <p>{job.job_description}</p>
          </JobDescription>
          <JobDescription>
            <SectionTitle>Requirements</SectionTitle>
            <ul>
              {splitTextIntoBulletPoints(job.special_requirements).map(
                (bulletPoint, index) => (
                  <li key={index}>{bulletPoint}</li>
                )
              )}
            </ul>
          </JobDescription>
          <JobDescription>
            <SectionTitle>Desirable</SectionTitle>
            <ul>
              {splitTextIntoBulletPoints(job.desirable).map(
                (bulletPoint, index) => (
                  <li key={index}>{bulletPoint}</li>
                )
              )}
            </ul>
          </JobDescription>
          <JobDescription>
            <SectionTitle>Benefits</SectionTitle>
            <ul>
              {splitTextIntoBulletPoints(job.benefits).map(
                (bulletPoint, index) => (
                  <li key={index}>{bulletPoint}</li>
                )
              )}
            </ul>
          </JobDescription>
          <Divider />
          <ShareJob>
            <SectionTitle>Share this job:</SectionTitle>
            <SocialButtons>
              <SocialButton
                aria-label='Share on Facebook'
                onClick={() =>
                  window.open(
                    `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
                      jobUrl
                    )}`,
                    '_blank'
                  )
                }>
                <img
                  src='https://cdn.builder.io/api/v1/image/assets/TEMP/325cc05d0a5e0e9499de5a5fb1fc7a5815c0ddc862ffabe3b67090ac761576d4?placeholderIfAbsent=true&apiKey=ac9769c5e7eb416e8a74975078a910d6'
                  alt='Facebook icon'
                />
              </SocialButton>
              <SocialButton
                aria-label='Share on Twitter'
                onClick={() =>
                  window.open(
                    `https://twitter.com/intent/tweet?url=${encodeURIComponent(
                      jobUrl
                    )}&text=Check%20out%20this%20job!`,
                    '_blank'
                  )
                }>
                <img
                  src='https://cdn.builder.io/api/v1/image/assets/TEMP/a45e9ddc1ea930fe30b3ffcba7b8ff47928d8df8495e1c55b7268da9dd5b9b7a?placeholderIfAbsent=true&apiKey=ac9769c5e7eb416e8a74975078a910d6'
                  alt='LinkedIn icon'
                />
              </SocialButton>
              <SocialButton
                aria-label='Share on LinkedIn'
                onClick={() =>
                  window.open(
                    `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
                      jobUrl
                    )}`,
                    '_blank'
                  )
                }>
                <img
                  src='https://cdn.builder.io/api/v1/image/assets/TEMP/af3d0b89a87a9841e16eba0e780c67f2841574dfaacf438eaba2ece6fa5b1b10?placeholderIfAbsent=true&apiKey=ac9769c5e7eb416e8a74975078a910d6'
                  alt='Twitter icon'
                />
              </SocialButton>
              <SocialButton
                aria-label='Share via Email'
                onClick={() =>
                  window.open(
                    `mailto:?subject=Check%20out%20this%20job&body=${encodeURIComponent(
                      jobUrl
                    )}`,
                    '_blank'
                  )
                }>
                <img
                  src='https://cdn.builder.io/api/v1/image/assets/TEMP/dcad6a4361717f37a4ef1e2017a471a7fa8af1e9693801f6e60d72b60e25ad8c?placeholderIfAbsent=true&apiKey=ac9769c5e7eb416e8a74975078a910d6'
                  alt='Email icon'
                />
              </SocialButton>
              <CopyToClipboard text={jobUrl} onCopy={() => setIsCopied(true)}>
                <CopyLinkButton>
                  <img
                    src='https://cdn.builder.io/api/v1/image/assets/TEMP/e98c6f4baca56d565b657987411017db503cf83e353256edc1cdc95ebc73987e?placeholderIfAbsent=true&apiKey=ac9769c5e7eb416e8a74975078a910d6'
                    alt='Copy link icon'
                  />
                  Copy Link
                </CopyLinkButton>
              </CopyToClipboard>
              {isCopied ? <span style={{ color: 'red' }}>Copied.</span> : null}
            </SocialButtons>
          </ShareJob>
        </JobDetailsScrollable>
      </JobDetailsContent>
      {/* {isDrawer && <CloseButton onClick={onClose}>×</CloseButton>} */}
    </JobDetailsContainer>
  );
};

// const CloseButton = styled.button`
//   position: absolute;
//   top: 20px;
//   right: 20px;
//   background: none;
//   border: none;
//   font-size: 24px;
//   color: #18191c;
//   cursor: pointer;
// `;

export default JobDescriptions;
