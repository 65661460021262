import styled from 'styled-components';
import '../../styles/GlobalStyle';
import { Steps, Upload, Button, Checkbox, Input, Card, Switch } from 'antd';

export const XSteps = styled(Steps)`
  .ant-steps-item-icon {
    background-color: ${({ theme }) => theme.colors.bgButton1} !important;
    border-color: ${({ theme }) => theme.colors.bgButton1} !important;
    width: 20px;
    height: 20px;
    line-height: 20px;
    font-size: 10px;
  }
  .ant-steps-item-title {
    line-height: 20px;
    padding-inline-end: 5px;
    background: ${({ theme }) => theme.colors.bgButton1};
  }
  .ant-steps-item-title::after {
    top: 11px;
    height: 2px;
  }
  .ant-steps-item-wait .ant-steps-item-icon {
    background-color: ${({ theme }) => theme.colors.border} !important;
    border-color: ${({ theme }) => theme.colors.border} !important;
  }
  .ant-steps-item-wait .ant-steps-item-icon span {
    color: ${({ theme }) => theme.colors.text1} !important;
  }
  .ant-steps-item-finish .ant-steps-item-icon .ant-steps-icon span svg {
    color: ${({ theme }) => theme.colors.white} !important;
    fill: ${({ theme }) => theme.colors.white} !important;
    margin-top: -5px;
  }
`;

export const XUpload = styled(Upload.Dragger)`
  .ant-upload-select {
    display: block !important;
  }
  .ant-upload-drag {
    width: 100% !important;
    background: ${({ theme }) => theme.colors.text4} !important;
    border: 1px dashed rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    padding: 15px;
  }
  p {
    margin: 0px;
    font-size: 14px;
    color: ${({ theme }) => theme.colors.text1};
  }
`;
export const PageWrap = styled.div`
  margin: 15px 0px;
`;
export const XButton = styled(Button)`
  background: #275df5;
  color: ${({ theme }) => theme.colors.white};
  border: none;
  font-size: 16px;
  font-weight: 600;
  line-height: 30px;
  padding: 2% 10%;
  height: auto;
  box-shadow: 0px 10px 50px rgba(39, 93, 245, 0.3);
  letter-spacing: 0.016em;
  border-radius: 10px;
  margin-right: 15px;
  width: 100%;
  &:focus,
  &:active,
  &:hover {
    color: white !important;
    background: #275df5 !important;
  }
  @media only screen and (min-width: 769px) {
    width: auto;
  }
`;
export const XButtonNoBg = styled(Button)`
  background: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.bgButton1};
  border: 1px solid ${({ theme }) => theme.colors.bgButton1};
  letter-spacing: 0.016em;
  font-size: 16px;
  font-weight: 600;
  line-height: 30px;
  padding: 1% 10%;
  height: auto;
  box-shadow: 0px 10px 10px rgba(39, 93, 245, 0.1);
  border-radius: 10px;
  width: 100%;
  &:focus,
  &:hover {
    border: 1px solid ${({ theme }) => theme.colors.bgButton1} !important;
    color: ${({ theme }) => theme.colors.bgButton1} !important;
  }
  @media only screen and (min-width: 769px) {
    width: auto;
  }
`;
export const ProfileGende = styled.div`
  .ant-radio-button-wrapper {
    margin: 0px;
    border: 1px solid ${({ theme }) => theme.colors.border};
    border-radius: 15px;
    margin-right: 15px;
    padding: 5px 15px;
    font-size: 12px !important;
    line-height: 20px !important;
    &::before {
      display: none !important;
    }
  }
  .ant-radio-button-wrapper-checked {
    border-color: ${({ theme }) => theme.colors.bgButton1} !important;
    color: ${({ theme }) => theme.colors.bgButton1} !important;
  }
`;
export const XCheckbox = styled(Checkbox)`
  font-size: 13px !important;
  line-height: 20px;
  font-weight: 400 !important;
  color: ${({ theme }) => theme.colors.text1};
`;
export const OverlapWrap = styled.section`
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0;
  z-index: 9;
  background: ${({ theme }) => theme.colors.white} !important;
  overflow-y: scroll;
`;
export const Title = styled.span`
  margin: 0px;
  line-height: 30px;
  font-size: 16px !important;
  font-weight: 600;
  margin-right: 15px;
  button {
    font-size: 16px !important;
    font-weight: 600;
    margin-right: 15px;
    svg {
      margin-top: -5px;
    }
  }
`;
export const Title2 = styled.h1`
  margin: 0px;
  font-size: 16px !important;
  line-height: 22px;
  font-weight: 600;
`;
export const Title3 = styled.h4`
  margin: 0px;
  font-size: 14px !important;
  line-height: 18px;
`;
export const XInput = styled(Input)`
  border-radius: 30px;
`;
export const List = styled.ul`
  margin: 0px;
  padding: 0px;
  li {
    margin: 0px;
    padding: 10px 15px;
    list-style-type: none;
    font-size: 14px;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.text1};
    opacity: 0.6;
    background: none;
    &:hover {
      background: ${({ theme }) => theme.colors.headerBg};
      border-radius: 15px;
      opacity: 1;
    }
  }
`;
export const RemoveAbs = styled.div`
  position: absolute;
  margin-top: -15px;
  right: 15px;
`;
export const XCard = styled(Card)`
  .ant-card-head {
    padding: 0px 10px;
  }
  .ant-card-body {
    padding: 10px !important;
  }
`;
export const XDesktopCard = styled(Card)`
  border: none;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 20px;
  box-shadow: 0px 4px 30px 0px rgba(39, 93, 245, 0.05);

  .ant-card-body {
    padding: 30px;
  }
  @media only screen and (max-width: 769px) {
    background: none;
    border-radius: 0px;
    box-shadow: none;
    .ant-card-body {
      padding: 0px;
    }
  }
`;
export const XButtonBack = styled(Button)`
  border: none;
  box-shadow: none;
  color: ${({ theme }) => theme.colors.text1};
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  line-height: 150%;
  &:hover {
    color: ${({ theme }) => theme.colors.text1} !important;
  }
  img {
    margin-right: 10px;
    margin-top: -1px;
  }
`;
export const XCardInner = styled(Card)`
  border: none;
  .ant-card-head {
    padding: 0px;
    margin-bottom: 15px;
  }
  .ant-card-body {
    padding: 0px;
  }
`;

// color: ${({ theme }) => theme.colors.bgButton1};
export const XButtonNonBg = styled(Button)`
  border-radius: 15px;
  background: #edf4fb;
  box-shadow: 0px 10px 50px 0px rgba(211, 211, 211, 0.3);
  color: ${({ theme }) => theme.colors.bgButton1};
  border: none;
  font-size: 14px;
  font-weight: 500;
  line-height: 30px;
  padding: 1% 10%;
  height: auto;
  width: 100%;
  &:focus,
  &:active,
  &:hover {
    color: ${({ theme }) => theme.colors.white} !important;
    background: ${({ theme }) => theme.colors.bgButton1};
  }
  @media only screen and (min-width: 769px) {
    width: auto;
  }
`;
export const TitleUpload = styled.h5`
  color: ${({ theme }) => theme.colors.bgButton1};
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  span {
    color: ${({ theme }) => theme.colors.text1};
  }
  @media only screen and (min-width: 769px) {
  }
`;

const BuildProfileStyle = () => {};

export default BuildProfileStyle;
