import { combineReducers } from "redux";
import login from "./reducers/loginReducer";
import signUp from "./reducers/signUpReducer";
import sendPasswordResetEmail from "./reducers/sendPasswordResetEmailReducer";
import verifyOtp from "./reducers/verifyOtpReducer";
import resetPassword from "./reducers/resetPasswordReducer";
import addUser from "./reducers/addUserReducer";
import editUser from "./reducers/editUserReducer";
import deleteUser from "./reducers/deleteUserReducer";
import getUsers from "./reducers/getUsersReducer";
import getUserById from "./reducers/getUserByIdReducer";
import createRole from "./reducers/createRoleReducer";
import editRole from "./reducers/editRoleReducer";
import deleteRole from "./reducers/deleteRoleReducer";
import getRoles from "./reducers/getRolesReducer";
import getDefaultRoles from "./reducers/getDefaultRolesReducer";
import getRolesDropdown from "./reducers/getRolesDropdownReducer";
import getRoleById from "./reducers/getRoleByIdReducer";
import getModules from "./reducers/getModulesReducer";
import updateProfile from "./reducers/updateProfileReducer";
import getUserDropdown from "./reducers/getUserDropdownReducer";
import logout from "./reducers/logoutReducer";
import changePassword from "./reducers/changePasswordReducer";
import getConfigurationOptions from "./reducers/getConfigurationOptionsReducer";
import getConfigurationById from "./reducers/getConfigurationByIdReducer";
import getConfigurations from "./reducers/getConfigurationsReducer";
import addConfiguration from "./reducers/addConfigurationReducer";
import editConfiguration from "./reducers/editConfigurationReducer";
import deleteConfiguration from "./reducers/deleteConfigurationReducer";
import getModuleById from "./reducers/getModuleByIdReducer";
import getModulesList from "./reducers/getModulesListReducer";
import deleteModule from "./reducers/deleteModuleReducer";
import editModule from "./reducers/editModuleReducer";
import addModule from "./reducers/addModuleReducer";
import consent from "./reducers/consentReducer";
import addEmployer from "./reducers/addEmployerReducer";
import addReseller from "./reducers/addResellerReducer";
import addJobToInventory from "./reducers/addJobToInventoryReducer";
import updateReseller from "./reducers/updateResellerReducer";
import updateEmployer from "./reducers/updateEmployerReducer";
import updateJobInventory from "./reducers/updateJobInventoryReducer";
import getJobFromInventory from "./reducers/getJobFromInventoryReducer";
import getResellerById from "./reducers/getResellerByIdReducer";
import getEmployerById from "./reducers/getEmployerByIdReducer";
import getIndustries from "./reducers/getIndustriesReducer";
import getIndustryCategories from "./reducers/getIndustryCategoriesReducer";
import getCountries from "./reducers/getCountriesReducer";
import getSkills from "./reducers/getSkillsReducer";
import getQualification from "./reducers/getQualificationReducer";
import getStates from "./reducers/getStatesReducer";
import getEmployers from "./reducers/getEmployersReducer";
import getResellers from "./reducers/getResellersReducer";
import getCandidateApplicationsById from "./reducers/getCandidateApplicationsByIdReducer";
import getCandidateDetails from "./reducers/getCandidateDetailsReducer";
import getJobApplications from "./reducers/getJobApplicationsReducer";
import getApplicationById from "./reducers/getApplicationByIdReducer";
import addCandidateJob from "./reducers/addCandidateJobReducer";
import savePersonalInformation from "./reducers/savePersonalInformationReducer";
import getDegreeList from "./reducers/getDegreeListReducer";
import getJobApplicationCount from "./reducers/getJobApplicationCountReducer";
import getJobApplicationStatusCount from "./reducers/getJobApplicationStatusCountReducer";
import getCandidateJobList from "./reducers/getCandidateJobListReducer";
import getCandidateJobListGuest from "./reducers/getCandidateJobListGuestReducer";
import getJobList from "./reducers/getJobListReducer";
import updateJobApplicationStatus from "./reducers/updateJobApplicationStatusReducer";
import getCandidateList from "./reducers/getCandidateListReducer";
import updateJobStatus from "./reducers/updateJobStatusReducer";
import getEmployerOrganizationsList from "./reducers/getEmployerOrganizationsListReducer";
import getJobApplicationStatistics from "./reducers/getJobApplicationStatisticsReducer";
import getJobApplicationStatisticsMonthly from "./reducers/getJobApplicationStatisticsMonthlyReducer";
import getJobCountByIndustry from "./reducers/getJobCountByIndustryReducer";
import getUserCounts from "./reducers/getUserCountsReducer";
import getJobCounts from "./reducers/getJobCountsReducer";
import getJobStatistics from "./reducers/getJobStatisticsReducer";
import addRemoveSavedJobs from "./reducers/addRemoveSavedJobsReducer";

const rootReducer = combineReducers({
  login,
  signUp,
  sendPasswordResetEmail,
  verifyOtp,
  resetPassword,
  addUser,
  editUser,
  deleteUser,
  getUsers,
  getUserById,
  createRole,
  editRole,
  deleteRole,
  getRoles,
  getDefaultRoles,
  getRolesDropdown,
  getRoleById,
  getModules,
  updateProfile,
  getUserDropdown,
  logout,
  changePassword,
  getConfigurationOptions,
  getConfigurationById,
  getConfigurations,
  addConfiguration,
  editConfiguration,
  deleteConfiguration,
  getModuleById,
  getModulesList,
  deleteModule,
  editModule,
  consent,
  addModule,
  addEmployer,
  addReseller,
  addJobToInventory,
  updateReseller,
  updateEmployer,
  updateJobInventory,
  getJobFromInventory,
  getResellerById,
  getEmployerById,
  getIndustries,
  getIndustryCategories,
  getCountries,
  getSkills,
  getQualification,
  getStates,
  getEmployers,
  getResellers,
  getCandidateApplicationsById,
  getCandidateDetails,
  getJobApplications,
  getApplicationById,
  addCandidateJob,
  savePersonalInformation,
  getDegreeList,
  getJobApplicationCount,
  getJobApplicationStatusCount,
  getCandidateJobList,
  getJobList,
  updateJobApplicationStatus,
  getCandidateList,
  updateJobStatus,
  getEmployerOrganizationsList,
  getJobApplicationStatistics,
  getJobApplicationStatisticsMonthly,
  getJobCountByIndustry,
  getUserCounts,
  getJobCounts,
  getJobStatistics,
  addRemoveSavedJobs,
  getCandidateJobListGuest,
});

export default rootReducer;
