import config from "../config";
import axios from "axios";
import { getUserToken } from "../utils/helper";

export const GET_CANDIDATE_JOB_LIST_GUEST = "GET_CANDIDATE_JOB_LIST_GUEST";
export const GET_CANDIDATE_JOB_LIST_GUEST_SUCCESS =
  "GET_CANDIDATE_JOB_LIST_GUEST_SUCCESS";
export const GET_CANDIDATE_JOB_LIST_GUEST_FAIL =
  "GET_CANDIDATE_JOB_LIST_GUEST_FAIL";
export const GET_CANDIDATE_JOB_LIST_GUEST_RESET =
  "GET_CANDIDATE_JOB_LIST_GUEST_RESET";

export const getCandidateJobListGuest = (params) => {
  return {
    type: GET_CANDIDATE_JOB_LIST_GUEST,
    params,
  };
};

export const getCandidateJobListGuestSuccess = (response) => {
  return {
    type: GET_CANDIDATE_JOB_LIST_GUEST_SUCCESS,
    response,
  };
};

export const getCandidateJobListGuestFail = (response) => {
  return {
    type: GET_CANDIDATE_JOB_LIST_GUEST_FAIL,
    response,
  };
};

export const getCandidateJobListGuestReset = () => {
  return {
    type: GET_CANDIDATE_JOB_LIST_GUEST_RESET,
  };
};

export const getCandidateJobListGuestApi = (params) => {
  return (dispatch) => {
    dispatch(getCandidateJobListGuest());
    axios
      .get(`${config.api.base_url}/explore/get-job-list-guest`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getUserToken()}`,
        },
        params,
      })
      .then((response) => {
        const getCandidateJobListGuestData = response.data;
        dispatch(getCandidateJobListGuestSuccess(getCandidateJobListGuestData));
      })
      .catch((error) => {
        const errorData = error.response.data;
        dispatch(getCandidateJobListGuestFail(errorData));
      });
  };
};
