import React, { useEffect, useState } from "react";
import JobDetails from "../../components/JobDescription/index";
import { Col, notification } from "antd";
import { connect } from "react-redux";
import { addCandidateJobApi } from "../../actions/addCandidateJobAction";
import { getCandidateApplicationsByIdApi } from "../../actions/getCandidateApplicationsByIdAction";
import { checkModulePermissions } from "../../utils/helper";
import { useForm } from "antd/es/form/Form";

import {
  getJobFromInventoryApi,
  getJobFromInventoryReset,
} from "../../actions/getJobFromInventoryAction";
import {
  ContentContainer,
  Header,
  PageContainer,
} from "../../styles/GlobalStyle";
import { useParams } from "react-router-dom";

const JobListingPage = (props) => {
  const defaultFilters = {
    page: 1,
    limit: 10,
    search: "",
    location: "",
    category: "",
    posted: "",
    industry: "",
    joined_on: "",
    sortDirection: null,
  };

  const {
    getJobFromInventory,
    getJobFromInventoryState,
    getJobFromInventoryReset,
    addCandidateJobState,
    addCandidateJob,
    getCandidateApplicationsByIdState,
    getCandidateApplicationsById,
  } = props;
  const [form] = useForm();

  const { jobId } = useParams();

  const [selectedJob, setSelectedJob] = useState(null);

  const getFiltersFromUrl = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const urlFilters = {};

    // Iterate through each query parameter and set it in the state
    for (const [key, value] of urlParams.entries()) {
      urlFilters[key] = value;
    }

    // Merge with default filters or initial state as needed
    const filters = {
      ...defaultFilters, // Replace with your default filters
      ...urlFilters,
    };

    return filters;
  };
  const initialFilters = getFiltersFromUrl();

  const [filters, setFilters] = useState(initialFilters);
  // const [tableData, setTableData] = useState([]);
  const [currentJobs, setCurrentJobs] = useState(null);
  const [candidateApplicationIds, setCandidateApplicationIds] = useState([]);

  const handleAddCandidateJob = (id) => {
    //id here includes employer_id, job_id
    if (id) addCandidateJob(id);
  };

  useEffect(() => {
    // Retrieve filters from the updated URL
    const updatedFilters = getFiltersFromUrl();
    setFilters({ ...updatedFilters });
    // Make API call with updated filters and pagination
    if (checkModulePermissions("explore").sub_modules.includes("list-jobs")) {
      getJobFromInventory({ id: jobId });
      getCandidateApplicationsById();
    }
  }, [window.location.search]);

  useEffect(() => {
    if (getJobFromInventoryState.apiState === "success") {
      setSelectedJob(getJobFromInventoryState.data);

      getJobFromInventoryReset();
    } else if (getJobFromInventoryState.apiState === "error") {
      console.log("API Error=>", getJobFromInventoryState.message);
      notification.error({
        message: "Unable to get Job List",
      });
      getJobFromInventoryReset();
    }
  }, [getJobFromInventoryState.apiState]);

  useEffect(() => {
    if (getCandidateApplicationsByIdState.apiState === "success") {
      getCandidateApplicationsByIdState.data?.applications?.map(
        (ele, index) => {
          setCandidateApplicationIds((prevState) => [
            ...prevState,
            ele.job_inventory_id,
          ]);
        }
      );
    }
  }, [getCandidateApplicationsByIdState.apiState]);

  useEffect(() => {
    if (addCandidateJobState.apiState === "success") {
      getCandidateApplicationsById();
    }
  }, [addCandidateJobState.apiState]);

  return (
    <>
      <PageContainer>
        <Header></Header>
        <ContentContainer>
          <JobDetails
            job={selectedJob}
            handleAddCandidateJob={handleAddCandidateJob}
            candidateApplicationIds={candidateApplicationIds}
          />
        </ContentContainer>
      </PageContainer>
    </>
  );
};

const mapStateToProps = (state) => ({
  getCandidateApplicationsByIdState: state.getCandidateApplicationsById,
  addCandidateJobState: state.addCandidateJob,
  getJobFromInventoryState: state.getJobFromInventory,
});

const mapDispatchToProps = (dispatch) => ({
  getCandidateApplicationsById: (params) =>
    dispatch(getCandidateApplicationsByIdApi(params)),
  addCandidateJob: (params) => dispatch(addCandidateJobApi(params)),
  getJobFromInventory: (params) => dispatch(getJobFromInventoryApi(params)),
  getJobFromInventoryReset: () => dispatch(getJobFromInventoryReset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(JobListingPage);
