import React from "react";
import styled from "styled-components";
import {
  Column,
  ContactInfo,
  FooterColumns,
  FooterContent,
  FooterWrapper,
  PhoneNumber,
  CallNow,
  Number,
  Address,
  ColumnTitle,
  LinkList,
  LinkItem,
  CopyrightBar,
  CopyrightContent,
  CopyrightText,
  SocialIcons,
  SocialIcon,
  FooterLogo,
  LogoWrapper,
  Logo,
} from "../../styles/GlobalStyle";
import { hasPermission } from "../../utils/helper";

const Footer = () => {
  return (
    <FooterWrapper className="">
      <FooterContent>
        <FooterLogo className="mb-2">
          <Logo>
            <img src="./images/logoFooter.png" alt="" height={"40px"} />
          </Logo>
        </FooterLogo>
        <FooterColumns>
          <Column>
            {/* <FooterLogo>Skillsprints</FooterLogo> */}
            <ContactInfo className="mt-0">
              <PhoneNumber className="mb-0">
                <CallNow>Call now:</CallNow>
                <Address>+91 84518 28462 /</Address>
              </PhoneNumber>
              <PhoneNumber className="my-0">
                {/* <CallNow>Call now:</CallNow> */}
                <Address>+91 91374 70060 /</Address>
              </PhoneNumber>{" "}
              <PhoneNumber className="mt-0">
                {/* <CallNow>Call now:</CallNow> */}
                <Address>+230 5745 0670</Address>
              </PhoneNumber>
              <PhoneNumber>
                <Address>info.skillsprints@gmail.com</Address>
              </PhoneNumber>
              <Address>400001, Mumbai, Navi Mumbai, Maharashtra, India</Address>
            </ContactInfo>
          </Column>
          <Column>
            <ColumnTitle>Quick Link</ColumnTitle>
            <LinkList>
              <LinkItem>
                <a href="/">Home</a>
              </LinkItem>
              <LinkItem>
                <a href="/about-us">About Us</a>
              </LinkItem>
              <LinkItem>
                <a href="/contact-us">Contact</a>
              </LinkItem>
              <LinkItem>
                <a href="/reset-password">Reset Password</a>
              </LinkItem>
            </LinkList>
          </Column>
          {hasPermission("footer", "candidate") ? (
            <>
              <Column>
                <ColumnTitle>Candidate</ColumnTitle>
                <LinkList>
                  <LinkItem>
                    <a href="/explore">Browse Jobs</a>
                  </LinkItem>
                  <LinkItem>
                    <a href="/my-applications">My Applications</a>
                  </LinkItem>
                  <LinkItem>
                    <a href="/candidate-profile">Profile</a>
                  </LinkItem>
                  <LinkItem>
                    <a href="/explore?savedJobs=1">Saved Jobs</a>
                  </LinkItem>
                </LinkList>
              </Column>
            </>
          ) : (
            <></>
          )}
          {hasPermission("footer", "employer") ? (
            <>
              <Column>
                <ColumnTitle>Employers</ColumnTitle>
                <LinkList>
                  <LinkItem>
                    <a href="/dashboard">Dashboard</a>
                  </LinkItem>
                  <LinkItem>
                    <a href="/inventory/add">Post a Job</a>
                  </LinkItem>
                  <LinkItem>
                    <a href="/applications">Applications</a>
                  </LinkItem>
                  <LinkItem>
                    <a href="/profile">Profile</a>
                  </LinkItem>
                </LinkList>
              </Column>
            </>
          ) : (
            <></>
          )}
          {hasPermission("footer", "recruiter") ? (
            <>
              <Column>
                <ColumnTitle>Recruiters</ColumnTitle>
                <LinkList>
                  <LinkItem>
                    <a href="/dashboard">Dashboard</a>
                  </LinkItem>
                  <LinkItem>
                    <a href="/candidates">Candidates</a>
                  </LinkItem>
                  <LinkItem>
                    <a href="/applications">Applications</a>
                  </LinkItem>
                  <LinkItem>
                    <a href="/profile">Profile</a>
                  </LinkItem>
                </LinkList>
              </Column>
            </>
          ) : (
            <></>
          )}
        </FooterColumns>
      </FooterContent>
      <CopyrightBar>
        <CopyrightContent>
          <CopyrightText>
            @ 2024 skillsprints - Job Portal. All rights Reserved
          </CopyrightText>
          <SocialIcons>
            <SocialIcon
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/cb214bee65be90895565b871f4f61e858f49e049d89beec31fb5e52f5b66ccc5?apiKey=e27c6d2dfca54bdabb20ac8325bd9dca&&apiKey=e27c6d2dfca54bdabb20ac8325bd9dca"
              alt="Facebook"
            />
            <SocialIcon
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/aa7fbe33a99c15a67586ef52ac20bdcae2491abb6171782dc6849b40b140182d?apiKey=e27c6d2dfca54bdabb20ac8325bd9dca&&apiKey=e27c6d2dfca54bdabb20ac8325bd9dca"
              alt="Twitter"
            />
            <SocialIcon
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/6f4cebdfa20f54d3a1b1c06a525b7f78e28dc5d78d8112536e85023979ac5b60?apiKey=e27c6d2dfca54bdabb20ac8325bd9dca&&apiKey=e27c6d2dfca54bdabb20ac8325bd9dca"
              alt="LinkedIn"
            />
            <SocialIcon
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/2276de5ff92492f9d92843e165df14e7b2e79db39cafc81f38fa857dc5520ef5?apiKey=e27c6d2dfca54bdabb20ac8325bd9dca&&apiKey=e27c6d2dfca54bdabb20ac8325bd9dca"
              alt="Instagram"
            />
          </SocialIcons>
        </CopyrightContent>
      </CopyrightBar>
    </FooterWrapper>
  );
};

export default Footer;
