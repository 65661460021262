import axios from "axios";
import config from "../config";
import { getUserToken } from "../utils/helper";

export const EDIT_MODULE = "EDIT_MODULE";
export const EDIT_MODULE_SUCCESS = "EDIT_MODULE_SUCCESS";
export const EDIT_MODULE_FAIL = "EDIT_MODULE_FAIL";
export const EDIT_MODULE_RESET = "EDIT_MODULE_RESET";

export const editModule = (params) => {
  return {
    type: EDIT_MODULE,
    params,
  };
};

export const editModuleSuccess = (response) => {
  return {
    type: EDIT_MODULE_SUCCESS,
    response,
  };
};

export const editModuleFail = (response) => {
  return {
    type: EDIT_MODULE_FAIL,
    response,
  };
};

export const editModuleReset = () => {
  return {
    type: EDIT_MODULE_RESET,
  };
};

export const editModuleApi = (data) => {
  return (dispatch) => {
    dispatch(editModule());
    axios
      .post(`${config.api.base_url}/modules/${data.id}`, data, {
        headers: {
          Authorization: "Bearer " + getUserToken(),
        },
      })
      .then((response) => {
        const editModuleData = response.data;
        dispatch(editModuleSuccess(editModuleData));
      })
      .catch((error) => {
        const errorMsg = error.message;
        dispatch(editModuleFail(errorMsg));
      });
  };
};
