import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import {
  sendPasswordResetEmailApi,
  sendPasswordResetEmailReset,
} from '../../actions/sendPasswordResetEmailAction';
import { handleInputChange } from '../../utils/formFunctions';
import styled from 'styled-components';
import { notification, Form, Input, Button as AntButton } from 'antd';

const Forgot = (props) => {
  const {
    sendPasswordResetEmail,
    sendPasswordResetEmailReset,
    sendPasswordResetEmailState,
  } = props;
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (sendPasswordResetEmailState.apiState === 'success') {
      localStorage.setItem('email', formData.email);
      sendPasswordResetEmailReset();
      navigate('/verify-otp');
    } else if (sendPasswordResetEmailState.apiState === 'error') {
      notification.error({
        message: sendPasswordResetEmailState.message,
      });
      sendPasswordResetEmailReset();
    }
  }, [sendPasswordResetEmailState]);

  return (
    <LoginContainer>
      <LoginWrapper>
        <BackgroundImage
          loading='lazy'
          src='https://cdn.builder.io/api/v1/image/assets/TEMP/27fb07fc02cff20cc788735d53684dd469e0f9b6e67090771ba6c797883cc595?placeholderIfAbsent=true&apiKey=ac9769c5e7eb416e8a74975078a910d6'
          alt='Background'
        />
        <LoginForm
          form={form}
          name='login'
          initialValues={{ remember: true }}
          onFinish={() => sendPasswordResetEmail(formData)}>
          <LoginContent>
            <LoginHeader>Forgot password</LoginHeader>
            <LoginFormWrapper>
              <LoginFormContent>
                {/* <LoginOptions>
                  <Divider />
                  <OrSignInText> Enter the email address associated with your account and we'll send you a link to reset your password</OrSignInText>
                  <Divider />
                </LoginOptions> */}
                <InputFieldsWrapper className='mb-4 pb-4'>
                  <InputField>
                    <Label>Email*</Label>
                    <StyledFormItem
                      name='email'
                      rules={[
                        { required: true, message: 'Please input your Email!' },
                        {
                          type: 'email',
                          message: 'The input is not valid E-mail!',
                        },
                      ]}>
                      <StyledInput
                        name='email'
                        onChange={(e) =>
                          handleInputChange(e, formData, setFormData)
                        }
                        aria-label='Email'
                      />
                    </StyledFormItem>
                  </InputField>
                </InputFieldsWrapper>
                {loading ? (
                  <SignInButton loading={loading} type='primary'>
                    Sending OTP...
                  </SignInButton>
                ) : (
                  <SignInButton type='primary' htmlType='submit'>
                    Send OTP
                  </SignInButton>
                )}
              </LoginFormContent>
            </LoginFormWrapper>
          </LoginContent>
        </LoginForm>
      </LoginWrapper>
    </LoginContainer>
  );
};

const LoginContainer = styled.main`
  background-color: #fff;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  min-height: 100vh;
`;

const LoginWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 100vh;
  align-items: flex-start;
  justify-content: center;
  @media (max-width: 991px) {
    max-width: 100%;
  }
  @media (max-width: 850px) {
    align-items: center;
  }
`;

const BackgroundImage = styled.img`
  position: absolute;
  inset: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
  @media (max-width: 850px) {
    display: none;
  }
`;

const LoginForm = styled(Form)`
  position: relative;
  background-color: #fff;
  display: flex;
  width: 50%;
  min-height: 100vh;
  max-width: 100%;
  flex-direction: column;
  overflow: hidden;
  padding: 40px 54px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  @media (max-width: 991px) {
    padding: 30px;
  }
  @media (max-width: 850px) {
    width: 100%;
    margin: auto;
    box-shadow: none;
  }
`;

const Logo = styled.h1`
  color: #275df5;
  align-self: flex-start;
  font: 600 22px/40px 'Inter', sans-serif;
  margin-bottom: 40px;
`;

const LoginContent = styled.div`
  display: flex;
  width: 100%;
  max-width: 410px;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
`;

const LoginHeader = styled.h2`
  width: 100%;
  color: #141c24;
  text-align: center;
  font: 600 28px/1.2 'Inter', sans-serif;
  margin-bottom: 36px;
`;

const LoginFormWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
`;

const LoginFormContent = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const LoginOptions = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  gap: 16px;
  color: #637083;
  text-align: center;
  justify-content: center;
  font: 400 14px 'Inter', sans-serif;
  margin-bottom: 32px;
`;

const Divider = styled.div`
  height: 1px;
  flex: 1;
  background-color: #e4e7ec;
`;

const OrSignInText = styled.span`
  white-space: nowrap;
`;

const InputFieldsWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 16px;
`;

const InputField = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const Label = styled.label`
  color: #253142;
  font: 500 14px/1.5 'Inter', sans-serif;
  margin-bottom: 6px;
`;

const StyledFormItem = styled(Form.Item)`
  margin-bottom: 0;
`;

const StyledInput = styled(Input)`
  border-radius: 8px;
  background-color: #fff;
  width: 100%;
  padding: 12px;
  border: 1px solid #ced2da;
  font: 400 16px/1.5 'Inter', sans-serif;
  &:focus {
    border-color: #275df5;
    box-shadow: 0 0 0 2px rgba(39, 93, 245, 0.2);
  }
`;

const SignInButton = styled(AntButton)`
  width: 100%;
  height: auto;
  padding: 12px;
  font: 600 16px/1.5 'Inter', sans-serif;
  border-radius: 8px;
  margin-bottom: 16px;
  &:hover,
  &:focus {
    opacity: 0.9;
  }
`;

const mapStateToProps = (state) => ({
  sendPasswordResetEmailState: state.sendPasswordResetEmail,
});

const mapDispatchToProps = (dispatch) => ({
  sendPasswordResetEmail: (params) =>
    dispatch(sendPasswordResetEmailApi(params)),
  sendPasswordResetEmailReset: () => dispatch(sendPasswordResetEmailReset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Forgot);
