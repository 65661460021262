import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Row,
  Col,
  Input,
  Form,
  Select,
  Button,
  Upload,
  Card,
  notification,
  Result,
} from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { SubmitBtn, SubmitNoBgBtn } from '../../styles/Button';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import config from '../../config';

// API Action
import {
  addEmployerApi,
  addEmployerReset,
} from '../../actions/addEmployerAction';
import {
  getEmployerByIdApi,
  getEmployerByIdReset,
} from '../../actions/getEmployerByIdAction';
import {
  updateEmployerApi,
  updateEmployerReset,
} from '../../actions/updateEmployerAction';
import { getIndustriesApi } from '../../actions/getIndustriesAction';

import { v4 as uuidv4 } from 'uuid';

import { legalEntityList } from '../../utils/constants';
import {
  validateMobileNumber,
  filterByLabel,
  handleKeyDown,
} from '../../utils/formFunctions';
import moment from 'moment';
import {
  checkModulePermissions,
  getFileNameFromDocPath,
  getSignedUrlForGetObject,
  getUserToken,
  hasPermission,
} from '../../utils/helper';
import { XCardsTransparent, XContainer } from '../../styles/GlobalStyle';
import { Title } from '../Login/LoginStyle';
import { getCountriesApi } from '../../actions/getCountriesAction';
import { getStatesApi } from '../../actions/getStatesAction';

const { Dragger } = Upload;
const { TextArea } = Input;
const { Option } = Select;

const AddEditEmployers = (props) => {
  let modulePermissions = checkModulePermissions('employers');

  const {
    addEmployer,
    addEmployerState,
    addEmployerReset,
    addEmployerResetState,
    getEmployerById,
    getEmployerByIdReset,
    getEmployerByIdResetState,
    getEmployerByIdState,
    updateEmployer,
    updateEmployerState,
    updateEmployerReset,
    updateEmployerResetState,
    getIndustriesState,
    getIndustries,
    getStatesState,
    getCountriesState,
    getStates,
    getCountries,
  } = props;

  const id = useParams().id || '';
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const formInitial = {
    id: '',
    organization_name: '',
    organization_address: '',
    pincode: '',
    country: '',
    city: '',
    state: '',
    legal_entity: '',
    industry: '',
    fixed_value: null,
    percentage_value: null,
    percentage_duration: null,
    cin_number: '',
    gstin_number: '',
    pan_number: '',
    msme_registration_number: null,
    agreement_document: '',
    logo: '',
    is_active: true,
  };
  const [formData, setFormData] = useState(formInitial);
  const [industryOptions, setIndustryOptions] = useState([]);
  const [title, setTitle] = useState('Add Employer');
  const [documentsToUpload, setDocumentsToUpload] = useState([]);
  const [triggerUpdate, setTriggerUpdate] = useState(false);
  const [uploadedDocuments, setUploadedDocuments] = useState(null);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [industry, setIndustry] = useState([]);
  const [selectedIndustry, setSelectedIndustry] = useState([]);
  const [loading, setLoading] = useState(false);
  const [uploadedDocumentsToS3, setUploadedDocumentsToS3] = useState({});
  const [locationDetails, setLocationDetails] = useState({
    country: null,
    state: null,
    city: null,
  });
  const [cities, setCities] = useState([]); // To store cities of the selected state

  useEffect(() => {
    getIndustries({});
    getCountries({});
    if (id) {
      if (hasPermission('employers', 'view-employers')) {
        setUploadedDocuments([{ key: 'logo' }, { key: 'agreement_document' }]);
        getEmployerById({ id: id });
      }
    } else {
      setDataLoaded(true);
      form.setFieldsValue(formData);
    }
  }, [id]);

  useEffect(() => {
    if (getEmployerByIdState.apiState === 'success' && id) {
      setFormData({
        ...getEmployerByIdState.data,
        pincode: getEmployerByIdState.data?.pincode.toString(),
        _id: getEmployerByIdState.data._id,
      });
      form.setFieldsValue({
        ...getEmployerByIdState.data,
      });
      setTitle(`${getEmployerByIdState.data.organization_name}`);
      setAllUploadedDocuments(getEmployerByIdState.data);
    } else if (getEmployerByIdState.apiState === 'error') {
      console.log('API Error=>', getEmployerByIdState.message);
      notification.error({
        message: 'Unable to get Employer',
      });
    }
    getEmployerByIdReset();
  }, [getEmployerByIdState.apiState]);

  useEffect(() => {
    if (getCountriesState.apiState === 'success') {
      if (getCountriesState.data) {
        // Format the API data
        const formattedCountryData = getCountriesState.data.map((country) => ({
          value: country.country,
          label: country.country,
          type: 'country',
          id: country.country_abbreviation,
        }));

        setLocationDetails({
          ...locationDetails,
          country: formattedCountryData,
        });
      }
    }
  }, [getCountriesState.apiState]);

  useEffect(() => {
    if (formData.country_code) {
      getStates({ country: formData.country_code });
    }
  }, [formData.country_code]);

  useEffect(() => {
    if (getStatesState.apiState === 'success') {
      if (getStatesState.data) {
        // Format the API data for states
        const formattedStateData = getStatesState.data.all_states.map(
          (state) => ({
            value: state.state,
            label: state.state,
            type: 'state',
            cities: state.cities, // Include cities for later use
          })
        );

        setLocationDetails((prevState) => ({
          ...prevState,
          state: formattedStateData,
        }));
      }
    }
  }, [getStatesState.apiState]);

  useEffect(() => {
    if (addEmployerState.apiState === 'success') {
      addEmployerReset();
      setTriggerUpdate(false);
      navigate('/employers');
    } else if (addEmployerState.apiState === 'error') {
      setTriggerUpdate(false);
      let form_errors = addEmployerState.error;
      if (form_errors && typeof form_errors === 'object') {
        form.setFields(
          Object.keys(form_errors).map((key) => {
            return {
              name: key,
              errors: [
                form_errors[key]
                  .replace('`', '')
                  .replace('`', '')
                  .replace('Path', '')
                  .replace('_', ' '),
              ],
            };
          })
        );
      } else {
        console.error(
          'Field errors are not available or not an object',
          form_errors
        );
      }

      notification.error({
        message: 'Unable to Add Employer',
      });
      addEmployerReset();
    }

    setTriggerUpdate(false);
  }, [addEmployerState.apiState]);

  useEffect(() => {
    if (updateEmployerState.apiState === 'success') {
      updateEmployerReset();
      setLoading(false);
      setTriggerUpdate(false);

      navigate('/employers');
    } else if (updateEmployerState.apiState === 'error') {
      setTriggerUpdate(false);

      let form_errors = updateEmployerState.error;

      if (form_errors && typeof form_errors === 'object') {
        form.setFields(
          Object.keys(form_errors).map((key) => {
            return {
              name: key,
              errors: [
                form_errors[key]
                  .replace('`', '')
                  .replace('`', '')
                  .replace('Path', '')
                  .replace('_', ' '),
              ],
            };
          })
        );
      } else {
        console.error(
          'Field errors are not available or not an object',
          form_errors
        );
      }

      notification.error({
        message: 'Unable to Update Employer',
      });
      updateEmployerReset();
    }
    if (updateEmployerState.apiState === 'loading') {
      setLoading(true);
    }
    setTriggerUpdate(false);
  }, [updateEmployerState.apiState]);

  useEffect(() => {
    if (getIndustriesState.apiState === 'success') {
      setIndustry(getIndustriesState.data.all_industries);
      if (getIndustriesState.data.all_industries.length > 0) {
        const industries = industry.map((ind) => {
          return {
            value: ind._id,
            label: ind.industry_name,
            type: 'industry',
          };
        });
        setIndustryOptions(industries);
      }
    }
  }, [getIndustriesState.apiState, industry]);

  // functions

  const handleStateChange = (selectedState) => {
    setFormData((prev) => ({
      ...prev,
      state: selectedState,
    }));

    // Find the selected state from the formatted state data
    const selectedStateData = locationDetails.state.find(
      (state) => state.value === selectedState
    );

    // Set the cities of the selected state
    if (selectedStateData) {
      setCities(
        selectedStateData.cities.map((city) => ({
          value: city,
          label: city,
          type: 'city',
        }))
      );
    }
  };
  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const getDocumentObject = async (document_path, folder_name) => {
    let url = await getSignedUrlForGetObject(
      'employers',
      document_path,
      folder_name
    );
    let file_name = getFileNameFromDocPath(document_path);
    return {
      name: file_name,
      url: url,
      status: 'done',
    };
  };

  const setAllUploadedDocuments = async (data) => {
    const documentPromises = uploadedDocuments.map(async (document) => {
      try {
        const document_path = data[document.key];
        const document_object = await getDocumentObject(
          document_path,
          data.s3_folder_name
        );
        document.name = document_object.name;
        document.url = document_object.url;
        document.status = document_object.status;
        return document;
      } catch (error) {
        console.log('Try and catch error => ', error.message);
        return error;
      }
    });
    await Promise.all(documentPromises);
    setDataLoaded(true);
  };

  const uploadFiles = async (currentId) => {
    // keep document paths in an object
    let documentFields = {};
    let allUploadsSuccessful = true; // Track the success status of all uploads
    const s3FolderName = formData.s3_folder_name
      ? formData.s3_folder_name
      : uuidv4();

    // Create an array of promises
    if (documentsToUpload.length > 0) {
      await Promise.all(
        documentsToUpload.map(async (document) => {
          const { fieldName, file, onError, onSuccess, onProgress, fileName } =
            document;
          // Skip the document if it's already uploaded
          if (uploadedDocumentsToS3[fieldName]) {
            return;
          }
          let folder_name = `employers`;
          let file_name = fileName;
          let get_ext = file.name.split('.');
          let ext = get_ext[get_ext.length - 1];

          try {
            const res = await axios.get(
              `${config.api.base_url}/settings/get-signed-url?action=putObject&folder_name=${folder_name}&file_name=${file_name}&ext=${ext}&id=${s3FolderName}`,
              {
                headers: {
                  'Content-type': 'application/json; charset=UTF-8',
                  Authorization: 'Bearer ' + getUserToken(),
                },
              }
            );

            const result = await axios.put(res.data.data, file, {
              onUploadProgress: ({ total, loaded }) => {
                onProgress(
                  {
                    percent: Math.round((loaded / total) * 100).toFixed(0),
                  },
                  file
                );
              },
            });

            if (result.status === 200) {
              let documentPath = `/${folder_name}/${s3FolderName}/${file_name}.${ext}`;
              // update document paths
              documentFields = {
                ...documentFields,
                [fieldName]: documentPath,
              };

              setFormData((prev) => ({
                ...prev,
                [fieldName]: documentPath,
              }));
              onSuccess(result, file);

              setUploadedDocumentsToS3((prevState) => ({
                ...prevState,
                [fieldName]: documentPath,
              }));
            } else {
              allUploadsSuccessful = false;
            }
          } catch (error) {
            console.log('Try and catch error => ', error.message);
            notification.error({
              message: `Unable to upload file - ${fieldName}`,
            });
            allUploadsSuccessful = false;
          }
        })
      );
      allUploadsSuccessful = true;
    }

    // // set document fields, id in form data and trigger upload
    if (allUploadsSuccessful) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        ...documentFields,
        s3_folder_name: s3FolderName,
      }));

      setTriggerUpdate(true);
      allUploadsSuccessful = false;
    }
  };

  useEffect(() => {
    if (triggerUpdate) {
      if (id) {
        updateEmployer({ ...formData, id });
      } else {
        setTriggerUpdate(false);
        addEmployer(formData);
      }
    }
    setTriggerUpdate(false);
  }, [triggerUpdate]);

  const handleIndustriesSearch = (value) => {
    if (value.length > 3)
      getIndustries({
        search: value,
      });
    else if (value.length === 0) getIndustries({});
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSelectChange = (value, name) => {
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleIndustySelect = (e, value) => {
    setSelectedIndustry([value.value]);
  };

  return (
    <>
      {hasPermission('employers', 'add-employers') ||
      hasPermission('employers', 'edit-employers') ? (
        <>
          <XContainer fluid className='mt-3'>
            <Card className='mb-3'>
              <Row>
                <Col xs={24} className='d-flex gap-3'>
                  <Button
                    onClick={() => navigate(-1)}
                    icon={<ArrowLeftOutlined />}
                    size='large'
                  />
                  {id ? (
                    <Title>Edit Employer</Title>
                  ) : (
                    <Title>Add Employer</Title>
                  )}
                </Col>
              </Row>
            </Card>
            <XCardsTransparent>
              <div className='mainContent'>
                <div className='pageWrapper'>
                  {dataLoaded && (
                    <Form
                      form={form}
                      layout='vertical'
                      onFinish={uploadFiles}
                      initialValues={formData}>
                      <Row gutter={[30, 0]}>
                        <Col xs={24} sm={24}>
                          <Form.Item
                            label='Organization Name'
                            name='organization_name'
                            className='formLable'
                            rules={[
                              {
                                required: true,
                                message: 'Please enter organization name',
                              },
                            ]}>
                            <Input
                              onKeyDown={handleKeyDown}
                              name='organization_name'
                              className='searchInputBrder removeBg'
                              size='large'
                              onChange={handleInputChange}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={12}>
                          <Form.Item
                            label='Mobile Number'
                            name='mobile_number'
                            className='formLable'
                            rules={[
                              {
                                required: true,
                                message: 'Please enter mobile number',
                              },
                              {
                                validator: validateMobileNumber,
                              },
                            ]}>
                            <Input
                              onKeyDown={handleKeyDown}
                              name='mobile_number'
                              className='searchInputBrder removeBg'
                              size='large'
                              placeholder='Enter Mobile Number'
                              onChange={handleInputChange}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={12}>
                          <Form.Item
                            label='Email ID'
                            name='email_address'
                            className='formLable'
                            rules={[
                              {
                                required: true,
                                message: 'Please enter email adress',
                              },
                              {
                                type: 'email',
                                message: 'Please enter a valid email address',
                              },
                            ]}>
                            <Input
                              onKeyDown={handleKeyDown}
                              name='email_address'
                              className='searchInputBrder removeBg'
                              size='large'
                              placeholder='Enter Email ID'
                              onChange={handleInputChange}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                          <Form.Item
                            label='Country'
                            className='formLable'
                            name={'country'}
                            rules={[
                              {
                                required: true,
                                message: 'please input country!',
                              },
                            ]}>
                            <Select
                              size='large'
                              placeholder='Select Country'
                              className='removeBg'
                              onChange={(e, option) => {
                                handleSelectChange(e, 'country');
                                setFormData((prev) => ({
                                  ...prev,
                                  country_code: option.id,
                                }));
                              }}
                              value={
                                formData.country
                                  ? formData.country
                                  : 'Select Country'
                              }
                              options={locationDetails.country}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                          <Form.Item
                            label='State'
                            className='formLable'
                            name={'state'}
                            rules={[
                              {
                                required: true,
                                message: 'please input state!',
                              },
                            ]}>
                            <Select
                              size='large'
                              placeholder='Select State'
                              className='removeBg'
                              // onChange={handleSelectChange}
                              onChange={handleStateChange}
                              value={
                                formData.state ? formData.state : 'Select State'
                              }
                              options={locationDetails.state}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                          <Form.Item
                            label='City'
                            className='formLable'
                            name={'city'}
                            rules={[
                              {
                                required: true,
                                message: 'please input city!',
                              },
                            ]}>
                            <Select
                              size='large'
                              placeholder='Select City'
                              className='removeBg'
                              onChange={(e) => handleSelectChange(e, 'city')}
                              value={
                                formData.city ? formData.city : 'Select City'
                              }
                              options={cities}
                            />
                          </Form.Item>
                        </Col>{' '}
                        <Col xs={24} md={12}>
                          <Form.Item
                            label='Pincode'
                            name='pincode'
                            className='formLable'
                            rules={[
                              {
                                required: true,
                                message: 'Please enter pincode',
                              },
                            ]}>
                            <Input
                              onKeyDown={handleKeyDown}
                              name='pincode'
                              className='searchInputBrder removeBg'
                              size='large'
                              placeholder='Enter Pincode'
                              onChange={(e) => {
                                handleInputChange(e, 'pincode');
                              }}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24}>
                          <Form.Item
                            label='Organization Address'
                            name='organization_address'
                            className='formLable'
                            rules={[
                              {
                                required: true,
                                message: 'Please enter organization address',
                              },
                            ]}>
                            <TextArea
                              onKeyDown={handleKeyDown}
                              name='organization_address'
                              className='removeBg'
                              placeholder='Enter organization address'
                              rows={3}
                              onChange={handleInputChange}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={12}>
                          <Form.Item
                            name='industry'
                            label='Industry'
                            className='formLable'
                            validateFirst={true}
                            validateTrigger='onChange'
                            rules={[
                              {
                                required: true,
                                message: 'Please select industry',
                              },
                            ]}>
                            <Select
                              showSearch
                              size='large'
                              className='removeBg'
                              onChange={(value, options) =>
                                handleSelectChange(value, 'industry')
                              }
                              filterOption={filterByLabel}
                              onSelect={handleIndustySelect}
                              onSearch={handleIndustriesSearch}
                              value={
                                formData.industry
                                  ? formData.industry
                                  : 'Select Industry'
                              }
                              options={industryOptions}
                              placeholder='Select Industry'
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={12}>
                          <Form.Item
                            label='Legal Entity'
                            name='legal_entity'
                            className='formLable'
                            rules={[
                              {
                                required: true,
                                message: 'Please enter Legal Entity',
                              },
                            ]}>
                            <Select
                              name='legal_entity'
                              size='large'
                              className='removeBg'
                              onChange={(value, options) =>
                                handleSelectChange(value, 'legal_entity')
                              }>
                              {legalEntityList.map((legal_entity) => {
                                return (
                                  <Option
                                    key={legal_entity.id}
                                    value={legal_entity.id}>
                                    {legal_entity.name}
                                  </Option>
                                );
                              })}
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={[30, 0]}>
                        <Col xs={24} sm={12}>
                          <Form.Item
                            label='MSME Registration Number'
                            name='msme_registration_number'
                            className='formLable'>
                            <Input
                              onKeyDown={handleKeyDown}
                              name='msme_registration_number'
                              className='searchInputBrder removeBg'
                              size='large'
                              placeholder='Enter MSME Registration Number'
                              onChange={handleInputChange}
                              onInput={(e) =>
                                (e.target.value = e.target.value
                                  .toUpperCase()
                                  .replace(/\s/g, ''))
                              }
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={12}>
                          <Form.Item
                            label='PAN Number'
                            name='pan_number'
                            className='formLable'
                            rules={[
                              {
                                required: true,
                                message: 'Please enter pan number',
                              },
                              {
                                pattern: /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/,
                                message: 'PAN number is invalid',
                              },
                            ]}>
                            <Input
                              onKeyDown={handleKeyDown}
                              name='pan_number'
                              className='searchInputBrder removeBg'
                              size='large'
                              placeholder='Enter PAN Number'
                              onChange={handleInputChange}
                              onInput={(e) =>
                                (e.target.value = e.target.value
                                  .toUpperCase()
                                  .replace(/\s/g, ''))
                              }
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={12}>
                          <Form.Item
                            label='CIN'
                            name='cin_number'
                            className='formLable'
                            rules={[
                              {
                                required: true,
                                message: 'Please enter cin',
                              },
                              {
                                pattern:
                                  /^([LUu]{1})([0-9]{5})([A-Za-z]{2})([0-9]{4})([A-Za-z]{3})([0-9]{6})$/,
                                message: 'CIN number is invalid',
                              },
                            ]}>
                            <Input
                              onKeyDown={handleKeyDown}
                              name='cin_number'
                              className='searchInputBrder removeBg'
                              size='large'
                              placeholder='Enter CIN'
                              onChange={handleInputChange}
                              onInput={(e) =>
                                (e.target.value = e.target.value
                                  .toUpperCase()
                                  .replace(/\s/g, ''))
                              }
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={12}>
                          <Form.Item
                            label='GSTIN'
                            name='gstin_number'
                            className='formLable'
                            rules={[
                              {
                                required: true,
                                message: 'Please enter gstin',
                              },
                              {
                                pattern:
                                  /\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/,
                                message: 'GSTIN number is invalid',
                              },
                            ]}>
                            <Input
                              onKeyDown={handleKeyDown}
                              name='gstin_number'
                              className='searchInputBrder removeBg'
                              size='large'
                              placeholder='Enter GSTIN'
                              onChange={handleInputChange}
                              onInput={(e) =>
                                (e.target.value = e.target.value
                                  .toUpperCase()
                                  .replace(/\s/g, ''))
                              }
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24}>
                          <Form.Item
                            label='Logo'
                            name='logo'
                            className='formLable'
                            accept='.jpg,.jpeg,.png,.svg'
                            getValueFromEvent={normFile}
                            rules={[
                              {
                                required: true,
                                message: 'Please upload agreement',
                              },
                            ]}>
                            <Dragger
                              maxCount={1}
                              onRemove={(file) => {
                                const updatedDocuments =
                                  documentsToUpload.filter(
                                    (item) => item.fieldName !== 'logo'
                                  );
                                setDocumentsToUpload(updatedDocuments);
                              }}
                              customRequest={(options) => {
                                const existingIndex =
                                  documentsToUpload.findIndex(
                                    (item) => item.fieldName === 'logo'
                                  );

                                if (existingIndex !== -1) {
                                  // Update existing object
                                  const updatedDocuments = [
                                    ...documentsToUpload,
                                  ];
                                  updatedDocuments[existingIndex] = {
                                    ...options,
                                    fileName: 'Logo',
                                    fieldName: 'logo',
                                  };
                                  setDocumentsToUpload(updatedDocuments);
                                } else {
                                  // Add new object
                                  setDocumentsToUpload([
                                    ...documentsToUpload,
                                    {
                                      ...options,
                                      fileName: 'Logo',
                                      fieldName: 'logo',
                                    },
                                  ]);
                                }

                                options.onSuccess();
                              }}
                              defaultFileList={
                                id
                                  ? uploadedDocuments.find(
                                      (doc) => doc.key === 'logo'
                                    ).name
                                    ? [
                                        uploadedDocuments.find(
                                          (doc) => doc.key === 'logo'
                                        ),
                                      ]
                                    : false
                                  : false
                              }>
                              <p className='ant-upload-drag-icon'>
                                <img src='/images/upload-icon.svg' alt='' />
                              </p>
                              <div className='uploadHeading'>
                                Drag & drop files or <span>Browse</span>
                              </div>
                              <p className='para3'>
                                Supported formates: JPEG, PNG, JPG, SVG
                              </p>
                            </Dragger>
                          </Form.Item>
                        </Col>
                        <Col xs={24}>
                          <Form.Item
                            label='Agreement'
                            name='agreement_document'
                            className='formLable'
                            getValueFromEvent={normFile}
                            accept='.pdf,.doc,.docx'
                            rules={[
                              {
                                required: true,
                                message: 'Please upload agreement',
                              },
                            ]}>
                            <Dragger
                              maxCount={1}
                              onRemove={(file) => {
                                const updatedDocuments =
                                  documentsToUpload.filter(
                                    (item) =>
                                      item.fieldName !== 'agreement_document'
                                  );
                                setDocumentsToUpload(updatedDocuments);
                              }}
                              customRequest={(options) => {
                                const existingIndex =
                                  documentsToUpload.findIndex(
                                    (item) =>
                                      item.fieldName === 'agreement_document'
                                  );

                                if (existingIndex !== -1) {
                                  // Update existing object
                                  const updatedDocuments = [
                                    ...documentsToUpload,
                                  ];
                                  updatedDocuments[existingIndex] = {
                                    ...options,
                                    fileName: 'Agreement',
                                    fieldName: 'agreement_document',
                                  };
                                  setDocumentsToUpload(updatedDocuments);
                                } else {
                                  // Add new object
                                  setDocumentsToUpload([
                                    ...documentsToUpload,
                                    {
                                      ...options,
                                      fileName: 'Agreement',
                                      fieldName: 'agreement_document',
                                    },
                                  ]);
                                }

                                options.onSuccess();
                              }}
                              defaultFileList={
                                id
                                  ? uploadedDocuments.find(
                                      (doc) => doc.key === 'agreement_document'
                                    ).name
                                    ? [
                                        uploadedDocuments.find(
                                          (doc) =>
                                            doc.key === 'agreement_document'
                                        ),
                                      ]
                                    : false
                                  : false
                              }>
                              <p className='ant-upload-drag-icon'>
                                <img src='/images/upload-icon.svg' alt='' />
                              </p>
                              <div className='uploadHeading'>
                                Drag & drop files or <span>Browse</span>
                              </div>
                              <p className='para3'>
                                Supported formates: PDF, Word
                              </p>
                            </Dragger>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row>
                        <Col
                          xs={24}
                          sm={24}
                          align='right'
                          className='d-flex justify-content-end align-items-center'>
                          <SubmitNoBgBtn className='mr-3' type='primary'>
                            Cancel
                          </SubmitNoBgBtn>
                          {loading ? (
                            <SubmitBtn
                              type='primary'
                              className='ms-2'
                              // htmlType="submit"
                            >
                              {id ? 'Saving Employer...' : 'Adding Employer...'}
                            </SubmitBtn>
                          ) : (
                            <SubmitBtn
                              type='primary'
                              htmlType='submit'
                              className='ms-2'>
                              {id ? 'Save Employer' : 'Add Employer'}
                            </SubmitBtn>
                          )}
                        </Col>
                      </Row>
                    </Form>
                  )}
                </div>
              </div>
            </XCardsTransparent>
          </XContainer>
        </>
      ) : (
        <Result
          status='404'
          title='404'
          subTitle='Sorry, the page you visited does not exist.'
          extra={
            <Button
              type='primary'
              onClick={() => {
                navigate('/');
              }}>
              Back Home
            </Button>
          }
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  addEmployerState: state.addEmployer,
  addEmployerResetState: state.addEmployerReset,
  getEmployerByIdState: state.getEmployerById,
  getEmployerByIdResetState: state.getEmployerByIdReset,
  updateEmployerState: state.updateEmployer,
  updateEmployerResetState: state.updateEmployerReset,
  getIndustriesState: state.getIndustries,
  getStatesState: state.getStates,
  getCountriesState: state.getCountries,
});

const mapDispatchToProps = (dispatch) => ({
  addEmployer: (params) => dispatch(addEmployerApi(params)),
  addEmployerReset: (params) => dispatch(addEmployerReset(params)),
  getEmployerById: (params) => dispatch(getEmployerByIdApi(params)),
  getEmployerByIdReset: (params) => dispatch(getEmployerByIdReset(params)),
  updateEmployer: (params) => dispatch(updateEmployerApi(params)),
  updateEmployerReset: (params) => dispatch(updateEmployerReset(params)),
  getIndustries: (params) => dispatch(getIndustriesApi(params)),
  getStates: (params) => dispatch(getStatesApi(params)),
  getCountries: (params) => dispatch(getCountriesApi(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddEditEmployers);
